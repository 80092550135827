<div id="patient-summary-sec">
    <div class="container">

        <div class="row new-designed-card mt-4">
            <div class="col-lg-9 col-md-9 col-sm-9 patient-info-sec">
                <h5 class="full-name">{{patientDetails?.lastName}}, {{patientDetails?.firstName}}
                    <span class="gender-age" *ngIf="patientDetails?.gender">{{patientDetails?.gender}}</span>
                    <span class="gender-age"
                        *ngIf="patientDetails?.usrDateOfBirth">{{ageCalculate(patientDetails?.usrDateOfBirth)}}</span>
                </h5>
                <span *ngIf="patientDetails?.primaryPhone"><i
                        class="las la-phone"></i>&nbsp;{{patientDetails?.primaryPhone}}</span>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="farm-actions d-flex">
                    <div class="actions notes" (click)="signout()" id="viewPatientDetails_signout"><i class="las la-sign-out-alt"></i> &nbsp; Sign-Out
                    </div>
                    <!-- <div class="actions add-appointment" (click)="addAppointment()" placement="bottom" ngbTooltip="New Appointment"></div> -->
                    <!-- <div class="actions rx-lab" (click)="newRaxLabOrder()" placement="bottom" ngbTooltip="Add Rx/Lab Order"><img src="../../../../../assets/images/flowboardIcons/farmSearch/ic_rx.svg"/></div> -->
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 mt-2">
                <nav class="navbar navbar-expand-lg navbar-light bg-light mobile-navbar">
                    <div class="container-fluid">
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                                <li class="nav-item" (click)="menuNavigation(0)" id="viewPatientDetails_demographics">
                                    <a class="nav-link" [ngClass]="{'active': selectedItem == 0}"
                                        aria-current="page">Demographics</a>
                                </li>
                                <li class="nav-item" (click)="menuNavigation(1)" id="viewPatientDetails_Insdetails">
                                    <a class="nav-link" [ngClass]="{'active': selectedItem == 1}">Insurance Details</a>
                                </li>
                                <li class="nav-item" (click)="menuNavigation(2)" id="viewPatientDetails_emergencyContact">
                                    <a class="nav-link" [ngClass]="{'active': selectedItem == 2}">Emergency Contact
                                        Details</a>
                                </li>
                                <li class="nav-item" (click)="menuNavigation(3)" id="viewPatientDetails_prefferedPharmacy">
                                    <a class="nav-link" [ngClass]="{'active': selectedItem == 3}">Preferred Pharmacy</a>
                                </li>
                                <li class="nav-item" (click)="menuNavigation(4)" id="viewPatientDetails_iddoc">
                                    <a class="nav-link" [ngClass]="{'active': selectedItem == 4}">ID Documents</a>
                                </li>
                                <!-- <li class="nav-item" (click)="menuNavigation(5)">
                                    <a class="nav-link" [ngClass]="{'active': selectedItem == 5}">Care Journey</a>
                                </li> -->
                                <li class="nav-item" (click)="menuNavigation(5)" id="viewPatientDetails_carejourney">
                                    <a class="nav-link" [ngClass]="{'active': selectedItem == 5}">Care Journey</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>

                <aw-wizard #detailsWizard class="arc-wizard new-designed-left-arcs" navBarLocation="left"
                    [awNavigationMode]="navigationMode" navigateBackward="allow" navigateForward="allow">
                    <aw-wizard-step stepTitle="Demographics" [awOptionalStep]="navgationVal" [canExit]="navgationVal">
                        <h5>Demographics</h5>
                        <hr />
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="" style="width: 50%;">
                                        <label class="labelStyle"> First Name </label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="" style="width: 50%;">
                                        <label> {{ patientDetails?.firstName || '--'}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="" style="width: 50%;">
                                        <label class="labelStyle"> Last Name </label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="" style="width: 50%;">
                                        <label> {{ patientDetails?.lastName || '--'}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="" style="width: 50%;">
                                        <label class="labelStyle"> Date of Birth </label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="" style="width: 50%;">
                                        <label> {{ patientDetails?.usrDateOfBirth || '--'}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="" style="width: 50%;">
                                        <label class="labelStyle"> Gender </label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="" style="width: 50%;">
                                        <label> {{ patientDetails?.gender || '--'}}</label>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="" style="width: 50%;">
                                        <label class="labelStyle"> Age </label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="" style="width: 50%;">
                                        <label>{{patientDetails?.age}}</label>
                                    </div>
                                </div>
                            </div> -->

                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="" style="width: 50%;">
                                        <label class="labelStyle"> Marital Status </label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="" style="width: 50%;">
                                        <label>{{patientDetails?.maritalStatus || '--'}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="" style="width: 50%;">
                                        <label class="labelStyle">Race</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="" style="width: 50%;">
                                        <label>{{patientDetails?.race || '--'}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="" style="width: 50%;">
                                        <label class="labelStyle">Ethnicity</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="" style="width: 50%;">
                                        <label>{{patientDetails?.ethnicity || '--'}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 mt-3">
                                <h6>Address and Contact</h6>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="" style="width: 50%;">
                                        <label class="labelStyle">Street</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="" style="width: 50%;">
                                        <label>{{patientDetails?.address?.street || '--'}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="" style="width: 50%;">
                                        <label class="labelStyle">City</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="" style="width: 50%;">
                                        <label>{{patientDetails?.address?.city || '--'}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="" style="width: 50%;">
                                        <label class="labelStyle">State</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="" style="width: 50%;">
                                        <label>{{patientDetails?.address?.state || '--'}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="" style="width: 50%;">
                                        <label class="labelStyle">ZIP Code</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="" style="width: 50%;">
                                        <label>{{patientDetails?.address?.zipCode || '--'}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="" style="width: 50%;">
                                        <label class="labelStyle">Phone Number</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="" style="width: 50%;">
                                        <label>{{patientDetails?.primaryPhone || '--'}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="" style="width: 50%;">
                                        <label class="labelStyle">Email</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="" style="width: 50%;">
                                        <label>{{patientDetails?.email || '--'}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </aw-wizard-step>
                    <aw-wizard-step stepTitle="Insurance Details" [awOptionalStep]="navgationVal"
                        [canExit]="navgationVal">
                        <!-- Insurance Details -->
                        <h5>Insurance Details</h5>
                        <hr />
                        <!-- <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="" style="width: 50%;">
                                        <label class="labelStyle">Coverage Type</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="" style="width: 50%;">
                                        <label *ngIf="patientDetails?.unInsured === true">Un-Insured</label>
                                        <label *ngIf="patientDetails?.selfPay === true">Self-Pay</label>
                                        <label
                                            *ngIf="patientDetails?.unInsured === false && patientDetails?.selfPay === false">Insurance</label>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <ng-container *ngIf="patientDetails?.insuranceDetails?.length > 0">
                            <div class="row insurance-items"
                                *ngFor="let insurance of patientDetails?.insuranceDetails; let i = index">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <h5 style="float: right;color: #296746;" *ngIf="insurance?.active">Active</h5>
                                    <h5 style="float: right;color: red" *ngIf="!insurance?.active">Inactive</h5>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Insurance Name</label> <span class="label-colon">
                                                :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{insurance?.name || '--'}} </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Member ID / Subscriber ID</label> <span
                                                class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{insurance?.memberId || '--'}} </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Payer ID</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{insurance?.payerId || '--'}} </label>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Group Number</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{insurance?.groupNumber || '--'}} </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Coverage Sequence</label> <span
                                                class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{insurance?.priority || '--'}} </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Insurance Class</label> <span class="label-colon">
                                                :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{insurance?.insuranceClass || '--'}} </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="patientDetails?.insuranceDetails?.length === 0">
                            <h6>Insurance Details are unavailable for this patient</h6>
                        </ng-container>

                    </aw-wizard-step>
                    <aw-wizard-step stepTitle="Emergency Contact Details" [awOptionalStep]="navgationVal"
                        [canExit]="navgationVal">
                        <!-- Emergency Contact Details section -->
                        <h5>Emergency Contact Details</h5>
                        <hr />
                        <div class="row insurance-items" *ngFor="let contact of patientDetails?.emergencyDetails">
                            <div class="col-lg-4 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="" style="width: 50%;">
                                        <label class="labelStyle">Name</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="" style="width: 50%;">
                                        <label> {{contact?.name || '--'}} </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="" style="width: 50%;">
                                        <label class="labelStyle">Relationship</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="" style="width: 50%;">
                                        <label> {{contact?.relationship || '--'}} </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="" style="width: 50%;">
                                        <label class="labelStyle">Emergency Contact</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="" style="width: 50%;">
                                        <label> {{contact?.phone || '--'}} </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-container
                            *ngIf="!patientDetails?.emergencyDetails || patientDetails?.emergencyDetails?.length === 0">
                            <span>Emergency Contact Details are unavailable for this patient.</span>
                        </ng-container>
                    </aw-wizard-step>
                    <!-- Preferred Pharmacy -->
                    <aw-wizard-step stepTitle="Preferred Pharmacy" [awOptionalStep]="navgationVal"
                        [canExit]="navgationVal">
                        <h5>Preferred Pharmacy</h5>
                        <hr />
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="" style="width: 50%;">
                                        <label class="labelStyle">Pharmacy Name</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="" style="width: 50%;">
                                        <label>{{patientDetails?.pharmacy?.name || '--'}} </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="" style="width: 50%;">
                                        <label class="labelStyle">Phone Number</label> <span class="label-colon">
                                            : </span>
                                    </div>
                                    <div class="" style="width: 50%;">
                                        <label>{{patientDetails?.pharmacy?.phoneNumber || '--'}} </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="" style="width: 50%;">
                                        <label class="labelStyle">Street</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="" style="width: 50%;">
                                        <label>{{patientDetails?.pharmacy?.address?.street || '--'}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="" style="width: 50%;">
                                        <label class="labelStyle">City</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="" style="width: 50%;">
                                        <label>{{patientDetails?.pharmacy?.address?.city || '--'}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="" style="width: 50%;">
                                        <label class="labelStyle">State</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="" style="width: 50%;">
                                        <label>{{patientDetails?.pharmacy?.address?.state || '--'}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="" style="width: 50%;">
                                        <label class="labelStyle">ZIP Code</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="" style="width: 50%;">
                                        <label>{{patientDetails?.pharmacy?.address?.zipCode || '--'}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </aw-wizard-step>
                    <aw-wizard-step stepTitle="ID Documents" [awOptionalStep]="navgationVal" [canExit]="navgationVal">
                        <h5>ID Documents</h5>
                        <hr />
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <!-- <ng-container *ngIf="drivingLicenseFile">
                                    
                                    <iframe id="dl-card" frameBorder="0" width="100%" height="500px"></iframe>
                                </ng-container>-->
                                <ng-container *ngIf="drivingLicenseFile && downloadedFileType === 'application/pdf'">
                                    <iframe id="dl-card" frameBorder="0" width="300" height="200px"></iframe>
                                </ng-container>
                                <ng-container *ngIf="drivingLicenseFile && downloadedFileType !== 'application/pdf'">
                                    <img style="padding: 0px 20px; width: 35%;" name="Image Shown" id="dl-card" />
                                </ng-container>


                                <ng-container *ngIf="!drivingLicenseFile">
                                    <h6>Driver's License is not available</h6>
                                </ng-container>
                            </div>
                        </div>
                    </aw-wizard-step>
                    <!-- <aw-wizard-step stepTitle="Appointment History">

                        <h5>Appointment History</h5>
                        <hr />
                        <ng-container *ngFor="let item of allAppntHistory">
                            <div class="row appointmentHistory">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <label class="labelStyle" style="color: #c8c6c6;">Visit Date</label> <span
                                                class="label-colon"> : </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <label>
                                                {{dateFormate(item?.appointment?.dateTime) || '--'}} </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <label class="labelStyle" style="color: #c8c6c6;">Doctor Name</label> <span
                                                class="label-colon"> : </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <label>Dr.&nbsp;{{item?.appointment?.authorized_official_first_name + ' ' +
                                                item?.appointment?.authorized_official_last_name || '--'}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <label class="labelStyle" style="color: #c8c6c6;">Purpose of Visit</label> <span
                                                class="label-colon"> : </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <label>{{item?.appointment?.purposeOfVisit || '--'}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </aw-wizard-step> -->
                    <!-- <aw-wizard-step stepTitle="Care Journey" [awOptionalStep]="navgationVal" [canExit]="navgationVal">
                        <h5>Care Journey</h5>
                        <hr />
                        <ng-container *ngIf="patientDetails?.uniqueUserToken">
                            <app-patient-life-cycle [notValidationRequire]="true"
                                [userUniqueToken]="patientDetails?.uniqueUserToken"></app-patient-life-cycle>
                        </ng-container>
                        <ng-container *ngIf="!patientDetails?.uniqueUserToken">
                            <h5>You don't have Care Journey Events.</h5>
                        </ng-container>
                    </aw-wizard-step> -->
                    <aw-wizard-step stepTitle="Care Journey" [awOptionalStep]="navgationVal" [canExit]="navgationVal">
                        <h5>Care Journey</h5>
                        <hr />
                        <ng-container *ngIf="patientDetails?.uniqueUserToken">
                            <app-care-events [notValidationRequire]="true"
                                [userUniqueToken]="patientDetails?.uniqueUserToken"></app-care-events>
                        </ng-container>
                        <ng-container *ngIf="!patientDetails?.uniqueUserToken">
                            <h5>You don't have Care Journey Events.</h5>
                        </ng-container>
                    </aw-wizard-step>
                </aw-wizard>
            </div>
        </div>
    </div>
    <!-- spinner -->
    <div class="custom-loader justify-content-center" *ngIf="loadSpinner" id="spinnerDisplay">
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>

<!-- Modal -->
<div id="book-appointment-modal" [style.display]="exceptionalModal ? 'block' : 'none'" class="modal" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-body">
                <h5 style="text-align: center;padding: 15px;">{{notifyText}}</h5>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <button type="button" class="btn btn-default ok-book" data-dismiss="modal" id="viewPatientDetails_ok"
                            (click)="exceptionalModal = false">Ok</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>