<!-- <p [routerLink]="['/invoice/invoiceBoard']">invoice Board</p> -->

<div class="sidebar" [class.sidebar-close]="!openSidebar">
  <div class="logo-icon-details">
    <div class="logo-icon">
      <img src="../../../assets/images/careeco-white-logo-1.png">
      <!-- <img src="../../../assets/images/careeco-logo-1.png"> -->
    </div>
  </div>
  <ul class="nav-links nav-menu" id="nav-links">
    <li *ngFor="let item of menuSidebar" #itemEl routerLinkActive="active">
      <div *ngIf="item.sub_menu.length == 0" class="dropdown-title">
        <a [routerLink]="[item.link]" id="navbarLeft_{{item.link_name}}">
          <!-- <i class="fa-solid fa-money-check-dollar"></i> -->

          <img src="../../../assets/images/menu-invoice.svg">

          <span class="link_name">{{item.link_name}}</span>
        </a>
      </div>
      <div *ngIf="item.sub_menu.length > 0" class="dropdown-title" (click)="showSubmenu(itemEl)">
        <a id="navbarLeft_{{item.link_name}}">
          <i [class]="item.icon"></i>
          <span class="link_name">{{item.link_name}}</span>
        </a>
        <i class='bx bxs-chevron-down arrow'></i>
      </div>
      <!-- <ul class="sub-menu" [class.blank]="item.sub_menu.length == 0">
          <li><a class="link_name">{{item.link_name}}</a></li>
          <li *ngFor="let item_sub of item.sub_menu" routerLinkActive="active">
            <a [routerLink]="[item_sub.link]">{{item_sub.link_name}}</a>
          </li>
        </ul> -->
    </li>
  </ul>
</div>
<section class="home-section">
  <div class="home-header">
    <div class="hamburger-menu">
      <input type="checkbox" id="checkbox-hamburger-menu" [(ngModel)]="openSidebar" />
      <label class="label-hamburger-menu" for="checkbox-hamburger-menu">
        <div class="bar1"></div>
        <div class="bar2"></div>
        <div class="bar3"></div>

      </label>
    </div>
    <div class="title">HealthWorks</div>
    <div #userProfile class="drp-user dropdown" ngbDropdown placement="auto">
      <a href="javascript:" ngbDropdownToggle>
        <div class="user-avatar" (click)="openProfile()">
          <i class="fas fa-user-md"></i>

          <div class="profile-tooltip">
            <p><b>{{getSubmitterDetails()?.lastName}}, {{getSubmitterDetails()?.firstName}}</b></p>
            <span class="material-symbols-outlined arrow">
              arrow_drop_up
            </span>
          </div>

        </div>

      </a>

      <div class=" dropdown-menu-right profile-notification signout_profile" [ngStyle]="" id="navBarLeft_profile"
        [ngStyle]="{'display': signout_profile? 'block':'none'}" ngbDropdownMenu>
        <div class="pro-head">
          <!-- <span>
              <div class="row">
                <div class="col-md-12">
                    1234
                </div>
              </div>
            </span> -->
          <a href="javascript:" class="dud-logout">
          </a>
        </div>
        <ul class="pro-body">

          <div class="user-pro">
            <div class="row">
              <div class="col-md-12">
                <i class="fas fa-user-md"></i>
              </div>
              <div class="col-md-12 user-name-margin">
                <div class="user-name"><b>{{getSubmitterDetails()?.lastName}}, {{getSubmitterDetails()?.firstName}}</b>
                </div>
                <!-- <div class="user-name user-role"><b>Patient</b></div> -->
              </div>
              <div class="col-md-12">
                <button (click)="signOut()" class="btn btn-primary btn-logout" id="navBarLeftLogout"
                  style="border-radius: 50px !important;"><i class="feather icon-log-out"></i> Signout</button>
              </div>

              <!-- <div class="col-md-12 privacy-terms">
                  <span class="privacy" id="navBarLeft_privacyPolicy"><b> Privacy Policy</b></span>
                  <span class="term" id="navBarLeft_terms"><b>Terms of Service</b></span>
  
                </div> -->
            </div>

          </div>

        </ul>
      </div>
    </div>
  </div>
  <router-outlet></router-outlet>
</section>