import { MapsAPILoader } from '@agm/core';
import { Location } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, Input, NgZone, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import { interval, Subscription } from 'rxjs';
import { ApisService } from 'src/app/shared/apis.service';
import { AppRegExpressionsConfig } from 'src/app/shared/app.regularexpressions';
import { HttpService } from 'src/app/shared/http.service';

declare var Square: any;

@Component({
  selector: 'app-payments-bill',
  templateUrl: './payments-bill.component.html',
  styleUrls: ['./payments-bill.component.scss']
})
export class PaymentsBillComponent implements OnInit, OnDestroy, OnChanges {

  public navigationMode: any = null;
  public loadSpinner?: boolean = false;
  public exceptionalModal?: boolean = false;
  public notifyText?: string;
  @Input() facilityId: any;
  public paymentBillsSearchForm!: FormGroup;
  public patientResponsibilityCreditsValues: any = [];
  public allPatientDetails: any;
  @ViewChild(WizardComponent) public paymentBillWizard!: WizardComponent;
  public selectedTotalPaymentsObj: any[] = [];
  public totalAmountSelected: any;
  paymentLink: any;
  public subscription!: Subscription;
  makeStatusWithOrderId: any;
  public isPaymentsCompleted: boolean = false;
  public successPaymentDetails: any;
  userInfoDetails: any;
  public paymentBackCancelModal!: boolean;
  public setBackStepIndex!: any;
  private paymentConfigDetails: any;
  private LOCATION_ID: any;
  private APPLICATION_ID: any;
  public cardPaymentSubscription!: Subscription;
  public enableNonPosCancelPayment!: boolean;
  public cardPaymentObj: any;
  public successPayDetails: any;
  public createPayAppointmentId: any;
  public shippingAddressForm!: FormGroup;
  public allCardDetails: any;
  public searchElementRef?: ElementRef;
  public latitude!: number;
  public longitude!: number;
  public currentLocation!: string;
  generateTokenDetails: any;
  public fromCareJourney!: boolean;
  @Input() noLogoAndStyles: any;
  public patientId: any;
  public isCareJourneyBackReq: boolean = false;
  public facilityData: any = {};
  public spinnerMessageToUser!: any;
  @ViewChild('searchStreetShipping') set content(content: ElementRef) {
    if (content) {
      // initially setter gets called with undefined
      this.searchElementRef = content;
    }
  }

  constructor(private activatedRoute: ActivatedRoute, private httpService: HttpService, private formBuilder: FormBuilder, public sanitizer: DomSanitizer, private router: Router, private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, private location: Location, private apisService: ApisService) {
    // Path Params
    this.activatedRoute?.params?.subscribe(data => {
      if (data && data['facilityId']) {
        this.facilityId = data['facilityId'] || null;
        this.patientId = data['patientId'] || null;
        sessionStorage?.setItem('headerFacilityId', this.facilityId);
        this.getPaymentConfig(this.facilityId);
        this.getProviderInfo(this.facilityId);
      }
    });
    // Query Params
    this.activatedRoute?.queryParams?.subscribe(
      (params: Params) => {
        if (Object.keys(params).length > 0) {
          if (params && params['patientId']) {
            this.patientId = params['patientId'] || null;
          }
          if (params && params['isCareJourney']) {
            this.isCareJourneyBackReq = params['isCareJourney'] === 'Yes' ? true : false;
          }
        }
      });

    this.paymentBillsSearchForm = this.formBuilder?.group({
      'patientAccountId': [null, Validators?.required],
      'isValidAccountID': [null, Validators?.required]
    });
    this.shippingAddressForm = this.formBuilder?.group({
      'street': [null, Validators?.required],
      'city': [null, Validators?.required],
      'state': [null, Validators?.required],
      'zipCode': [null, Validators.compose([Validators.required, Validators.pattern(AppRegExpressionsConfig?.pincode)])],
      'country': [null],
      'firstName': [null, Validators?.required],
      'lastName': [null, Validators?.required],
      'saveCardInformation': [null]
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      // this.noLogoAndStyles = true;
      if (this.facilityId) {
        sessionStorage?.setItem('headerFacilityId', this.facilityId);
        this.getPaymentConfig(this.facilityId);
        this.fromCareJourney = true;
      }
    }
  }

  ngOnInit(): void {
    //load Places Autocomplete
    this.mapsAPILoader?.load()?.then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef?.nativeElement, {
        types: ["address"]
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.setCurrentPosition();
        });
      });
    });

    this.paymentBillsSearchForm?.get('patientAccountId')?.setValue(this.patientId || null);
  }

  private setCurrentPosition() {
    this.mapsAPILoader.load().then(() => {
      let geocoder = new google.maps.Geocoder;
      let latlng: any = {
        lat: this.latitude || undefined,
        lng: this.longitude || undefined
      };
      geocoder?.geocode({
        location: latlng
      }, (results) => {
        if (results[0]) {
          this.currentLocation = results[0].formatted_address;
          this.setZipCode(results);
        } else {
          console.log('Not found');
        }
      });
    });
  }

  // Get Payment Config details for facility id
  private getPaymentConfig(facilityId?: any): any {
    // const action = `providerConfiguration/getPaymentConfig?facilityId=${facilityId}`;
    this.apisService?.getPaymentConfig(facilityId)?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.paymentConfigDetails = data?.responseObject;
        this.APPLICATION_ID = this.paymentConfigDetails?.clientId;
        this.LOCATION_ID = this.paymentConfigDetails?.locationId;
        if (this.paymentConfigDetails) {
          this.cardPayment();
        }
      }
    },
      (error) => {
        // this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        // this.exceptionModal?.modalShow();
      });
    // this.httpService?.makeGetRequest(action)?.subscribe((data: any) => {
    //   if (data?.status === 'SUCCESS') {
    //     this.paymentConfigDetails = data?.responseObject;
    //     this.APPLICATION_ID = this.paymentConfigDetails?.clientId;
    //     this.LOCATION_ID = this.paymentConfigDetails?.locationId;
    //     if(this.paymentConfigDetails) {
    //       this.cardPayment();
    //     }
    //   }
    // },
    //   (error) => {
    //     this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
    //     this.exceptionModal?.modalShow();
    //   });
  }

  // get all patient responsibility 
  public getPatientResponsibility(): void {
    if (this.paymentBillsSearchForm?.get('patientAccountId')?.invalid) {
      this.paymentBillsSearchForm?.get('patientAccountId')?.markAllAsTouched();
      return;
    }
    // const action = `appointment/registration/getPatientResponsibilityNCredits?patientAccountId=${this.paymentBillsSearchForm?.value?.patientAccountId}`;
    this.loadSpinner = true;
    this.apisService?.getPatientRespNCreditsCall(this.paymentBillsSearchForm?.value?.patientAccountId)?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.paymentBillsSearchForm?.get('isValidAccountID')?.setValue(true);
        this.paymentBillsSearchForm?.get('isValidAccountID')?.updateValueAndValidity();
        this.allPatientDetails = data?.responseObject || null;
        this.patientResponsibilityCreditsValues = data?.responseObject?.patientRespNCredits?.filter((item: any) => item?.paymentAmount !== 0) || [];
        this.getUserInfoDetails(data?.responseObject?.userInfoId);
        if (data?.responseObject?.userInfoId === null) {
          this.notifyText = 'Invalid Patient Account ID. Please verify the ID and try again';
          this.exceptionalModal = true;
          this.loadSpinner = false;

          return;

        }
        this.paymentBillWizard?.goToNextStep();
      } else {
        this.notifyText = 'Invalid Patient Account ID or system error. Please click "OK" and try again.';
        this.exceptionalModal = true;
      }
      this.loadSpinner = false;
    }, (error) => {
      this.notifyText = 'Invalid Patient Account ID or system error. Please click "OK" and try again.';
      this.exceptionalModal = true;
      this.loadSpinner = false;
    });
    // this.httpService?.makeGetRequest(action)?.subscribe((data: any) => {
    //   if (data?.status === 'SUCCESS') {
    //     this.paymentBillsSearchForm?.get('isValidAccountID')?.setValue(true);
    //     this.paymentBillsSearchForm?.get('isValidAccountID')?.updateValueAndValidity();
    //     this.allPatientDetails = data?.responseObject || null;
    //     this.patientResponsibilityCreditsValues = data?.responseObject?.patientRespNCredits?.filter((item: any) => item?.paymentAmount !== 0) || [];
    //     this.getUserInfoDetails(data?.responseObject?.userInfoId);
    //     this.paymentBillWizard?.goToNextStep();
    //   } else {
    //     this.notifyText = 'Invalid Patient Account ID or Something went wrong. Click OK and try again';
    //     this.exceptionalModal = true;
    //   }
    //   this.loadSpinner = false;
    // }, (error) => {
    //   this.notifyText = 'Invalid Patient Account ID or Something went wrong. Click OK and try again';
    //   this.exceptionalModal = true;
    //   this.loadSpinner = false;
    // });
  }

  //  get user ifno details
  public getUserInfoDetails(userInfoId: string): void {
    if (!userInfoId) {
      this.userInfoDetails=null;
      this.notifyText = 'Invalid Patient Account ID or Something went wrong. Click OK and try again';
      this.exceptionalModal = true;
      return;
    }
    const action = `userInfo/getUserInfo?id=${userInfoId}`;
    this.loadSpinner = true;
    this.httpService?.makeGetRequest(action)?.subscribe((data: any) => {
      this.loadSpinner = false;
      if (data?.status === 'SUCCESS') {
        this.userInfoDetails = data?.userInfoResponse || null;
        if (this.userInfoDetails) {
          this.shippingAddressForm?.get('street')?.setValue(this.userInfoDetails?.address?.street);
          this.shippingAddressForm?.get('city')?.setValue(this.userInfoDetails?.address?.city);
          this.shippingAddressForm?.get('state')?.setValue(this.userInfoDetails?.address?.state);
          this.shippingAddressForm?.get('zipCode')?.setValue(this.userInfoDetails?.address?.zipCode);
          this.shippingAddressForm?.get('firstName')?.setValue(this.userInfoDetails?.firstName);
          this.shippingAddressForm?.get('lastName')?.setValue(this.userInfoDetails?.lastName);
        }
      }
    },
      (error) => {
        this.loadSpinner = false;
      });
  };

  public selectPatientPayment(event: any, payment: any, selectedIndex: number): void {
    if (event.target.checked) {
      payment.index = selectedIndex
      this.selectedTotalPaymentsObj?.push(payment);
    } else {
      this.selectedTotalPaymentsObj = this.selectedTotalPaymentsObj?.filter(item => item?.index !== selectedIndex);
    }

    if (this.selectedTotalPaymentsObj?.length > 0) {
      this.totalAmountSelected = this.selectedTotalPaymentsObj?.map(item => item?.paymentAmount || 0)?.reduce((prev, curr) => prev + curr);
      //this.createPayAppointmentId = this.selectedTotalPaymentsObj[0]?.appointmentId;
      const selectedArray:any = JSON.parse(JSON.stringify(this.selectedTotalPaymentsObj));
      const sortedArray = selectedArray.reverse();
      this.createPayAppointmentId = sortedArray[0]?.appointmentId;
      
    } else {
      this.totalAmountSelected = 0 || null;
    }

  }

  // create Sqaure apyment link
  public createPayment(): void {
    if (this.selectedTotalPaymentsObj?.length === 0) {
      this.notifyText = 'Please select any one payment to continue.';
      this.exceptionalModal = true;
      return;
    } else {
      this.paymentBillWizard?.goToNextStep()
    }
    return;
    const action = 'payment/createPaymentLink';
    const amount: any = this.totalAmountSelected * 100;
    const params = {
      "amount": parseInt(amount),
      "description": "",
      "facilityId": parseInt(this.facilityId),
      "phoneNumber": "",
      "userInfoId": this.allPatientDetails?.userInfoId
    }
    this.loadSpinner = true;
    this.httpService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        const responseObj = data?.responseObject;
        this.paymentLink = responseObj;
        this.makeStatusWithOrderId = JSON?.parse(JSON?.stringify(responseObj));
        const iframeEle = document.getElementById('paySquareBill') as HTMLElement;
        // const tructUrl = this.sanitizer?.bypassSecurityTrustResourceUrl(this.paymentLink?.url)?.toString() || '';
        iframeEle.setAttribute('src', this.paymentLink?.url);
        this.paymentBillWizard?.goToNextStep();
        iframeEle?.addEventListener("load", ev => {
          this.loadSpinner = false;
        });
      } else {
        this.loadSpinner = false;
      }
      // this.loadSpinner = false;
    }, (error) => {
      this.notifyText = error?.body?.message || error?.message || 'Something went wrong. Click OK and try again';
      this.exceptionalModal = true;
      this.loadSpinner = false;
    });
  }

  public getOrderStatus(): void {
    // this.getPosPaymentStatus(this.makeStatusWithOrderId?.orderId);
    // const source = interval(5000);
    // this.subscription = source.subscribe(val => { this.getPosPaymentStatus(this.makeStatusWithOrderId?.orderId) });
  }

  private getPosPaymentStatus(orderId: string): void {
    const action = `payment/getPaymentByOrderId/${orderId}`;
    this.httpService?.makeGetRequest(action)?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        if (data?.responseObject?.status === 'COMPLETED') {
          this.successPaymentDetails = data?.responseObject;
          this.subscription?.unsubscribe();
          this.updatePatientResponsibility();
          this.isPaymentsCompleted = true;
        }
      }
    },
      (error) => {

      });
  };

  // date and time format
  public dateFormate(dateTime: string): string {
    return this.httpService?.getAppointmentTime(dateTime);
  }

  public paymenturl() {
    return this.sanitizer?.bypassSecurityTrustResourceUrl(this.paymentLink?.url) || null;
  }

  // update Patient Responsibility
  public updatePatientResponsibility(): void {
    if (this.selectedTotalPaymentsObj && (this.selectedTotalPaymentsObj?.length === 1 || this.selectedTotalPaymentsObj?.length === 0)) {
      return;
    }
    // const action = 'payment/updatePatientResponsibility';
    // this.selectedTotalPaymentsObj?.forEach((item) => {
    //   item.patientResponsibility = 0;
    // })

    // const allPatientRespItems: any[] = [];
    // let totalPaidAmount: any = this.totalAmountSelected;
    // this.selectedTotalPaymentsObj?.forEach(((item: any) => {
    //   if (this.createPayAppointmentId !== item?.appointmentId) {
    //     const dupPayments = JSON?.parse(JSON?.stringify(item?.paymentAmount || 0));
    //     if (totalPaidAmount) {
    //       const getActPayment = item?.paymentAmount;
    //       item.paymentAmount = (!item?.paymentAmount || item?.paymentAmount === 0) ? null : (totalPaidAmount - getActPayment < 0) ? totalPaidAmount : getActPayment;
    //       totalPaidAmount = totalPaidAmount - getActPayment <= 0 ? 0 : totalPaidAmount - getActPayment;
    //     } else {
    //       item.paymentAmount = 0;
    //     }
    //     if (item?.paymentAmount > 0 && (dupPayments !== item?.paymentAmount)) {
    //       allPatientRespItems?.push(item);
    //     };
    //   }
    // }));

    const params = {
      "latestAppointmentId": this.createPayAppointmentId || this.allPatientDetails?.latestAppointmentId,
      "patientAccountId": this.allPatientDetails?.patientAccountId,
      "patientRespNCredits": this.selectedTotalPaymentsObj?.filter((item: any) => this.createPayAppointmentId !== item?.appointmentId) || [],
      "totalCredits": this.allPatientDetails?.totalCredits,
      "totalRemainingCredits": this.allPatientDetails?.totalRemainingCredits || 0,
      "creditsAppts": null,
      "userInfoId": this.allPatientDetails?.userInfoId,

      "facilityId": parseInt(this.facilityId),
      "modeOfPay": 'NON_POS',
      "paymentId": this.successPayDetails?.paymentId || null,
      "paymentOrderId": this.makeStatusWithOrderId?.orderId || null,
      "receiptUrl": this.successPayDetails?.receiptUrl || null,
      "latestApptpendingPatientResponsibility": 0,
      "latestApptpaymentAmount": this.selectedTotalPaymentsObj?.find((item: any) => this.createPayAppointmentId === item?.appointmentId)?.paymentAmount || this.totalAmountSelected,
    };
    this.loadSpinner = true;
    this.apisService?.updatePatientResponsibilityExtrGateway(params)?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.selectedTotalPaymentsObj = [];
        this.notifyText = data?.message || data?.msg;
        this.exceptionalModal = true;
      } else {
        this.notifyText = data?.message || data?.msg;
        this.exceptionalModal = true;
      }
      this.loadSpinner = false;
    },
      (error) => {
        this.notifyText = error?.message || error?.msg || 'Something went wrong please try again later.';
        this.exceptionalModal = true;
        this.loadSpinner = false;
      });
    // this.httpService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
    //   if (data?.status === 'SUCCESS') {
    //     this.selectedTotalPaymentsObj = [];
    //     this.notifyText = data?.message || data?.msg;
    //     this.exceptionalModal = true;
    //   } else {
    //     this.notifyText = data?.message || data?.msg;
    //     this.exceptionalModal = true;
    //   }
    //   this.loadSpinner = false;
    // },
    //   (error) => {
    //     this.notifyText = error?.message || error?.msg || 'Something went wrong please try again later.';
    //     this.exceptionalModal = true;
    //     this.loadSpinner = false;
    //   });
  }

  public navigateLaunchPad(): void {
    this.router?.navigate([`${this.facilityId}/patientPortal`]);
  }

  public viewDownloadReceipt(): void {
    window.open(this.successPaymentDetails?.receiptUrl, '_blank');
  }

  public ageCalculate(dateOfBirth: any): any {
    return this.httpService?.calculateAge(dateOfBirth);
  }

  public cancelPaymentBack(): void {
    this.paymentBackCancelModal = false;
    this.subscription?.unsubscribe();
    this.paymentBillWizard?.goToPreviousStep();
    if (this.setBackStepIndex === 2) {
      this.selectedTotalPaymentsObj = [];
      this.totalAmountSelected = null;
      this.setBackStepIndex = null;
    }
  }

  // card payment
  public async cardPayment() {
    this.loadSpinner = true;
    await new Promise(resolve => setTimeout(resolve, 2000));
    const containerEle = document?.getElementById('card-container');
    if (containerEle?.innerHTML) {
      containerEle.innerHTML = '';
    }
    const payments = Square?.payments(this.APPLICATION_ID, this.LOCATION_ID);
    const card = await payments?.card();
    this.allCardDetails = card?.context?.session || null;
    await card?.attach('#card-container');

    // async function eventHandler(this: any) {
    //   this.loadSpinner = true;
    //   // event.preventDefault();
    //   try {
    //     this.loadSpinner = true;
    //     const result = await card?.tokenize();
    //     this.loadSpinner = false;
    //     if (result?.status === 'OK') {
    //       console.log(result);
    //       this.createCardPayment(result?.token);
    //     }
    //     if (result?.status === 'Invalid') {
    //       this.loadSpinner = false;
    //     }
    //   } catch (e) {
    //     this.loadSpinner = false;
    //   }
    // };
    const cardButton = document?.getElementById('card-button');
    cardButton?.addEventListener('click', async () => {
      this.loadSpinner = true;
      // const statusContainer: any = document.getElementById('payment-status-container');
      try {
        const result = await card.tokenize();
        if (result.status === 'OK') {
          this.loadSpinner = false;
          this.generateTokenDetails = result;
          this.createCardPayment(result?.token);
          // console.log(`Payment token is ${result.token}`);
          // statusContainer.innerHTML = "Payment Successful";
        } else {
          this.loadSpinner = false;
          let errorMessage = `Tokenization failed with status: ${result.status}`;
          if (result.errors) {
            errorMessage += ` and errors: ${JSON.stringify(
              result.errors
            )}`;
          }
          throw new Error(errorMessage);

        }
      } catch (e) {
        console.error(e);
        // statusContainer.innerHTML = "Payment Failed";
        this.loadSpinner = false;
      }
    });
    // cardButton?.addEventListener('click', eventHandler.bind(this));
    this.loadSpinner = false;
  }

  // Create Card Payment
  private async createCardPayment(paymentToken: string) {
    if (this.selectedTotalPaymentsObj?.length === 0) {
      this.notifyText = 'Please select any one payment to continue.';
      this.exceptionalModal = true;
      return;
    }
    // const action = 'payment/createPayment';
    const totalPayment = (this.totalAmountSelected || 0);
    const amount = (totalPayment)?.toFixed(2)?.split('.')?.join('');
    // let params = {
    //   "serviceCharges": [{ amount: this.totalAmountSelected, serviceType: "Past Visit Charges" }],
    //   "modeOfPay": "NON_POS",
    //   "amount": parseInt(amount),
    //   "appointmentId": this.createPayAppointmentId,
    //   "sourceId": paymentToken,
    //   "facilityId": this.facilityId
    // }

    const params = {
      "amount": parseInt(amount),
      "appointmentId": this.createPayAppointmentId || null,
      "facilityId": parseInt(this.facilityId),
      "modeOfPay": 'NON_POS',
      "serviceCharges": [{ amount: this.totalAmountSelected, serviceType: "Past Visit Charges" }] || [],
      "sourceId": paymentToken || null,
      "terminal": null,
      // "type": payItem?.claimProcessorType || this.selectedType,
      "userInfoId": this.allPatientDetails?.userInfoId || null,
      "description": 'Patient Responsibility',
      "paymentCategory": 'Visit Charges',
    }

    this.loadSpinner = true;
    this.spinnerMessageToUser = "Request is in progress, please do not close or refresh.";
    this.apisService?.createNonPosPaymentExtrGateway(params)?.subscribe((data: any) => {
      this.loadSpinner = false;
      if (data?.status === 'SUCCESS') {
        if (data?.responseObject?.status === 'APPROVED') {
          this.enableNonPosCancelPayment = true;
          this.cardPaymentObj = data?.responseObject;
          const paymentId = data?.responseObject?.paymentId;
          const cardPaymentSource = interval(15000);
          this.cardPaymentSubscription = cardPaymentSource.subscribe(val => { this.getCardPaymentStatus(paymentId) });
        }
        if (data?.responseObject?.status === 'PENDING') {
          this.enableNonPosCancelPayment = true;
          this.cardPaymentObj = data?.responseObject;
          const paymentId = data?.responseObject?.paymentId;
          const cardPaymentSource = interval(15000);
          this.cardPaymentSubscription = cardPaymentSource.subscribe(val => { this.getCardPaymentStatus(paymentId) });
        }
        if (data?.responseObject?.status === 'COMPLETED') {
          this.successPayDetails = data?.responseObject;
          this.successPaymentDetails = data?.responseObject;
          this.cardPaymentSubscription?.unsubscribe();
          this.notifyText = 'Your payment is successful.';
          this.exceptionalModal = true;
          this.loadSpinner = false;
          this.updatePatientResponsibility();
          this.spinnerMessageToUser = null;
          // this.router.navigate([`${this.facilityId}/patientPortal`]);
        }
        if (data?.responseObject?.status === 'CANCELED') {
          this.cardPaymentSubscription?.unsubscribe();
          this.notifyText = 'Your payment request is cancelled.';
          this.exceptionalModal = true;
          this.loadSpinner = false;
          this.spinnerMessageToUser = null;
        }
        if (data?.responseObject?.status === 'FAILED') {
          this.cardPaymentSubscription?.unsubscribe();
          this.notifyText = 'Your payment is Failed.';
          this.exceptionalModal = true;
          this.loadSpinner = false;
          this.spinnerMessageToUser = null;
        }
      } else {
        this.notifyText = data?.message || data?.msg || 'Something went wrong please try again later.';
        this.exceptionalModal = true;
        this.saveCardDetailsSquareApis();
        this.spinnerMessageToUser = null;
      }
    },
      (error) => {
        this.notifyText = error?.message || error?.msg || 'Something went wrong please try again later.';
        this.exceptionalModal = true;
        this.loadSpinner = false;
      });
    // this.httpService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
    //   if (data?.status === 'SUCCESS') {
    //     if (data?.responseObject?.status === 'APPROVED') {
    //       this.enableNonPosCancelPayment = true;
    //       this.cardPaymentObj = data?.responseObject;
    //       const paymentId = data?.responseObject?.paymentId;
    //       const cardPaymentSource = interval(15000);
    //       this.cardPaymentSubscription = cardPaymentSource.subscribe(val => { this.getCardPaymentStatus(paymentId) });
    //     }
    //     if (data?.responseObject?.status === 'PENDING') {
    //       this.enableNonPosCancelPayment = true;
    //       this.cardPaymentObj = data?.responseObject;
    //       const paymentId = data?.responseObject?.paymentId;
    //       const cardPaymentSource = interval(15000);
    //       this.cardPaymentSubscription = cardPaymentSource.subscribe(val => { this.getCardPaymentStatus(paymentId) });
    //     }
    //     if (data?.responseObject?.status === 'COMPLETED') {
    //       this.successPayDetails = data?.responseObject;
    //       this.successPaymentDetails = data?.responseObject;
    //       this.cardPaymentSubscription?.unsubscribe();
    //       this.notifyText = 'Your payment is successful.';
    //       this.exceptionalModal = true;
    //       this.loadSpinner = false;
    //       this.updatePatientResponsibility();
    //       // this.router.navigate([`${this.facilityId}/patientPortal`]);
    //     }
    //   } else {
    //     this.notifyText = data?.message || data?.msg || 'Something went wrong please try again later.';
    //     this.exceptionalModal = true;
    //     this.saveCardDetailsSquareApis();
    //   }
    //   this.loadSpinner = false;
    // },
    //   (error) => {

    //   });
  }

  // get the CREDIT CARD PAYMENT STATUS
  public getCardPaymentStatus(paymentId: string): void {
    // const action = `payment/getPayment/${paymentId}`;
    this.loadSpinner = true;
    this.successPayDetails = null;
    this.successPaymentDetails = null;
    setTimeout(() => {
      this.spinnerMessageToUser = 'This is taking longer than expected, Please hold on.';
    }, 5000);
    this.apisService?.getStatusNonPosPaymentExtrGateway(paymentId)?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        if (data?.responseObject?.status === 'COMPLETED') {
          this.successPayDetails = data?.responseObject;
          this.successPaymentDetails = data?.responseObject;
          this.updatePatientResponsibility();
          this.cardPaymentSubscription?.unsubscribe();
          this.notifyText = 'Your payment is successful.';
          this.exceptionalModal = true;
          this.loadSpinner = false;
          // this.router.navigate([`${this.facilityId}/patientPortal`]);
          this.spinnerMessageToUser = null;
        }
        if (data?.responseObject?.status === 'CANCELED') {
          this.cardPaymentSubscription?.unsubscribe();
          this.notifyText = 'Your payment request is cancelled.';
          this.exceptionalModal = true;
          this.loadSpinner = false;
          this.spinnerMessageToUser = null;
        }
        if (data?.responseObject?.status === 'FAILED') {
          this.cardPaymentSubscription?.unsubscribe();
          this.notifyText = 'Your payment is failed.';
          this.exceptionalModal = true;
          this.loadSpinner = false;
          this.spinnerMessageToUser = null;
        }
      } else {
        // this.loadSpinner = false;
      }
    },
      (error) => {
        this.cardPaymentSubscription?.unsubscribe();
        this.notifyText = 'Something went wrong please try again later.';
        this.exceptionalModal = true;
        this.spinnerMessageToUser = null;
        this.loadSpinner = false;
      });
    // this.httpService?.makeGetRequest(action)?.subscribe((data: any) => {
    //   if (data?.status === 'SUCCESS') {
    //     if (data?.responseObject?.status === 'COMPLETED') {
    //       this.successPayDetails = data?.responseObject;
    //       this.successPaymentDetails = data?.responseObject;
    //       this.updatePatientResponsibility();
    //       this.cardPaymentSubscription?.unsubscribe();
    //       this.notifyText = 'Your payment is successful.';
    //       this.exceptionalModal = true;
    //       this.loadSpinner = false;
    //       // this.router.navigate([`${this.facilityId}/patientPortal`]);
    //     }
    //     if (data?.responseObject?.status === 'CANCELED') {
    //       this.cardPaymentSubscription?.unsubscribe();
    //       this.notifyText = 'Your payment request is cancelled.';
    //       this.exceptionalModal = true;
    //       this.loadSpinner = false;
    //     }
    //     if (data?.responseObject?.status === 'FAILED') {
    //       this.cardPaymentSubscription?.unsubscribe();
    //       this.notifyText = 'Your payment is failed.';
    //       this.exceptionalModal = true;
    //       this.loadSpinner = false;
    //     }
    //   }
    // },
    //   (error) => {
    //     this.cardPaymentSubscription?.unsubscribe();
    //   });
  }

  // Cancel Card Payment
  public cancelPayment(): void {
    // const action = `payment/cancelPayment/${this.cardPaymentObj?.paymentId}`;
    this.loadSpinner = true;
    this.apisService?.cancelNonPosPaymentExtrGateway(this.cardPaymentObj?.paymentId)?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.notifyText = data?.message;
        this.exceptionalModal = true;
      } else {
        this.notifyText = data?.message;
        this.exceptionalModal = true;
      }
      this.loadSpinner = false;
      this.cardPaymentSubscription?.unsubscribe();
    },
      (error) => {
        this.notifyText = error?.message;
        this.exceptionalModal = true;
        this.loadSpinner = false;
      });
    // this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
    //   if (data?.status === 'SUCCESS') {
    //     this.notifyText = data?.message;
    //     this.exceptionalModal = true;
    //   } else {
    //     this.notifyText = data?.message;
    //     this.exceptionalModal = true;
    //   }
    //   this.loadSpinner = false;
    //   this.cardPaymentSubscription?.unsubscribe();
    // },
    //   (error) => {
    //     this.notifyText = error?.message;
    //     this.exceptionalModal = true;
    //     this.loadSpinner = false;
    //   });
  }

  // Save CARD Details with Square API's
  public saveCardDetailsSquareApis(): void {
    if (!this.shippingAddressForm?.get('saveCardInformation')?.value) {
      return;
    }
    const action = `https://connect.squareup.com/v2/cards`;
    const params: any = {
      idempotencyKey: this.createPayAppointmentId,
      sourceId: this.generateTokenDetails?.token,
      card: {
        cardholderName: `${this.shippingAddressForm?.get('firstName')?.value} ${this.shippingAddressForm?.get('lastName')?.value}`,
        billingAddress: {
          addressLine1: this.shippingAddressForm?.get('street')?.value,
          addressLine2: '',
          locality: this.shippingAddressForm?.get('city')?.value,
          administrativeDistrictLevel1: this.shippingAddressForm?.get('state')?.value,
          postalCode: this.shippingAddressForm?.get('zipCode')?.value,
          country: this.shippingAddressForm?.get('country')?.value
        },
        customerId: '',
        referenceId: `${this.shippingAddressForm?.get('firstName')?.value}-${this.shippingAddressForm?.get('lastName')?.value}`
      }
    }
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.paymentConfigDetails?.refreshToken}`
      }),
    }
    this.httpService?.squarePaymentPostRequest(action, params, headers)?.subscribe((data: any) => {
      console.log(data);
    }, error => {

    });
  }

  public addPrefixZipCode(addressZipCode: any, valid: any): any {
    let prefixZipcode = addressZipCode;
    if (prefixZipcode) {
      const checkLen = prefixZipcode?.toString()?.length;
      if (checkLen === 4) {
        prefixZipcode = '0' + prefixZipcode;
      }
      if (checkLen === 3) {
        prefixZipcode = '00' + prefixZipcode;
      }
    }
    if (valid) {
      this.shippingAddressForm?.get('zipCode')?.setValue(prefixZipcode || addressZipCode || '');
    }

    return prefixZipcode || addressZipCode || '';
  }

  // Auto address
  public setZipCode(results: any): void {
    this.shippingAddressForm?.get('street')?.setValue(null);
    let street = '';
    for (let i = 0; i < results.length; i++) {
      for (var j = 0; j < results[i].address_components.length; j++) {
        for (var k = 0; k < results[i].address_components[j].types.length; k++) {

          if (results[i].address_components[j].types[k] === "street_number") {
            let street_num = results[i].address_components[j].long_name;
            street += `${street_num} `;
          }
          if (this.shippingAddressForm?.get('street')?.value === null && results[i].address_components[j].types[k] === "route") {
            const route = results[i].address_components[j].long_name;
            street += `${route}`;
            if (this.shippingAddressForm?.get('street')?.value === null) {
              this.shippingAddressForm?.get('street')?.setValue(street);
            }
          }

          if (results[i].address_components[j].types[k] === "postal_code") {
            const zipCode = results[i].address_components[j].short_name;
            const zipcodeUpdate = this.addPrefixZipCode(zipCode, '');
            this.shippingAddressForm?.get('zipCode')?.setValue(zipcodeUpdate || zipCode);
          }

          if (results[i].address_components[j].types[k] === "country") {
            const country = results[i].address_components[j].long_name;
            this.shippingAddressForm?.get('country')?.setValue(country);
          }

          if (results[i].address_components[j].types[k] === "administrative_area_level_1") {
            const state = results[i].address_components[j].short_name;
            this.shippingAddressForm?.get('state')?.setValue(state);
          }

          if (results[i].address_components[j].types[k] === "locality") {
            const city = results[i].address_components[j].long_name;
            this.shippingAddressForm?.get('city')?.setValue(city);
          }
        }
      }
    }
  }
  // Back navigation
  public backNavigation(): void {
    this.location?.back();
  }

  public getProviderInfo(facilityId: any): void {
    let action: any = `providerInfo/getProviderInfo`;
    const params = {
      "facilityName": facilityId,
      "id": facilityId,
      "phoneNumber": "",
      "zipCode": 0
    }
    this.httpService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS' && data?.resultList != null) {
        this.facilityData = data && data?.resultList[0];
      }
    }, (error: any) => {

    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

}
