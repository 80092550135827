import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { DataService } from '../data-sharing.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  isLogin: any;
  private facilityId: string | null;

  constructor(public router: Router, public dataService: DataService) {
    this.facilityId = sessionStorage?.getItem('headerFacilityId');
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (sessionStorage.getItem('isLogin') === 'true') {
      this.dataService?.setIsLogin(true);
      return true;
    } else {
      const facilityId = sessionStorage?.getItem('headerFacilityId');
      this.router?.navigate([`${this.facilityId || facilityId}/providerPortal`]);
      return false;
    }
  }

}
