<section id="consent-forms-details">
    <form [formGroup]="bookAppointmentForm">
        <div class="bg-style consent-forms">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <h5>Consent Forms</h5>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="app-modal-body add-app-body radioBtnsDiv categories background-style">
                        <table class="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th>TEMPLATE NAME</th>
                                    <th>STATUS</th>
                                    <th>ACTION</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container>
                                    <tr *ngFor="let listTemplate of listTemplates; let i = index;"
                                        [ngStyle]="{ backgroundColor: i % 2 == 0 ? '#f8fdfc' :  '#fff' }">
                                        <td style="padding-top: 20px !important">{{listTemplate?.templateName}}</td>
                                        <td style="padding-top: 20px !important">{{listTemplate?.status}}</td>
                                        <td>
                                            <button
                                                *ngIf="listTemplate.status != 'SIGNED' &&  listTemplate?.status != 'OUT_FOR_SIGNATURE'"
                                                class="btn btn-primary search-btn book-appointment-btn book-Esign-btn ok-book"
                                                (click)="openConscentModal(listTemplate);" id="consentForms_esign_{{i}}"
                                                [disabled]="listTemplate?.status === 'SIGNED' || listTemplate?.status === 'OUT_FOR_SIGNATURE'">
                                                E-Sign </button>

                                            <button *ngIf="listTemplate.status === 'SIGNED'" id="consentForms_view_{{i}}"
                                                class="btn btn-primary search-btn book-appointment-btn book-Esign-btn ok-book"
                                                (click)="downloadConsentForm(listTemplate);">
                                                View </button>

                                        </td>
                                        <!-- || listTemplate?.status === 'IN_PROCESS' -->
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 text-center mt-4">
                <button type="button" data-target="#bookSuccessfull" (click)="saveConsentForms()" id="consentForms_submit"
                    [disabled]="disableSubmitBtn" class="btn btn-primary continue-btn">Submit</button>
            </div>
        </div>

    </form>
</section>
<!-- spinner -->
<div class="custom-loader justify-content-center" *ngIf="loadSpinner" id="spinnerDisplay">
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<div class="modal" id="conscent-detail-modal" [style.display]="exceptionalModal ? 'block' : 'none'" role="dialog">
    <div class="modal-dialog modal-dialog-centered">

        <!-- Modal content-->
        <div class="modal-content ">
            <div class="row">

                <div style="float:right;margin-right:20px;"><button type="button" class="close basic-close closebutton" id="consentForms_clear"
                        data-dismiss="modal" aria-label="Close" (click)="clearData()"><span
                            aria-hidden="true">&times;</span></button>
                </div>
            </div>
            <div class="row pdfmodal">
                <div>

                    <img *ngIf="this.facilityId === '81'" src="assets/images/goodhealth-logo.png" height="100px"
                        alt="Good Health" style="float:right;">
                    <img *ngIf="this.facilityId === '201'" src="assets/images/patientsFirst-logo.png"
                        alt="Patient First" style="float:right;">
                    <img *ngIf="this.facilityId === '83'" src="assets/images/AWH-logo.png" alt="AWH" width="350px"
                        style="float:right;">

                </div>

            </div>
            <div class="modal-body pdfmodal" *ngIf="templateDetails">
                <form [formGroup]="conscentForm">

                    <!-- <h5 style="text-align: center;padding: 15px;">{{templateDetails?.templateName}}</h5>-->

                    <ng-container>

                        <div class="row pdfrow" *ngFor="let data of templateQuestions; let cati = index">

                            <span class="heading"
                                *ngIf="data.style === 'Header'   &&  data.inputFieldName != 'parentGuardianSignature' &&  data.inputFieldName != 'date'"
                                [innerHTML]="data.description"
                                [ngClass]="data.type === 'input' ? 'listspan' : ''"></span>


                            <!-- <span *ngIf="data.type === 'Checkbox'">
                                <input ngbButton *ngIf="data.inputFieldName === 'certifyCheck'" type="radio"
                                    class="checkbox" (change)="iAgreecertifyCheck($event.target)"
                                    name="confirmsubmission" id={{data.inputFieldName}} autocomplete="off" value="">
                                <input ngbButton *ngIf="data.inputFieldName === 'declineCheck'" type="radio"
                                    class="checkbox" (change)="iAgreedeclineCheck($event.target)"
                                    name="confirmsubmission" id={{data.inputFieldName}} autocomplete="off" value="">
                                <span
                                    *ngIf="data.style != 'Header'   &&  data.inputFieldName != 'parentGuardianSignature' &&  data.inputFieldName != 'date'"
                                    [innerHTML]="data.description"
                                    [ngClass]="data.type === 'input' ? 'listspan' : ''"></span>


                            </span>-->
                            <span [ngClass]="data.type === 'input' ? 'listspan' : ''">
                                <span
                                    *ngIf="data.style != 'Header' && data.style != 'footer' && data.type != 'Checkbox'   &&  data.inputFieldName != 'parentGuardianSignature' &&  data.inputFieldName != 'date' "
                                    [innerHTML]="data.description" style="width:auto">

                                </span>
                                <span class="requiredStar"
                                    *ngIf="data.isMandatory && data.style != 'Header' && data.style != 'footer' &&   data.inputFieldName != 'parentGuardianSignature' &&  data.inputFieldName != 'date' && data.inputFieldName != 'certifyCheck'">
                                    *</span>
                            </span>
                            <span *ngIf="data.type === 'Radio button'">
                                <label class="btn " [ngClass]="data.inputFieldName === 'certifyCheck' ? 'cerify' : ''"
                                    ngbButtonLabel *ngFor="let rd of data.options; let idx = index">
                                    <input type="radio" ngbButton formControlName="{{data.inputFieldName}}" id="{{rd}}"
                                        value="{{rd}}"><small style="margin-left:5px; margin-right:10px;">{{rd}}</small>
                                </label>

                            </span>

                            <span *ngIf="data.type === 'Checkbox'">
                                <p *ngIf="data.style != 'footer'" [innerHTML]="data.description"></p>


                                <!--<div>

                                <input type="checkbox"  formControlName="{{data.inputFieldName}}" id="{{rd}}" value="{{rd}}" 
								[disabled] = "selectedTemplateAPI === 'uploadAuthorizationForMedicalRecordsForm' &&  data.inputFieldName === 'informationRequested'  ? true : false">
                                                                <label style="margin-left:5px; margin-right:10px;">{{rd}}</label>
                                                            </div>-->


                                <label class="btn " *ngFor="let rd of data.options; let idx = index">
                                    <input type="checkbox" formControlName="{{data.inputFieldName}}" id="{{rd}}"
                                        value="{{rd}}" (change)="createSelectedList(data.options,$event)"><small
                                        style="margin-left:5px; margin-right:10px;">{{rd}}</small>
                                </label>

                            </span>

                            <!--<p *ngIf="data.type === 'Radio button' && data.inputFieldName === 'certifyCheck'">
                                <label class="btn " style="padding: 35px;" ngbButtonLabel
                                    *ngFor="let cd of data.options; let cdx = index">
                                   <input type="radio" ngbButton formControlName="{{data.inputFieldName}}" id="{{cd}}"
                                        value="{{cdx}}" [checked]="(cdx === 0)"><small style="margin-left:5px; margin-right:10px;">{{cd}}</small>
                                </label><br><br>
							</p>-->
                            <span
                                *ngIf="(data.inputFieldName === 'fromDate' ||  data.inputFieldName === 'toDate' ) &&  ( data.inputFieldName !== 'dob') "
                                [ngClass]="data.type === 'input' ? 'listspan' : ''">
                                <input type="date" class="form-control" formControlName="{{data.inputFieldName}}"
                                    id="{{data.inputFieldName}}"
                                    placeholder="{{data.inputFieldName === 'date'  ? 'mm/dd/yyyy' : ''}}"
                                    (change)="firechange($event);" [min]="minToDate" [max]="maxToDate"
                                    onkeydown="return false">
                            </span>
                            <span
                                *ngIf="data.inputFieldName !== 'fromDate' &&  data.inputFieldName !== 'toDate' &&  data.inputFieldName === 'dob' "
                                [ngClass]="data.type === 'input' ? 'listspan' : ''">
                                <input type="date" class="form-control" formControlName="{{data.inputFieldName}}"
                                    id="{{data.inputFieldName}}"
                                    placeholder="{{data.inputFieldName === 'date'  ? 'mm/dd/yyyy' : ''}}"
                                    (change)="firechange($event);" onkeydown="return false">

                                <span class="required"
                                    *ngIf="data.inputFieldName === 'dob' && conscentForm?.get(data.inputFieldName)?.errors?.required && conscentForm?.get(data.inputFieldName)?.touched"><br>
                                    {{data.description}} is required</span>

                            </span>
                            <span
                                *ngIf="data.type === 'input' &&  data.inputFieldName != 'parentGuardianSignature' &&  data.inputFieldName != 'date' && data.inputFieldName !== 'fromDate' &&  data.inputFieldName !== 'toDate'  &&  data.inputFieldName !== 'dob'  "
                                [ngClass]="data.type === 'input' ? 'listspan' : ''">
                                <input
                                    type="{{data.inputFieldName === 'email'  ? 'email' :  data.inputFieldName === 'fax'  ? 'number' : 'text'}}"
                                    class="form-control" formControlName="{{data.inputFieldName}}">
                                <span class="required"
                                    *ngIf="data.inputFieldName != 'email' && data.inputFieldName != 'cellNumber'  && conscentForm?.get(data.inputFieldName)?.errors?.required && conscentForm?.get(data.inputFieldName)?.touched">{{data.description}}
                                    is required</span>



                                <span class="required"
                                    *ngIf="data.inputFieldName === 'primaryNumber' && conscentForm?.get(data.inputFieldName)?.invalid"><br>
                                    Enter
                                    a valid 10 digit Phone Number</span>

                                <span
                                    *ngIf="data.inputFieldName === 'cellNumber'  && conscentForm?.get(data.inputFieldName)?.errors?.required && conscentForm?.get(data.inputFieldName)?.touched && !conscentForm?.get(data.inputFieldName)?.invalid"
                                    class="error">Mobile Number is Required</span>
                                <span class="error"
                                    *ngIf="data.inputFieldName === 'cellNumber'  && conscentForm?.get(data.inputFieldName)?.invalid && !conscentForm?.get('incorrectNumber')?.errors?.required">Enter
                                    a valid 10 digit Mobile Number</span>

                                <span class="required"
                                    *ngIf="data.inputFieldName === 'fax' && conscentForm?.get(data.inputFieldName)?.invalid"><br>
                                    Enter
                                    a valid 10 digit Fax Number</span>

                                <span class="error"
                                    *ngIf="data.inputFieldName === 'email'  && conscentForm?.get(data.inputFieldName)?.invalid">
                                    <br>Enter a valid email address</span>
                                <span
                                    *ngIf="data.inputFieldName === 'email'  && conscentForm?.get(data.inputFieldName)?.errors?.required && conscentForm?.get(data.inputFieldName)?.touched && !conscentForm?.get(data.inputFieldName)?.invalid"
                                    class="error">Email is Required</span>


                            </span>



                        </div>


                        <div class="row">
                            <div class="col-sm-6 col-md-6 col-lg-6 ">
                                <span>
                                    <input type="hidden" class="form-control" formControlName="parentGuardianSignature" id="consentForms_sign"
                                        value="{{signatureImg}}">
                                    <strong>Patient (or Guardian) signature <span class="requiredStar">
                                            *</span></strong>
                                    <canvas #canvas (touchstart)="startDrawing($event)"
                                        (touchmove)="moved($event)"></canvas>

                                    <button class="btn btn-primary" (click)="clearPad()" id="consentForms_clear1">Clear</button>
                                    <button class="btn btn-primary" color="secondary" (click)="savePad()" id="consentForms_save">Save</button>
                                    <p style="color:#2B3278">{{signatureImgString}}</p>
                                    <!--<img id= "signatureImg"src='{{ signatureImg }}' />-->
                                </span>
                                <span id="signatureValidation" class="required"
                                    *ngIf="conscentForm?.get('parentGuardianSignature')?.errors?.required && conscentForm?.get('parentGuardianSignature')?.touched">
                                    Patient (or Guardian) signature should be signed and saved</span>
                            </div>

                            <div class="col-sm-6 col-md-6 col-lg-6 ">
                                <span> <strong>Date:</strong>
                                    <input type="date" readonly class="form-control" formControlName="date" id="consentForms_date"
                                        value="{{currentDate}}" placeholder="{{currentDate}}">

                                </span>
                            </div>
                        </div>


                        <div class="row"
                            *ngIf="selectedTemplateAPI === 'uploadAuthorizationForMedicalRecordsForm' && templateFooter !== null && templateFooter !== ''"
                            align="center">
                            <span [innerHTML]="templateFooter"></span>

                        </div>




                    </ng-container>
                </form>
            </div>
            <div class="modal-footer" style="display: block;">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                        <button type="button" class="btn btn-default ok-book" id="consentForms_submit1"
                            (click)="submitConscentForm(templateDetails);">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="pdfConsentFormViewModal" [style.display]="pdfConsentFormViewModal ? 'block' : 'none'" class="modal"
    role="dialog">
    <div class="modal-dialog modal-dialog-centered" id="pdfviewmodal">

        <!-- Modal content-->
        <div class="modal-content ">


            <div class="row">

                <div style="float:right;margin-right:20px;"><button type="button" class="close basic-close closebutton"
                        data-dismiss="modal" aria-label="Close" (click)="clearData()" id="consentForms_clear2"><span
                            aria-hidden="true">&times;</span></button>
                </div>
            </div>

            <div class="app-modal-body add-app-body">
                <div class="consent-pdf">
                    <iframe id="consent-form-view-pdf" frameBorder="0" width="100%" height="500px"></iframe>
                </div>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <button type="button" class="btn btn-default ok-book" (click)="clearData();" id="consentForms_ok">Ok</button>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>


<div id="conscentsubmitredirectModal" [style.display]="conscentsubmitredirectModal ? 'block' : 'none'" class="modal"
    role="dialog">
    <div class="modal-dialog modal-dialog-centered">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-body">
                <h5 style="text-align: center;padding: 15px;">Thank you for submitting pre-visit documentation.</h5>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <button type="button" class="btn btn-default ok-book" data-dismiss="modal" id="consentForms_ok1"
                            (click)="navigateToHomePage()">Ok</button>
                    </div>
                </div>
            </div>

        </div>

        <!--  <div class="modal-content ">


            <div class="row">

                <div style="float:right;margin-right:20px;"><button type="button" class="close basic-close closebutton"
                        data-dismiss="modal" aria-label="Close" (click)="navigateToHomePage()"><span
                            aria-hidden="true">&times;</span></button>
                </div>
            </div>

            <div class="app-modal-body add-app-body" style="text-align:center">
                <div class="consent-pdf">
                    Thank you for submitting Pre-Visit Documentation.
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default ok-book" (click)="navigateToHomePage();">Ok</button>
            </div>
		</div>-->
    </div>
</div>




<!-- Modal -->
<div id="book-appointment-modal" [style.display]="errorShownModal ? 'block' : 'none'" class="modal" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-body">
                <h5 style="text-align: center;padding: 15px;">{{notifyText}}</h5>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <button type="button" class="btn btn-default ok-book" data-dismiss="modal" id="consentForms_ok2"
                            (click)="errorShownModal = false">Ok</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>