<div class="container">
    <div class="payments-sec" [class.mt-1]="noLogoAndStyles">
        <div class="feedback-check" [class.noWidthPayments]="noLogoAndStyles">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="!noLogoAndStyles">
                    <div class="logo-center">
                        <a class="navbar-brand" href="javascript:;" style="display: flex;">
                            <!-- <img src="../../../assets/images/careeco-logo.svg" alt="CareEco Logo"> -->
                            <!-- <img src="../../../assets/images/careeco-logo.png" alt="CareEco Logo" > -->
                            <!-- <h5>CareEco</h5> -->
                            <ng-container *ngIf="facilityData && facilityData?.smsLogoS3Link">
                                <div class="facility-logo-sec">
                                    <img class="facility-logo" src="{{facilityData?.smsLogoS3Link}}"
                                        alt="{{facilityData?.facilityName}}">
                                </div>
                            </ng-container>
                            <ng-container *ngIf="facilityData && !facilityData?.smsLogoS3Link">
                                <div class="logo"></div>
                            </ng-container>
                        </a>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <aw-wizard #paymentBillWizard class="arc-wizard new-designed-left-arcs feedback-forms"
                        navBarLocation="top" [awNavigationMode]="navigationMode" navigateBackward="allow"
                        navigateForward="allow">

                        <aw-wizard-step>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 colsm-12 text-center mb-3">
                                    <h5 class="heading">Please Enter Patient Account Number to pay pending bills </h5>
                                </div>
                            </div>
                            <div class="row" [formGroup]="paymentBillsSearchForm">
                                <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                                    <div class="form-group mb-3">
                                        <!-- <label>Primary Subscriber Last Name <span class="required">*</span></label> -->
                                        <input type="text" class="form-control input-text"
                                            formControlName="patientAccountId" id="patientAccountId"
                                            placeholder="Please Enter Patient Account Number" autofocus>
                                        <span
                                            *ngIf="paymentBillsSearchForm?.get('patientAccountId')?.errors?.required && paymentBillsSearchForm?.get('patientAccountId')?.touched"
                                            class="error required">Patient Account Number is Required</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                                    <button type="button" 
                                        class="btn btn-primary continue-btn" (click)="backNavigation()" id="paymentBill_back">Back</button>
                                    <button type="button" style="float: right;" (click)="getPatientResponsibility()"
                                        class="btn btn-primary continue-btn" id="paymentBill_continue">Continue</button>
                                </div>
                            </div>
                        </aw-wizard-step>

                        <aw-wizard-step>
                            <div class="row mb-2" *ngIf="userInfoDetails">
                                <div class="col-lg-12 col-md-12 col-sm-12 patient-info-sec">
                                    <h5 class="full-name">{{userInfoDetails?.lastName | titlecase}}, {{userInfoDetails?.firstName | titlecase}}
                                        <span class="gender-age" *ngIf="userInfoDetails?.gender">{{userInfoDetails?.gender}}</span>
                                        <span class="gender-age"
                                            *ngIf="userInfoDetails?.usrDateOfBirth">{{ageCalculate(userInfoDetails?.usrDateOfBirth)}}</span>
                                    </h5>
                                    <span class="phone-num" *ngIf="userInfoDetails?.primaryPhone"><i
                                            class="las la-phone"></i>&nbsp;{{userInfoDetails?.primaryPhone}}</span>
                                    <div class="user-address" *ngIf="userInfoDetails?.address"><i
                                            class="las la-map-marker-alt"></i>&nbsp;
                                        {{userInfoDetails?.address?.street}}, {{userInfoDetails?.address?.city}},
                                        {{userInfoDetails?.address?.state}} - {{userInfoDetails?.address?.zipCode}}
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                                    <h5 class="heading mb-3 text-center">Pending Payments Details</h5>
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>Select</th>
                                                    <th>Service Date</th>
                                                    <th>Purpose of Visit</th>
                                                    <th>Patient Responsibility</th>

                                                </tr>

                                            </thead>
                                            <tbody class="table-scroll">
                                                <ng-container *ngIf="patientResponsibilityCreditsValues?.length > 0">
                                                    <ng-container
                                                        *ngFor="let payment of patientResponsibilityCreditsValues; let i = index">
                                                        <tr *ngIf="payment?.paymentAmount > 0">
                                                            <td>
                                                                <div class="form-group text-left list-group">
                                                                    <div class="form-check">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            id="{{payment?.appointmentId}}paycheck"
                                                                            (change)="selectPatientPayment($event, payment, i)">
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>{{payment?.serviceDate ?
                                                                dateFormate(payment?.serviceDate) : '--'}}</td>
                                                            <td>{{payment?.serviceDescription || '--'}}</td>
                                                            <td><b>${{payment?.paymentAmount}}</b></td>
                                                        </tr>
                                                    </ng-container>
                                                    <tr *ngIf="totalAmountSelected">
                                                        <td colspan="3">Total Amount</td>
                                                        <td><b>${{totalAmountSelected}}</b></td>
                                                    </tr>
                                                </ng-container>
                                                <ng-container *ngIf="patientResponsibilityCreditsValues?.length === 0">
                                                    <tr>
                                                        <td colspan="4" class="text-center">No Pending Payments Found
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <!-- <div class="col-sm-12 col-md-12 col-lg-12 mt-4 d-flex">
                                    <div class="total-label">Total Amount</div>
                                    <div class="amount" style="margin-left: 60%;"></div>
                                </div> -->
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                                    <button type="button" class="btn btn-primary continue-btn"
                                        (click)="paymentBackCancelModal = true; setBackStepIndex = 2" id="paymentBill_back1">Back</button>
                                    <button type="button" style="float: right;"
                                        [attr.disabled]="patientResponsibilityCreditsValues?.length === 0 ? 'true' : null"
                                        (click)="createPayment()" class="btn btn-primary continue-btn" id="paymentBill_continue1">Continue</button>
                                </div>
                            </div>
                        </aw-wizard-step>

                        <!-- (stepEnter)="callFeedbackQuestions('Platform')"
                    [canEnter]="consultationExpCheckedValues?.length > 0 || false" -->
                        <aw-wizard-step (stepEnter)="getOrderStatus()">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 colsm-12 text-center mb-1">
                                    <h5 class="heading">Pay Your Bill</h5>
                                </div>
                            </div>
                            <h5 class="mb-2 text-center merchant-name">{{allCardDetails?.merchantName}}</h5>
                            <form id="payment-form" [class.p-0]="noLogoAndStyles">
                                <div class="main-payments-form">
                                    <div class="pt-resp br-btm-mrg">
                                        <h4 class="text-center mt-2 patient-res">Patient Responsibility</h4>
                                        <h4 class="text-center patient-res">${{(totalAmountSelected || 0) | number :
                                            '1.2-2'}}</h4>
                                    </div>
                                    <div class="address-scroll">

                                        <!-- Shipping Address Form -->
                                        <div class="row br-btm-mrg" [formGroup]="shippingAddressForm">
                                            <div class="col-12 mb-3">
                                                <h5 style="font-size: 14px;font-weight: bold;">BILLING ADDRESS</h5>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6 pr-1">
                                                <div class="form-group mb-1">
                                                    <input type="text" class="form-control input-text"
                                                        formControlName="firstName" id="firstName"
                                                        placeholder="First Name">
                                                    <span
                                                        *ngIf="shippingAddressForm?.get('firstName')?.errors?.required && shippingAddressForm?.get('firstName')?.touched"
                                                        class="error required">First Name is Required</span>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6 pl-1">
                                                <div class="form-group mb-1">
                                                    <input type="text" class="form-control input-text"
                                                        formControlName="lastName" id="lastName"
                                                        placeholder="Last Name">
                                                    <span
                                                        *ngIf="shippingAddressForm?.get('lastName')?.errors?.required && shippingAddressForm?.get('lastName')?.touched"
                                                        class="error required">Last Name is Required</span>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6 pr-1">
                                                <div class="form-group mb-1">
                                                    <input type="text" class="form-control input-text" autocorrect="off"
                                                        autocapitalize="off" spellcheck="off" #searchStreetShipping
                                                        formControlName="street" id="ADDRESS"
                                                        placeholder="Street Address">
                                                    <span
                                                        *ngIf="shippingAddressForm?.get('street')?.errors?.required && shippingAddressForm?.get('street')?.touched"
                                                        class="error required">Street is Required</span>
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-md-6 col-sm-6 pl-1">
                                                <div class="form-group mb-1">
                                                    <input class="form-control input-text" type="text"
                                                        placeholder="City" id="City" formControlName="city" />
                                                    <span class="error required"
                                                        *ngIf="shippingAddressForm?.get('city')?.errors?.required && shippingAddressForm?.get('city')?.touched">City
                                                        is Required</span>
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-md-6 col-sm-6 pr-1">
                                                <div class="form-group mb-1">
                                                    <input class="form-control input-text" type="text"
                                                        placeholder="State" id="state" formControlName="state" />
                                                    <span class="error required"
                                                        *ngIf="shippingAddressForm?.get('state')?.errors?.required && shippingAddressForm?.get('state')?.touched">State
                                                        is Required</span>
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-md-6 col-sm-6 pl-1">
                                                <div class="form-group mb-1">
                                                    <input class="form-control input-text" formControlName="zipCode"
                                                        id="zipCode" minlength="3" maxlength="5" placeholder="ZIP Code"
                                                        (blur)="addPrefixZipCode(shippingAddressForm?.get('zipCode')?.value,'valid')">
                                                    <span
                                                        *ngIf="shippingAddressForm?.get('zipCode')?.errors?.required && shippingAddressForm?.get('zipCode')?.touched"
                                                        class="error required">ZIP Code is Required</span>
                                                    <span
                                                        *ngIf="shippingAddressForm?.get('zipCode')?.invalid && !shippingAddressForm?.get('zipCode')?.errors?.required"
                                                        class="error required">Please enter valid ZIP Code, it accepts
                                                        five digit
                                                        number</span>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Payment card -->
                                        <div class="labels mb-3">
                                            <label style="font-size: 14px;font-weight: bold;">PAYMENT <br />All
                                                transactions are secure and
                                                encrypted</label>
                                        </div>
                                        <!-- <div id="payment-status-container"></div> -->
                                        <div id="card-container"></div>
                                        <div class="br-btm-mrg"></div>

                                        <div class="remember-me br-btm-mrg" [formGroup]="shippingAddressForm">
                                            <h5 class="mb-4" style="font-size: 14px;font-weight: bold;">REMEMBER ME</h5>
                                            <label class="sqpay-toggle mainSwitchSec">
                                                <div class="swicthSaveSec">
                                                    <input type="checkbox" name="sqpay-save" id="paymentBill_check"
                                                        formControlName="saveCardInformation" class="saveSwictchInput">
                                                </div>
                                                <div>
                                                    Save my information for a faster checkout
                                                </div>
                                            </label>
                                        </div>

                                    </div>


                                    <div class="credit-card-btns mt-2">
                                        <div class="form-group" style=" text-align: center;">
                                            <button style="width: 100%;padding: 10px;"
                                                *ngIf="!enableNonPosCancelPayment" id="card-button"
                                                class="btn btn-primary dash-actions" type="button"><i
                                                    class="las la-check-double"></i>&nbsp; Pay ${{(totalAmountSelected
                                                || 0) | number :
                                                '1.2-2'}}</button>
                                            <button style="width: 100%;padding: 10px;" *ngIf="enableNonPosCancelPayment"
                                                id="terminal-pay" class="btn btn-primary dash-actions" type="button"
                                                (click)="cancelPayment()">Cancel Payment</button>
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <!-- <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <iframe id="paySquareBill" title="Payment Url" width="100%" height="600" frameborder="0"></iframe>
                                </div>
                            </div> -->
                            <!-- <div class="row" *ngIf="successPaymentDetails && successPaymentDetails?.receiptUrl">
                                <div class="col-sm-12 col-md-12 col-lg-12 mt-3">
                                    <button type="button" class="btn btn-primary continue-btn" (click)="viewDownloadReceipt()">View/Download Receipt</button>
                                </div>
                            </div> -->
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12 mt-1">
                                    <button type="button" *ngIf="!successPaymentDetails"
                                        class="btn btn-primary continue-btn"
                                        (click)="paymentBackCancelModal = true" id="paymentBill_back2">Back</button>

                                    <button type="button"
                                        *ngIf="successPaymentDetails && successPaymentDetails?.receiptUrl"
                                        class="btn btn-primary continue-btn" id="paymentBill_viewInvoice" (click)="viewDownloadReceipt()">View
                                        Invoice</button>

                                    <button type="button" style="float: right;" [disabled]="!successPaymentDetails"
                                        (click)="navigateLaunchPad()" id="paymentBill_signout" class="btn btn-primary continue-btn"
                                        awNextStep>Sign-Out</button>
                                </div>
                            </div>
                        </aw-wizard-step>
                    </aw-wizard>
                </div>
            </div>
            <!-- spinner -->
            <div class="custom-loader justify-content-center" *ngIf="loadSpinner" id="spinnerDisplay">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                    <span class="spinnerMsg" *ngIf="spinnerMessageToUser">{{spinnerMessageToUser}}</span>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal *ngIf="successPaymentDetails && successPaymentDetails?.receiptUrl"-->
<div id="book-appointment-modal" [style.display]="exceptionalModal ? 'block' : 'none'" class="modal" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-body">
                <h5 style="text-align: center;padding: 15px;">{{notifyText}}</h5>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="successPaymentDetails && successPaymentDetails?.receiptUrl" class="btn btn-primary continue-btn" data-dismiss="modal"
                    (click)="exceptionalModal = false;navigateLaunchPad()" id="paymentBill_ok">Ok</button>
                <button type="button" *ngIf="!successPaymentDetails" class="btn btn-primary continue-btn" data-dismiss="modal"
                    (click)="exceptionalModal = false;" id="paymentBill_ok2">Ok</button>
                <button type="button" *ngIf="successPaymentDetails && successPaymentDetails?.receiptUrl" class="btn btn-primary continue-btn" (click)="viewDownloadReceipt()" id="paymentBill_viewInvoice1">View Invoice</button>
            </div>
        </div>
    </div>
</div>

<!-- Payment Back Cancel Modal -->
<div id="book-appointment-modal" [style.display]="paymentBackCancelModal ? 'block' : 'none'" class="modal"
    role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-body">
                <h5 style="text-align: center;padding: 15px;">Are you sure, you want to cancel the payment?</h5>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary continue-btn" data-dismiss="modal" id="paymentBill_no"
                    (click)="paymentBackCancelModal = false;">NO</button>
                <button type="button" class="btn btn-primary continue-btn" data-dismiss="modal" id="paymentBill_yes"
                    (click)="cancelPaymentBack()">YES</button>
            </div>
        </div>
    </div>
</div>