import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { WizardComponent } from 'angular-archwizard';
import { HttpService } from 'src/app/shared/http.service';

@Component({
  selector: 'app-medical-history',
  templateUrl: './medical-history.component.html',
  styleUrls: ['./medical-history.component.scss']
})
export class MedicalHistoryComponent implements OnInit {

  @Input() appointmentDetails: any;
  public medicalHistoryNode?: any;
  rosOthersSelectedValues: any;
  public loadSpinner?: boolean;
  public medicalHistoryForm!: FormGroup;
  public allSectionsModules!: FormArray;
  @Input() previsitDocsWizard!: WizardComponent;

  constructor(private httpService: HttpService, private formBuilder: FormBuilder) {
    this.medicalHistoryForm = this.formBuilder?.group({
      'allSectionsModules': this.formBuilder?.array([])
    });
  }


  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.getModuleInfo();
  }

  public checkGenderPurposeVisit(modeMetaData: any): boolean {
    if ((modeMetaData?.gender === 'All' || modeMetaData?.gender === this.appointmentDetails?.gender) && (modeMetaData?.purposeOfVisit?.includes('All') || modeMetaData?.purposeOfVisit?.includes(this.appointmentDetails?.appointment?.purposeOfVisit))) {
      return true;
    }
    return false;
  }

  public checkAnnual(nodeName: string): boolean {
    let check: boolean = false;
    if (nodeName) {
      check = true;
    }
    if (nodeName === 'Annual Wellness Visit' && this.appointmentDetails?.appointment?.purposeOfVisit === 'Annual Physicals' && this.appointmentDetails?.age < 65 && this.appointmentDetails?.facilityId === 201) {
      check = false;
    }
    if (nodeName === 'Annual Wellness Visit' && this.appointmentDetails?.appointment?.purposeOfVisit === '65 Above Annual Wellness Visit' && this.appointmentDetails?.age >= 65) {
      check = true;
    } else if (nodeName === 'Annual Wellness Visit' && this.appointmentDetails?.appointment?.purposeOfVisit === '65 Above Annual Wellness Visit' && this.appointmentDetails?.age < 65) {
      check = false;
    }
    return check || false;
  }

  public getModuleInfo(): void {
    // const action = `careFlow/getCareFlowModuleInfo?facilityId=${this.appointmentDetails?.facilityId}&module=ROS`;
    const action = `careFlow/getCareFlowModuleAllInfo?module=Medical History&name=Cad-ModuleDetails`;
    this.loadSpinner = true;
    this.httpService.makeGetRequest(action).subscribe(
      (data: any) => {
        if (data?.status === 'SUCCESS') {
          this.medicalHistoryNode = data?.responseObject?.resourceType || [];
          if (data?.status === 'SUCCESS') {
            this.medicalHistoryNode = data?.responseObject?.resourceType || [];

            // loop and created dynamic formGroups
            const rosNodeSections = JSON.parse(JSON?.stringify(this.medicalHistoryNode)) || [];

            rosNodeSections?.forEach((vitalFormItem: any) => {

              // if (!this.checkGenderPurposeVisit(vitalFormItem?.sectionMetadata)) {
              //   return;
              // }

              let sectionsForm: FormGroup;
              sectionsForm = this.formBuilder?.group({
                'module': ['Medical History' || null],
                'resourceType': [vitalFormItem?.name || null],
                'sections': this.formBuilder?.array([])
              });

              // looping all sections to craete parent Form Groups
              vitalFormItem?.sections?.forEach((secItem: any) => {

                let formControls: FormGroup;
                formControls = this.formBuilder?.group({
                  'name': [secItem?.name, null],
                  'answers': this.formBuilder?.array([])
                });
                const subControlsFormArray = formControls?.get('answers') as FormArray;

                // formControls?.get('name')?.setValue(secItem?.name);
                this.clearFormArray(subControlsFormArray as FormArray);

                // looping the questions or sub sections
                secItem?.questions?.forEach((queItem: any) => {
                  // creating the Form Array if have multiselect with Sub Sections
                  if (queItem?.subSections && queItem?.subSections?.length > 0) {

                    let subControls: FormGroup = this.formBuilder?.group({
                      'label': [queItem?.label || null],
                      'actualValue': [null],
                      'additionalText': [queItem?.additionalText],
                      'properties': [queItem?.properties || null],
                      'values': [queItem?.values || null],
                      'subSections': this.formBuilder?.array([]),
                      'selectedSubValues': this.formBuilder?.array([])
                    });
                    if (queItem?.additionalText) {
                      subControls?.addControl('otherNotes', new FormControl(null));
                    }
                    const subItemArrayForm = subControls?.get('subSections') as FormArray;
                    // This is Sub sections Form Group

                    queItem?.subSections?.forEach((arrayItem: any) => {
                      const subItemGroup: FormGroup = this.formBuilder?.group({
                        'label': [arrayItem?.label || null],
                        'additionalText': [arrayItem?.additionalText],
                        'properties': [arrayItem?.properties || null],
                        'values': [arrayItem?.values || null],
                      });
                      if (arrayItem?.additionalText) {
                        subItemGroup?.addControl('otherNotes', new FormControl(null));
                      }
                      let controlAdd: string;
                      controlAdd = arrayItem?.label;
                      if (arrayItem?.label?.includes('Duration')) {
                        controlAdd = 'duration';
                      }
                      if (arrayItem?.label?.includes('Severity')) {
                        controlAdd = 'severityStatus';
                      }
                      subItemGroup?.addControl(controlAdd, new FormControl(null));
                      subItemArrayForm?.push(subItemGroup);
                    });
                    subControlsFormArray?.push(subControls);

                  } else if (!queItem?.subSections) {
                    let subControls: FormGroup = this.formBuilder?.group({
                      'label': [queItem?.label || null],
                      'actualValue': [null],
                      'additionalText': [queItem?.additionalText || null],
                      'properties': [queItem?.properties || null],
                      'values': [queItem?.values || null]
                    });
                    if (queItem?.additionalText) {
                      subControls?.addControl('otherNotes', new FormControl(null));
                    }
                    subControlsFormArray?.push(subControls);
                  }
                });

                const sectionsFormArray = sectionsForm?.get('sections') as FormArray;
                sectionsFormArray?.push(formControls);
              });

              const allGroups = this.medicalHistoryForm?.get('allSectionsModules') as FormArray;
              allGroups?.push(sectionsForm);
            });
          }
        }
        console.log(this.medicalHistoryForm);
        this.loadSpinner = false;
      },
      (error) => {
        this.loadSpinner = false;
      }
    );
  }

  // common clear form array
  public clearFormArray(formArray: FormArray): void {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }

  // to make selected object for Review of Systems
  public changeMedicalHistoryCheckValues(event: any, answer: any): void {
    // const allResourceTypeArray = this.medicalHistoryForm?.get('allSectionsModules') as FormArray;
    // let getFormGroup = allResourceTypeArray?.controls?.find((groupItem: any) => node?.name === groupItem?.value?.resourceType) as FormGroup;

    // const sectionsArray = getFormGroup?.get('sections') as FormArray;
    // const secFormGroup = sectionsArray?.controls?.find((secItem: any) => node?.name === secItem?.value?.name) as FormGroup;

    // const answersArray = secFormGroup?.get('answers') as FormArray;
    // const getAnsFormGroup = answersArray?.controls?.find((ansItem: any) => question?.label === ansItem?.value?.label) as FormGroup;
    const answerForm = answer as FormGroup;
    let value = JSON.parse(JSON?.stringify(answerForm?.get('actualValue')?.value || []));
    if (event.target.checked) {
      value?.push(event.target.value)
      answerForm?.get('actualValue')?.setValue(value);
    } else {
      value = value?.filter((unCheckItem: any) => unCheckItem !== event.target.value);
      answerForm?.get('actualValue')?.setValue(value);
    }
  }

  // Change Radio Buttons
  
  public changeRadio(sectionsName?: string, answerLabel?: string, optionsActualValue?: string, answer?: any, optValue?: string): void {
    const newFormGroup = answer as FormGroup;
    const selectedValue = newFormGroup?.get('actualValue')?.value;
    const element = document?.getElementById(`${sectionsName || ''}${answerLabel}${optionsActualValue}`) as HTMLInputElement;
    if (selectedValue === optValue) {
      element.checked = false;
      newFormGroup?.get('actualValue')?.reset();
    }
  }

  // save ReviewOfSystems data
  public checkMedicalHistoryParams(): any {
    let requestPayload = JSON?.parse(JSON?.stringify(this.medicalHistoryForm?.get('allSectionsModules')?.value || []));

    requestPayload?.forEach((item: any) => {
      item.otherNotes = '';
      item.completed = false;
      item.auditInfo = {
        "createdBy": "",
        "createdDate": "",
        "updatedBy": "",
        "updatedDate": ""
      }
      item?.sections?.forEach((secItem: any) => {
        secItem?.answers?.forEach((ansItem: any) => {
          if (Array?.isArray(ansItem.actualValue)) {
            ansItem.actualValue = ansItem?.actualValue?.map((mapItem: any) => {
              return {
                answer: mapItem?.actualValue || mapItem
              }
            });
          } else {
            ansItem.actualValue = ansItem?.actualValue ? [{ answer: ansItem?.actualValue }] : [];
          }
          delete ansItem?.additionalText;
          delete ansItem?.properties;
          delete ansItem?.subSections;
          delete ansItem?.values;
          delete ansItem?.selectedSubValues;
        })
      });
    });

    // requestPayload = requestPayload?.filter((resItem: any) => resItem?.resourceType !== 'Additional Notes') || [];

    const params = {
      appointmentId: this.appointmentDetails?.id,
      facilityId: this.appointmentDetails?.facilityId,
      userInfoId: this.appointmentDetails.userInfoId,
      moduleName: 'Medical History',
      careFlowModule: requestPayload || [],
    };
    return params;
  }

  get allModulesGroup(): FormArray {
    return this.medicalHistoryForm?.get('allSectionsModules') as FormArray;
  }

  public sectionsModuleForm(sectionsModule: any): FormArray {
    return sectionsModule?.get('sections') as FormArray;
  }

  public answersForm(sections: any): FormArray {
    return sections?.get('answers') as FormArray;
  }

  public getlabelId(labelName: any){
 
    labelName = labelName.replace(/\s/g, '');
    return labelName;
 
  }
}
