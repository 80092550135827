<div class="pre-appointment-questions review-of-systems-compt-questions" [formGroup]="medicalHistoryForm">
    <ng-container *ngIf="medicalHistoryNode?.length > 0">
        <div class="form-array-check" formArrayName="allSectionsModules">
            <div class="loop-form-array" *ngFor="let sectionsModule of allModulesGroup?.controls; let mainI = index">
                <div class="formGrpu" [formGroupName]="mainI">
                    <div>
                        <!-- <h5>{{sectionsModule?.value?.resourceType}}</h5> -->
                        <div class="row" formArrayName="sections">
                            <div *ngFor="let sections of sectionsModuleForm(sectionsModule)?.controls;let secI=index"
                                [formGroupName]="secI" class="col-sm-12">
                                <h5>{{sections?.value?.name}}</h5>
                                <hr>
                                <div class="row" formArrayName="answers">

                                    <div *ngFor="let answer of answersForm(sections)?.controls; let ansI = index"
                                        [formGroupName]="ansI" class="col-lg-12 col-md-12 col-sm-12 otherRos">
                                        <div class="categories">

                                            <label for="first-name">{{answer?.value?.label}} </label>
                                            <div class="row">

                                                <ng-container *ngIf="answer?.value?.properties?.inPutType==='checkBox'">
                                                    <ng-container
                                                        *ngFor="let item of answer?.value?.values;let checki = index">
                                                        <div class="form-group text-left list-group">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox"
                                                                    [value]="item?.actualValue"
                                                                    id="{{getlabelId(item[checki]?.actualValue)}}{{getlabelId(answer?.value.label)}}{{getlabelId(item?.actualValue)}}"
                                                                    (change)="changeMedicalHistoryCheckValues($event, answer)">
                                                                <label
                                                                    for="{{item[checki]?.actualValue}}{{answer?.value.label}}{{item?.actualValue}}"
                                                                    class="cr list-name-label">{{item?.actualValue}}</label>
                                                            </div>
                                                        </div>

                                                    </ng-container>
                                                </ng-container>

                                                <ng-container *ngIf="answer?.value?.additionalText">
                                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                                        <textarea class="form-control" type="text"
                                                            formControlName="actualValue"
                                                            placeholder="Other / Additional Notes"
                                                            id=questionAdditonalText></textarea>
                                                    </div>
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="answer?.value?.properties?.inPutType === 'textArea'">
                                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                                        <textarea class="form-control" formControlName="actualValue"
                                                            placeholder={{answer?.value?.values[0]?.actualValue}}
                                                            id={{getlabelId(answer?.value?.values[0]?.actualValue)}}></textarea>
                                                    </div>
                                                </ng-container>
                                            </div>

                                            <div class="row">
                                                <div class="col-lg-12 col-md-12 col-sm-12">

                                                    <div class="form-group">
                                                        <ng-container
                                                            *ngIf="answer?.value?.properties?.inPutType === 'textBox'">
                                                            <input class="form-control input-text"
                                                                formControlName="actualValue" type="text"
                                                                placeholder={{answer?.value?.values[0]?.actualValue}}
                                                                id="location1" />
                                                        </ng-container>

                                                        <ng-container
                                                            *ngIf="answer?.value?.properties?.inPutType === 'number'">
                                                            <input class="form-control input-text"
                                                                formControlName="actualValue" type="number"
                                                                placeholder={{answer?.value?.values[0]?.actualValue}}
                                                                id="location2" />
                                                        </ng-container>

                                                        <!-- <ng-container
                                                            *ngIf="answer?.value?.properties?.inPutType === 'slideScale' ">
                                                            <ngx-slider [options]="options"
                                                                formControlName="actualValue">
                                                            </ngx-slider>
                                                        </ng-container> -->

                                                        <ng-container
                                                            *ngIf="answer?.value?.properties?.inPutType === 'autoComplete'">
                                                            <input class="form-control bmi input-text"
                                                                formControlName="actualValue" type="text"
                                                                placeholder={{answer?.value?.values[0]?.actualValue}}
                                                                id="{{getlabelId(answer?.value?.values[0]?.actualValue)}}" />
                                                        </ng-container>

                                                        <ng-container
                                                            *ngIf="answer?.value?.properties?.inPutType === 'dropDown'">
                                                            <ng-select
                                                                placeholder="Choose {{answer?.value?.values[0]?.actualValue}}"
                                                                bindValue="actualValue" formControlName="actualValue"
                                                                [addTag]="true">
                                                                <ng-option *ngFor="let options of answer?.value?.values"
                                                                    [value]="options?.actualValue">
                                                                    {{options?.actualValue}}
                                                                </ng-option>
                                                            </ng-select>
                                                        </ng-container>

                                                        <ng-container
                                                            *ngIf="answer?.value?.properties?.inPutType === 'radioButton'">
                                                            <!-- <br /> -->
                                                            <!-- <label class="radio-inline"
                                                                *ngFor="let options of answer?.value?.values">
                                                                <input type="radio" formControlName="actualValue"
                                                                    id="{{sections?.value?.name}}{{answer?.value?.label}}"
                                                                    [value]="options?.actualValue"><span
                                                                    class="desc">{{options?.actualValue}}</span>
                                                            </label> -->

                                                            <div class="form-check form-check-inline" *ngFor="let options of answer?.value?.values">
                                                                <input class="form-check-input"
                                                                type="radio" formControlName="actualValue"
                                                                id="{{getlabelId(sections?.value?.name)}}{{getlabelId(answer?.value?.label)}}{{getlabelId(options?.actualValue)}}" (click)="changeRadio(sections?.value?.name, answer?.value?.label, options?.actualValue, answer, options?.actualValue)"
                                                                [value]="options?.actualValue">
                                                                <label class="form-check-label"
                                                                    for="{{sections?.value?.name}}{{answer?.value?.label}}{{options?.actualValue}}">{{options?.actualValue}}</label>
                                                            </div>

                                                        </ng-container>

                                                        <!-- <ng-container
                                                            *ngIf="answer?.value?.properties?.inPutType === 'multiSelect'">
                                                            <ng-select
                                                                placeholder="Select {{answer?.value?.label}}"
                                                                [multiple]="true" bindLabel="actualValue"
                                                                bindValue="actualValue" [items]="answer?.value?.values"
                                                                [closeOnSelect]="false" clearAllText="Clear"
                                                                [addTag]="true" formControlName="actualValue">
                                                                <ng-template ng-multi-label-tmp let-items="items"
                                                                    let-clear="clear">
                                                                    <div class="ng-value"
                                                                        *ngFor="let item of items | slice:0:3">
                                                                        <span
                                                                            class="ng-value-label">{{item?.actualValue}}</span>
                                                                        <span class="ng-value-icon right"
                                                                            (click)="clear(item)"
                                                                            aria-hidden="true">×</span>
                                                                    </div>
                                                                    <div class="ng-value" *ngIf="items.length > 3">
                                                                        <span class="ng-value-label">{{items.length -
                                                                            3}} more...</span>
                                                                    </div>
                                                                </ng-template>
                                                            </ng-select>
                                                        </ng-container> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<!-- spinner -->
<div class="custom-loader justify-content-center" *ngIf="loadSpinner" id="spinnerDisplay">
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>