import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild, Inject, Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { HttpService } from '../../shared/http.service';
import { MapsAPILoader } from '@agm/core';

import { Router, ActivatedRoute, Params } from '@angular/router';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { concat, Subject, Observable, of, Subscription } from 'rxjs';
import jsPDF from 'jspdf';
import { DomSanitizer } from '@angular/platform-browser'
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { environment } from 'src/environments/environment';
import { AppRegExpressionsConfig } from 'src/app/shared/app.regularexpressions';
import { Location, DOCUMENT, LocationStrategy, formatDate } from '@angular/common';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { ConscentformsComponent } from './conscentforms/conscentforms.component';
import { ChiefComplaintsComponent } from '../chief-complaints/chief-complaints.component';
import { ReviewOfSystemsComponent } from '../review-of-systems/review-of-systems.component';
import { WizardComponent } from 'angular-archwizard';
import { MedicalHistoryComponent } from '../medical-history/medical-history.component';
import { BoneMineralDensityScanComponent } from '../bone-mineral-density-scan/bone-mineral-density-scan.component';
import { CareOutreachService } from 'src/app/shared/careoutreach.service';


export interface cmInterface {
  name: string,
  values: any[]
}

@Component({
  selector: 'app-schedule-appointment',
  templateUrl: './schedule-appointment.component.html',
  styleUrls: ['./schedule-appointment.component.scss']
})
export class ScheduleAppointmentComponent implements OnInit {
  public patientType: string = '';
  public searchElementRef?: ElementRef;
  public newPurposeOfVisit = [];
  facilityAddress: any;
  public loadSpinner?: boolean;
  public navigateBackToProviderFlowBoard?: boolean = false;
  public zipcodeValidationIfmorethan?: boolean;
  public exceptionalModal?: boolean;
  notifyText: any;
  navigateBacktoCareJouney: any;
  public medicationRefillsModal?: boolean;
  public getUserInfoIdWithPatientDetails?: any;
  public medicationRefillsCheck?: string;
  public selectedPurposeOfVisit: any;
  facilityId: any;
  public appointmentIdForReSchedule: any;
  public providerInfoAllDoctors: any[] = [];
  insuranceDetailsRetaining: any;
  allCadModulesDetails: any;
  public outreachToken: any;
  isAwhMammogramQue: any;
  is85FacilityChange: any;
  visorEpisodeOfCareId: any;
  outreachCampaignId: any;
  
  @ViewChild('search') set content(content: ElementRef) {
    if (content) {
      // initially setter gets called with undefined
      this.searchElementRef = content;
    }
  }

  public bookAppointmentForm: UntypedFormGroup;
  public minAppointmentDate?: Date;
  public latitude?: any;
  public longitude?: any;
  currentLocation?: string;
  public doctorsList = [];
  public doctorsListAdd = [];
  public getAppointmentSlotsAvailable = [];
  public selectedProvider: any;
  public typeOfServices = [];
  public errorMessage: any;
  public appointmentsNotAvailable = false;
  public listTemplates: any[] = [];
  public typeOfPatient = [
    { value: 'Old', desc: 'Established Patient' },
    { value: 'New', desc: 'New Patient' }
  ]
  public showModal = false;
  public showEsignModal = false;
  public messageShown: any;
  public appointmentSlotsMessage: any;
  public apiStatus?: boolean;
  public purposeOfVisit = [];
  public patientPreExistingConditions = [
    { value: 'None', desc: 'None' },
    { value: 'Cancer', desc: 'Cancer' },
    { value: 'Chronic Kidney Disease', desc: 'Chronic Kidney Disease' },
    { value: 'COPD (Chronic Obstructive Pulmonary Disease)', desc: 'COPD (Chronic Obstructive Pulmonary Disease)' },
    { value: 'Down Syndrome', desc: 'Down Syndrome' },
    { value: 'Serious Heart Conditions, such as heart failure, coronary artery disease, or cardiomyopathies', desc: 'Serious Heart Conditions, such as heart failure, coronary artery disease, or cardiomyopathies' },
    { value: 'Immunocompromised state (weak immune system)', desc: 'Immunocompromised state (weak immune system)' },
    { value: 'Obesity (body mass index of 30 kg/m or higher)', desc: 'Obesity (body mass index of 30 kg/m or higher)' },
    { value: 'Sickle Cell Disease', desc: 'Sickle Cell Disease' },
    { value: 'Current or Former Cigarette Smoker', desc: 'Current or Former Cigarette Smoker' },
    { value: 'Type 2 Diabetes Mellitus', desc: 'Type 2 Diabetes Mellitus' },
    { value: 'Intellectual or Developmental Disability', desc: 'Intellectual or Developmental Disability' },
    { value: 'Severe and Persistent Mental or Behavioral Health Condition', desc: 'Severe and Persistent Mental or Behavioral Health Condition' },
    { value: 'Immunocompromised or Immunosuppressed', desc: 'Immunocompromised or Immunosuppressed' },
    { value: 'Other', desc: 'Other' }
  ];
  public isSelfPay = [
    { value: 'Insurance', desc: 'Insurance' },
    { value: 'Self-Pay', desc: 'Self-Pay' },
    { value: 'healthPass', desc: 'Health Pass' },
    // { value: 'Un-Insured', desc: 'Un-Insured' }
  ]

  public insuranceTypes = [
    { value: 'medicare', desc: 'Medicare' },
    { value: 'other', desc: 'Other' },
  ]

  public allEthnicity = [
    { value: 'Hispanic', desc: 'Hispanic' },
    { value: 'Not Hispanic', desc: 'Not Hispanic' },
    { value: 'Unknown', desc: 'Unknown' },
    { value: 'I don’t want to specify', desc: 'I don’t want to specify' }
  ]
  public allRaceItems = [
    { value: 'American Indian or Alaska Native', desc: 'American Indian or Alaska Native' },
    { value: 'Asian', desc: 'Asian' },
    { value: 'Black or African American', desc: 'Black or African American' },
    { value: 'Native Hawaiian or Other pacific Islander', desc: 'Native Hawaiian or Other pacific Islander' },
    { value: 'White', desc: 'White' },
    { value: 'Other Race/Multiracial', desc: 'Other Race/Multiracial' },
    { value: 'Unknown', desc: 'Unknown' },
    { value: 'I don’t want to specify', desc: 'I don’t want to specify' }
  ]
  public allInsurances: any[] = [];
  portalId: any;
  public arrayValues: any[] = [];
  public getParamId: any;
  public isurgentCare = false;
  public allPreAppntQuestionsCategories: any;
  facilityName: any;
  checkReschedule: any;
  public showAppointmentSlots = false;
  public previsitDocsSubmited = false;
  public doctorDropdownValidation = false;
  public getWebToken: any;
  public address: any;
  public facilityWorkingHours = [];
  public facilityContact = [];
  public insuranceCardImageId: string = ''
  public identityImageId: string = ''
  public imagesCount: number = 0;
  public drivingImagesCount: number = 0
  public insuranceImagesCount: number = 0;
  public displayReasonForVisitErrMsg: boolean = false;
  public insurancePrimarySubscriber = [
    { value: 'Self', desc: 'Self' },
    { value: 'Other', desc: 'Other' },
  ]

  public covidVaccineDosage = [
    { value: '1', desc: 'First Dosage' },
    { value: '2', desc: 'Second Dosage' },
  ]
  public maxAppointmentDate?: Date;
  public minDOB = this.convertDate(new Date('01 Jan 1910'));
  // toggle webcam on/off
  public showWebcam = false;
  public showInsuranceWebcam = false;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId?: string;
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];

  // latest snapshot
  public webcamInsuranceImage = null as unknown as WebcamImage;
  public webcamLicenseImage = null as unknown as WebcamImage;
  public webcamInsuranceImages: WebcamImage[] = [];
  public webcamLicenseImages: WebcamImage[] = [];
  public insuranceImagePDFFile = new jsPDF();
  public licenseImagePDFFile = new jsPDF();
  public formData: any = new FormData();
  public aplhaNumericHyphenWithSpace: RegExp = /^[a-zA-Z0-9-]+[a-zA-Z0-9-\s]*$/;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  private triggerLicense: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
  public checkCovidVisit?: boolean;
  private routeSub: Subscription;
  public displayInsuranceImages: boolean = false;
  public displayLicenseImages: boolean = false;
  public selectedROSAndCC: any[] = [];
  public age?: 0;
  public gender: string = '';
  public maritalStatus: string = '';
  public appointmentId: string = '';
  public userInfoId: string = '';
  public ifNoPatientDetails: any;
  public noPatientDetailsMsg: any;
  public displayPatientInfo: boolean = true;
  public displayInsuranceInfo: boolean = false;
  public displayChiefComplaints: boolean = false;
  public displayROS: boolean = false;
  public displayConsentForms: boolean = false;
  public disableSubmitBtn: boolean = true;
  public displaySuccessScreen: boolean = false;
  public dtAppointmentDetails: any;
  public providerworkflow: any;
  public currentYear = new Date().getFullYear();
  public currentMonth = new Date().getMonth();
  public currentDate = new Date().getDate();
  public iAgreeToTerms: boolean = true;
  public iAgreeToTelemedicineTerms: boolean = true;
  public consentDocsSubmited: boolean = false;
  public noSlots: boolean = false;
  public isFormSubmit: boolean = false;
  public genderSelection = [
    { value: "Male", desc: "Male" },
    { value: "Female", desc: "Female" },
    { value: "Trans Female", desc: "Trans Female" },
    { value: "Trans Male", desc: "Trans Male" },
    { value: "Non-binary", desc: "Non-binary" },
    { value: "Other", desc: "Other" },
    { value: "Unknown", desc: "Unknown" },

  ]

  public maritalStatusSelection = [
    { value: "Single", desc: "Single" },
    { value: "Partnered", desc: "Partnered" },
    { value: "Married", desc: "Married" },
    { value: "Separated", desc: "Separated" },
    { value: "Divorced", desc: "Divorced" },
    { value: "Widowed", desc: "Widowed" },
    { value: "Other", desc: "Other" },
    { value: "Unknown", desc: "Unknown" },
  ]
  private chiefSelectedValues!: cmInterface;
  private rosOthersSelectedValues: any[] = [];
  public kioskAddAppointment: boolean = false;
  public addPatientScheduleType = [
    { value: 'In Queue', desc: 'In Queue' },
    { value: 'Future Appointment', desc: 'Future Appointment' }
  ];
  public insuranceSequenceList = [
    { desc: 'Primary', value: 'Primary' },
    { desc: 'Secondary', value: 'Secondary' },
    { desc: 'Tertiary', value: 'Tertiary' }
  ];
  public insuranceClassList = ['Commercial', 'Medicare', 'Medicare Advantage', 'Medicaid'];

  public allInsuranceList?: Observable<any>;
  public insuranceLoading = false;
  public insuranceInput = new Subject<string>();
  public insuranceRelationship = [
    { value: 'Father', desc: 'Father' },
    { value: 'Mother', desc: 'Mother' },
    { value: 'Spouse', desc: 'Spouse' },
    { value: 'Siblings', desc: 'Siblings' },
    { value: 'Friend', desc: 'Friend' },
    { value: 'Guardian', desc: 'Guardian' },
    { value: 'Son', desc: 'Son' },
    { value: 'Daughter', desc: 'Daughter' },
    { value: 'Partner', desc: 'Partner' },
    { value: 'Child', desc: 'Child' }
  ];
  public otherNotes?: string;
  public selectedUserInfoId: any;
  public availableSlots: any = [];
  public availableSlotsAdd: any = [];


  isdesabled: boolean = false;

  @ViewChild(ConscentformsComponent) ConscentComponent?: ConscentformsComponent;
  public seletcedIdCard: any;
  @ViewChild(ChiefComplaintsComponent) private chiefComplaintsComp?: ChiefComplaintsComponent;
  @ViewChild(ReviewOfSystemsComponent) private reviewOfSystemsComp?: ReviewOfSystemsComponent;
  public isHealthpassDisabled: boolean = true;
  public displayMedicalHistory?: boolean;
  public displayBoneDensity?: boolean;
  public navgationVal: boolean = true;
  public navigationMode: any = null;
  @ViewChild(WizardComponent) public previsitDocsWizard!: WizardComponent;
  @ViewChild(MedicalHistoryComponent) public medicalHistoryComp?: MedicalHistoryComponent;
  @ViewChild(BoneMineralDensityScanComponent) public boneMineralDensitySacnComp?: BoneMineralDensityScanComponent;

  public selectedVal: any = 'No';
  public disablePurposeOfVisit: boolean = false;
  public doctorFirstName: any;
  public doctorLastName: any;
  public selcetdSlotIndex: any;
  public visitTypearr: any = [];
  public newVisitArr: any = [];
  public multiVisitCheck: boolean = false;
  public visitSelected: boolean = false;
  public defaultChk: boolean = false;
  public isDoctorSelected: boolean = false;
  public selcetdVisitIndex: any;
  public addlAppointmentsInfo: any = [];
  public appointmentGap: any;
  public nextApptDt: any;
  public dtGapValid: boolean = true;
  public aapDetails: any;
  public visitRules: any;
  public isFutureAppt: boolean = false;
  public showWaitlistChk: boolean = false;
  public validationRequired: boolean = true;
  public languageList:any = [
    { value: 'English', desc: 'English' },
    { value: 'Chinese', desc: 'Chinese' },
    { value: 'Spanish', desc: 'Spanish' },
    { value: 'Hindi', desc: 'Hindi' }
  ]
  public purposeOfVisitSel: any;

  constructor(@Inject(DOCUMENT) private readonly document: Document,
    private readonly locationStrategy: LocationStrategy, private formBuilder: UntypedFormBuilder, private httpService: HttpService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone, private router: Router, private activeRoute: ActivatedRoute, private sanitizer: DomSanitizer, private _location: Location, private careOutreachService: CareOutreachService) {
    this.bookAppointmentForm = this.formBuilder.group({
      'PURPOSE_OF_VISIT': [null, Validators.required],
      //'PATIENT_FIRST_NAME': [null, Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z \-\']+')])],
      //'PATIENT_MIDDLE_NAME': [null, Validators.compose([Validators.pattern('^[a-zA-Z \-\']+')])],
      //'PATIENT_LAST_NAME': [null, Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z \-\']+')])],
      
      'PATIENT_FIRST_NAME': [null, Validators.compose([Validators.required, Validators.pattern(AppRegExpressionsConfig.patientNameValidator)])],
      'PATIENT_MIDDLE_NAME': [null, Validators.compose([Validators.pattern(AppRegExpressionsConfig.patientNameValidator)])],
      'PATIENT_LAST_NAME': [null, Validators.compose([Validators.required, Validators.pattern(AppRegExpressionsConfig.patientNameValidator)])],
      'DATE_OF_BIRTH': [null, Validators.required],
      'GENDER': [null, Validators.required],
      'MARITAL_STATUS': [null, Validators.required],
      'ADDRESS': [null, Validators.required],
      'CITY': [null, Validators.required],
      'STATE': [null, Validators.required],
      'COUNTRY': [null],
      'ZIPCODE': [null, Validators.compose([Validators.required, Validators.pattern(AppRegExpressionsConfig.pincode)])],
      'PHONE_NUMBER': [null, Validators.compose([Validators.required, Validators.pattern(AppRegExpressionsConfig?.mobile)])],
      // 'EMAIL': [null],
      'DOCTOR': [null, Validators.required],
      'APPOINTMENT_DATE': [null,[Validators.required,this.dateValidator] ],
      'APPOINTMENT_SLOT': [null, Validators.required],
      'TYPE_OF_SERVICE': [null, Validators.required],
      'TYPE_OF_PATIENT': [null, Validators.required],
      'IS_SELF_PAY': [null, Validators.required],
      'ETHNICITY': [null, Validators.required],
      'RACE': [null, Validators.required],
      'SCHEDULE_TYPE': [null],
      'ENDTIME_SLOT': [null],
      'eligibilityRealtimePayerID': [null],
      'claimPayerId': [null],
      'eligibilityPortalID': [null],
      'idDocument': this.formBuilder?.group({
        documentType: [null],
        documentId: [null],
        issueDate: [null],
        expiryDate: [null],
        identityImageId: [null]
      }),
      'doctor_first_name': [null],
      'doctor_last_name': [null],
      'patientConsent': [null],
      'waitList': [null],
      'notes': [null],// athena related notes
      'preferredLanguage':[null]
    });

    this.routeSub = this.activeRoute.params.subscribe(params => {
      // console.log(params) //log the entire params object
      if (params['id']) {
        this.getPatientDetails(params['id']);
      }
      const facilityId = params['facilityId'];
      sessionStorage?.setItem('headerFacilityId', facilityId);
      this.getProviderInfo(facilityId);
      this.facilityId = facilityId;
    });

    this.activeRoute?.queryParams?.subscribe(
      (params: Params) => {
        if (Object.keys(params).length > 0) {
          this.checkReschedule = JSON.parse(sessionStorage?.getItem('isReschedule') || '{}') || null;
          if (params && params['facilityId']) {
            const id = params['facilityId'] || null;
            this.facilityId = id;
            sessionStorage?.setItem('headerFacilityId', this.facilityId);
            this.getProviderInfo(id);
          }
          if (params && params['previsitId']) {
            const appointmentId = params['previsitId'] || null;
            this.appointmentId = appointmentId;
            this.getAppointmentById(appointmentId);
            this.getAllCareFlowCadModulesDetails(appointmentId);
          }
          if (params && params['id']) {
            const getId = params['id'] || null;
            this.getWebToken = parseInt(getId);
            if (getId && this.checkReschedule?.isReschedule) {
              this.getPatientDetailsforReSchedule(getId)
            } else {
              this.getPatientDetails(getId);
            }
          }
          // to prefill the data if navigate from outreach id
          if (params && params['outreachId']) {
            const outreachId = params['outreachId'] || null;
            this.outreachToken = outreachId;
            this.getPatientDetails(outreachId, params['newCampaign']);
          }

          if (params && params['campaignId']) {
            const campaignId = params['campaignId'] || null;
            this.outreachCampaignId = campaignId;
          }

          if (params && params['navigateCareJouney']) {
            const navigateCareJouney = params['navigateCareJouney'] || null;
            this.navigateBacktoCareJouney = navigateCareJouney || false;
          }
          // to check kisok Appouintment schedule
          if (params && params['kioskAppointment'] === 'yes') {
            sessionStorage.setItem('kioskAppointment', 'yes');
            this.kioskAddAppointment = true;
            this.navigateBackToProviderFlowBoard = true;
            this.bookAppointmentForm?.get('SCHEDULE_TYPE')?.setValidators([Validators?.required]);
            this.bookAppointmentForm?.get('SCHEDULE_TYPE')?.updateValueAndValidity();
          }

          // to check kisok submit pending pre-visit docs complete
          if (params && params['navigate'] === 'flowBoard') {
            sessionStorage.setItem('kioskAppointment', 'yes');
            this.navigateBackToProviderFlowBoard = true;
          }

          // Visor facility related params
          if (params && params['episodeOfCareId']) {
            this.visorEpisodeOfCareId = params['episodeOfCareId'];
          }
          if (params && params['userInfoId']) {
            this.getUserDemographicDetails(params['userInfoId']);
          }
        }
      }
    );
    this.callAnalyticsApi(this.facilityId, 'bookappt', this.outreachToken);
    this.minAppointmentDate = this.httpService?.convertDate(new Date());
    this.maxAppointmentDate = this.httpService?.convertDate(new Date());
    this.bookAppointmentForm?.get('waitList')?.setValue(false);
    this.validationRequired = true;
    if (this.facilityId == 86) {
      this.validationRequired = false;
      this.bookAppointmentForm?.get('MARITAL_STATUS')?.clearValidators();
      this.bookAppointmentForm?.get('RACE')?.clearValidators();
      this.bookAppointmentForm?.get('ETHNICITY')?.clearValidators();
      const index = this.isSelfPay.findIndex(item => item.value === 'healthPass');
      if (index !== -1) {
        this.isSelfPay.splice(index, 1);
      }
    }
  }


  // ngAfterViewInit() {
  //   // this.signaturePad is now available
  //   this.signaturePad.set('minWidth', 2); 
  //   this.signaturePad.clear(); 
  // }

  ngOnInit() {

    this.bookAppointmentForm.addControl(
      'patientConsent',
      new UntypedFormControl(null, Validators.required)
    );

    this.selectedProvider = JSON.parse(sessionStorage.getItem('selectedProvider') || '{}') || null;
    this.webcamInsuranceImage = {} as WebcamImage;
    this.webcamLicenseImage = {} as WebcamImage;
    this.webcamInsuranceImages = [];
    this.webcamLicenseImages = [];
    this.selectedROSAndCC = [];
    this.insuranceImagePDFFile = new jsPDF();
    this.licenseImagePDFFile = new jsPDF();
    this.drivingImagesCount = 0;
    this.insuranceImagesCount = 0;
    this.listTemplates = [];
    if (Object.keys(this.selectedProvider)?.length > 0) {
      // this.doctorsList = this.selectedProvider.eligibilityDetails && this.selectedProvider.eligibilityDetails.doctor;
      this.purposeOfVisit = (this.selectedProvider?.purposeOfVisit) || [];
      this.newPurposeOfVisit = this.selectedProvider?.purposeOfVisit?.map((item: any) => {
        return {
          id: item,
          value: item
        }
      });
      this.typeOfServices = (this.selectedProvider?.typeOfService) || [];
      this.isurgentCare = (this.selectedProvider?.urgentcare);
      this.facilityName = (this.selectedProvider?.facilityName) || null;
      this.facilityAddress = this.selectedProvider?.address || {};
      this.address = (this.selectedProvider?.address) || {};
      if (this.address?.zipCode) {
        this.address.zipCode = this.addPrefixZipCode(this.address?.zipCode, '');
      }
      this.facilityWorkingHours = (this.selectedProvider?.facilityWorkingHours) || [];
      this.facilityContact = (this.selectedProvider?.facilityContact) || [];
      //set current position
      // this.setCurrentPosition();
      if (this.selectedProvider.urgentcare) {
        this.bookAppointmentForm?.get('DOCTOR')?.setValidators(null);
        this.bookAppointmentForm?.get('TYPE_OF_PATIENT')?.setValidators(null);
        this.bookAppointmentForm.updateValueAndValidity();
      }
      this.bookAppointmentForm?.get('APPOINTMENT_SLOT')?.disable();
      this.minAppointmentDate = this.httpService?.convertDate(new Date());
      this.maxAppointmentDate = this.httpService?.convertDate(new Date());
    }

    //load Places Autocomplete
    this.mapsAPILoader?.load()?.then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef?.nativeElement, {
        types: ["address"]
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.setCurrentPosition();
        });
      });
    });

    if (sessionStorage?.getItem('kioskAppointment')) {
      this.kioskAddAppointment = true;
      this.navigateBackToProviderFlowBoard = true;
      this.bookAppointmentForm?.get('SCHEDULE_TYPE')?.setValidators([Validators?.required]);
      this.bookAppointmentForm?.get('SCHEDULE_TYPE')?.updateValueAndValidity();
    }
    this.getInsuranceAutoComplete();
    // to check values changes of fields to check established user or not
    this.appointmentFormValueChanges();
    this.onlyRegularVisitPF();
    this.seletcedIdCard = null;
    this.bookAppointmentForm?.get('preferredLanguage')?.setValue('English')

  }

  // Analytics 
  private callAnalyticsApi(facilityId?: number, message?: string, phNumber?: string): void {
    let action = `appointment/registration/appAnalytics?category=${message}`;
    if (phNumber && message === 'Phone Number') {
      action = action + `&phoneNumber=${phNumber}`;
    }
    if(this.outreachToken) {
      action = action + `&webToken=${this.outreachToken}`;
    }
    if(this.outreachCampaignId) {
      action = action + `&campaignId=${this.outreachCampaignId}`;
    }
    this.httpService?.makeRequestWithAction(action)?.subscribe((data: any) => {
      this.loadSpinner = false;
    }, error => {
      this.loadSpinner = false;
    });
  }

  // get patinet details with id  https://careeco-qa.herokuapp.com/appointment/getByCohortId?id=19132670.
  public getPatientDetails(id: any, isNewCmapign?: any): void {
    let action: any = isNewCmapign ? `campaign/getTransByWebToken?webToken=${id}` : `appointment/registration/getByCohortId?id=${id}`;
    this.loadSpinner = true;
    (isNewCmapign ? this.careOutreachService?.makeGetRequest(action) : this.httpService?.makeGetRequest(action))
      .subscribe((data: any) => {
        if (data.status === 'SUCCESS') {
          const getResponse = data && data?.responseObject;
          if (getResponse?.patientPhoneNumber?.includes('+1')) {
            getResponse.patientPhoneNumber = getResponse?.patientPhoneNumber?.replace('+1', '')
          }
          if (getResponse?.phoneNumber?.includes('+1')) {
            getResponse.phoneNumber = getResponse?.phoneNumber?.replace('+1', '')
          }
          this.bookAppointmentForm?.get('PATIENT_FIRST_NAME')?.setValue(getResponse?.patientFirstName || getResponse?.firstName);
          this.bookAppointmentForm?.get('GENDER')?.setValue((getResponse?.patientGender === 'M' || getResponse?.gender === 'M' ? 'Male' : 'Female') || null);
          this.bookAppointmentForm?.get('MARITAL_STATUS')?.setValue(getResponse?.maritalStatus);
          this.bookAppointmentForm?.get('PATIENT_LAST_NAME')?.setValue(getResponse?.patientLastName || getResponse?.lastName);
          this.bookAppointmentForm?.get('DATE_OF_BIRTH')?.setValue(this.formatDob(getResponse?.patientDOB || getResponse?.dob));
          this.bookAppointmentForm?.get('PHONE_NUMBER')?.setValue(getResponse?.patientPhoneNumber || getResponse?.phoneNumber);

          this.bookAppointmentForm?.get('ADDRESS')?.setValue(getResponse?.patientAddress?.street || getResponse?.address?.street);
          this.bookAppointmentForm?.get('CITY')?.setValue(getResponse?.patientAddress?.city || getResponse?.address?.city);
          this.bookAppointmentForm?.get('STATE')?.setValue(getResponse?.patientAddress?.state || getResponse?.address?.state);
          this.bookAppointmentForm?.get('COUNTRY')?.setValue(getResponse?.patientAddress?.country || getResponse?.address?.country);
          this.bookAppointmentForm?.get('ZIPCODE')?.setValue(getResponse?.patientAddress?.zipCode || getResponse?.address?.zipCode);
          this.bookAppointmentForm?.get('ETHNICITY')?.setValue(getResponse?.ethnicity);
          this.bookAppointmentForm?.get('RACE')?.setValue(getResponse?.race);
          // this.bookAppointmentForm?.get('EMAIL')?.setValue(getResponse.patientEmail);
          this.getProviderInfo(getResponse?.facilityId);
        } else {
          this.notifyText = data?.message || 'Something went wrong please try again later..!';
          this.exceptionalModal = true;
        }
        this.loadSpinner = false;
      },
        error => {
          this.loadSpinner = false;
          this.notifyText = error?.message || 'Something went wrong please try again later..!';
          this.exceptionalModal = true;
        });
  }

  public navigateToHomeScreen(homeScreen: any): void {
    if (homeScreen) {
      if (this.navigateBackToProviderFlowBoard) {
        this.naviagetBackToProviderFlowBoard();
        sessionStorage?.removeItem('kioskAppointment')
        return;
      }
      if (this.navigateBacktoCareJouney === true) {
        this._location?.back();
        return;
      }
      // this.router.navigate(['/results-page']);
      // this.router.navigate(['/home']);
      const base_url = window.location.origin;
      location?.replace(`${base_url}/${this.facilityId}/patientPortal`);
      //location?.replace("https://www.careeco.net/home");
      sessionStorage.removeItem('selectedProvider');
    }
    else {
      this.previsitDocsSubmited = false;
      this.updatePannelFlags('displaySuccessScreen');
    }
  }

  public getPatientDetailsforReSchedule(id: any): void {
    // let action: any = `appointment/getrescheduleById?id=${id}`;
    let action: any = `appointment/registration/getRescheduleById?id=${id}`;
    this.loadSpinner = true;
    this.httpService.makeGetRequest(action)
      .subscribe((data: any) => {
        this.loadSpinner = false;
        if (data.status === 'SUCCESS') {
          const getResponse = data && data?.responseObject;
          this.appointmentIdForReSchedule = getResponse?.id;
          this.getProviderInfo(getResponse?.facilityId);
          if (getResponse?.patientPrimaryPhone?.includes('+1')) {
            getResponse.patientPrimaryPhone = getResponse?.patientPrimaryPhone?.replace('+1', '')
          }
          if (getResponse?.patientPrimaryPhone?.includes('+91')) {
            getResponse.patientPrimaryPhone = getResponse?.patientPrimaryPhone?.replace('+91', '')
          }
          this.bookAppointmentForm?.get('PATIENT_FIRST_NAME')?.setValue(getResponse?.firstName);
          this.bookAppointmentForm?.get('PATIENT_MIDDLE_NAME')?.setValue(getResponse?.middleName);
          this.bookAppointmentForm?.get('GENDER')?.setValue(getResponse?.gender);
          this.bookAppointmentForm?.get('MARITAL_STATUS')?.setValue(getResponse?.maritalStatus);
          this.bookAppointmentForm?.get('PATIENT_LAST_NAME')?.setValue(getResponse?.lastName);
          this.bookAppointmentForm?.get('DATE_OF_BIRTH')?.setValue(this.formatDob(getResponse?.dob));
          this.bookAppointmentForm?.get('PHONE_NUMBER')?.setValue(getResponse?.patientPrimaryPhone);
          this.bookAppointmentForm?.get('ETHNICITY')?.setValue(getResponse?.ethnicity);
          this.bookAppointmentForm?.get('RACE')?.setValue(getResponse?.race);

          const isNewPatient = getResponse?.newPatient === true ? 'New' : 'Old';
          this.bookAppointmentForm?.get('TYPE_OF_PATIENT')?.setValue(isNewPatient);
          // if(isNewPatient=='Old'){
          //         this.patientConsent()
          //       }
          //       else{
          //         this.isdesabled=false
          //       }



          this.bookAppointmentForm?.get('ADDRESS')?.setValue(getResponse?.address?.street);
          this.bookAppointmentForm?.get('CITY')?.setValue(getResponse?.address?.city);
          this.bookAppointmentForm?.get('STATE')?.setValue(getResponse?.address?.state);
          this.bookAppointmentForm?.get('COUNTRY')?.setValue(getResponse?.address?.country);
          this.bookAppointmentForm?.get('ZIPCODE')?.setValue(getResponse?.address?.zipCode);

          this.bookAppointmentForm?.get('doctor_first_name')?.setValue(getResponse?.appointment?.authorized_official_first_name);
          this.bookAppointmentForm?.get('doctor_last_name')?.setValue(getResponse?.appointment?.authorized_official_last_name);

          if (getResponse?.appointment?.purposeOfVisit) {
            this.changePurposeVisit(getResponse);
          }

          let selfPayCheck: string = '';
          if (getResponse?.selfPay) {
            selfPayCheck = 'Self-Pay'
          }
          if (getResponse?.unInsured) {
            selfPayCheck = 'Un-Insured';
          }
          if (getResponse?.insuranceDetails?.name) {
            selfPayCheck = 'Insurance';
          }
          this.bookAppointmentForm?.get('IS_SELF_PAY')?.setValue(selfPayCheck);
          if (selfPayCheck === 'Insurance') {
            this.checkPaymentType();

            this.bookAppointmentForm?.get('INSURANCE_NAME')?.setValue(getResponse?.insuranceDetails?.name);
            this.bookAppointmentForm?.get('MEMBER_ID')?.setValue(getResponse?.insuranceDetails?.memberId);
            this.bookAppointmentForm?.get('GROUP_NUMBER')?.setValue(getResponse?.insuranceDetails?.groupNumber);
            this.bookAppointmentForm?.get('PAYER_ID')?.setValue(getResponse?.insuranceDetails?.payerId);

            const checkPrimarySub = (getResponse?.firstName === getResponse?.insuranceDetails?.subscriberfirstname) && getResponse?.lastName === getResponse?.insuranceDetails?.subscriberlastname ? 'Self' : 'Other';
            this.bookAppointmentForm?.get('PATIENT_PRIMARY_SUBSCRIBER')?.setValue(checkPrimarySub);
            this.checkPrimarySubscriber();

            const medicareFlag = getResponse?.insuranceDetails?.medicareFlag === true ? 'medicare' : 'other';
            this.bookAppointmentForm?.get('INSURANCE_TYPE')?.setValue(medicareFlag);

            if (medicareFlag === 'medicare') {
              this.bookAppointmentForm?.get('COVERAGE_EFFECTIVE_START_DATE')?.setValue(getResponse?.insuranceDetails?.medicareStartDate)
            }
          }
        }
      },
        error => {
          this.ifNoPatientDetails = error?.responseObject || null;
          this.noPatientDetailsMsg = error?.message ? `${error?.message}. Please fill all the details to book your appointment.` : 'Patient details not found. Please fill all the details to book your appointment.';
          this.loadSpinner = false;
        });
  }

  // due to timezone is added in dob, split and 
  public formatDob(dob: string): any {
    if(!dob) {
      return null;
    }
    let split = dob?.includes('T') ? dob?.toString()?.split('T') : dob;
    const dateSplit = split && split[0] ? split[0] : split;
    return this.convertDob(dateSplit) || '';
  }

  // to format the dob with timezone
  public convertDob(date: string | any): any {
    const dateArray = date && date?.split('-');
    let dateString = `${dateArray[0]}-${dateArray[1]}-${dateArray[2]}`;
    return dateString || date;
  }

  public convertDate(dob: string | number | Date): any {
    let currentDate = new Date(dob);
    let date = currentDate.getDate();
    let formatedate = ("0" + date).slice(-2);
    let month = currentDate.getMonth() + 1;
    let formateMonth = ("0" + month).slice(-2);
    let year = currentDate.getFullYear();
    let dateString = `${year}-${formateMonth}-${formatedate}`;
    return dateString;
  }

  private setCurrentPosition() {
    this.mapsAPILoader.load().then(() => {
      let geocoder = new google.maps.Geocoder;
      let latlng: any = {
        lat: this.latitude || undefined,
        lng: this.longitude || undefined
      };
      geocoder.geocode({
        'location': latlng
      }, (results) => {
        if (results[0]) {
          this.currentLocation = results[0].formatted_address;
          this.setZipCode(results);
        } else {
          console.log('Not found');
        }
      });
    });
  }

  public setZipCode(results: any): void {
    this.bookAppointmentForm?.get('ADDRESS')?.setValue(null);
    let street = '';
    for (let i = 0; i < results.length; i++) {
      for (var j = 0; j < results[i].address_components.length; j++) {
        for (var k = 0; k < results[i].address_components[j].types.length; k++) {

          if (results[i].address_components[j].types[k] === "street_number") {
            let street_num = results[i].address_components[j].long_name;
            street += `${street_num} `;
          }
          if (this.bookAppointmentForm?.get('ADDRESS')?.value === null && results[i].address_components[j].types[k] === "route") {
            const route = results[i].address_components[j].long_name;
            street += `${route}`;
            if (this.bookAppointmentForm?.get('ADDRESS')?.value === null) {
              if (street?.toString()?.length > 5) {
                this.zipcodeValidationIfmorethan = true;
              } else {
                this.zipcodeValidationIfmorethan = false;
              }
              this.bookAppointmentForm?.get('ADDRESS')?.setValue(street);
            }
          }

          if (results[i].address_components[j].types[k] === "postal_code") {
            const zipCode = results[i].address_components[j].short_name;
            const zipcodeUpdate = this.addPrefixZipCode(zipCode, '');
            this.bookAppointmentForm?.get('ZIPCODE')?.setValue(zipcodeUpdate || zipCode);
          }

          if (results[i].address_components[j].types[k] === "country") {
            const country = results[i].address_components[j].long_name;
            this.bookAppointmentForm?.get('COUNTRY')?.setValue(country);
          }

          if (results[i].address_components[j].types[k] === "administrative_area_level_1") {
            const state = results[i].address_components[j].short_name;
            this.bookAppointmentForm?.get('STATE')?.setValue(state);
          }

          if (results[i].address_components[j].types[k] === "locality") {
            const city = results[i].address_components[j].long_name;
            this.bookAppointmentForm?.get('CITY')?.setValue(city);
          }
        }
      }
    }
  }

  public changeAppointmentDate(vtype: string, row: number): void {
    //this.bookAppointmentForm?.get('waitList')?.setValue(false);
    const currentDate = new Date();
    const enteredDate = new Date(this.bookAppointmentForm?.get('APPOINTMENT_DATE')?.value);
    if (enteredDate > currentDate) {
      this.isFutureAppt = true;
    } else {
      this.isFutureAppt = false;
      this.bookAppointmentForm?.get('waitList')?.setValue(false);
    }


    if (vtype === 'default') {
      this.resetNxtVisit();
    }
    this.noSlots = false;
    // const purposeOfVisiting = this.bookAppointmentForm?.value?.PURPOSE_OF_VISIT;
    const purposeOfVisiting = this.visitTypearr[row];
    if (!purposeOfVisiting) {
      this.doctorDropdownValidation = true;
      this.appointmentSlotsMessage = 'Please select your Purpose Of Visit';
      this.bookAppointmentForm?.get('APPOINTMENT_DATE')?.setValue(null);
    }
    else {
      this.doctorDropdownValidation = false;
      this.appointmentSlotsMessage = '';
      const typeOfService = this.bookAppointmentForm?.value?.TYPE_OF_SERVICE;

      if (this.providerworkflow?.regular == false && typeOfService == null) {
        this.doctorDropdownValidation = true;
        this.appointmentSlotsMessage = 'Please select Type Of Visit';
        this.bookAppointmentForm?.get('APPOINTMENT_DATE')?.setValue(null);
      }
      else {
        const typeOfPatient = this.bookAppointmentForm?.value?.TYPE_OF_PATIENT;
        if (typeOfPatient == null) {
          this.doctorDropdownValidation = true;
          this.appointmentSlotsMessage = 'Please select Patient Type';
          this.bookAppointmentForm?.get('APPOINTMENT_DATE')?.setValue(null);
        }
        else {
          this.doctorDropdownValidation = false;
          this.appointmentSlotsMessage = '';
          const doctor = this.bookAppointmentForm?.value?.DOCTOR;
          if (doctor == null) {
            this.doctorDropdownValidation = true;
            this.appointmentSlotsMessage = 'Please select Doctor';
            this.bookAppointmentForm?.get('APPOINTMENT_DATE')?.setValue(null);
          }
          else {
            this.doctorDropdownValidation = false;
            this.appointmentSlotsMessage = '';
            this.appointmentsNotAvailable = false;
            const enteredDate = new Date(this.bookAppointmentForm?.get('APPOINTMENT_DATE')?.value);
            if (enteredDate?.getFullYear() <= 1910 ||  enteredDate?.getFullYear() > new Date().getFullYear()) {
              return;
            }
            // if (enteredDate?.getFullYear() < this.currentYear) {
            //   return;
            // }
            if (this.validateManualApptDate(this.bookAppointmentForm?.get('APPOINTMENT_DATE')?.value, this.currentYear, this.currentMonth + 1, this.currentDate)) {
              this.bookAppointmentForm?.get('APPOINTMENT_DATE')?.setValue(null);
              return;
            }
            this.getAppointmentSlots(vtype, row);
          }
        }
      }
    }

  }

  public getAppointmentSlots(vtype: string, row: number): void {
    let rowIndex = '';
    if (vtype === 'default') {
      rowIndex = '';
    }
    else {
      rowIndex = "_" + row;
    }
    // ${this.selectedProvider.id} 
    // appointment/getAvailableSlotsForDay?facilityId=81&date=2021-01-19&isNewPatient=true&doctorNpi=1598887473&typeOfService=convertDateTele
    // const appointDate = this.convertDate(this.bookAppointmentForm?.value?.APPOINTMENT_DATE);
    this.getAppointmentSlotsAvailable = [];
    this.bookAppointmentForm?.get('APPOINTMENT_SLOT' + rowIndex)?.disable();
    this.bookAppointmentForm?.get('APPOINTMENT_SLOT' + rowIndex)?.setValue(null);
    let action: any;
    let appointmentDate = this.bookAppointmentForm?.value?.APPOINTMENT_DATE;
    if (vtype === "additional") {
      appointmentDate = this.bookAppointmentForm?.get('APPOINTMENT_DATE' + rowIndex)?.value;
    }
    // const currentDate = new Date((new Date()).setHours(0, 0, 0, 0));
    // if (new Date(appointmentDate).getTime() < currentDate.getTime()) {
    //   this.bookAppointmentForm?.get('APPOINTMENT_DATE')?.setValue(null);
    //   return;
    // }
    if (!appointmentDate || appointmentDate === "") {
      this.bookAppointmentForm?.get('APPOINTMENT_DATE' + rowIndex)?.setValue(null);
      this.bookAppointmentForm?.get('APPOINTMENT_SLOT' + rowIndex)?.disable();
      this.bookAppointmentForm?.get('APPOINTMENT_SLOT' + rowIndex)?.setValue(null);
      this.loadSpinner = false;
      return;
    }
    let doctorNpi = null;
    const isNewPatient = this.bookAppointmentForm?.value?.TYPE_OF_PATIENT === 'New' ? true : false;
    // const purposeOfVisiting = this.bookAppointmentForm?.value?.PURPOSE_OF_VISIT;

    let purposeOfVisiting = null;
    if (!this.visitSelected) {
      purposeOfVisiting = this.bookAppointmentForm?.value?.PURPOSE_OF_VISIT;
      doctorNpi = parseInt(this.bookAppointmentForm?.value?.DOCTOR);
    }
    else {
      if (vtype === "additional") {
        purposeOfVisiting = this.newVisitArr[row].trim();
        doctorNpi = parseInt(this.bookAppointmentForm?.get('DOCTOR' + rowIndex)?.value);
      } else {
        purposeOfVisiting = this.visitTypearr[row].trim();
        doctorNpi = parseInt(this.bookAppointmentForm?.value?.DOCTOR);
      }

    }


    let typeOfService = this.bookAppointmentForm?.value?.TYPE_OF_SERVICE;
    // if (purposeOfVisiting === 'Covid19 Vaccination' || purposeOfVisiting === 'Covid19 Testing') {
    if (this.providerworkflow?.regular == true) {
      typeOfService = 'Regular Visit';
    }
    let params: any;
    const userinfoId = this.selectedUserInfoId ? this.selectedUserInfoId : '';
    if ((this.selectedProvider?.urgentcare === true) || this.isurgentCare) {
      action = `appointment/registration/getAvailableSlotsForDay?facilityId=${this.selectedProvider?.id}&date=${appointmentDate}&typeOfService=${typeOfService}`;
      params = {
        "doctorNpi": '',
        "docFirstName": this.bookAppointmentForm?.get('doctor_first_name')?.value,
        "docLastName": this.bookAppointmentForm?.get('doctor_last_name')?.value,
        "facilityDate": appointmentDate,
        "facilityId": this.selectedProvider?.id,
        "newPatient": '',
        "purposeOfVisit": '',
        "typeOfService": typeOfService,
        "userInfoId": userinfoId
      }
    } else {
      action = `appointment/registration/getAvailableSlotsForDay?facilityId=${this.selectedProvider?.id}&date=${appointmentDate}&newPatient=${isNewPatient}&doctorNpi=${doctorNpi}&typeOfService=${typeOfService}&purposeOfVisit=${purposeOfVisiting}`;


      let docFname = this.bookAppointmentForm?.get('doctor_first_name')?.value;
      let docLast = this.bookAppointmentForm?.get('doctor_last_name')?.value;

      if (vtype === "additional") {
        docFname = this.bookAppointmentForm?.get('doctor_first_name' + rowIndex)?.value;
        docLast = this.bookAppointmentForm?.get('doctor_last_name' + rowIndex)?.value;
      }

      params = {
        "doctorNpi": doctorNpi,
        "docFirstName": docFname,
        "docLastName": docLast,
        "facilityDate": appointmentDate,
        "facilityId": this.selectedProvider?.id,
        "newPatient": isNewPatient,
        "purposeOfVisit": purposeOfVisiting,
        "typeOfService": typeOfService,
        "userInfoId": userinfoId
      }
    }
    this.loadSpinner = true;
    this.httpService.makeRequestWithErrorMessage(action, params)
      .subscribe((data: any) => {
        this.loadSpinner = false;
        if (data?.status === 'SUCCESS') {
          const allSlots = data && data.resultList;
          allSlots.forEach((element: any) => {
            const split = element.startTime.split(':');
            const array = split.splice(2, 1);
            element.startTime = split.join(':');
            element.descStartTime = this.httpService.timeConvert(element.startTime);
          });
          this.getAppointmentSlotsAvailable = allSlots || [];
          this.bookAppointmentForm?.get('APPOINTMENT_SLOT' + rowIndex)?.enable();
          if (allSlots && allSlots.length === 0) {
            this.showAppointmentSlots = true;
          }
          this.noSlots = false;
        } else {
          this.noSlots = true;
          this.appointmentsNotAvailable = true;
          this.loadSpinner = false;
          // this.showAppointmentSlots = true;
          this.notifyText = data?.message || 'Something went wrong please try again later..!';
          this.exceptionalModal = true;
          this.errorMessage = data?.message;
        }
      },
        error => {
          // this.showAppointmentSlots = true;
          this.loadSpinner = false;
          this.notifyText = error?.message || 'Something went wrong please try again later..!';
          this.exceptionalModal = true;
        });
  }

  public getProviderInfo(facilityId: any): void {
    let action: any = `providerInfo/getProviderInfo`;
    const params = {
      "facilityName": facilityId,
      "id": facilityId,
      "phoneNumber": "",
      "zipCode": 0
    }
    this.loadSpinner = true;
    this.httpService.makeRequestWithAction(action, params)
      .subscribe((data: any) => {
        if (data.status === 'SUCCESS' && data.resultList != null) {
          const getObj = data && data.resultList[0];
          sessionStorage.setItem('selectedProvider', JSON.stringify(getObj));
          this.selectedProvider = JSON.parse(sessionStorage?.getItem('selectedProvider') || '{}');
          this.providerInfoAllDoctors = getObj?.eligibilityDetails?.doctor || [];
          this.purposeOfVisit = getObj && getObj.purposeOfVisit || [];
          this.newPurposeOfVisit = getObj?.purposeOfVisit?.map((item: any) => {
            return {
              id: item,
              value: item
            }
          });
          this.typeOfServices = getObj && getObj.typeOfService || [];
          this.isurgentCare = (getObj && getObj.urgentcare);
          this.facilityName = (getObj && getObj.facilityName) || null;
          this.address = getObj?.address || this.selectedProvider?.address;
          if (this.address?.zipCode) {
            this.address.zipCode = this.addPrefixZipCode(this.address?.zipCode, '');
          }
          this.facilityWorkingHours = (this.selectedProvider.facilityWorkingHours) || [];
          this.facilityContact = (this.selectedProvider.facilityContact) || [];
          //set current position
          // this.setCurrentPosition();
          if (getObj && getObj.urgentcare) {
            this.bookAppointmentForm?.get('DOCTOR')?.setValidators(null);
            this.bookAppointmentForm?.get('TYPE_OF_PATIENT')?.setValidators(null);
            this.bookAppointmentForm.updateValueAndValidity();
          }
          if (getObj && getObj.emrinfo) {
            if (getObj.emrinfo.slots === false) {
              this.showWaitlistChk = true;
            }
          }


        } else {
          this.notifyText = data?.message || 'Something went wrong please try again later..!';
          this.exceptionalModal = true;
        }
        this.loadSpinner = false;
      },
        error => {
          this.loadSpinner = false;
          this.notifyText = error?.message || 'Something went wrong please try again later..!';
          this.exceptionalModal = true;
        });
  }

  public submitAppointment(): void {

    if (this.bookAppointmentForm?.invalid) {
      this.bookAppointmentForm?.markAllAsTouched();
      return;
    }
    console.log('valid');
    const checkSelfPay = this.bookAppointmentForm?.value?.IS_SELF_PAY || null;
    // if (checkSelfPay === 'Insurance') {
    //   this.uploadInsuranceImage();
    // }
    // else {
    //   this.insuranceCardImageId='';
    //   this.bookAppointment();
    // }
    // this.insuranceCardImageId = '';
    this.bookAppointment();
    this.identityImageId = '';
  }


  public uploadInsuranceImage(): void {
    if (this.webcamInsuranceImages && this.webcamInsuranceImages?.length === 0) {
      return;
    }
    /*if(this.insuranceCardImageId !== '' && this.insuranceCardImageId !== null)
    {
      this.deleteDocument(this.insuranceCardImageId, this.selectedUserInfoId)
    }*/

    const isConnect = false;

    let action = null;
    if (this.insuranceCardImageId !== '' && this.insuranceCardImageId !== null) {
      action = `document/user/uploadInsuranceImage?userInfoId=${this.selectedUserInfoId}&isConnect=${isConnect}&deletekey=${this.insuranceCardImageId}`;
    }
    else {
      action = `document/user/uploadInsuranceImage?userInfoId=${this.selectedUserInfoId}&isConnect=${isConnect}`;
    }



    this.formData = new FormData();
    this.insuranceCardImageId = '';
    // output as blob
    var insurancePDF = this.insuranceImagePDFFile.output('blob');
    this.formData.append('idFile', insurancePDF, 'InsuranceImage.pdf');
    this.loadSpinner = true;
    this.httpService.makePostWithFormData(action, this.formData)
      .subscribe((data: any) => {
        if (data.status === 'SUCCESS') {
          console.log(data);
          this.insuranceCardImageId = data.keyName || null;
          this.loadSpinner = false;
          const additionalKey = data?.keyName || null;
          if (this.insuranceCardImageId != '' && this.insuranceCardImageId != null) {
            this.updateInsuranceIdentityImageId('insuraCard');
          }
        } else {
          this.insuranceCardImageId = '';
          this.notifyText = data?.message || 'Something went wrong please try again later..!';
          this.exceptionalModal = true;
          this.errorMessage = data.message;
          this.loadSpinner = false;
        }
      },
        error => {
          this.loadSpinner = false;
          this.notifyText = error?.message || 'Something went wrong please try again later..!';
          this.exceptionalModal = true;
        });
  }

  public uploadLicenseImage(): void {
    if (this.webcamLicenseImages && this.webcamLicenseImages?.length === 0) {
      return;
    }
    /*if(this.seletcedIdCard !== '' && this.seletcedIdCard !== null)
    {
      this.deleteDocument(this.seletcedIdCard, this.selectedUserInfoId)
    }*/
    const isConnect = false;

    let action = null;
    if (this.seletcedIdCard !== '' && this.seletcedIdCard !== null) {
      action = `document/user/uploadId?userInfoId=${this.selectedUserInfoId}&isConnect=${isConnect}&deletekey=${this.seletcedIdCard}`;
    }
    else {
      action = `document/user/uploadId?userInfoId=${this.selectedUserInfoId}&isConnect=${isConnect}`;
    }

    this.formData = new FormData();
    this.identityImageId = '';
    // output as blob
    var licensePDF = this.licenseImagePDFFile.output('blob');
    this.formData.append('idFile', licensePDF, 'LicenseImage.pdf');
    this.loadSpinner = true;
    this.httpService.makePostWithFormData(action, this.formData)
      .subscribe((data: any) => {
        if (data.status === 'SUCCESS') {
          this.identityImageId = data.keyName || null;
          this.loadSpinner = false;
          const additionalKey = data?.keyName || null;
          if (this.identityImageId != '' && this.identityImageId != null) {
            this.updateInsuranceIdentityImageId('dlCard');
          }
        } else {
          this.identityImageId = '';
          this.notifyText = data?.message || 'Something went wrong please try again later..!';
          this.exceptionalModal = true;
          this.errorMessage = data.message;
          this.loadSpinner = false;
        }
      },
        error => {
          this.loadSpinner = false;
          this.notifyText = error?.message || 'Something went wrong please try again later..!';
          this.exceptionalModal = true;
        });
  }



  public updateInsuranceIdentityImageId(cardType?: string): void {

    // let action: any = `document/updateDocsInfoInAppointment?appointmentId=` + this.appointmentId + `&idImageName=` + this.identityImageId + `&insImageName=` + this.insuranceCardImageId;
    // const params = {
    //   "appointmentId": this.appointmentId,
    //   "insImageName": this.insuranceCardImageId,
    //   "idImageName ": this.identityImageId
    // }

    let action: string = `document/uploadInsuranceimageforAppointment?appointmentId=${this.appointmentId}&insImageName=${this.insuranceCardImageId}&memberId=${this.bookAppointmentForm?.value?.MEMBER_ID}`;
    if (cardType === 'dlCard') {
      action = `document/uploadIDimageforAppointment?appointmentId=${this.appointmentId}&idImageName=${this.identityImageId}`;
    }

    this.loadSpinner = true;
    this.httpService.makeRequestWithAction(action, '')
      .subscribe((data: any) => {
        if (data.status !== 'FAILED') {
          this.apiStatus = true;
          this.loadSpinner = false;
        } else {
          this.apiStatus = false;
          this.notifyText = data?.message || 'Something went wrong please try again later..!';
          this.exceptionalModal = true;
          this.errorMessage = data?.message;
          this.loadSpinner = false;
        }
      },
        error => {
          this.loadSpinner = false;
          this.notifyText = error?.message || 'Something went wrong please try again later..!';
          this.exceptionalModal = true;
        });

  }


  // book Appointment API service Call
  public bookAppointment(): void {
    // let action: any = `appointment/webappt`;
    let action: any = `appointment/registration/webAppointment`;
    let dateTime: any;
    let endTime: any;
    if (this.bookAppointmentForm?.value?.SCHEDULE_TYPE === 'In Queue' && this.kioskAddAppointment) {
      const currentLocalDate = new Date().toLocaleDateString(undefined, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });
      const currentTime = new Date().toLocaleTimeString('en-GB', {
        hour: '2-digit',
        minute: '2-digit'
      });
      const splitDate = currentLocalDate.split('/');
      const dateConvert = `${splitDate[2]}-${splitDate[0]}-${splitDate[1]}`;
      dateTime = `${dateConvert}T${currentTime}`;
    } else {
      dateTime = `${this.bookAppointmentForm?.value?.APPOINTMENT_DATE}T${this.bookAppointmentForm?.value?.APPOINTMENT_SLOT}`;
      endTime = `${this.bookAppointmentForm?.value?.APPOINTMENT_DATE}T${this.bookAppointmentForm?.value?.ENDTIME_SLOT}`;
    }

    let mainPurposeOfVisit = null;
    if (this.newVisitArr && this.newVisitArr.length !== 0) {
      mainPurposeOfVisit = this.visitTypearr[0].trim();
    }
    else {

      mainPurposeOfVisit = this.bookAppointmentForm?.value?.PURPOSE_OF_VISIT?.trim();
    }


    let params: any = {
      "gender": this.bookAppointmentForm?.value?.GENDER,
      "address": {
        "city": this.bookAppointmentForm?.value?.CITY,
        "zipCode": parseInt(this.bookAppointmentForm?.value?.ZIPCODE),
        "state": this.bookAppointmentForm?.value?.STATE,
        "street": this.bookAppointmentForm?.value?.ADDRESS,
        "country": this.bookAppointmentForm?.value?.COUNTRY
      },
      "lastName": this.bookAppointmentForm?.value?.PATIENT_LAST_NAME?.trim(),
      // "email": this.bookAppointmentForm?.value?.EMAIL,
      "firstName": this.bookAppointmentForm?.value?.PATIENT_FIRST_NAME?.trim(),
      "middleName": this.bookAppointmentForm?.value?.PATIENT_MIDDLE_NAME?.trim(),
      "facilityId": this.selectedProvider.id,
      "dob": this.bookAppointmentForm?.value?.DATE_OF_BIRTH,
      "maritalStatus": this.bookAppointmentForm?.value?.MARITAL_STATUS || null,
      "appointment": {
        "purposeOfVisit": mainPurposeOfVisit,
        "dateTime": dateTime || null,
        "typeOfService": this.bookAppointmentForm?.value?.TYPE_OF_SERVICE || '',
        "scheduledAppt": true,
        "doctorNpi": parseInt(this.bookAppointmentForm?.value?.DOCTOR),
        "endTime": endTime || null,
        "authorized_official_first_name": this.bookAppointmentForm?.value?.doctor_first_name || null,
        "authorized_official_last_name": this.bookAppointmentForm?.value?.doctor_last_name || null
      },
      "patientPrimaryPhone": `+1${this.bookAppointmentForm?.value?.PHONE_NUMBER.toString()}`,
      "age": this.calculateAge(this.bookAppointmentForm?.value?.DATE_OF_BIRTH) || null,
      "ethnicity": this.bookAppointmentForm?.value?.ETHNICITY,
      "race": this.bookAppointmentForm?.value?.RACE,
      "primaryPhoneType": true,
      "alternativePhoneType": null,
      "alternativePhoneNumber": null,
      "consentForm": this.providerworkflow?.consentForms === true ? false : true,
      "appointmentQuestionnaire": this.providerworkflow?.patientQuestions === true ? false : true,
      "idDocument": this.bookAppointmentForm?.get('idDocument')?.value,
      "patientConsent": this.bookAppointmentForm?.get('patientConsent')?.value,
      "waitList": this.bookAppointmentForm?.get('waitList')?.value,
      "emergencyDetails": this.getUserInfoIdWithPatientDetails?.emergencyDetails || [],
      'notes': this.bookAppointmentForm?.get('notes')?.value,// athena related notes
      'preferredLanguage':this.bookAppointmentForm?.get('preferredLanguage')?.value,
      "billingAddressSameAsMailing":true,
      "billingAddress": {
        "city": this.bookAppointmentForm?.value?.CITY,
        "zipCode": parseInt(this.bookAppointmentForm?.value?.ZIPCODE),
        "state": this.bookAppointmentForm?.value?.STATE,
        "street": this.bookAppointmentForm?.value?.ADDRESS,
        "country": this.bookAppointmentForm?.value?.COUNTRY
      },
      "episodeOfCareId": this.visorEpisodeOfCareId,
      "channel": 'cad'
    }
    this.aapDetails = {
      'app1': mainPurposeOfVisit,
      "app1Date": dateTime || null
    }
    const checkSelfPay = this.bookAppointmentForm?.value?.IS_SELF_PAY || null;
    if (checkSelfPay === 'Insurance') {
      params['insuranceDetails'] = [{
        'active': true,
        'priority': 'Primary',
        'name': this.bookAppointmentForm?.value?.INSURANCE_NAME?.trim() || null,
        'memberId': this.bookAppointmentForm?.value?.MEMBER_ID || null,
        'groupNumber': this.bookAppointmentForm?.value?.GROUP_NUMBER || null,
        'payerId': this.bookAppointmentForm?.value?.PAYER_ID || null,
        'eligibilityRealtimePayerID': this.bookAppointmentForm?.value?.eligibilityRealtimePayerID || null,
        'eligibilityPortalID': this.bookAppointmentForm?.value?.eligibilityPortalID || null,
        'subscriberfirstname': this.bookAppointmentForm?.value?.PRIMARY_SUBSCRIBER_FIRST_NAME?.trim() || null,
        'subscriberlastname': this.bookAppointmentForm?.value?.PRIMARY_SUBSCRIBER_LAST_NAME?.trim() || null,
        'subscribergender': this.bookAppointmentForm?.value?.PRIMARY_SUBSCRIBER_GENDER || null,
        'subscriberdateOfBirth': this.bookAppointmentForm?.value?.PRIMARY_SUBSCRIBER_DOB || null,
        'medicareFlag': (this.bookAppointmentForm?.value?.INSURANCE_TYPE === 'medicare') ? true : false,
        'insuranceCardImageId': this.insuranceCardImageId,
        'insurancePrimarySubscriber': this.bookAppointmentForm?.value?.PATIENT_PRIMARY_SUBSCRIBER || null,
        'claimPayerId': this.bookAppointmentForm?.value?.claimPayerId || null,
        'medicareStartDate': this.bookAppointmentForm?.value?.COVERAGE_EFFECTIVE_START_DATE || null,
        'insuranceClass': this.bookAppointmentForm?.value?.insuranceClass || null,
        'relationToSubscriber': this.bookAppointmentForm?.value?.relationToSubscriber || null,
        "employerAddress": {
          "city": null,
          "zipCode": null,
          "state": null,
          "street": null,
        },
        "employerName": null,
      }];
      const existingInsurances = this.getUserInfoIdWithPatientDetails?.insuranceDetails || [];
      if (existingInsurances && existingInsurances?.length > 0) {
        const getNonPrimaryInsuList = existingInsurances?.filter((element: any) => element?.priority !== 'Primary') || [];
        params.insuranceDetails = [...params.insuranceDetails, ...getNonPrimaryInsuList];
      }
      // if (this.bookAppointmentForm?.value?.INSURANCE_TYPE === 'medicare') {
      //   this.changeMedicare();
      //   params['insuranceDetails']['medicareStartDate'] = this.bookAppointmentForm?.value?.COVERAGE_EFFECTIVE_START_DATE || null;
      // }
      params['selfPay'] = false;
      params['healthPass'] = false;
    } else if (checkSelfPay === 'Un-Insured') {
      params['unInsured'] = true;
      params['selfPay'] = false;
      params['healthPass'] = false;
    }
    else if (checkSelfPay === 'healthPass') {
      params['unInsured'] = false;
      params['selfPay'] = false;
      params['healthPass'] = true;
    }
    else {
      params['selfPay'] = true;
      params['healthPass'] = false;
      // params['insuranceDetails'] = {
      //   'Self-pay':  'Self-pay'
      // }
    }
    const isNewPatient = this.bookAppointmentForm?.value?.TYPE_OF_PATIENT === 'New' ? true : false;
    params['newPatient'] = isNewPatient;
    const purposeOfVisit = this.bookAppointmentForm?.get('PURPOSE_OF_VISIT')?.value;
    if (purposeOfVisit === 'Covid19 Vaccination') {
      params['addlinfo'] = this.arrayValues || [];
      params.appointment['dosageSeq'] = this.bookAppointmentForm?.value?.COVID_VACCINE_DOSAGE || '';
    }
    if (this.providerworkflow?.regular == true) {
      params.appointment['typeOfService'] = 'Regular Visit';
    }
    // params['webToken'] = this.getWebToken || null;

    if (this.bookAppointmentForm?.value?.SCHEDULE_TYPE === 'In Queue') {
      params.appointment['scheduledAppt'] = false;
    }

    if (this.newVisitArr && this.newVisitArr.length !== 0) {
      this.addlAppointmentsInfo = [];
      this.newVisitArr?.forEach((arrayItem: any, index: number) => {
        const addlauthorized_official_first_name = this.bookAppointmentForm?.get('doctor_first_name_' + index)?.value;
        const addlauthorized_official_last_name = this.bookAppointmentForm?.get('doctor_last_name_' + index)?.value;

        const addldoctorNpi = parseInt(this.bookAppointmentForm?.get('DOCTOR_' + index)?.value);

        const addlpurposeOfVisit = this.newVisitArr[index].trim();

        const addldateTime = this.bookAppointmentForm?.get('APPOINTMENT_DATE_' + index)?.value + 'T' + this.bookAppointmentForm?.get('APPOINTMENT_SLOT_' + index)?.value;
        const addlendTime = this.bookAppointmentForm?.get('APPOINTMENT_DATE_' + index)?.value + 'T' + this.bookAppointmentForm?.get('ENDTIME_SLOT_' + index)?.value;


        const obj = {
          "addlauthorized_official_first_name": addlauthorized_official_first_name,
          "addlauthorized_official_last_name": addlauthorized_official_last_name,
          "addldateTime": addldateTime,
          "addldoctorNpi": addldoctorNpi,
          "addlendTime": addlendTime,
          "addlpurposeOfVisit": addlpurposeOfVisit,
        }
        this.addlAppointmentsInfo?.push(obj);

      });
    }

    params['addlAppointmentsInfo'] = this.addlAppointmentsInfo;

    //console.log(this.addlAppointmentsInfo);
    //console.log(params);
    //return;

    this.loadSpinner = true;
    this.httpService.makeRequestWithAction(action, params)
      .subscribe((data: any) => {
        if (data.status !== 'FAILED') {
          if (!data?.responseObject) {
            this.apiStatus = false;
            this.notifyText = data?.message || 'Something went wrong please try again later..!';
            this.exceptionalModal = true;
            this.loadSpinner = false;
            return;
          }
          const isSelfPay = this.bookAppointmentForm?.get('IS_SELF_PAY')?.value;
          this.selectedUserInfoId = data?.responseObject?.userInfoId;
          if (isSelfPay === 'Insurance' && data?.responseObject) {
            this.saveInsuranceAndLicenseImages();
          }
          this.apiStatus = true;
          var consentForm = this.dtAppointmentDetails?.consentForm;
          if (this.providerworkflow?.patientQuestions == true || this.providerworkflow?.consentForms == true) {

            this.messageShown = 'Your appointment(s) has been scheduled. Please complete pre-visit documentation.'; //data.message;
          } else {
            this.messageShown = 'Your appointment has been scheduled.';
          }

          // to show messge if its re-schedule
          if (this.checkReschedule?.isReschedule) {
            if (this.providerworkflow?.patientQuestions == true || this.providerworkflow?.consentForms == true) {
              this.messageShown = 'Your appointment has been re-scheduled. Please complete pre-visit documentation.'; //data.message;
            } else {
              this.messageShown = 'Your appointment has been re-scheduled successfully.!';
            }
          }
          this.age = data.responseObject.age;
          this.gender = data.responseObject.gender;
          this.appointmentId = data.responseObject.id;
          this.userInfoId = data.responseObject.userInfoId;

          setTimeout(() => {
            this.loadSpinner = false;
            this.showModal = true;
          }, 1500);
          if (this.checkReschedule?.isReschedule) {
            this.canceltheReScheduleAppointment();
          }
          // this.router.navigate(['/appt?previsitId='+this.appointmentId]);

        } else {
          this.apiStatus = false;
          this.notifyText = data?.message || 'Something went wrong please try again later..!';
          this.exceptionalModal = true;
          this.loadSpinner = false;
        }
      },
        error => {
          this.loadSpinner = false;
          this.notifyText = error?.message || 'Something went wrong please try again later..!';
          this.exceptionalModal = true;
        });
  }

  // Cancel Appointment API call
  public canceltheReScheduleAppointment(): void {
    // let action = `appointment/registration/cancel?id=${this.getWebToken}&reason=Re-Schedule Appointment`;
    let action: any = 'appointment/registration/updateStatus';
    let params: any = {
      "apiName": "",
      "appointmentId": this.appointmentIdForReSchedule,
      "buttonClick": 'cancel',
      "cancellationReason": "re-schedule",
      "email": "",
      "facilityID": this.facilityId
    }
    this.loadSpinner = true;
    this.httpService.makeRequestWithActionError(action, params)
      .subscribe((data: any) => {
        if (data?.status == 'SUCCESS') {
          // this.notifyText = 'Appointment has been Cancelled Successfully..!'
          // this.exceptionalModal = true;
        } else {
          // this.notifyText = data?.body?.msg;
          // this.exceptionalModal = true;
        }
        this.loadSpinner = false;
      },
        error => {
          this.loadSpinner = false;
          // this.notifyText = error?.message || error?.msg || 'Something went wrong please try again later';
          // this.exceptionalModal = true;
        });
  }

  public navigateToChiefComplaints(): void {
    if (this.facilityId === '95' || this.facilityId === 95) {
      this.router?.navigate([`/${this.facilityId}/visor/indentity-detailed-info`]);
      return;
    }
    if (this.apiStatus) {
      const base_url = window.location.origin;


      var consentForm = this.dtAppointmentDetails?.consentForm;
      //if(consentForm === false){

      if (this.providerworkflow?.patientQuestions == true || this.providerworkflow?.consentForms == true) {
        //location?.replace(`${environment.userWebUrl}/${this.facilityId}/appt?previsitId=${this.appointmentId}`);
        location?.replace(`${base_url}/${this.facilityId}/appt?previsitId=${this.appointmentId}`);
        this.showModal = false;
      }

      else {
        this.navigateToHomeScreen(true);
      }
    }
    else {
      this.showModal = false;
    }
  }

  public updatePannelFlags(tabName: any): void {
    if (this.displayPatientInfo && this.checkValidToContinue()) {
      return;
    }
    this.displayPatientInfo = false;
    this.displayChiefComplaints = false;
    this.displayROS = false;
    this.displayConsentForms = false;
    this.displayInsuranceInfo = false;
    this.displaySuccessScreen = false;
    // this[tabName] = true;
    switch (tabName) {
      case 'displayPatientInfo':
        this.displayPatientInfo = true;
        break;
      case 'displayChiefComplaints':
        this.displayChiefComplaints = true;
        break;
      case 'displayROS':
        this.displayROS = true;
        break;
      case 'displayConsentForms':
        this.displayConsentForms = true;
        break;
      case 'displayInsuranceInfo':
        this.displayInsuranceInfo = true;
        // this.getInsuranceDetails();
        break;
      case 'displaySuccessScreen':
        this.displaySuccessScreen = true;
        break;
    }
  }
  // navigateToImageCaptureSection(event?: Event) {
  //   this.showWebcam = true;
  //   this.showInsuranceWebcam = true; 
  // }

  saveInsuranceAndLicenseImages(event?: Event): void {
    if (this.providerworkflow?.insuranceImage == true) {
      this.uploadInsuranceImage();
    }
    if (this.providerworkflow?.idImage == true) {
      this.uploadLicenseImage();
    }
  }


  public calculateAge(birthday: any): any {
    const date = new Date(birthday);
    var ageDifMs = Date.now() - date.getTime();
    var ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }


  public getProviderList(visitType: any, onlyDoctor?: string) {
    let visitname = '';
    let purposeOfVisit = '';
    let visits = [];
    if (visitType.indexOf('&') > -1 && (this.facilityId === 83 || this.facilityId === '83')) {
      this.multiVisitCheck = true;
      if (visitType.indexOf('(Both)') > -1) {
        visitname = visitType.replace("(Both)", "");
      }
      else {
        visitname = visitType;
      }
      visits = visitname.split("&");
      purposeOfVisit = visits[0].trim();
    }
    else {
      visits = [visitType];
      purposeOfVisit = visitType || this.bookAppointmentForm?.get('PURPOSE_OF_VISIT')?.value;
    }
    const id = this.selectedProvider.id;
    const patientType = this.bookAppointmentForm?.value?.TYPE_OF_PATIENT === 'New' ? true : false;
    const action = `npi/getvalidNPIforvisit?facilityId=${id}&purposeOfVisit=${purposeOfVisit}&newPatient=${patientType}`;
    this.loadSpinner = true;
    this.httpService.makeGetRequest(action).subscribe((data: any) => {
      if (data && data.status === 'SUCCESS') {

        const doctors = this.providerInfoAllDoctors?.map((doctorItem: any) => {
          return {
            firstName: doctorItem?.basic?.authorized_official_first_name || '',
            lastName: doctorItem?.basic?.authorized_official_last_name || '',
            npi: doctorItem?.number,
            fullName: `${doctorItem?.basic?.authorized_official_first_name || ''} ${doctorItem?.basic?.authorized_official_last_name || ''}`
          }
        }) || [];

        data?.npiDoctorList?.forEach((purposeNpiItem: any) => {
          const getDoctor = doctors?.find(subDoc => subDoc?.npi === purposeNpiItem.npi && purposeNpiItem?.doctorName?.includes(subDoc?.firstName || subDoc?.lastName));
          purposeNpiItem.firstName = getDoctor?.firstName;
          purposeNpiItem.lastName = getDoctor?.lastName;
        });

        this.doctorsListAdd = data.npiDoctorList || [];
        if (onlyDoctor) {
          this.doctorsList = data.npiDoctorList || [];
        }
        if (this.doctorsListAdd?.length === 1) {
          this.bookAppointmentForm?.get('DOCTOR')?.setValue(data.npiDoctorList[0]?.npi);
          this.changeDoctorName(purposeOfVisit, 1, 'default', this.doctorsListAdd[0]);
        }
      } else {
        this.notifyText = data?.message || 'Something went wrong please try again later..!';
        this.exceptionalModal = true;
      }
      this.loadSpinner = false;
    },
      error => {
        this.loadSpinner = false;
        this.notifyText = error?.message || 'Something went wrong please try again later..!';
        this.exceptionalModal = true;
      });

  }

  public changePurposeVisit(getResponse?: any): void {
    this.visitSelected = true;
    this.doctorsList = [];
    this.doctorsListAdd = [];
    if (!getResponse) {
      this.resetDoctorAndAppointmentFields();
    }
    if (getResponse) {
      this.bookAppointmentForm?.get('PURPOSE_OF_VISIT')?.setValue(getResponse?.appointment?.purposeOfVisit);
      this.bookAppointmentForm?.get('TYPE_OF_SERVICE')?.setValue(getResponse?.appointment?.typeOfService);
      this.onlyRegularVisitPF();
      this.typeOfServiceSelection();
      this.bookAppointmentForm?.get('DOCTOR')?.setValue(getResponse?.appointment?.doctorNpi);
    } else {
      this.bookAppointmentForm?.get('DOCTOR')?.setValue(null);
    }

    const purposeOfVisitSel = this.bookAppointmentForm?.get('PURPOSE_OF_VISIT')?.value;
    this.purposeOfVisitSel = purposeOfVisitSel;
    let visitname = '';
    let purposeOfVisit = '';
    let visits = [];

    if (purposeOfVisitSel.indexOf('&') > -1 && (this.facilityId === 83 || this.facilityId === '83')) {
      this.multiVisitCheck = true;
      if (purposeOfVisitSel.indexOf('(Both)') > -1) {
        visitname = purposeOfVisitSel.replace("(Both)", "");

      }
      else {
        visitname = purposeOfVisitSel;
      }
      visits = visitname.split("&");
      purposeOfVisit = visits[0].trim();


    }
    else {
      visits = [purposeOfVisitSel];
      purposeOfVisit = this.bookAppointmentForm?.get('PURPOSE_OF_VISIT')?.value;
    }
    this.visitTypearr = [];
    this.visitTypearr.push(visits[0]);

    this.newVisitArr = visits.slice(1);
    this.newVisitArr?.forEach((arrayItem: any, index: number) => {
      this.bookAppointmentForm.addControl('DOCTOR_' + index, new UntypedFormControl(null, Validators.required));
      this.bookAppointmentForm.addControl('APPOINTMENT_DATE_' + index, new UntypedFormControl(null, Validators.required));
      this.bookAppointmentForm.addControl('APPOINTMENT_SLOT_' + index, new UntypedFormControl(null, Validators.required));
      this.bookAppointmentForm.addControl('ENDTIME_SLOT_' + index, new UntypedFormControl(null));


      this.bookAppointmentForm.addControl('doctor_first_name_' + index, new UntypedFormControl(null, Validators.required));
      this.bookAppointmentForm.addControl('doctor_last_name_' + index, new UntypedFormControl(null, Validators.required));

    });
    if (purposeOfVisit === 'Covid19 Vaccination') {
      this.bookAppointmentForm.addControl('PATIENT_PRE_EXISTING', new UntypedFormControl(null, Validators.required));
      this.bookAppointmentForm.addControl('COVID_VACCINE_DOSAGE', new UntypedFormControl(null, Validators.required));
    } else {
      this.bookAppointmentForm.removeControl('PATIENT_PRE_EXISTING');
      this.bookAppointmentForm.removeControl('COVID_VACCINE_DOSAGE');
    }
    this.bookAppointmentForm.updateValueAndValidity();

    const id = (getResponse && getResponse.facilityId) || this.selectedProvider.id;
    if (!this.isurgentCare && purposeOfVisit) {
      const action = `npi/getvalidNPIforvisit?facilityId=${id}&purposeOfVisit=${purposeOfVisit}`;
      this.loadSpinner = true;
      this.httpService.makeGetRequest(action).subscribe((data: any) => {
        if (data && data.status === 'SUCCESS') {

          const doctors = this.providerInfoAllDoctors?.map((doctorItem: any) => {
            return {
              firstName: doctorItem?.basic?.authorized_official_first_name || '',
              lastName: doctorItem?.basic?.authorized_official_last_name || '',
              npi: doctorItem?.number,
              fullName: `${doctorItem?.basic?.authorized_official_first_name || ''} ${doctorItem?.basic?.authorized_official_last_name || ''}`
            }
          }) || [];

          data?.npiDoctorList?.forEach((purposeNpiItem: any) => {
            const getDoctor = doctors?.find(subDoc => subDoc?.npi === purposeNpiItem.npi && purposeNpiItem?.doctorName?.includes(subDoc?.firstName || subDoc?.lastName));
            purposeNpiItem.firstName = getDoctor?.firstName;
            purposeNpiItem.lastName = getDoctor?.lastName;
          });

          this.doctorsList = data?.npiDoctorList || [];
          if (this.doctorsList?.length === 1) {
            this.bookAppointmentForm?.get('DOCTOR')?.setValue(data?.npiDoctorList[0]?.npi);
            this.changeDoctorName(purposeOfVisit, 0, 'default', this.doctorsList[0]);
          }
        } else {
          this.notifyText = data?.message || 'Something went wrong please try again later..!';
          this.exceptionalModal = true;
        }
        this.loadSpinner = false;
      },
        error => {
          this.loadSpinner = false;
          this.notifyText = error?.message || 'Something went wrong please try again later..!';
          this.exceptionalModal = true;
        });
    }
    if (purposeOfVisit) {
      this.getProviderConfig(purposeOfVisit, id, '', getResponse);
      this.checkEstablishedDetails();
    }
  }

  public getProviderConfig(purposeOfVisit: any, facilityId: any, appointmentId: any, getResponse?: any): void {
    const action = `providerInfo/getProviderConfig?facilityId=${facilityId}`;
    const params = {
      "callerType": "string",
      "facilityId": facilityId,
      "futureappt": true,
      "insurance": true,
      "purposeOfVisit": purposeOfVisit
    }
    this.loadSpinner = true;
    this.httpService.makeRequestWithAction(action, params).subscribe((data: any) => {

      if (data && data.status === 'SUCCESS') {
        this.providerworkflow = data?.providerworkflow;
        this.visitRules = data?.visitRules;
        this.loadSpinner = false;
        if (this.providerworkflow?.regular == true) {
          this.bookAppointmentForm?.get('TYPE_OF_SERVICE')?.reset();
          // this.bookAppointmentForm?.get('TYPE_OF_SERVICE')?.disable();
          this.bookAppointmentForm?.get('TYPE_OF_SERVICE')?.setValue('Regular Visit');
          this.checkCovidVisit = true;
          this.iAgreeToTelemedicineTerms = false;
        } else {
          if (!getResponse) {
            // this.bookAppointmentForm?.get('TYPE_OF_SERVICE')?.enable();
            this.bookAppointmentForm?.get('TYPE_OF_SERVICE')?.reset();
            this.checkCovidVisit = false;
          }
        }

        if (appointmentId != '') {
          this.loadROSorConsentForms(appointmentId);
        }
        this.selectedPurposeOfVisit = purposeOfVisit;
        // check for only Medication Refills
        if (purposeOfVisit === 'Medication Refill') {
          this.medicationRefillsModal = true;
          this.medicationRefillsCheck = 'Medication Refill Service is for Established Patients only, new patients should schedule General office visits.';
          this.bookAppointmentForm?.get('TYPE_OF_PATIENT')?.reset();
          // this.bookAppointmentForm?.get('TYPE_OF_PATIENT')?.disable();
          this.bookAppointmentForm?.get('TYPE_OF_PATIENT')?.setValue('Old');
        } else {
          this.medicationRefillsModal = false;
          // this.bookAppointmentForm?.get('TYPE_OF_PATIENT')?.enable();
          this.bookAppointmentForm?.get('TYPE_OF_PATIENT')?.reset();
        }
        this.onlyRegularVisitPF();
      } else {
        this.notifyText = data?.message || 'Something went wrong please try again later..!';
        this.exceptionalModal = true;
        this.loadSpinner = false;
      }
    },
      error => {
        this.loadSpinner = false;
        this.notifyText = error?.message || 'Something went wrong please try again later..!';
        this.exceptionalModal = true;
      });
  }

  public checkPaymentType(): void {
    const isSelfPay = this.bookAppointmentForm?.get('IS_SELF_PAY')?.value;
    const isMedicare = this.bookAppointmentForm?.get('INSURANCE_TYPE')?.value;
    if (isSelfPay === 'Insurance') {
      this.bookAppointmentForm.addControl('INSURANCE_NAME', new UntypedFormControl(null, Validators.required));
      this.bookAppointmentForm.addControl('MEMBER_ID', new UntypedFormControl(null, Validators.compose([Validators.required, Validators.pattern(this.aplhaNumericHyphenWithSpace)])));
      this.bookAppointmentForm.addControl('GROUP_NUMBER', new UntypedFormControl(null, Validators.compose([Validators.pattern(this.aplhaNumericHyphenWithSpace)])));
      // this.bookAppointmentForm.addControl('PAYER_ID', new FormControl(null, Validators.required));
      this.bookAppointmentForm.addControl('PAYER_ID', new UntypedFormControl(null, Validators.compose([Validators.required, Validators.pattern(AppRegExpressionsConfig.aplhaNumericHyphenWithSpace)])));
      this.bookAppointmentForm?.addControl('insuranceClass', new UntypedFormControl(null, Validators?.required))

      this.bookAppointmentForm.addControl('PATIENT_PRIMARY_SUBSCRIBER', new UntypedFormControl(null, Validators.required));
      this.bookAppointmentForm.addControl('PRIMARY_SUBSCRIBER_FIRST_NAME', new UntypedFormControl(null, [Validators.required, Validators.pattern(AppRegExpressionsConfig.patientNameValidator)]));
      this.bookAppointmentForm.addControl('PRIMARY_SUBSCRIBER_LAST_NAME', new UntypedFormControl(null, [Validators.required, Validators.pattern(AppRegExpressionsConfig.patientNameValidator)]));
      this.bookAppointmentForm.addControl('PRIMARY_SUBSCRIBER_GENDER', new UntypedFormControl(null, Validators.required));
      this.bookAppointmentForm.addControl('PRIMARY_SUBSCRIBER_DOB', new UntypedFormControl(null, Validators.required));
      this.bookAppointmentForm.addControl('INSURANCE_TYPE', new UntypedFormControl(null, [Validators?.required]));
      // this.httpService.yearValidator.bind(this)
      
      this.bookAppointmentForm.addControl('relationToSubscriber', new UntypedFormControl(null));
      this.insuranceDetailsReatinCoverageTypeChange();
    } else {
      this.insuranceDetailsRetaining = { ...this.bookAppointmentForm?.value };
      this.bookAppointmentForm.removeControl('INSURANCE_NAME');
      this.bookAppointmentForm.removeControl('MEMBER_ID');
      this.bookAppointmentForm.removeControl('GROUP_NUMBER');
      this.bookAppointmentForm.removeControl('PAYER_ID');
      this.bookAppointmentForm.removeControl('insuranceClass');

      this.bookAppointmentForm.removeControl('PATIENT_PRIMARY_SUBSCRIBER');
      this.bookAppointmentForm.removeControl('PRIMARY_SUBSCRIBER_FIRST_NAME');
      this.bookAppointmentForm.removeControl('PRIMARY_SUBSCRIBER_LAST_NAME');
      this.bookAppointmentForm.removeControl('PRIMARY_SUBSCRIBER_GENDER');
      this.bookAppointmentForm.removeControl('PRIMARY_SUBSCRIBER_DOB');
      this.bookAppointmentForm.removeControl('INSURANCE_TYPE');
      this.bookAppointmentForm.removeControl('COVERAGE_EFFECTIVE_START_DATE');
      this.bookAppointmentForm.removeControl('relationToSubscriber');
    }
    this.bookAppointmentForm.updateValueAndValidity();
  }

  // Insurance retaining 
  public insuranceDetailsReatinCoverageTypeChange() {
    if (this.insuranceDetailsRetaining) {
      this.bookAppointmentForm?.get('INSURANCE_NAME')?.setValue(this.insuranceDetailsRetaining?.INSURANCE_NAME || null)
      this.bookAppointmentForm?.get('MEMBER_ID')?.setValue(this.insuranceDetailsRetaining?.MEMBER_ID || null)
      this.bookAppointmentForm?.get('GROUP_NUMBER')?.setValue(this.insuranceDetailsRetaining?.GROUP_NUMBER || null)
      this.bookAppointmentForm?.get('PAYER_ID')?.setValue(this.insuranceDetailsRetaining?.PAYER_ID || null)
      this.bookAppointmentForm?.get('eligibilityRealtimePayerID')?.setValue(this.insuranceDetailsRetaining?.eligibilityRealtimePayerID || null)
      this.bookAppointmentForm?.get('eligibilityPortalID')?.setValue(this.insuranceDetailsRetaining?.eligibilityPortalID || null)
      this.bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_FIRST_NAME')?.setValue(this.insuranceDetailsRetaining?.PRIMARY_SUBSCRIBER_FIRST_NAME || null)
      this.bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_LAST_NAME')?.setValue(this.insuranceDetailsRetaining?.PRIMARY_SUBSCRIBER_LAST_NAME || null)
      this.bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_GENDER')?.setValue(this.insuranceDetailsRetaining?.PRIMARY_SUBSCRIBER_GENDER || null)
      this.bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_DOB')?.setValue(this.insuranceDetailsRetaining?.PRIMARY_SUBSCRIBER_DOB || null)
      this.bookAppointmentForm?.get('INSURANCE_TYPE')?.setValue(this.insuranceDetailsRetaining?.INSURANCE_TYPE || null);
      this.bookAppointmentForm?.get('PATIENT_PRIMARY_SUBSCRIBER')?.setValue(this.insuranceDetailsRetaining?.PATIENT_PRIMARY_SUBSCRIBER || null)
      this.bookAppointmentForm?.get('claimPayerId')?.setValue(this.insuranceDetailsRetaining?.claimPayerId || null)
      this.bookAppointmentForm?.get('COVERAGE_EFFECTIVE_START_DATE')?.setValue(this.insuranceDetailsRetaining?.COVERAGE_EFFECTIVE_START_DATE || null)
      this.bookAppointmentForm?.get('insuranceClass')?.setValue(this.insuranceDetailsRetaining?.insuranceClass || null);
      if (this.insuranceCardImageId !== '' && this.insuranceCardImageId !== null) {
        this.downloadInsuranceCard(this.insuranceCardImageId);
      }
      if (this.insuranceDetailsRetaining?.PATIENT_PRIMARY_SUBSCRIBER === 'Self') {
        this.bookAppointmentForm?.get('relationToSubscriber')?.disable();
      } else {
        this.bookAppointmentForm?.get('relationToSubscriber')?.enable();
        this.bookAppointmentForm?.get('relationToSubscriber')?.setValidators([Validators?.required])
        this.bookAppointmentForm?.get('relationToSubscriber')?.setValue(this.insuranceDetailsRetaining?.relationToSubscriber);
      }
    }
    this.bookAppointmentForm?.get('relationToSubscriber')?.updateValueAndValidity();
  }

  // check medicare selection

  public changeMedicare(): void {
    const isMedicare = this.bookAppointmentForm?.get('INSURANCE_TYPE')?.value;
    if (isMedicare === 'medicare') {
      this.bookAppointmentForm.addControl('COVERAGE_EFFECTIVE_START_DATE', new UntypedFormControl(null, [Validators?.required,this.httpService.yearValidator.bind(this)]));
    } else {
      this.bookAppointmentForm.removeControl('COVERAGE_EFFECTIVE_START_DATE');
    }
    this.bookAppointmentForm.updateValueAndValidity();
  }

  public getInsurances(): void {
    const insuText = this.bookAppointmentForm?.value?.INSURANCE_NAME;
    let action: any = `insurance/getInsuranceList?searchQry=${insuText}`;

    this.httpService.makeGetRequest(action)
      .subscribe((data: any) => {
        this.allInsurances = data.responseObject || [];
      },
        error => {
          // this.notifyText = error?.message || 'Something went wrong please try again later..!';
          // this.exceptionalModal = true;
        });
  }

  // insurance autocomplete
  private getInsuranceAutoComplete() {
    this.allInsuranceList = concat(
      of([]), // default items
      this.insuranceInput.pipe(
        distinctUntilChanged(),
        tap(() => this.insuranceLoading = true),
        switchMap(term => this.httpService?.getInsuranceAutoComplete(term)?.pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.insuranceLoading = false)
        ))
      )
    );
  };

  public trackByFn(item: any) {
    return item?.name;
  }

  // set the insurance related values
  public selectedInsurance(event: any): void {
    this.bookAppointmentForm?.get('INSURANCE_NAME')?.setValue(event?.name || null);
    this.bookAppointmentForm?.get('PAYER_ID')?.setValue(event?.payorid || null);
    this.bookAppointmentForm?.get('eligibilityRealtimePayerID')?.setValue(event?.eligibilityRealtimePayerID || null);
    this.bookAppointmentForm?.get('claimPayerId')?.setValue(event?.cpid || null);
    this.bookAppointmentForm?.get('eligibilityPortalID')?.setValue(event?.eligibilityPortalID || null);
    this.portalId = event?.eligibilityPortalID || null;
  }

  //set PayerID 
  setPayerId(insurance: any) {
    for (const element of this.allInsurances) {
      if (element.name === insurance) {
        this.bookAppointmentForm.controls['PAYER_ID'].setValue(element?.payorid || null);
        this.bookAppointmentForm?.controls['eligibilityRealtimePayerID']?.setValue(element?.eligibilityRealtimePayerID || null);
        this.bookAppointmentForm?.controls['claimPayerId']?.setValue(element?.cpid || null);
        this.bookAppointmentForm?.controls['eligibilityPortalID']?.setValue(element?.eligibilityPortalID || null);
        this.portalId = element.eligibilityPortalID || null;
        break;
      }
    }
  }

  public checkPrimarySubscriber(): void {
    const insurancePrimary = this.bookAppointmentForm?.get('PATIENT_PRIMARY_SUBSCRIBER')?.value;
    if (insurancePrimary === 'Self') {
      const firstName = this.bookAppointmentForm?.value?.PATIENT_FIRST_NAME || null;
      const lastName = this.bookAppointmentForm?.value?.PATIENT_LAST_NAME || null;
      const gender = this.bookAppointmentForm?.value?.GENDER || null;
      const dob = this.bookAppointmentForm?.value?.DATE_OF_BIRTH || null;
      this.bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_FIRST_NAME')?.setValue(firstName);
      this.bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_LAST_NAME')?.setValue(lastName);
      this.bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_DOB')?.setValue(dob);
      this.bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_GENDER')?.setValue(gender);
      this.bookAppointmentForm?.get('relationToSubscriber')?.reset();
      this.bookAppointmentForm?.get('relationToSubscriber')?.setValue(null);
      this.bookAppointmentForm?.get('relationToSubscriber')?.disable();
      // this.bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_GENDER')?.disable();
      // this.bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_FIRST_NAME')?.disable();
      // this.bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_LAST_NAME')?.disable();
      // this.bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_DOB')?.disable();
    } else {
      this.bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_FIRST_NAME')?.setValue(null);
      this.bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_LAST_NAME')?.setValue(null);
      this.bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_DOB')?.setValue(null);
      this.bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_GENDER')?.setValue(null);
      this.bookAppointmentForm?.get('relationToSubscriber')?.enable();
      this.bookAppointmentForm?.get('relationToSubscriber')?.setValidators([Validators?.required]);
      // this.bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_GENDER')?.enable();
      // this.bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_FIRST_NAME')?.enable();
      // this.bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_LAST_NAME')?.enable();
      // this.bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_DOB')?.enable();
    }
    this.bookAppointmentForm?.get('relationToSubscriber')?.updateValueAndValidity();
  }

  public checkedPreExisting(event: any, condition: string): void {
    if (event.checked) {
      this.arrayValues.push(condition);
    } else if (this.arrayValues?.includes(condition)) {
      let index = this.arrayValues?.indexOf(condition);
      this.arrayValues?.splice(index, 1);
    }
  }

  public iAgreeToTermsChecked(event: any): void {

    if (event.checked) {
      this.iAgreeToTerms = false;
      const typeOfService = this.bookAppointmentForm?.value?.TYPE_OF_SERVICE;
      if (typeOfService == null || typeOfService == undefined) {
        this.iAgreeToTelemedicineTerms = false;
      }
    } else {
      this.iAgreeToTerms = true;
    }
  }

  public confirmingConsentForTelemedicine(event: any): void {
    if (event.checked) {
      this.iAgreeToTelemedicineTerms = false;
    } else {
      this.iAgreeToTelemedicineTerms = true;
    }
  }

  public resetNxtVisit() {

    if (this.newVisitArr.length > 0) {
      this.defaultChk = false;
      this.appointmentGap = 0;
      this.availableSlotsAdd = [];
      this.doctorsListAdd = [];
      this.newVisitArr?.forEach((arrayItem: any, index: number) => {
        this.bookAppointmentForm?.get('DOCTOR_' + index)?.setValue(null);
        this.bookAppointmentForm?.get('APPOINTMENT_DATE_' + index)?.setValue(null);
        this.bookAppointmentForm?.get('APPOINTMENT_SLOT_' + index)?.setValue(null);
        this.bookAppointmentForm?.get('doctor_first_name_' + index)?.setValue(null);
        this.bookAppointmentForm?.get('doctor_last_name_' + index)?.setValue(null);

      });
    }
  }


  public changeDoctorName(visitName: any, row: number, vtype: string, event?: any): void {

    this.selcetdVisitIndex = row;
    let rowIndex = '';
    if (vtype === 'default') {
      this.resetNxtVisit();
      rowIndex = '';
    }
    else {
      this.isDoctorSelected = true;
      rowIndex = "_" + row;
      const currPurposeofVisit = this.newVisitArr[row].trim();
      let prevPurposeofVisit = null;
      if (row === 0) {
        prevPurposeofVisit = this.visitTypearr[0].trim();
      }
      else {
        prevPurposeofVisit = this.newVisitArr[row - 1].trim();
      }
      this.getAppointmentDepencies(currPurposeofVisit, prevPurposeofVisit, row);
    }


    this.bookAppointmentForm?.get('APPOINTMENT_DATE' + rowIndex)?.setValue(null);
    this.bookAppointmentForm?.get('APPOINTMENT_SLOT' + rowIndex)?.setValue(null);
    this.bookAppointmentForm?.get('doctor_first_name' + rowIndex)?.setValue(event?.firstName);
    this.bookAppointmentForm?.get('doctor_last_name' + rowIndex)?.setValue(event?.lastName);
    this.getAppointmentSlotsAvailable = [];
    this.doctorFirstName = event?.firstName;
    this.doctorLastName = event?.lastName;

    this.getTimeSlots(visitName, row, vtype);
  }

  public typeOfServiceSelection() {
    const typeOfService = this.bookAppointmentForm?.value?.TYPE_OF_SERVICE;
    if (typeOfService === 'Tele-Medicine' || typeOfService === 'Tele-Health') {
      this.iAgreeToTelemedicineTerms = true;
    }
    else {
      this.iAgreeToTelemedicineTerms = false;
    }
    this.resetDoctorAndAppointmentFields();
  }

  public resetDoctorAndAppointmentFields(isChangeFlag?: string): void {
    // this.bookAppointmentForm.controls['patientConsent'].setValue(false)    
    if (this.doctorsList.length == 1) {
      return;
    }
    this.patientType = this.bookAppointmentForm.value.TYPE_OF_PATIENT;

    this.bookAppointmentForm?.get('APPOINTMENT_DATE')?.setValue(null);
    this.bookAppointmentForm?.get('APPOINTMENT_SLOT')?.setValue(null);
    this.bookAppointmentForm?.get('DOCTOR')?.setValue(null);
    this.getAppointmentSlotsAvailable = [];

    if (isChangeFlag && this.bookAppointmentForm?.get('PURPOSE_OF_VISIT')?.valid) {
      this.getProviderList(this.bookAppointmentForm?.get('PURPOSE_OF_VISIT')?.value, 'onlyDoctor');
    }

    if (this.patientType == 'Old') {
      this.patientConsent(this.selectedUserInfoId);
      this.getUserInfoDetails(this.selectedUserInfoId)
    }
    else {
      this.bookAppointmentForm.controls['patientConsent'].setValue(false);
      this.bookAppointmentForm?.get('preferredLanguage')?.setValue('English');
      this.isdesabled = false
    }

    this.resetNxtVisit();
  }

  public okAppointments(): void {
    this.showAppointmentSlots = false;
  }


  /** Below all methods related to Web cam for Insurance*/

  public triggerInsuranceSnapshot(): void {
    this.trigger.next();
    this.displayInsuranceImages = true;
    this.webcamInsuranceImage = this.webcamInsuranceImages[0];
    if (this.showInsuranceWebcam && this.webcamInsuranceImages?.length === 2) {
      this.closeInsuranceCamera();
    }
  }

  public openInsuranceCamera(): void {
    this.showInsuranceWebcam = true;
  }

  public closeInsuranceCamera(): void {
    this.showInsuranceWebcam = false;
  }

  public toggleInsuranceWebcam(): void {
    this.showInsuranceWebcam = !this.showInsuranceWebcam;
  }


  public clearInsuranceSnapshot(removeIndex: any): void {
    this.webcamInsuranceImages.splice(removeIndex, 1);
    //this.webcamInsuranceImages = [];
    this.insuranceImagesCount = this.webcamInsuranceImages.length;
    this.webcamInsuranceImage = {} as WebcamImage;
    this.insuranceImagePDFFile = new jsPDF();
  }


  /** Below all methods related to Web cam for License*/

  public triggerLicenseSnapshot(): void {
    this.triggerLicense.next();
    this.displayLicenseImages = true;
    this.webcamLicenseImage = this.webcamLicenseImages[0];
    if (this.showWebcam && this.webcamLicenseImages?.length === 1) {
      this.closeLicenseCamera();
    }
  }

  public openLicenseCamera(): void {
    this.showWebcam = true;
  }

  public closeLicenseCamera(): void {
    this.showWebcam = false;
  }

  public toggleLicenseWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }


  public clearLicenseSnapshot(): void {
    this.webcamLicenseImages = [];
    this.drivingImagesCount = 0;
    this.webcamLicenseImage = {} as WebcamImage;
    this.licenseImagePDFFile = new jsPDF();
  }


  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleInsuranceImage(webcamImage: WebcamImage): void {
    console.info('received webcam image', webcamImage);
    this.insuranceImagesCount = this.insuranceImagesCount + 1;
    this.webcamInsuranceImages?.push(webcamImage);
    // const PDFDocument = require('pdfkit');
    // const fs = require('fs');

    // let pdfDoc = new PDFDocument;
    // pdfDoc.pipe(fs.createWriteStream('file.pdf'));
    // pdfDoc.image(webcamImage.imageAsDataUrl, {
    //   fit: [250, 300],
    //   align: 'center',
    //   valign: 'center'
    // });
    // pdfDoc.end(); 
    this.insuranceImagePDFFile?.addImage(webcamImage?.imageAsDataUrl, 'JPEG', 10, 50, 150, 150);
    if (this.insuranceImagesCount === 1) {
      this.insuranceImagePDFFile?.addPage();
    }
    //this.insuranceImagePDFFile.save('file.pdf'); 
  }

  public handleLicenseImage(webcamImage: WebcamImage): void {
    console.info('received webcam image', webcamImage);
    this.drivingImagesCount = this.drivingImagesCount + 1;
    this.webcamLicenseImages?.push(webcamImage);
    this.licenseImagePDFFile?.addImage(webcamImage?.imageAsDataUrl, 'JPEG', 10, 50, 150, 150);
    if (this.drivingImagesCount === 1) {
      this.licenseImagePDFFile?.addPage();
    }
    // this.insuranceImagePDFFile.save('file.pdf'); 
  }

  public cameraWasSwitched(deviceId: string): void {
    console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  public get triggerLicenseObservable(): Observable<void> {
    return this.triggerLicense.asObservable();
  }

  public get nextLicenseWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }


  public getPreAppointmentQuestions(): void {

    let action: any = `appointment/question/getPreAppointmentQuestions`;
    //let action: any = `appointment/question/getPreAppointmentQuestions?age=` + this.age + `&facilityId=81&gender=` + this.gender + `&purposeOfVisit=` + this.purposeOfVisit;
    const params = {
      "age": this.age,
      "facilityId": this.selectedProvider.id,
      "gender": this.gender,
      "purposeOfVisit": this.bookAppointmentForm?.value?.PURPOSE_OF_VISIT
    }
    this.loadSpinner = true;
    this.httpService.makeRequestWithAction(action, params)
      .subscribe((data: any) => {
        this.apiStatus = false;
        this.loadSpinner = false;
        if (data?.categories) {
          let fomateArray: any[] = [];
          data?.categories.forEach((element: any) => {
            element.name = element?.name?.replace(/\u200B/g, '');
            // element.size = element?.list?.length;
            fomateArray?.push(element);
          });
          // sort by lenth of the list for each question
          // fomateArray?.sort((a, b) => {
          //   return b?.size - a?.size;
          // });

          this.allPreAppntQuestionsCategories = fomateArray || [];
          if (data?.categories && data?.categories?.length === 0) {
            this.messageShown = data?.message || "No Pre Appointment Questions are available for given details.";
            this.errorMessage = data?.message || "No Pre Appointment Questions are available for given details.";
            this.showModal = true;
          }
        } else {
          this.messageShown = data?.message || "Something went wrong please Try Again Later";
          this.errorMessage = data?.message || "Something went wrong please Try Again Later";
          this.showModal = true;
        }
      },
        error => {
          this.notifyText = error?.message || 'Something went wrong please try again later..!';
          this.exceptionalModal = true;
          this.loadSpinner = false;
        });

  }

  navigateToROS() {
    // if (!(this.chiefSelectedValues != undefined && this.chiefSelectedValues != null
    //   && this.chiefSelectedValues?.values?.length != 0)) {
    //   this.displayReasonForVisitErrMsg = true;
    //   return;
    // }
    // this.displayReasonForVisitErrMsg = false;
    // this.saveCareFlowCheifComplaints();

    // this is old call
    // this.saveChiefComplaints();
    // this.updatePannelFlags('displayROS');
    // this.updatePannelFlags('displayROS');
  }

  public triggerApiCalls(module: string): void {
    this.boneMineralDensitySacnComp?.resetCallMethods(module);
  }

  get currentStep(): string {
    return this.previsitDocsWizard?.currentStep?.stepTitle || this.allCadModulesDetails[0];
  }

  // This is to save all the modules 
  public saveCadModules(moduleName?: string): void {
    const action = `careFlow/saveCareFlowModuleInfo`;
    let params: any;
    // if(moduleName === 'Chief Complaints') {
    //   params = this.chiefComplaintsComp?.checkedValuesParams();
    // }else if(moduleName === 'ROS' || moduleName === 'Review of Systems' || moduleName === 'Review Of Systems'){
    //   params = this.reviewOfSystemsComp?.checkRosParams();
    // }else if(moduleName === 'Bone Mineral Density Scan'){
    params = this.boneMineralDensitySacnComp?.checkBoneDensityScanParams();
    // }else if(moduleName === 'Medical History'){
    //   params = this.medicalHistoryComp?.checkMedicalHistoryParams();
    // }

    if (!params) {
      return;
    }

    const base_url = window.location.origin;
    const appointmentQuestionnaire = this.dtAppointmentDetails?.appointmentQuestionnaire;
    const consentForm = this.dtAppointmentDetails?.consentForm;

    this.loadSpinner = true;
    this.httpService?.makeRequestWithAction(action, params)?.subscribe(
      (data: any) => {
        if (data?.body?.status === 'SUCCESS') {
        }
        this.previsitDocsWizard?.goToNextStep();
        this.apiStatus = true;
        if (this.previsitDocsWizard?.isLastStep()) {
          this.updateAppointmentQuestionier();
          if (consentForm === false) {
            setTimeout(() => {
              location?.replace(`${base_url}/${this.facilityId}/appt?previsitId=${this.appointmentId}`);
            }, 500);
          }
          else {
            this.consentDocsSubmited = true;
          }
        }
        this.loadSpinner = false;
        // this.updatePannelFlags('displayROS');
      },
      (error) => {
        this.loadSpinner = false;
        this.notifyText = error?.message || 'Something went wrong please try again later..!';
        this.exceptionalModal = true;
      }
    );
  }

  public saveCareFlowReviewOFSystems(): void {
    const action = `careFlow/saveCareFlowModuleInfo`;
    const params = this.reviewOfSystemsComp?.checkRosParams();
    if (!params) {
      return;
    }
    const base_url = window.location.origin;
    const appointmentQuestionnaire = this.dtAppointmentDetails?.appointmentQuestionnaire;
    const consentForm = this.dtAppointmentDetails?.consentForm;

    this.loadSpinner = true;
    this.httpService?.makeRequestWithAction(action, params)?.subscribe(
      (data: any) => {
        if (data?.body?.status === 'SUCCESS') {
        }
        this.apiStatus = true;
        this.loadSpinner = false;
        if (consentForm === false) {
          location?.replace(`${base_url}/${this.facilityId}/appt?previsitId=${this.appointmentId}`);
        }
        else {
          this.consentDocsSubmited = true;
        }
      },
      (error) => {
        this.loadSpinner = false;
        this.notifyText = error?.message || 'Something went wrong please try again later..!';
        this.exceptionalModal = true;
      }
    );
  }

  // Update Appointment Questionier
  public updateAppointmentQuestionier(): void {
    const action = `appointment/registration/updateAppointmentQuestionnaire?appointmentId=${this.appointmentId}`;
    this.httpService?.makeRequestWithAction(action)?.subscribe((data: any) => {

    },
      error => {

      });
  }

  // // Save Chief Complaints & Review Of systems
  public saveReviewOfSystems(): void {
    const base_url = window.location.origin;
    var appointmentQuestionnaire = this.dtAppointmentDetails?.appointmentQuestionnaire;
    var consentForm = this.dtAppointmentDetails?.consentForm;
    const action = `treatmentFlow/saveReviewOfSystems?appointmentId=` + this.appointmentId;
    const params = {
      // "categories": this.selectedROSAndCC,
      "categories": this.rosOthersSelectedValues || [],
    }
    this.loadSpinner = true;
    this.httpService.makeRequestWithAction(action, params)
      .subscribe((data: any) => {
        if (data.status !== 'FAILED') {
          this.apiStatus = true;
          this.loadSpinner = false;
          // if (this.providerworkflow?.consentForms == true) {
          if (consentForm === false) {
            // this.getListTemplates(this.appointmentId);
            //this.ConscentComponent?.getListTemplates(this.appointmentId);
            // location?.replace(`${environment.userWebUrl}/${this.facilityId}/appt?previsitId=${this.appointmentId}`);
            location?.replace(`${base_url}/${this.facilityId}/appt?previsitId=${this.appointmentId}`);
          }
          else {
            // this.previsitDocsSubmited=true;
            // this.navigateToHomeScreen(false);
            this.consentDocsSubmited = true;


          }
        } else {
          this.apiStatus = false;
          this.notifyText = data?.message || 'Something went wrong please try again later..!';
          this.exceptionalModal = true;
          this.errorMessage = data.message;
          this.loadSpinner = false;
        }
      },
        error => {
          this.loadSpinner = false;
          this.notifyText = error?.message || 'Something went wrong please try again later..!';
          this.exceptionalModal = true;
        });
  }


  saveConsentForms() {
    // this.previsitDocsSubmited = true; 
    this.navigateToHomeScreen(false);
  }

  viewImages(type: string) {
    if (type == 'Insurance') {
      this.displayInsuranceImages = true;
    }
    else if (type == 'License') {
      this.displayLicenseImages = true;
    }
  }

  hideImages(type: string) {
    if (type == 'Insurance') {
      this.displayInsuranceImages = false;
    }
    else if (type == 'License') {
      this.displayLicenseImages = false;
    }
  }

  currentInsuranceSlide(event: any, index: any) {
    this.webcamInsuranceImage = this.webcamInsuranceImages[index];
  }
  currentLicenseSlide(event: any, index: any) {
    this.webcamLicenseImage = this.webcamLicenseImages[index];
  }

  changeROSOrChiefComplaints($event: any) {
    if ($event.checked) {
      if (this.selectedROSAndCC.find((item: any) => item?.name == $event?.name)) {
        this.selectedROSAndCC.find((item: any) => item?.name == $event?.name)?.values?.push($event.value);
      }
      else {
        this.selectedROSAndCC.push({
          name: $event.name,
          values: []
        });
        this.selectedROSAndCC.find(c => c.name == $event.name).values.push($event.value);
      }
    }
    else {
      if (this.selectedROSAndCC.find(c => c.name == $event.name)) {
        if (this.selectedROSAndCC.find(c => c.name == $event.name).values.length > 0) {
          this.selectedROSAndCC.find(c => c.name == $event.name).values = this.selectedROSAndCC.find(c => c.name == $event.name).values.filter((c: any) => c != $event.value);
        }
        else {
          this.selectedROSAndCC = this.selectedROSAndCC.find(c => c.name != $event.name);
        }
      }
    }
  }

  // This method is to get list of templates based on appointment id


  public getListTemplates(appointmentId: any): void {
    this.listTemplates = [];
    let action: any = `SignForm/listTemplates?appointmentId=` + appointmentId;
    this.loadSpinner = true;
    this.httpService.makeGetRequest(action)
      .subscribe((data: any) => {
        if (data && data?.length > 0) {
          this.previsitDocsSubmited = false;
          this.listTemplates = data || [];
          this.disableSubmitBtn = this.listTemplates.filter((l: any) => l?.status != 'SIGNED').length > 0 ? true : false;
          this.updatePannelFlags('displayConsentForms');
          const agreementId = sessionStorage.getItem('agreementId');
          this.listTemplates?.forEach((formList: any) => {
            if (formList?.status !== 'SIGNED' && formList?.agreementId === agreementId) {
              this.getSignedStatus(appointmentId, formList?.agreementId);
            }
          });
        }
        else {
          //  this.previsitDocsSubmited = true; 
          this.navigateToHomeScreen(false);
          // this.notifyText = data?.message || 'Something went wrong please try again later..!';
          // this.exceptionalModal = true;
        }
        this.loadSpinner = false;

      },
        error => {
          this.loadSpinner = false;
          this.notifyText = error?.message || 'Something went wrong please try again later..!';
          this.exceptionalModal = true;
        });
  }

  // This method is to get Adobe signing URL
  public getSigningUrl(agreementId: any): void {
    this.loadSpinner = true;
    this.showEsignModal = false;
    let action = `SignForm/getSigningUrl?agreementId=${agreementId}&appointmentId=${this.appointmentId}`;
    sessionStorage.setItem('agreementId', agreementId);
    this.httpService.makeGetRequest(action).subscribe((data: any) => {
      this.loadSpinner = false;
      window.open(data.responseObject, '_self');
    },
      (error: any) => {
        this.loadSpinner = false;
        this.getSignedStatus(this.appointmentId);
      });
  }

  // This method is used to update status of the document based on the appointment Id and agreementId
  public getSignedStatus(appointmentId: any, agreementIdList?: string): void {
    // this.loadSpinner = true;
    const agreementId = agreementIdList || sessionStorage.getItem('agreementId');
    if (agreementId != null) {
      let action = `SignForm/getSignedStatus?agreementId=` + agreementId + `&appointmentId=` + appointmentId;
      this.httpService.makeGetRequest(action).subscribe((data: any) => {
        // sessionStorage.removeItem('agreementId');
        if (!agreementIdList) {
          //this.getListTemplates(appointmentId);
        }
      },
        (error: any) => {
          if (!agreementIdList) {
            // this.getListTemplates(appointmentId);
          }
          // sessionStorage.removeItem('agreementId');
          // this.notifyText = error?.message || 'Something went wrong please try again later..!';
          // this.exceptionalModal = true;
        });
    }
    else {
      //this.getListTemplates(appointmentId);
    }
  }

  public doctorsDropDownClick() {
    const purposeOfVisiting = this.bookAppointmentForm?.value?.PURPOSE_OF_VISIT;
    if (!purposeOfVisiting) {
      this.doctorDropdownValidation = true;
      this.appointmentSlotsMessage = 'Please select your purpose of visit';
    }
    else {
      this.doctorDropdownValidation = false;
      this.appointmentSlotsMessage = '';
    }
  }


  // this is for get the FacilityID details..
  public getAppointmentById(appointmentId: string): void {
    if (this.appointmentId != '0') {
      let action: any = `appointment/registration/getAppointmentById?id=` + appointmentId;
      this.loadSpinner = true;
      this.httpService.makeGetRequest(action)
        .subscribe((data: any) => {
          if (data.status == 'SUCCESS') {
            this.dtAppointmentDetails = data.responseObject || [];
            this.getProviderConfig(this.dtAppointmentDetails?.appointment?.purposeOfVisit, this.dtAppointmentDetails.facilityId, appointmentId);
          } else {
            this.notifyText = data?.message || 'Something went wrong please try again later..!';
            this.exceptionalModal = true;
          }
        }, error => {
          this.loadSpinner = false;
          this.notifyText = error?.message || 'Something went wrong please try again later..!';
          this.exceptionalModal = true;
        });
    }
  }

  // this is for get the FacilityID details..
  public getAllCareFlowCadModulesDetails(appointmentId: string): void {
    if (this.appointmentId != '0') {
      let action: any = `careFlow/getCareFlowAllModules?appointmentId=${appointmentId}&name=Cad-Modules`;
      this.loadSpinner = true;
      this.httpService.makeGetRequest(action)
        .subscribe((data: any) => {
          if (data.status == 'SUCCESS') {
            this.allCadModulesDetails = data?.responseObject || [];
          } else {
            this.notifyText = data?.message || 'Something went wrong please try again later..!';
            this.exceptionalModal = true;
          }
        }, error => {
          this.loadSpinner = false;
          this.notifyText = error?.message || 'Something went wrong please try again later..!';
          this.exceptionalModal = true;
        });
    }
  }

  public loadROSorConsentForms(appointmentId: string): void {
    var appointmentQuestionnaire = this.dtAppointmentDetails?.appointmentQuestionnaire;
    var consentForm = this.dtAppointmentDetails?.consentForm;

    if (this.providerworkflow?.patientQuestions === true && appointmentQuestionnaire === false) {
      this.displayPatientInfo = false;
      this.age = this.dtAppointmentDetails?.age;
      this.selectedProvider.id = this.dtAppointmentDetails?.facilityId;
      this.gender = this.dtAppointmentDetails?.gender;
      this.bookAppointmentForm.value.PURPOSE_OF_VISIT = this.dtAppointmentDetails?.appointment?.purposeOfVisit;
      // this.getPreAppointmentQuestions();
      this.updatePannelFlags('displayChiefComplaints');
    }
    else if (this.providerworkflow?.consentForms === true && (appointmentQuestionnaire === true && consentForm === false)) {
      //else if (this.providerworkflow?.consentForms === false && (appointmentQuestionnaire === true && consentForm === true)) {
      this.displayPatientInfo = false;
      this.displayConsentForms = true;
      // get the Signed Status after 7 seconds
      //this.loadSpinner = true;
      /*setTimeout(()=>{
        // Signed Document Saving in Progress
        this.getSignedStatus(appointmentId);
      }, 7000);*/
    }
    else {
      // this.navigateToHomeScreen(true);
    }

  }


  //This method is to validate manually entered date value
  public validateManualEnterdob(e: any, fieldName: any): void {
    if (e.target.value != '' && e.target.value != null) {
      // const date = new Date(e.target.value);
      // if (date?.getFullYear() <= 1900) {
      //   return;
      // }
      if (this.validateManualEnterdate(e.target.value, this.currentYear, this.currentMonth + 1, this.currentDate)) {
        // this.bookAppointmentForm?.get(fieldName)?.setValue(null);
        this.bookAppointmentForm?.get(fieldName)?.setErrors({ invalidDate: true });
      }
    }
  }

  //This method is to validate manually entered date value
  public validateManualEnterdate(enteredDate: any, currentYear: any, currentMonth: any, currentDate: any): boolean {
    const date = new Date(enteredDate);
    const toDayDate = new Date();
    const dateArray = enteredDate.split('-');
    if (date != undefined && date != null) {
      // var inputYear = date.getFullYear();
      // var inputMonth = date.getMonth() + 1;
      // //var inputDate = date.getDate();
      // var inputDate = Number(dateArray[2]);
      // if (inputYear >= 1900 && inputYear <= currentYear) {
      //   if (!(inputYear < currentYear || (inputYear == currentYear && inputMonth < currentMonth) ||
      //     (inputYear == currentYear && inputMonth == currentMonth && inputDate <= currentDate))) {
      //     return true
      //   }
      // }
      // else {
      //   return true
      // }
      if (date.getFullYear() < 1910 || date > toDayDate) {
        return true; // Invalid date
      }
      return false
    }
    return false
  }

  //This method is to validate manually entered date value
  public validateAppointmentDate(e: any, fieldName: any): void {
    if (e.target.value != '' && e.target.value != null) {
      const date = new Date(e.target.value);
      if (date?.getFullYear() < this.currentYear) {
        return;
      }
      if (this.validateManualApptDate(e.target.value, this.currentYear, this.currentMonth + 1, this.currentDate)) {
        this.bookAppointmentForm?.get(fieldName)?.setValue(null);
      }
    }
  }

  //This method is to validate manually entered date value
  public validateManualApptDate(enteredDate: any, currentYear: any, currentMonth: any, currentDate: any): boolean {
    const date = new Date(enteredDate);
    const dateArray = enteredDate.split('-');
    if (date != undefined && date != null) {
      var inputYear = date.getFullYear();
      var inputMonth = date.getMonth() + 1;
      //var inputDate = date.getDate();
      var inputDate = Number(dateArray[2]);
      if (inputYear >= currentYear) {
        if ((inputYear === currentYear && inputMonth === currentMonth && inputDate >= currentDate) || (inputYear === currentYear && inputMonth >= currentMonth) || inputYear > currentYear) {
          return false;
        } else {
          return true;
        }
      }
      else {
        return true;
      }
    }

    return false;

  }

  // disbale the cointinue butt if not fill all the details
  public checkValidToContinue(): boolean {

    if (this.bookAppointmentForm?.get('PURPOSE_OF_VISIT')?.value === 'Covid19 Vaccination') {
      if (this.bookAppointmentForm?.get('PATIENT_PRE_EXISTING')?.invalid ||
        this.bookAppointmentForm?.get('COVID_VACCINE_DOSAGE')?.invalid) {
        this.bookAppointmentForm?.markAllAsTouched();
        return true
      }
    }
    // For Athena
    // if(this.facilityId === '90' || this.facilityId === 90) {
    //   if(this.selectedVal === 'Yes') {
    //     if(this.bookAppointmentForm?.get('notes')?.invalid) {
    //       window?.scrollTo(0, 0);
    //       this.bookAppointmentForm?.markAllAsTouched();
    //       return true;
    //     }
    //   }
    // }
    // Demogogrpahics validation check
    if (this.bookAppointmentForm?.get('PURPOSE_OF_VISIT')?.invalid || this.bookAppointmentForm?.get('PATIENT_FIRST_NAME')?.invalid || this.bookAppointmentForm?.get('PATIENT_LAST_NAME')?.invalid || this.bookAppointmentForm?.get('DATE_OF_BIRTH')?.invalid || this.bookAppointmentForm?.get('GENDER')?.invalid || this.bookAppointmentForm?.get('MARITAL_STATUS')?.invalid || this.bookAppointmentForm?.get('ETHNICITY')?.invalid || this.bookAppointmentForm?.get('RACE')?.invalid || this.bookAppointmentForm?.get('ADDRESS')?.invalid || this.bookAppointmentForm?.get('ZIPCODE')?.invalid || this.bookAppointmentForm?.get('PHONE_NUMBER')?.invalid || this.bookAppointmentForm?.get('TYPE_OF_SERVICE')?.invalid || this.bookAppointmentForm?.get('TYPE_OF_PATIENT')?.invalid || this.bookAppointmentForm?.get('DOCTOR')?.invalid || this.bookAppointmentForm?.get('APPOINTMENT_DATE')?.invalid || this.bookAppointmentForm?.get('APPOINTMENT_SLOT')?.invalid || !this.bookAppointmentForm?.get('APPOINTMENT_SLOT')?.value) {
      if (!this.bookAppointmentForm?.get('APPOINTMENT_SLOT')?.value) {
        this.bookAppointmentForm?.get('APPOINTMENT_SLOT')?.enable();
        this.bookAppointmentForm?.get('APPOINTMENT_SLOT')?.setValidators([Validators?.required]);
      }
      this.bookAppointmentForm?.markAllAsTouched();
      return true;
    }
    // city and State validation
    if (this.bookAppointmentForm?.get("ADDRESS")?.valid && this.bookAppointmentForm?.get("STATE")?.invalid && this.bookAppointmentForm?.get("CITY")?.invalid) {
      this.notifyText = 'Please select the valid Street/Address from google suggested places..!';
      this.exceptionalModal = true;
      this.bookAppointmentForm?.markAllAsTouched();
      return true;
    }

    if (this.iAgreeToTerms || ((this.bookAppointmentForm?.get('TYPE_OF_SERVICE')?.value === 'Tele-Medicine' || this.bookAppointmentForm?.get('TYPE_OF_SERVICE')?.value === 'Tele-Health') && this.iAgreeToTelemedicineTerms)) {
      return true;
    }
    return false;
  }


  public goBackFromscheduleAppointment(): void {
    const base_url = window.location.origin;
    location?.replace(`${base_url}/${this.facilityId}/patientPortal`);
  }
  // to check is Established user or not
  public checkIsEstablishedUserOrNot(): void {
    this.isFormSubmit = true;
    if ((this.displayPatientInfo && this.checkValidToContinue()) || this.iAgreeToTerms || this.iAgreeToTelemedicineTerms) {

      return;
    }

    const action = `userInfo/pickEstablishedUser`;
    const params = {
      "dob": this.bookAppointmentForm?.get('DATE_OF_BIRTH')?.value,
      "facilityId": this.selectedProvider?.id,
      "firstName": this.bookAppointmentForm?.get('PATIENT_FIRST_NAME')?.value,
      "gender": this.bookAppointmentForm?.get('GENDER')?.value,
      "lastName": this.bookAppointmentForm?.get('PATIENT_LAST_NAME')?.value,
      "patientPrimaryPhone": `+1${this.bookAppointmentForm?.get('PHONE_NUMBER')?.value}`
    };
    this.loadSpinner = true;
    this.httpService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
      this.loadSpinner = false;
      if (data?.responseObject) {
        console.log(data?.responseObject);
        this.getUserInfoDetails(data?.responseObject);
        this.selectedUserInfoId = data?.responseObject;
        if (this.selectedUserInfoId) {
          this.patientConsent(this.selectedUserInfoId);
        }

        this.bookAppointmentForm?.get('TYPE_OF_PATIENT')?.setValue('Old');
        this.validateHealthpass(data?.responseObject);
        this.getUserInfoDetails(this.selectedUserInfoId);
      } else {
        this.cleartheInsuranceDetailsPrefill();
        this.getUserInfoIdWithPatientDetails = null;
        this.bookAppointmentForm?.get('TYPE_OF_PATIENT')?.setValue('New');
        this.isHealthpassDisabled = true;
      }
      console.log(this.isHealthpassDisabled);
      // if(this.selectedPurposeOfVisit === 'Medication Refill' && !data?.responseObject && data?.responseObject === null){
      //   this.medicationRefillsModal = true;
      //   this.medicationRefillsCheck = 'Medication Refill Service is for Established Patients only, new patients should schedule General office visits.'
      // }else{
      this.updatePannelFlags('displayInsuranceInfo');
      // }
    },
      (error) => {
        this.updatePannelFlags('displayInsuranceInfo');
        this.loadSpinner = false;
        // this.notifyText = error?.message || 'Something went wrong please try again later..!';
        // this.exceptionalModal = true;
      });
  };

  public validateHealthpass(selectedUserInfoId: any) {

    const action = `healthPass/validateHealthpassUser?userInfoId=${selectedUserInfoId}`;
    this.httpService?.makeGetRequest(action)?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        const responseDt = data?.responseObject || null;
        if (responseDt == true) {
          this.isHealthpassDisabled = false;
        }
        else {
          this.isHealthpassDisabled = true;
        }

      } else {
        this.isHealthpassDisabled = true;
      }
      console.log(this.isHealthpassDisabled);
    },
      (error) => {


      });
  }

  //  get user ifno details
  public getUserInfoDetails(userInfoId: string): void {
    const action = `userInfo/getUserInfo?id=${userInfoId}`;
    this.loadSpinner = true;
    this.httpService?.makeGetRequest(action)?.subscribe((data: any) => {
      this.loadSpinner = false;
      if (data?.status === 'SUCCESS') {
        console.log(data);
        this.getUserInfoIdWithPatientDetails = data?.userInfoResponse || null;
        if (data?.userInfoResponse) {
          this.primaryInsuranceDetailsPrefill(data?.userInfoResponse?.insuranceDetails || []);
        }
        if (data?.userInfoResponse?.idDetails) {
          this.bookAppointmentForm?.get('idDocument')?.get('documentType')?.setValue(data?.userInfoResponse?.idDetails?.type || null);
          this.bookAppointmentForm?.get('idDocument')?.get('documentId')?.setValue(data?.userInfoResponse?.idDetails?.number || null);
          this.bookAppointmentForm?.get('idDocument')?.get('issueDate')?.setValue(data?.userInfoResponse?.idDetails?.idValidFromDate || null);
          this.bookAppointmentForm?.get('idDocument')?.get('expiryDate')?.setValue(data?.userInfoResponse?.idDetails?.idValidToDate || null);
          this.bookAppointmentForm?.get('idDocument')?.get('identityImageId')?.setValue(data?.userInfoResponse?.idDetails?.imageId || null);
          // this.bookAppointmentForm?.get('idDocument')?.patchValue(data?.userInfoResponse?.idDetails);
          this.seletcedIdCard = data?.userInfoResponse?.idDetails?.imageId;
          const userInfoId = data?.userInfoResponse?.id;
          if (data?.userInfoResponse?.insuranceDetails && data?.userInfoResponse?.insuranceDetails?.length > 0) {
            this.downloadIdentificationCard(this.seletcedIdCard, "Identification Record", userInfoId);
          }
        }
      }
    },
      (error) => {
        this.loadSpinner = false;
      });
  };


  public patchPrefferdValue(userInfoId: string): void {
    const action = `userInfo/getUserInfo?id=${userInfoId}`;
    this.loadSpinner = true;
    this.httpService?.makeGetRequest(action)?.subscribe((data: any) => {
      this.loadSpinner = false;
      if (data?.status === 'SUCCESS') {
        console.log(data);
        this.getUserInfoIdWithPatientDetails = data?.userInfoResponse || null;
        if (data?.userInfoResponse) {
          if(data?.userInfoResponse?.preferredLanguage){
          this.bookAppointmentForm.get('preferredLanguage')?.setValue(data?.userInfoResponse?.preferredLanguage);
          }else{
            this.bookAppointmentForm.get('preferredLanguage')?.setValue('English');
          }
        }
      }
    },
      (error) => {
        this.loadSpinner = false;
      });
  };

  public primaryInsuranceDetailsPrefill(insuranceDetails: any[]) {
    const getActivePrimaryInsurance = insuranceDetails?.find((item: any) => item?.active === true && item?.priority === "Primary") || null;
    if (getActivePrimaryInsurance) {
      this.bookAppointmentForm?.get('IS_SELF_PAY')?.setValue('Insurance');
      this.checkPaymentType();
      this.bookAppointmentForm?.get('INSURANCE_NAME')?.setValue(getActivePrimaryInsurance?.name || null)
      this.bookAppointmentForm?.get('MEMBER_ID')?.setValue(getActivePrimaryInsurance?.memberId || null)
      this.bookAppointmentForm?.get('GROUP_NUMBER')?.setValue(getActivePrimaryInsurance?.groupNumber || null)
      this.bookAppointmentForm?.get('PAYER_ID')?.setValue(getActivePrimaryInsurance?.payerId || null)
      this.bookAppointmentForm?.get('eligibilityRealtimePayerID')?.setValue(getActivePrimaryInsurance?.eligibilityRealtimePayerID || null)
      this.bookAppointmentForm?.get('eligibilityPortalID')?.setValue(getActivePrimaryInsurance?.eligibilityPortalID || null)
      this.bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_FIRST_NAME')?.setValue(getActivePrimaryInsurance?.subscriberfirstname || null)
      this.bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_LAST_NAME')?.setValue(getActivePrimaryInsurance?.subscriberlastname || null)
      this.bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_GENDER')?.setValue(getActivePrimaryInsurance?.subscribergender || null)
      this.bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_DOB')?.setValue(getActivePrimaryInsurance?.subscriberdateOfBirth || null)
      this.bookAppointmentForm?.get('INSURANCE_TYPE')?.setValue(getActivePrimaryInsurance?.medicareFlag === true ? 'medicare' : 'other');
      this.insuranceCardImageId = getActivePrimaryInsurance?.insuranceCardImageId;
      this.bookAppointmentForm?.get('PATIENT_PRIMARY_SUBSCRIBER')?.setValue(getActivePrimaryInsurance?.insurancePrimarySubscriber || null)
      this.bookAppointmentForm?.get('claimPayerId')?.setValue(getActivePrimaryInsurance?.claimPayerId)
      this.bookAppointmentForm?.get('COVERAGE_EFFECTIVE_START_DATE')?.setValue(getActivePrimaryInsurance?.medicareStartDate || null)
      this.bookAppointmentForm?.get('insuranceClass')?.setValue(getActivePrimaryInsurance?.insuranceClass || null);
      if (getActivePrimaryInsurance?.insuranceCardImageId !== '' && getActivePrimaryInsurance?.insuranceCardImageId !== null && getActivePrimaryInsurance?.insuranceCardImageId !== "undefined") {
        this.downloadInsuranceCard(getActivePrimaryInsurance?.insuranceCardImageId);
      }
      if (getActivePrimaryInsurance?.insurancePrimarySubscriber === 'Self') {
        this.bookAppointmentForm?.get('relationToSubscriber')?.disable();
        // this.bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_GENDER')?.disable();
        // this.bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_FIRST_NAME')?.disable();
        // this.bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_LAST_NAME')?.disable();
        // this.bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_DOB')?.disable();
      } else {
        this.bookAppointmentForm?.get('relationToSubscriber')?.enable();
        this.bookAppointmentForm?.get('relationToSubscriber')?.setValidators([Validators?.required])
        this.bookAppointmentForm?.get('relationToSubscriber')?.setValue(getActivePrimaryInsurance?.relationToSubscriber);
        // this.bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_GENDER')?.enable();
        // this.bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_FIRST_NAME')?.enable();
        // this.bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_LAST_NAME')?.enable();
        // this.bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_DOB')?.enable();
      }
    } else {
      this.cleartheInsuranceDetailsPrefill();
    }
    this.bookAppointmentForm?.get('relationToSubscriber')?.updateValueAndValidity();
  }

  public cleartheInsuranceDetailsPrefill(): void {
    this.bookAppointmentForm?.get('IS_SELF_PAY')?.setValue(null);
    this.bookAppointmentForm?.get('INSURANCE_NAME')?.setValue(null)
    this.bookAppointmentForm?.get('MEMBER_ID')?.setValue(null)
    this.bookAppointmentForm?.get('GROUP_NUMBER')?.setValue(null)
    this.bookAppointmentForm?.get('PAYER_ID')?.setValue(null)
    this.bookAppointmentForm?.get('eligibilityRealtimePayerID')?.setValue(null)
    this.bookAppointmentForm?.get('eligibilityPortalID')?.setValue(null)
    this.bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_FIRST_NAME')?.setValue(null)
    this.bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_LAST_NAME')?.setValue(null)
    this.bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_GENDER')?.setValue(null)
    this.bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_DOB')?.setValue(null)
    this.bookAppointmentForm?.get('INSURANCE_TYPE')?.setValue(null);
    this.insuranceCardImageId = '';
    this.bookAppointmentForm?.get('PATIENT_PRIMARY_SUBSCRIBER')?.setValue(null)
    this.bookAppointmentForm?.get('claimPayerId')?.setValue(null)
    this.bookAppointmentForm?.get('COVERAGE_EFFECTIVE_START_DATE')?.setValue(null)
    this.bookAppointmentForm?.get('insuranceClass')?.setValue(null);
  }

  // Insurance / DL Image Card Download
  /*public downloadInsuranceCard(cardId: string): void {
    const action = `document/user/getInsuranceCard/${cardId}`;
    this.httpService.downloadImage(action).subscribe((data: any) => {
      var file = new Blob([data], { type: 'application/pdf' })
      var fileURL = URL.createObjectURL(file);
      const iframeEle = document.getElementById('insurance-card') as HTMLElement;
      iframeEle.setAttribute('src', fileURL);
    },
      (error: any) => {
      });
  }*/

  public downloadIdentificationCard(additionalKey: any, documentType: any, userInfoId: any) {
    const action = `record/listIndividualDocument?documentType=${documentType}&userInfoId=${userInfoId}&additionalKey=${additionalKey}`;
    this.httpService?.makeGetRequest(action)?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        if (data?.responseObject == null || data?.responseObject == '') {

        }
        else {
          const cardType = "Identification Record";
          const s3Key = data?.responseObject?.s3Key;
          const s3Path = data?.responseObject?.s3Path;
          const additionalKey = data?.responseObject?.additionalKey;
          const fileType = data?.responseObject?.fileType;

          this.downloadIdFile(s3Key, s3Path, additionalKey, fileType, cardType);
        }
      }

    }, (error) => {

    });
  }


  public downloadInsuranceCard(additionalKey: string): void {
    const documentType = "Insurance";
    const action = `record/listIndividualDocument?documentType=${documentType}&additionalKey=${additionalKey}&userInfoId=${this.selectedUserInfoId}`;

    this.httpService?.makeGetRequest(action)?.subscribe((data: any) => {

      if (data?.status === 'SUCCESS') {
        if (data?.responseObject == null || data?.responseObject == '') {

        }
        else {
          const cardType = "Insurance";
          const s3Key = data?.responseObject?.s3Key;
          const s3Path = data?.responseObject?.s3Path;
          const additionalKey = data?.responseObject?.additionalKey;
          const fileType = data?.responseObject?.fileType;
          this.downloadIdFile(s3Key, s3Path, additionalKey, fileType, cardType);
        }
      }
      // this.dtTriggerAppointmentDocs?.next();
    }, (error: any) => {

    });
  }

  public downloadIdFile(s3Key: any, s3Path: any, additionalKey: any, fileType: any, cardType: any) {
    const downloadaction = `record/downloadDocument?s3Key=${s3Key}&s3Path=${s3Path}`;

    this.httpService.downloadImage(downloadaction).subscribe((dData: any) => {
      var file = new Blob([dData], { type: fileType })
      var fileURL = URL.createObjectURL(file);
      let iframeEle = null;
      if (cardType === 'Insurance') {
        iframeEle = document.getElementById('insurance-card') as HTMLElement;
        iframeEle.setAttribute('src', fileURL);
      }
      else if (cardType === 'Identification Record') {
        iframeEle = document.getElementById('id-card') as HTMLElement;
        iframeEle.setAttribute('src', fileURL);
      }
    }, (error: any) => {

    });
  }


  // get the insurances details
  public getInsuranceDetails(): void {
    // if(this.displayPatientInfo && this.checkValidToContinue()){
    //   return;
    // }
    const action = 'userInfo/getInsuranceDetails';
    const params = {
      "address": {
        "city": this.bookAppointmentForm?.get('CITY')?.value,
        "country": this.bookAppointmentForm?.get('COUNTRY')?.value,
        "state": this.bookAppointmentForm?.get('STATE')?.value,
        "street": this.bookAppointmentForm?.get('ADDRESS')?.value,
        "zipCode": parseInt(this.bookAppointmentForm?.get('ZIPCODE')?.value)
      },
      "dob": this.bookAppointmentForm?.get('DATE_OF_BIRTH')?.value,
      "firstName": this.bookAppointmentForm?.get('PATIENT_FIRST_NAME')?.value,
      "gender": this.bookAppointmentForm?.get('GENDER')?.value,
      "lastName": this.bookAppointmentForm?.get('PATIENT_LAST_NAME')?.value,
      "patientPrimaryPhone": `+1${this.bookAppointmentForm?.get('PHONE_NUMBER')?.value}`
    };
    this.loadSpinner = true;
    this.httpService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
      console.log(data);
      // this.updatePannelFlags('displayInsuranceInfo');
      this.loadSpinner = false;
    },
      (error) => {
        this.loadSpinner = false;
        this.notifyText = error?.message || 'Something went wrong please try again later..!';
        this.exceptionalModal = true;
      });
  }

  // to get slected values chief complaints
  public changeChiefComplaints(event: any, name: string): void {
    let obj = {
      name: name,
      values: this.chiefSelectedValues?.values || [],
      otherNotes: this.otherNotes
    }
    this.chiefSelectedValues = obj;
    if (event?.target?.checked) {
      this.displayReasonForVisitErrMsg = false;
      this.chiefSelectedValues?.values?.push(event?.target?.value);
    } else {
      this.chiefSelectedValues.values = this.chiefSelectedValues?.values?.filter(e => e !== event?.target?.value);
    }
  }

  // to check if other select
  get checkIfOtherSelects(): boolean {
    const findObj = this.chiefSelectedValues?.values?.find((item: any) => item === 'Other')
    if (findObj) {
      return true;
    }
    return false;
  }

  // save Chief Complaints data
  public saveChiefComplaints(): void {
    const action = `treatmentFlow/saveChiefComplaints?appointmentId=${this.appointmentId}`;
    if (this.chiefSelectedValues.values?.length === 0) {
      return;
    }
    let params: any = this.chiefSelectedValues;
    params.otherNotes = this.otherNotes || null;
    this.loadSpinner = true;
    this.httpService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
      this.loadSpinner = false;
      this.updatePannelFlags('displayROS');
    },
      (error) => {
        this.loadSpinner = false;
        this.notifyText = error?.message || 'Something went wrong please try again later..!';
        this.exceptionalModal = true;
      })
  }

  // to make selected object for Review of Systems
  public othersRosSelected(event: any, categoryItem: any): void {
    let slectedSection: any;
    let index: any;
    if (categoryItem?.question && categoryItem?.name?.trim() === 'Annual Wellness Visit') {
      slectedSection = this.rosOthersSelectedValues?.find(e => e?.question === categoryItem?.question) || null;
      index = this.rosOthersSelectedValues?.findIndex(e => e?.question === categoryItem?.question);
    } else {
      slectedSection = this.rosOthersSelectedValues?.find(e => e?.name === categoryItem?.name) || null;
      index = this.rosOthersSelectedValues?.findIndex(e => e?.name === categoryItem?.name);
    }

    if (slectedSection && slectedSection?.length !== 0 && index !== -1) {
      let obj = {
        name: categoryItem?.name?.trim(),
        values: slectedSection?.values || [],
        question: categoryItem?.question
      }
      if (event?.target?.checked) {
        obj?.values?.push(event?.target?.value);
      } else {
        obj.values = obj?.values?.filter((e: any) => e !== event?.target?.value);
      }

      if (obj?.values.length === 0) {
        this.rosOthersSelectedValues.splice(index, 1);
      } else {
        this.rosOthersSelectedValues[index] = obj;
      }
    } else {
      const values = [];
      values?.push(event?.target?.value);
      let obj = {
        name: categoryItem?.name?.trim(),
        values: values,
        question: categoryItem?.question
      }
      this.rosOthersSelectedValues.push(obj);
    }
  }
  dateValidator(date:any){
    const inputDate = new Date(date.value);
    if (isNaN(inputDate.getTime())) {
      return { 'invalidDate': true };
    }
    const minYear = 1900;
    const maxYear = new Date().getFullYear() + 10;
    if (inputDate.getFullYear() < minYear || inputDate.getFullYear() > maxYear) {
      return { 'invalidDate': true };
    }
    return null;
  }

  // Kiosk Add Appointment 
  // check schedule type
  public changeScheduleType(): void {
    this.getAppointmentSlotsAvailable = [];
    const scheduleType = this.bookAppointmentForm?.get('SCHEDULE_TYPE')?.value;
    this.bookAppointmentForm?.get('ENDTIME_SLOT')?.setValue(null);
    this.bookAppointmentForm?.get('APPOINTMENT_DATE')?.setValue(null);
    this.bookAppointmentForm?.get('APPOINTMENT_SLOT')?.setValue(null);
    if (scheduleType === 'Future Appointment') {
      this.bookAppointmentForm?.get('APPOINTMENT_DATE')?.setValidators([Validators?.required,this.dateValidator]);
      this.bookAppointmentForm?.get('APPOINTMENT_SLOT')?.setValidators([Validators?.required]);
      this.bookAppointmentForm?.get('APPOINTMENT_SLOT')?.disable();
    } else {
      this.bookAppointmentForm?.get('APPOINTMENT_DATE')?.clearValidators();
      this.bookAppointmentForm?.get('APPOINTMENT_SLOT')?.clearValidators();
    }
    this.bookAppointmentForm?.get('APPOINTMENT_DATE')?.updateValueAndValidity();
    this.bookAppointmentForm?.get('APPOINTMENT_SLOT')?.updateValueAndValidity();
    this.bookAppointmentForm?.get('ENDTIME_SLOT')?.updateValueAndValidity();
  }

  // change slot time
  public changeSlotTime(vtype: string, row: number): void {
    let rowIndex = '';
    if (vtype === 'default') {
      rowIndex = '';
      this.defaultChk = true;
      if (this.newVisitArr.length > 0) {
        this.getProviderList(this.newVisitArr[0].trim());
      }
    }
    else {
      rowIndex = '_' + row;
      const provider = document.getElementById('doctorDiv_' + row) as HTMLElement;
      provider.classList.remove('disabled');
    }
    const startTime = this.bookAppointmentForm?.get('APPOINTMENT_SLOT' + rowIndex)?.value;
    const timeObj: any = this.getAppointmentSlotsAvailable?.find((item: any) => item?.startTime === startTime);
    //this.bookAppointmentForm?.get('ENDTIME_SLOT')?.setValue(timeObj?.endTime);
    this.bookAppointmentForm?.get('ENDTIME_SLOT' + rowIndex)?.setValue(timeObj?.endTime);
  }

  naviagetBackToProviderFlowBoard(): void {
    window?.open(`${environment?.providerConnetUrl}hospital/hosp-dashboard`, environment?.windowName);
    window.close();
  }

  // calling everytime when value is changing from form controls
  public appointmentFormValueChanges(): void {
    this.bookAppointmentForm?.get('DATE_OF_BIRTH')?.valueChanges?.pipe(
      debounceTime(2000),
      distinctUntilChanged()
    )?.subscribe(value => {
      if (value && this.bookAppointmentForm?.get('PATIENT_FIRST_NAME')?.value && this.bookAppointmentForm?.get('GENDER')?.value && this.bookAppointmentForm?.get('PATIENT_LAST_NAME')?.value && this.bookAppointmentForm?.get('PHONE_NUMBER')?.value && this.bookAppointmentForm?.get('PHONE_NUMBER')?.value?.length === 10) {
        this.checkEstablishedDetails();
      }
    });
    this.bookAppointmentForm?.get('PATIENT_FIRST_NAME')?.valueChanges?.pipe(
      debounceTime(2000),
      distinctUntilChanged()
    )?.subscribe(value => {
      if (value && this.bookAppointmentForm?.get('DATE_OF_BIRTH')?.value && this.bookAppointmentForm?.get('GENDER')?.value && this.bookAppointmentForm?.get('PATIENT_LAST_NAME')?.value && this.bookAppointmentForm?.get('PHONE_NUMBER')?.value && this.bookAppointmentForm?.get('PHONE_NUMBER')?.value?.length === 10) {
        this.checkEstablishedDetails();
      }
    });
    this.bookAppointmentForm?.get('GENDER')?.valueChanges?.pipe(
      debounceTime(2000),
      distinctUntilChanged()
    )?.subscribe(value => {
      if (value && this.bookAppointmentForm?.get('PATIENT_FIRST_NAME')?.value && this.bookAppointmentForm?.get('DATE_OF_BIRTH')?.value && this.bookAppointmentForm?.get('PATIENT_LAST_NAME')?.value && this.bookAppointmentForm?.get('PHONE_NUMBER')?.value && this.bookAppointmentForm?.get('PHONE_NUMBER')?.value?.length === 10) {
        this.checkEstablishedDetails();
      }
    });
    this.bookAppointmentForm?.get('PATIENT_LAST_NAME')?.valueChanges?.pipe(
      debounceTime(2000),
      distinctUntilChanged()
    )?.subscribe(value => {
      if (value && this.bookAppointmentForm?.get('PATIENT_FIRST_NAME')?.value && this.bookAppointmentForm?.get('GENDER')?.value && this.bookAppointmentForm?.get('DATE_OF_BIRTH')?.value && this.bookAppointmentForm?.get('PHONE_NUMBER')?.value && this.bookAppointmentForm?.get('PHONE_NUMBER')?.value?.length === 10) {
        this.checkEstablishedDetails();
      }
    });
    this.bookAppointmentForm?.get('PHONE_NUMBER')?.valueChanges?.pipe(
      debounceTime(2000),
      distinctUntilChanged()
    )?.subscribe(value => {
      if (this.bookAppointmentForm?.get('PHONE_NUMBER')?.value?.length === 10 && this.bookAppointmentForm?.get('PATIENT_FIRST_NAME')?.value && this.bookAppointmentForm?.get('GENDER')?.value && this.bookAppointmentForm?.get('PATIENT_LAST_NAME')?.value && this.bookAppointmentForm?.get('DATE_OF_BIRTH')?.value) {
        this.checkEstablishedDetails();;
      }
    });
  }

  // to check is Established user or not
  public checkEstablishedDetails(): void {
    if (!this.bookAppointmentForm?.get('DATE_OF_BIRTH')?.value && !this.bookAppointmentForm?.get('PATIENT_FIRST_NAME')?.value && !this.bookAppointmentForm?.get('GENDER')?.value && !this.bookAppointmentForm?.get('PATIENT_LAST_NAME')?.value && !this.bookAppointmentForm?.get('PHONE_NUMBER')?.value) {
      return;
    }
    const action = `userInfo/pickEstablishedUser`;
    const params = {
      "dob": this.bookAppointmentForm?.get('DATE_OF_BIRTH')?.value,
      "facilityId": this.selectedProvider?.id,
      "firstName": this.bookAppointmentForm?.get('PATIENT_FIRST_NAME')?.value,
      "gender": this.bookAppointmentForm?.get('GENDER')?.value,
      "lastName": this.bookAppointmentForm?.get('PATIENT_LAST_NAME')?.value,
      "patientPrimaryPhone": `+1${this.bookAppointmentForm?.get('PHONE_NUMBER')?.value}`
    };
    this.httpService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
      if (data?.responseObject) {
        this.selectedUserInfoId = data?.responseObject;
        this.bookAppointmentForm?.get('TYPE_OF_PATIENT')?.setValue('Old');
        this.patchPrefferdValue(this.selectedUserInfoId);
        this.patientConsent(data?.responseObject);
        this.validateHealthpass(data?.responseObject);
      } else {
        this.bookAppointmentForm?.get('TYPE_OF_PATIENT')?.setValue('New');
        this.bookAppointmentForm.get('preferredLanguage')?.setValue('English');
        this.isHealthpassDisabled = true;
      }
    },
      (error) => {
      });
  };

  public checkConscentForms(event: any) {
    if (event) {
      this.previsitDocsSubmited = false;
      //this.listTemplates = data || [];
      // this.disableSubmitBtn = this.listTemplates.filter((l: any) => l?.status != 'SIGNED').length > 0 ? true : false;
      this.updatePannelFlags('displayConsentForms');
    }
  }

  public navigateToConscentList() {
    location?.replace(`${this.document.location.origin}/${this.facilityId}/appt?previsitId=${this.appointmentId}`);
  }

  navigateToCADHomePage() {
    const base_url = window.location.origin;
    location?.replace(`${base_url}/${this.facilityId}/patientPortal`);
    // location?.replace("https://www.careeco.net/home");
  }

  public onlyRegularVisitPF(): void {
    if (!this.facilityId) {
      return;
    }
    if (parseInt(this.facilityId) === 201) {
      this.facilityId = this.facilityId;
      this.iAgreeToTelemedicineTerms = false;
      this.bookAppointmentForm?.get('TYPE_OF_SERVICE')?.reset();
      // this.bookAppointmentForm?.get('TYPE_OF_SERVICE')?.disable();
      this.bookAppointmentForm?.get('TYPE_OF_SERVICE')?.setValue('Regular Visit');
    }
  }

  public updateIdCardKeys(existingS3Key: any, news3Key: any): void {
    let action = '';
    if (news3Key !== '') {
      action = `record/updateIdImageUserInfoKey?existingS3Key=${existingS3Key}&news3Key=${news3Key}`;
    }
    else {
      action = `record/updateIdImageUserInfoKey?existingS3Key=${existingS3Key}`;
    }

    this.httpService?.makeRequestWithAction(action, '')
      .subscribe((data: any) => {
        if (data?.body?.status === 'SUCCESS') {

        } else {

        }
      },
        error => {

        });
  }


  public deleteDocument(existingS3Key: any, userInfoId: any): void {
    const action = `record/deleteDocument?s3Key=${existingS3Key}&userInfoId=${userInfoId}`;
    this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {

    }, error => {

    })
  }

  // checkbox for Patient Consent 
  public patientConsent(id: any) {
    // const userInfoId= sessionStorage.getItem('userInfoId');
    const action = `userInfo/getPatientConsentDetails?userInfoId=${id}`;
    this.httpService.makeGetRequest(action).subscribe((data: any) => {
      if (data?.status == 'SUCCESS') {
        if (data.responseObject.patientAgreement) {
          this.bookAppointmentForm.controls['patientConsent'].setValue(true)
          this.isdesabled = true
        }
        else {
          // this.bookAppointmentForm.controls['patientConsent'].setValue(false)     
          this.isdesabled = false
        }
      }

    })
  }

  public getPurposeOfVisit(event: any): void {
    this.visitSelected = false;
    this.selectedVal = event.target.value;
    if (this.selectedVal === 'Yes') {
    }
    else {
      // this.disablePurposeOfVisit = false;
      //this.bookAppointmentForm.controls['PURPOSE_OF_VISIT'].enable();
      this.bookAppointmentForm.controls['PURPOSE_OF_VISIT'].setValue(null);
    }
  }

  // For 85 facility question
  public newFacilityChange(event: any): void {
    this.is85FacilityChange = event?.target?.value;
  }

  // to check is Time Slots for doctor user or not
  public getTimeSlots(visitName: any, row: any, vtype: any): void {
    if (!this.doctorFirstName && !this.doctorLastName) {
      return;
    }
    const purposeOfVisit = visitName.trim();
    if (vtype === 'default') {
      this.availableSlots = [];
    }
    else {
      this.availableSlotsAdd = [];
    }
    this.loadSpinner = true;
    const action = `appointment/registration/getAvailableSlotDates?firstName=${this.doctorFirstName}&lastName=${this.doctorLastName}&purposeOfVisit=${purposeOfVisit}`;
    this.httpService?.makeGetRequest(action)?.subscribe((data: any) => {
      if (data?.responseObject) {
        this.loadSpinner = false;
        const slotsInfo = data?.responseObject || [];
        let allDates: any[] = [];
        slotsInfo?.forEach((dateItem: any) => {
          const splitDate = dateItem && dateItem?.includes('T') ? dateItem?.split('T') : dateItem;
          allDates?.push(splitDate && splitDate[0]);
        });
        if (vtype === 'default') {
          this.availableSlots = allDates || [];
          console.log(this.availableSlots);
        }
        else {
          this.availableSlotsAdd = allDates || [];
          console.log(this.availableSlotsAdd);
        }
      } else {
        this.loadSpinner = false;
        // this.notifyText = data?.message || 'Something went wrong please try again later..!';
      }
    },
      (error) => {
        this.loadSpinner = false;
        this.notifyText = error?.message || 'Something went wrong please try again later..!';
        this.exceptionalModal = true;
      });
  };

  public setSelectedSlot(slotDate: any, indexVal: any, vtype: string, row: number) {
    //  this.bookAppointmentForm?.get('waitList')?.setValue(false);}
    const typeOfService = this.bookAppointmentForm?.value?.TYPE_OF_SERVICE;
    if (typeOfService == null || typeOfService == '') {
      // this.bookAppointmentForm?.get('DOCTOR_')?.setValue(null);
      this.doctorDropdownValidation = true;
      this.appointmentSlotsMessage = 'Please select Type Of Visit';
      return;
    }
    this.selcetdSlotIndex = indexVal;
    // const converTdate = this.httpService?.convertDate(slotDate);
    if (vtype === 'default') {
      this.bookAppointmentForm?.get('APPOINTMENT_DATE')?.setValue(slotDate);
      const currentDate = new Date();
      const enteredDate = new Date(slotDate);
      if (enteredDate > currentDate) {
        this.isFutureAppt = true;
      } else {
        this.isFutureAppt = false;
        this.bookAppointmentForm?.get('waitList')?.setValue(false);
      }
      this.resetNxtVisit();
    }
    else {
      this.bookAppointmentForm?.get('APPOINTMENT_DATE_' + row)?.setValue(slotDate);
    }
    this.getAppointmentSlots(vtype, row);
  }

  public getAppointmentDepencies(currPurposeofVisit: any, prevPurposeofVisit: any, row: number) {
    const action = `appointment/registration/getApptDependencies?currPurposeofVisit=${currPurposeofVisit}&prevPurposeofVisit=${prevPurposeofVisit}`;
    this.httpService?.makeGetRequest(action)?.subscribe((data: any) => {
      this.appointmentGap = 0;
      if (data?.responseObject) {
        this.appointmentGap = data?.responseObject;
      } else {
        this.appointmentGap = 0;
      }
      const firstApptDt = this.bookAppointmentForm?.get('APPOINTMENT_DATE')?.value;
      const dateArray = firstApptDt?.split('-');
      //this.nextApptDt = Number(dateArray[2]) + this.appointmentGap;
      const CurentDate = new Date(this.bookAppointmentForm?.get('APPOINTMENT_DATE')?.value);
      CurentDate?.setDate(CurentDate?.getDate() + this.appointmentGap);
      const newDt = CurentDate?.toLocaleDateString();
      const elapsedDate = formatDate(newDt, 'yyyy-MM-dd', 'en_US');

      this.nextApptDt = elapsedDate;
      //const dateFiled = this.bookAppointmentForm?.get('APPOINTMENT_DATE_'+row)?.value;
      const dateFiled = document.getElementById('c_datemin_' + row) as HTMLElement;
      dateFiled.setAttribute("min", elapsedDate);
    },
      (error) => {

      });
  }

  public getDateFromString(dateVal: any) {
    const date1 = new Date(dateVal);
    const date2 = new Date(this.nextApptDt);
    this.dtGapValid = true;
    if (date1 < date2) {
      this.dtGapValid = false;
    }
    return this.dtGapValid;
  }

  // Add PRefix of Zero before the zipcode if below 4 digits
  public addPrefixZipCode(addressZipCode: any, valid: any): any {
    let prefixZipcode = addressZipCode;
    if (prefixZipcode) {
      const checkLen = prefixZipcode?.toString()?.length;
      if (checkLen === 4) {
        prefixZipcode = '0' + prefixZipcode;
      }
      if (checkLen === 3) {
        prefixZipcode = '00' + prefixZipcode;
      }
    }
    if (valid) {
      this.bookAppointmentForm?.get('ZIPCODE')?.setValue(prefixZipcode || addressZipCode || '');
    }

    return prefixZipcode || addressZipCode || '';
  }
  public dateFormate(dateTime: string): string {
    // return this.httpService?.getAppointmentTime(dateTime);
    return this.httpService?.formatDateTimeWithoutAmPm(dateTime);
  }

  // Mammogram check Value
  public checkValue(event?: any): void {
    this.selectedVal = event?.target?.value;
    if (this.selectedVal === 'Yes') {
      // this.bookAppointmentForm?.get('notes')?.setValidators([Validators?.required]);
    } else {
      // this.bookAppointmentForm?.get('notes')?.setValidators(null);
      this.bookAppointmentForm?.get('notes')?.setValue(null);
    }
    this.bookAppointmentForm?.get('notes')?.updateValueAndValidity();
  }

  public awhMammogramQue(event: any): void {
    this.isAwhMammogramQue = event?.target?.value;
  }

  public getlabelId(labelName: any){
 
    labelName = labelName.replace(/\s/g, '');
    return labelName;
 
  }

  // Get the user demographic details
  public getUserDemographicDetails(userInfoId: string): void {
    // const action = `common/getChartInfo?userInfoId=${userInfoId || this.userInfoId}&chartModuleName=UserInfo`;
    const action = `userInfo/getUserInfo?id=${userInfoId}`;
    this.loadSpinner = true;
    this.httpService?.makeGetRequest(action)?.subscribe((data: any) => {
      this.loadSpinner = false;
      if (data?.status === 'SUCCESS') {
        const getResponse = data && data?.userInfoResponse;
        if (getResponse?.primaryPhone?.includes('+1')) {
          getResponse.primaryPhone = getResponse?.primaryPhone?.replace('+1', '')
        }
        this.bookAppointmentForm?.get('PATIENT_FIRST_NAME')?.setValue(getResponse?.firstName);
        this.bookAppointmentForm?.get('GENDER')?.setValue(getResponse?.gender || null);
        this.bookAppointmentForm?.get('MARITAL_STATUS')?.setValue(getResponse?.maritalStatus);
        this.bookAppointmentForm?.get('PATIENT_LAST_NAME')?.setValue(getResponse?.lastName);
        this.bookAppointmentForm?.get('DATE_OF_BIRTH')?.setValue(this.formatDob(getResponse?.usrDateOfBirth || getResponse?.dob));
        this.bookAppointmentForm?.get('PHONE_NUMBER')?.setValue(getResponse?.primaryPhone);

        this.bookAppointmentForm?.get('ADDRESS')?.setValue(getResponse?.address?.street);
        this.bookAppointmentForm?.get('CITY')?.setValue(getResponse?.address?.city);
        this.bookAppointmentForm?.get('STATE')?.setValue(getResponse?.address?.state);
        this.bookAppointmentForm?.get('COUNTRY')?.setValue(getResponse?.address?.country);
        this.bookAppointmentForm?.get('ZIPCODE')?.setValue( getResponse?.address?.zipCode);
        this.bookAppointmentForm?.get('ETHNICITY')?.setValue(getResponse?.ethnicity);
        this.bookAppointmentForm?.get('RACE')?.setValue(getResponse?.race);

        this.getUserInfoIdWithPatientDetails = data?.userInfoResponse || null;
        if (data?.userInfoResponse) {
          this.primaryInsuranceDetailsPrefill(data?.userInfoResponse?.insuranceDetails || []);
        }
        if (data?.userInfoResponse?.idDetails) {
          this.bookAppointmentForm?.get('idDocument')?.get('documentType')?.setValue(data?.userInfoResponse?.idDetails?.type || null);
          this.bookAppointmentForm?.get('idDocument')?.get('documentId')?.setValue(data?.userInfoResponse?.idDetails?.number || null);
          this.bookAppointmentForm?.get('idDocument')?.get('issueDate')?.setValue(data?.userInfoResponse?.idDetails?.idValidFromDate || null);
          this.bookAppointmentForm?.get('idDocument')?.get('expiryDate')?.setValue(data?.userInfoResponse?.idDetails?.idValidToDate || null);
          this.bookAppointmentForm?.get('idDocument')?.get('identityImageId')?.setValue(data?.userInfoResponse?.idDetails?.imageId || null);
          this.seletcedIdCard = data?.userInfoResponse?.idDetails?.imageId;
          const userInfoId = data?.userInfoResponse?.id;
          if (data?.userInfoResponse?.insuranceDetails && data?.userInfoResponse?.insuranceDetails?.length > 0) {
            this.downloadIdentificationCard(this.seletcedIdCard, "Identification Record", userInfoId);
          }
        }
      }
    },
      (error) => {
        this.loadSpinner = false;
      });
  };

}

