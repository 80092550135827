import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, delay } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExternalGatewayService {
  public baseUrl: string = environment?.externalGateway;
  private accessWithOptions: any
  hideCallBacks = [];

  showLoader() { };
  hideLoader() { };

  constructor(
    public http: HttpClient,
  ) {
    this.accessWithOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'x-access-code': localStorage.getItem('token'),
      }),
    }
  }

  public makeRequestWithAction(action: string, params?: any): Observable<any> {
    return this.http.post(this.baseUrl + action, params, this.accessWithOptions.headers)
      .pipe(
        map(res => res),
        catchError(error => throwError(error.message || error))
      );
  }

  public makeGetRequest(action: any): Observable<any> {
    return this.http.get(this.baseUrl + action, this.accessWithOptions.headers)
      .pipe(
        map(res => res),
        catchError(error => throwError(error.message || error))
      );
  }

  public makeGetInvoice(action: string): Observable <any> {
    this.accessWithOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'facilityId': '91',
        // 'x-access-code': localStorage.getItem('token'),
      }),
    }
    return this.http.get(this.baseUrl + action, this.accessWithOptions.headers)
      .pipe(
        map(res => res),
        catchError(error => throwError(error.message || error))
      );
  }
}
