import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from 'src/app/shared/http.service';

@Component({
  selector: 'app-summitt-medicine',
  templateUrl: './summitt-medicine.component.html',
  styleUrls: ['./summitt-medicine.component.scss']
})
export class SummittMedicineComponent implements OnInit {

  public loadSpinner = true;
  constructor(private activeRoute: ActivatedRoute, private httpService: HttpService) { }

  ngOnInit(): void {
    this.activeRoute?.params?.subscribe(params => {
      const facilityId = params['facilityId'];
      const facilityName = params['facilityName'];
      const message = params['message'];
      sessionStorage?.setItem('headerFacilityId', facilityId);
      this.callAnalyticsApi(facilityId, message);
    });
  }

  private callAnalyticsApi(facilityId?: number, message?: string): void {
    const action = `appointment/registration/appAnalytics?category=${message}`;
    this.httpService?.makeRequestWithAction(action)?.subscribe((data: any) => {
      this.loadSpinner = false;
      window?.open('https://qrco.de/bdc2nt', "_self");
    }, error => {
      this.loadSpinner = false;
      window?.open('https://qrco.de/bdc2nt', "_self");
    });
  }

}
