import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class DataService {

    public providerSuggestLoaction: any = {};
    public urgentCareCards: any ;
    
    public applicationid: any;
    public loginDetails: any;
    loginStatus: boolean = false;
    facilitydetailsData: any;

    public allData: any = {
        'isLogin' : false,
        'facilitydetails': [],
        'waitTime' : {},
        'allDoctors': [],
        'eligibilityMessageArray': [],
        'allAppointments': [],
        'setPatientHealthData': {},
        'selectedCardTitle': '',
        'selectedEstablishedPatient': {}
    }

    private defaultData = new BehaviorSubject(this.allData);
    currentMessage = this.defaultData.asObservable();
    allAppointments: any;
    public dataTable = new BehaviorSubject(false);

    constructor(public router: Router) { }


    setLoginDetails(logindetails?:any){
        this.loginDetails = logindetails
    }

    getLoginDetails(){
        return this.loginDetails
    }

    public setIsLogin(status: boolean){
        this.allData.isLogin = status;
        this.defaultData?.next(this.allData);
    }

    getIsLogin(){
       return this.loginStatus
    }  


    logout(){
        sessionStorage.removeItem('token');
        this.router.navigate(['/home/provider/signin']);
        this.allData.isLogin = null;
        this.defaultData.next(this.allData);
    }

}
  
