import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Router, RouterLink } from '@angular/router';

@Component({
  selector: 'app-exception-modal',
  templateUrl: './exception-modal.component.html',
  styleUrls: ['./exception-modal.component.scss']
})
export class ExceptionModalComponent implements OnChanges {

  @Input() exceptionalModalFlag!: boolean;
  @Input() notifyText!: string;
  @Output() exceptionalModalEmit = new EventEmitter<any>();
  @Input() saveInvoice!:boolean ;
  @Input() saveAndUpdate!:boolean ;
  @Input() isSuccessNotify !:boolean ;

  public facilityId:any;

  constructor( private router: Router){
    this.facilityId = sessionStorage?.getItem('headerFacilityId') || '';

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.exceptionalModalFlag = this.exceptionalModalFlag;
    }
  }

  get updateFlag(): boolean {
    return this.exceptionalModalFlag;
  }

  public closeModal(): void {
    this.exceptionalModalFlag = false;
    this.exceptionalModalEmit?.emit(false);
   // this.router.navigate(['/invoice/invoiceBoard']);
    if(this.saveInvoice == true || this.saveAndUpdate == true){
      this.router.navigate(['/invoice/invoiceBoard']);
    }
  }

}
