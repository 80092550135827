import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { WizardComponent } from 'angular-archwizard';
import { HttpService } from 'src/app/shared/http.service';

@Component({
  selector: 'app-bone-mineral-density-scan',
  templateUrl: './bone-mineral-density-scan.component.html',
  styleUrls: ['./bone-mineral-density-scan.component.scss']
})
export class BoneMineralDensityScanComponent implements OnInit {

  @Input() appointmentDetails: any;
  public boneMineralDensityScanNode?: any;
  rosOthersSelectedValues: any;
  public loadSpinner?: boolean;
  public boneMineralDensityScanForm!: FormGroup;
  public allSectionsModules!: FormArray;
  @Input() previsitDocsWizard!: WizardComponent;
  public yesOrNo = [
    {desc: 'Yes', value: 'Yes'},
    {desc: 'No', value: 'No'},
  ];
  @Input() moduleName!: string;
  public showErrorMessagePopup?: boolean = false;
  public messageShown?: string = '';

  constructor(private httpService: HttpService, private formBuilder: FormBuilder) {
    this.boneMineralDensityScanForm = this.formBuilder?.group({
      'allSectionsModules': this.formBuilder?.array([])
    });
  }


  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.getModuleInfo(this.moduleName);
  }

  public resetCallMethods(module: string): void {
    // this.boneMineralDensityScanNode = [];
    this.boneMineralDensityScanForm?.reset();
    this.clearFormArray(this.boneMineralDensityScanForm?.get('allSectionsModules') as FormArray);
    this.getModuleInfo(module);
  }

  public checkGenderPurposeVisit(modeMetaData: any): boolean {
    if (!modeMetaData || (modeMetaData?.gender === 'All' || modeMetaData?.gender === this.appointmentDetails?.gender) && (modeMetaData?.purposeOfVisit?.includes('All') || modeMetaData?.purposeOfVisit?.includes(this.appointmentDetails?.appointment?.purposeOfVisit))) {
      return true;
    }
    return false;
  }

  public checkAnnual(nodeName: string): boolean {
    let check: boolean = false;
    if (nodeName) {
      check = true;
    }
    if (nodeName === 'Annual Wellness Visit' && this.appointmentDetails?.appointment?.purposeOfVisit === 'Annual Physicals' && this.appointmentDetails?.age < 65 && this.appointmentDetails?.facilityId === 201) {
      check = false;
    }
    if (nodeName === 'Annual Wellness Visit' && this.appointmentDetails?.appointment?.purposeOfVisit === '65 Above Annual Wellness Visit' && this.appointmentDetails?.age >= 65) {
      check = true;
    } else if (nodeName === 'Annual Wellness Visit' && this.appointmentDetails?.appointment?.purposeOfVisit === '65 Above Annual Wellness Visit' && this.appointmentDetails?.age < 65) {
      check = false;
    }
    return check || false;
  }

  public getModuleInfo(module: string): void {
    this.moduleName = module;
    // const action = `careFlow/getCareFlowModuleInfo?facilityId=${this.appointmentDetails?.facilityId}&module=ROS`;
    const action = `careFlow/getCareFlowModuleAllInfo?module=${module || this.moduleName}&name=Cad-ModuleDetails`;
    this.loadSpinner = true;
    this.httpService.makeGetRequest(action).subscribe(
      (data: any) => {
        if (data?.status === 'SUCCESS') {
          this.boneMineralDensityScanNode = data?.responseObject?.resourceType || [];

          // loop and created dynamic formGroups
          const rosNodeSections = JSON.parse(JSON?.stringify(this.boneMineralDensityScanNode)) || [];

          rosNodeSections?.forEach((vitalFormItem: any) => {

            if (vitalFormItem?.sectionMetadata && !this.checkGenderPurposeVisit(vitalFormItem?.sectionMetadata)) {
              return;
            }

            let sectionsForm: FormGroup;
            sectionsForm = this.formBuilder?.group({
              'module': [this.moduleName || null],
              'resourceType': [vitalFormItem?.name || null],
              'sections': this.formBuilder?.array([]),
              'additionalText': [vitalFormItem?.additionalText],
              'sectionMetadata': [vitalFormItem?.sectionMetadata]
            });

            if (vitalFormItem?.additionalText) {
              sectionsForm?.addControl('otherNotes', new FormControl(null));
            }

            // looping all sections to craete parent Form Groups
            vitalFormItem?.sections?.forEach((secItem: any) => {

              let formControls: FormGroup;
              formControls = this.formBuilder?.group({
                'name': [secItem?.name, null],
                'answers': this.formBuilder?.array([]),
                'additionalText': [secItem?.additionalText],
              });
              if (secItem?.additionalText) {
                formControls?.addControl('otherNotes', new FormControl(null));
              }
              const subControlsFormArray = formControls?.get('answers') as FormArray;

              // formControls?.get('name')?.setValue(secItem?.name);
              this.clearFormArray(subControlsFormArray as FormArray);

              // looping the questions or sub sections
              secItem?.questions?.forEach((queItem: any) => {
                // creating the Form Array if have multiselect with Sub Sections
                if (queItem?.subSections && queItem?.subSections?.length > 0) {

                  let subControls: FormGroup = this.formBuilder?.group({
                    'label': [queItem?.label || null],
                    'actualValue': [null],
                    'additionalText': [queItem?.additionalText],
                    'properties': [queItem?.properties || null],
                    'values': [queItem?.values || null],
                    'subSections': this.formBuilder?.array([]),
                    'selectedSubValues': this.formBuilder?.array([])
                  });
                  if (queItem?.additionalText) {
                    subControls?.addControl('otherNotes', new FormControl(null));
                  }
                  const subItemArrayForm = subControls?.get('subSections') as FormArray;
                  const subSelectedValuesForm = subControls?.get('selectedSubValues') as FormArray;
                  // This is Sub sections Form Group
                  const fieldsSubForm: FormGroup = this.formBuilder?.group({});

                  queItem?.subSections?.forEach((arrayItem: any) => {
                    const subItemGroup: FormGroup = this.formBuilder?.group({
                      'label': [arrayItem?.label || null],
                      'additionalText': [arrayItem?.additionalText],
                      'properties': [arrayItem?.properties || null],
                      'values': [arrayItem?.values || null],
                    });
                    if (arrayItem?.additionalText) {
                      subItemGroup?.addControl('otherNotes', new FormControl(null));
                    }
                    let controlAdd: string;
                    controlAdd = arrayItem?.label;

                    // Medications
                    if (arrayItem?.label?.includes('Medication')) {
                      controlAdd = 'medicine';
                    }
                    if (arrayItem?.label?.includes('Dose')) {
                      controlAdd = 'dose';
                    }
                    if (arrayItem?.label?.includes('How often')) {
                      controlAdd = 'howOften';
                    }
                    if (arrayItem?.label?.includes('Doctor who prescribed')) {
                      controlAdd = 'drWhoPrescribed';
                    }
                    // Allergies
                    if (arrayItem?.label?.includes('Allergy')) {
                      controlAdd = 'allergy';
                    }
                    if (arrayItem?.label?.includes('Reaction')) {
                      controlAdd = 'reaction';
                    }
                    fieldsSubForm?.addControl(controlAdd, new FormControl(null));
                    subItemGroup?.addControl(controlAdd, new FormControl(null));
                    subItemArrayForm?.push(subItemGroup);
                  });
                  subControlsFormArray?.push(subControls);

                  subSelectedValuesForm?.push(fieldsSubForm);

                } else if (!queItem?.subSections) {
                  let subControls: FormGroup = this.formBuilder?.group({
                    'label': [queItem?.label || null],
                    'actualValue': [null],
                    'additionalText': [queItem?.additionalText || null],
                    'properties': [queItem?.properties || null],
                    'values': [queItem?.values || null]
                  });
                  if (queItem?.additionalText) {
                    subControls?.addControl('otherNotes', new FormControl(null));
                  }
                  subControlsFormArray?.push(subControls);
                }
              });

              const sectionsFormArray = sectionsForm?.get('sections') as FormArray;
              sectionsFormArray?.push(formControls);
            });

            const allGroups = this.boneMineralDensityScanForm?.get('allSectionsModules') as FormArray;
            allGroups?.push(sectionsForm);
          });
        }
        this.loadSpinner = false;
      },
      (error) => {
        this.loadSpinner = false;
      }
    );
  }

  // common clear form array
  public clearFormArray(formArray: FormArray): void {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }

  // to make selected object for Review of Systems
  public checkBoneDensityValues(event: any, answer: any, itemActValue?: string): void {
    if (event.target.checked && answer?.value?.properties?.maxAllowed && (answer?.value?.properties?.maxAllowed === answer?.get('actualValue')?.value?.length) && answer?.get('actualValue')?.value?.find((valItem: any) => valItem?.answer !== itemActValue)) {
      event.target.checked = false;
      this.showErrorMessagePopup = true;
      this.messageShown = `You can select a maximum of ${answer?.value?.properties?.maxAllowed} complaints.`;
      return;
    }
    // const allResourceTypeArray = this.boneMineralDensityScanForm?.get('allSectionsModules') as FormArray;
    // let getFormGroup = allResourceTypeArray?.controls?.find((groupItem: any) => node?.name === groupItem?.value?.resourceType) as FormGroup;

    // const sectionsArray = getFormGroup?.get('sections') as FormArray;
    // const secFormGroup = sectionsArray?.controls?.find((secItem: any) => node?.name === secItem?.value?.name) as FormGroup;

    // const answersArray = secFormGroup?.get('answers') as FormArray;
    // const getAnsFormGroup = answersArray?.controls?.find((ansItem: any) => question?.label === ansItem?.value?.label) as FormGroup;
    const answerForm = answer as FormGroup;
    let value = JSON.parse(JSON?.stringify(answerForm?.get('actualValue')?.value || []));
    if (event.target.checked) {
      let obj = {};
      if(answer?.value?.properties?.inPutType === 'YesOrNo') {
        obj = {
          answer: itemActValue,
          answerValue: event.target.value
        }
      }else {
        obj = {
          answer: event.target.value
        }
      }
      value = value?.filter((fItm: any) => fItm?.answer !== itemActValue);
      value?.push(obj);
      answerForm?.get('actualValue')?.setValue(value);
    } else {
      value = value?.filter((unCheckItem: any) => unCheckItem?.answer !== itemActValue);
      answerForm?.get('actualValue')?.setValue(value);
    }
  }

  // save ReviewOfSystems data
  public checkBoneDensityScanParams(): any {
    let requestPayload = JSON?.parse(JSON?.stringify(this.boneMineralDensityScanForm?.get('allSectionsModules')?.value || []));

    requestPayload?.forEach((item: any) => {
      // item.otherNotes = '';
      item.completed = false;
      item.auditInfo = {
        "createdBy": "",
        "createdDate": "",
        "updatedBy": "",
        "updatedDate": ""
      }
      item?.sections?.forEach((secItem: any) => {
        secItem?.answers?.forEach((ansItem: any) => {
          if (Array?.isArray(ansItem.actualValue)) {
            // ansItem.actualValue = ansItem?.actualValue?.map((mapItem: any) => {
            //   return {
            //     answer: mapItem?.actualValue || mapItem
            //   }
            // });
          } else {
            ansItem.actualValue = ansItem?.actualValue ? [{ answer: ansItem?.actualValue }] : [];
          }

          if(ansItem?.properties?.inPutType === 'YesOrNo') {
            ansItem?.values?.forEach((valItem: any) => {
              const isFind = ansItem.actualValue?.find((checkItem: any) => checkItem?.answer === valItem?.actualValue);
              if(!isFind) {
                const obj = {
                  answer: valItem?.actualValue,
                  answerValue: 'No'
                }
                ansItem?.actualValue?.push(obj)
              }
            });
          }

          if(ansItem?.selectedSubValues && ansItem?.selectedSubValues?.length > 0){
            ansItem?.selectedSubValues?.forEach((element: any, index: number) => {
              element.seqId = index + 1;
            });
            ansItem.actualValue = ansItem?.selectedSubValues;
          }

          delete ansItem?.additionalText;
          delete ansItem?.properties;
          delete ansItem?.subSections;
          delete ansItem?.values;
          delete ansItem?.selectedSubValues;
          delete secItem?.sectionMetadata;
        })
      });
    });

    if(this.moduleName === 'Chief Complaints') {
      requestPayload?.forEach((item: any) => {
        item?.sections?.forEach((secItem: any) => {
          let actAnswrs: any[] = [];
          secItem?.answers?.forEach((ansItem: any) => {
            ansItem?.actualValue?.forEach((actValueItem: any) => {
              const obj = {
                actualValue: [actValueItem]
              }
              actAnswrs?.push(obj);
            });
          });
          secItem.answers = actAnswrs;
        });
      });
      requestPayload = requestPayload?.filter((resItem: any) => resItem?.resourceType !== 'Additional Notes') || [];
    }
    // requestPayload = requestPayload?.filter((resItem: any) => resItem?.resourceType !== 'Additional Notes') || [];

    const params = {
      appointmentId: this.appointmentDetails?.id,
      facilityId: this.appointmentDetails?.facilityId,
      userInfoId: this.appointmentDetails.userInfoId,
      moduleName: this.moduleName,
      careFlowModule: requestPayload || [],
    };
    return params;
  }

  get allModulesGroup(): FormArray {
    return this.boneMineralDensityScanForm?.get('allSectionsModules') as FormArray;
  }

  public sectionsModuleForm(sectionsModule: any): FormArray {
    return sectionsModule?.get('sections') as FormArray;
  }

  public answersForm(sections: any): FormArray {
    return sections?.get('answers') as FormArray;
  }

  public answersFormSubSections(answerForm: any): FormArray {
    return answerForm?.get('subSections') as FormArray;
  }

  public answersFormSubSelectedValues(answerForm: any): any {
    return answerForm?.get('selectedSubValues') as FormArray;
  }

  public changeRadio(actValue?: string, mainLabel?: string, questionLabel?: string, yesNoItemDes?: string, answerGroup?: any, optValue?: any): void {
    const newFormGroup = answerGroup as FormGroup;
    const selectedValue = newFormGroup?.get('actualValue')?.value;
    const element = document?.getElementById(`${actValue || ''}${mainLabel}${questionLabel}${yesNoItemDes}`) as HTMLInputElement;
    if (selectedValue?.find((item: any) => item?.answer === questionLabel && item?.answerValue === optValue)) {
      element.checked = false;
      const value = selectedValue?.filter((item: any) => item?.answer !== questionLabel) || [];
      newFormGroup?.get('actualValue')?.setValue(value);
    }
  }

  // Change Radio Buttons
  
  public changeRadioCheckNew(sectionsName?: string, answerLabel?: string, optionsActualValue?: string, answer?: any, optValue?: string): void {
    const newFormGroup = answer as FormGroup;
    const selectedValue = newFormGroup?.get('actualValue')?.value;
    const element = document?.getElementById(`${sectionsName || ''}${answerLabel}${optionsActualValue}`) as HTMLInputElement;
    if (selectedValue === optValue) {
      element.checked = false;
      newFormGroup?.get('actualValue')?.reset();
    }
  }

  // check if Other Complaint have selected 
  public isOtherComplaintSelected(answer: any): boolean {
    const answerForm = answer as FormGroup;
    let value = JSON.parse(JSON?.stringify(answerForm?.get('actualValue')?.value || []));
    if (value?.find((item: any) => item?.answer === 'Other')) {
      return true;
    }
    return false;
  }

  public saveInNotes(answer: any, otherNotes?: string): void {
    const answerForm = answer as FormGroup;
    let value = JSON.parse(JSON?.stringify(answerForm?.get('actualValue')?.value || []));
    const obj = value?.find((item: any) => item?.answer === 'Other')
    obj.notes = otherNotes;
    answerForm?.get('actualValue')?.setValue(value);
  }

  public isCancerSelection(answer: any): boolean {
    const answerForm = answer as FormGroup;
    let value = JSON.parse(JSON?.stringify(answerForm?.get('actualValue')?.value || []));
    if (value?.find((item: any) => item?.answer === 'Cancer' && item?.answerValue === 'Yes')) {
      return true;
    }
    return false;
  }

  public saveCancerNotes(answer: any, otherNotes?: string): void {
    const answerForm = answer as FormGroup;
    let value = JSON.parse(JSON?.stringify(answerForm?.get('actualValue')?.value || []));
    const obj = value?.find((item: any) => item?.answer === 'Cancer')
    obj.notes = otherNotes;
    answerForm?.get('actualValue')?.setValue(value);
  }

  // Create FOrm Control from the html page while creating in dom
  public getFormControl(labelName: string): string {

    // Medications
    if (labelName?.includes('Medication')) {
      return'medicine';
    }
    if (labelName?.includes('Dose')) {
      return'dose';
    }
    if (labelName?.includes('How often')) {
      return'howOften';
    }
    if (labelName?.includes('Doctor who prescribed')) {
      return'drWhoPrescribed';
    }
    // Allergies
    if (labelName?.includes('Allergy')) {
      return'allergy';
    }
    if (labelName?.includes('Reaction')) {
      return'reaction';
    }

    if (labelName?.includes('Duration')) {
      return 'duration';
    }
    if (labelName?.includes('Severity')) {
      return 'severityStatus';
    }
    if (labelName?.includes('Past Medical History')) {
      return 'medicalHistory';
    }
    if (labelName?.includes('Member Status')) {
      return 'status';
    }
    if (labelName?.includes('Last Vaccinated Date')) {
      return 'medicalHistory';
    }
    if (labelName?.includes('Past Disease History')) {
      return 'medicalHistory';
    }
    if (labelName?.includes('Notes')) {
      return 'notes';
    }
    return labelName || '';
  }

  // Add more Repeater values
  public addRepeater(answer: any): void {
    const subSelectedValuesForm = answer?.get('selectedSubValues') as FormArray;
    // This is Sub sections Form Group
    const fieldsSubForm: FormGroup = this.formBuilder?.group({});
    answer?.value?.subSections?.forEach((arrayItem: any) => {
      let controlAdd: string;
      controlAdd = arrayItem?.label;
      // Medications
      if (arrayItem?.label?.includes('Medication')) {
        controlAdd = 'medicine';
      }
      if (arrayItem?.label?.includes('Dose')) {
        controlAdd = 'dose';
      }
      if (arrayItem?.label?.includes('How often')) {
        controlAdd = 'howOften';
      }
      if (arrayItem?.label?.includes('Doctor who prescribed')) {
        controlAdd = 'drWhoPrescribed';
      }
      // Allergies
      if (arrayItem?.label?.includes('Allergy')) {
        controlAdd = 'allergy';
      }
      if (arrayItem?.label?.includes('Reaction')) {
        controlAdd = 'reaction';
      }
      fieldsSubForm?.addControl(controlAdd, new FormControl(null));
    });
    subSelectedValuesForm?.push(fieldsSubForm);
  }

  // Delete selected Repeater Values
  public deleteSelectedRepeaterValue(answer: any, index: number): void {
    const subValueFormArray = answer?.get('selectedSubValues') as FormArray;
    subValueFormArray?.removeAt(index);
  }
  public getlabelId(labelName: any){
    console.log(labelName);
 
    labelName = labelName.replace(/\s/g, '').trim();
    labelName = labelName.replace(/\u200B/g,'');
    return labelName;
 
  }
}
