import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-hipaa-consent-authorization',
  templateUrl: './hipaa-consent-authorization.component.html',
  styleUrls: ['./hipaa-consent-authorization.component.scss'],
  encapsulation: ViewEncapsulation?.None
})
export class HipaaConsentAuthorizationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
