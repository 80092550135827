<div class="pre-appointment-questions chief-compt-questions">
    <ng-container *ngIf="chiefCompNode?.length > 0">
        <ng-container *ngFor="let node of chiefCompNode;let i=index">
            <ng-container
                *ngIf="node?.sectionMetadata?.gender === 'All' || node?.sectionMetadata?.gender === appointmentDetails?.gender">
                <ng-container *ngFor="let visitPurpose of node?.sectionMetadata?.purposeOfVisit">
                    <ng-container
                        *ngIf="visitPurpose === 'All' || visitPurpose === appointmentDetails?.appointment?.purposeOfVisit">
                        <!-- <h5>{{node?.name}}</h5> -->
                        <div class="row">
                            <div *ngFor="let sections of node?.sections;let j=index" class="col-sm-12">
                                <!-- <h5>{{sections?.name}}</h5>
                                <hr> -->

                                <div class="row">
                                    <div *ngFor="let question of sections?.questions"
                                        class="col-lg-12 col-md-12 col-sm-12">


                                        <div class="categories">
                                            <h5><u>{{question?.label}}</u></h5>
                                            <div class="row">
                                                <ng-container *ngIf="question?.properties?.inPutType==='checkBox'">
                                                    <ng-container
                                                        *ngFor="let item of question?.values;let checki = index">
                                                        <div class="col-lg-12 col-md-12 col-sm-12">

                                                            <div class="form-group text-left list-group">
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="checkbox"
                                                                        [value]="item"
                                                                        id="{{item[checki]?.actualValue}}{{question.label}}{{item?.actualValue}}"
                                                                        (change)="checkChiefComp($event, item.actualValue)">
                                                                    <label
                                                                        for="{{item[checki]?.actualValue}}{{question.label}}{{item?.actualValue}}"
                                                                        class="cr list-name-label">{{item?.actualValue}}</label>
                                                                </div>
                                                            </div>

                                                            <!-- <div class="checkbox checkbox-primary d-flex">

                                                                <input class="check-comp" type="checkbox"
                                                                    [value]="item"
                                                                    id="{{item[checki]?.actualValue}}{{question.label}}"
                                                                    (change)="checkChiefComp($event, item.actualValue)">
                                                                <label
                                                                    for="{{item[checki]?.actualValue}}{{question.label}}"
                                                                    class="cr list-name-label">{{item?.actualValue}}</label>
                                                            </div> -->
                                                        </div>
                                                    </ng-container>
                                                </ng-container>

                                                <ng-container
                                                    *ngIf="sections?.name === 'Chief Complaints' && isOtherComplaintSelected">
                                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                                        <textarea class="form-control" [(ngModel)]="otherComplaints"
                                                            type="text" placeholder="Enter Other Complaints If Any..."
                                                            id=questionAdditonalText></textarea>
                                                    </div>
                                                </ng-container>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                </ng-container>

            </ng-container>

        </ng-container>
    </ng-container>
</div>

<span class="error required" *ngIf="displayReasonForVisitErrMsg">
    Choose any one reason to continue
</span>

<!-- spinner -->
<div class="custom-loader justify-content-center" *ngIf="loadSpinner" id="spinnerDisplay">
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>