<div class="pre-appointment-questions review-of-systems-compt-questions" [formGroup]="boneMineralDensityScanForm">
    <ng-container *ngIf="boneMineralDensityScanNode?.length > 0">
        <div class="form-array-check" formArrayName="allSectionsModules">
            <div class="loop-form-array" *ngFor="let sectionsModule of allModulesGroup?.controls; let mainI = index">
                <div class="formGrpu" [formGroupName]="mainI">
                    <div *ngIf="checkGenderPurposeVisit(sectionsModule?.value?.sectionMetadata)">
                        <!-- <h5>{{sectionsModule?.value?.resourceType}}</h5> -->
                        <div class="row" formArrayName="sections">
                            <div *ngFor="let sections of sectionsModuleForm(sectionsModule)?.controls;let secI=index"
                                [formGroupName]="secI" class="col-sm-12">
                                <ng-container *ngIf="checkAnnual(sectionsModule?.value?.resourceType)">
                                    <ng-container *ngIf="moduleName !== sections?.value?.name">
                                        <h5>{{sections?.value?.name}}</h5>
                                        <hr>
                                    </ng-container>
                                    <div class="row" formArrayName="answers">

                                        <div *ngFor="let answer of answersForm(sections)?.controls; let ansI = index"
                                            [formGroupName]="ansI" class="col-lg-12 col-md-12 col-sm-12 otherRos">
                                            <div class="categories">

                                                <h6>{{answer?.value?.label}} <span *ngIf="answer?.value?.properties?.maxAllowed">(Any {{answer?.value?.properties?.maxAllowed}})</span></h6>
                                                <!-- <label class="required error" *ngIf="(answer?.value?.properties?.maxAllowed && answer?.value?.properties?.maxAllowed === answer?.get('actualValue')?.value?.length)">* Only a limited number of options are allowed.</label> -->
                                                <div class="row">
                                                    <!-- [attr.disabled]="(answer?.value?.properties?.maxAllowed && answer?.value?.properties?.maxAllowed === answer?.get('actualValue')?.value?.length) ? (item?.checked ? null : true) : null" -->
                                                    <ng-container
                                                        *ngIf="answer?.value?.properties?.inPutType==='checkBox'">
                                                        <ng-container
                                                            *ngFor="let item of answer?.value?.values;let checki = index">
                                                            <div class="form-group text-left list-group">
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="checkbox"
                                                                        [value]="item?.actualValue" 
                                                                        id="{{item[checki]?.actualValue}}{{answer?.value.label}}{{item?.actualValue}}"
                                                                        (change)="checkBoneDensityValues($event, answer, item?.actualValue)">
                                                                    <label
                                                                        for="{{item[checki]?.actualValue}}{{answer?.value.label}}{{item?.actualValue}}"
                                                                        class="cr list-name-label">{{item?.actualValue}}</label>
                                                                </div>
                                                            </div>

                                                        </ng-container>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="sections?.value?.name === 'Chief Complaints' && isOtherComplaintSelected(answer)">
                                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                                            <textarea #otherNotes class="form-control" type="text"
                                                                placeholder="Enter Other Complaints If Any..."
                                                                id="questionAdditonalText"
                                                                (input)="saveInNotes(answer, otherNotes?.value)"></textarea>
                                                        </div>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="answer?.value?.properties?.inPutType === 'YesOrNo'">
                                                        <ng-container
                                                            *ngFor="let item of answer?.value?.values;let checki = index">
                                                            <!-- <div class="form-group text-left list-group">
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="checkbox"
                                                                        [value]="item?.actualValue"
                                                                        id="{{item[checki]?.actualValue}}{{answer?.value.label}}{{item?.actualValue}}"
                                                                        (change)="checkBoneDensityValues($event, answer)">
                                                                    <label
                                                                        for="{{item[checki]?.actualValue}}{{answer?.value.label}}{{item?.actualValue}}"
                                                                        class="cr list-name-label">{{item?.actualValue}}</label>
                                                                </div>
                                                            </div> -->
                                                            <label class="mt-2">{{item?.actualValue}}</label>
                                                            <div class="form-group d-flex">
                                                                <!-- <div class="form-check" style="margin-right: 45px;" *ngFor="let yesNoItem of yesOrNo">
                                                                    <input class="form-check-input" type="checkbox"
                                                                        [value]="yesNoItem?.value"
                                                                        id="{{item[checki]?.actualValue}}{{answer?.value.label}}{{item?.actualValue}}{{yesNoItem?.desc}}"
                                                                        (change)="checkBoneDensityValues($event, answer, item?.actualValue)">
                                                                    <label
                                                                        for="{{item[checki]?.actualValue}}{{answer?.value.label}}{{item?.actualValue}}{{yesNoItem}}"
                                                                        class="cr list-name-label">{{yesNoItem?.desc}}</label>
                                                                </div> -->

                                                                <div class="form-check form-check-inline"
                                                                    style="margin-right: 45px;"
                                                                    *ngFor="let yesNoItem of yesOrNo; let radioIn = index">
                                                                    <input class="form-check-input" type="radio"
                                                                        name="{{item?.actualValue}}"
                                                                        [value]="yesNoItem?.value"
                                                                        id="{{item[checki]?.actualValue}}{{answer?.value.label}}{{item?.actualValue}}{{yesNoItem?.desc}}"
                                                                        (change)="checkBoneDensityValues($event, answer, item?.actualValue)"
                                                                        (click)="changeRadio(item[checki]?.actualValue,answer?.value.label, item?.actualValue, yesNoItem?.desc, answer, yesNoItem?.value)">
                                                                    <label class="form-check-label"
                                                                        for="{{item[checki]?.actualValue}}{{answer?.value.label}}{{item?.actualValue}}{{yesNoItem?.desc}}">{{yesNoItem?.desc}}</label>
                                                                </div>
                                                            </div>
                                                            <ng-container
                                                            *ngIf="sections?.value?.name === 'Medical History' && item?.actualValue === 'Cancer' && isCancerSelection(answer)">
                                                            <div class="col-lg-12 col-md-12 col-sm-12">
                                                                <textarea #cancerNotes class="form-control" type="text"
                                                                    placeholder="Enter Cancer Type..." id="cancerNotes"
                                                                    (input)="saveCancerNotes(answer, cancerNotes?.value)"></textarea>
                                                            </div>
                                                        </ng-container>

                                                        </ng-container>
                                                    </ng-container>

                                                    <ng-container *ngIf="answer?.value?.additionalText">
                                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                                            <textarea class="form-control" type="text"
                                                                formControlName="actualValue"
                                                                placeholder="Other / Additional Notes"
                                                                id="questionAdditonalText"></textarea>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="answer?.value?.properties?.inPutType === 'textArea'">
                                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                                            <textarea class="form-control" formControlName="actualValue"
                                                                placeholder={{getlabelId(answer?.value?.values[0]?.actualValue)}}
                                                                id="{{getlabelId(answer?.value?.values[0]?.actualValue)}}"></textarea>
                                                        </div>
                                                    </ng-container>

                                                    <!-- Repeater for multiple  *ngIf="answersFormSubSections(answer)?.controls && answersFormSubSections(answer)?.controls?.length < 1"-->
                                                    <ng-container
                                                        *ngIf="answer?.value?.properties?.inPutType === 'repeater'">
                                                        <div class="dym-repeater" formArrayName="selectedSubValues">
                                                            <div class="col-12" style="display: flex;align-items: center;">
                                                                <div class="add-addlBp mb-2">
                                                                    <i style="font-size: 30px;color: #5CA6DB;cursor: pointer;" id="add1"
                                                                        class="las la-plus-circle" (click)="addRepeater(answer)"></i>
                                                                </div>
                                                            </div>
                                                            <div class="col-12">
                                                                <ng-container *ngFor="let subSelectedValue of answersFormSubSelectedValues(answer)?.controls; let selctedSubIndex = index"
                                                                [formGroupName]="selctedSubIndex">
                                                                    <div class="row bg-style">
                                                                        <div class="col-11">
                                                                            <div class="row">
                                                                                <div class="col-lg-6 col-md-6 col-sm-12"
                                                                                    *ngFor="let subItem of answersFormSubSections(answer)?.controls; let subIndex = index">
                                                                                    <label>{{subItem?.value?.label}}</label>
                                                                                    <ng-container
                                                                                        *ngIf="subItem?.value?.properties?.inPutType === 'textArea'">
        
                                                                                        <textarea class="form-control"
                                                                                            formControlName="actualValue"
                                                                                            placeholder={{subItem?.value?.values[0]?.actualValue}}
                                                                                            id="{{getlabelId(subItem?.value?.values[0]?.actualValue)}}"></textarea>
        
                                                                                    </ng-container>
        
                                                                                    <ng-container
                                                                                        *ngIf="subItem?.value?.properties?.inPutType==='textBox'">
        
                                                                                        <input class="form-control input-text"
                                                                                            type="text"
                                                                                            formControlName="{{getFormControl(subItem?.value?.label)}}"
                                                                                            placeholder={{subItem?.value?.values[0]?.actualValue}}
                                                                                            id="{{subItem?.value?.label}}_{{subIndex}}_{{selctedSubIndex}}" />
        
                                                                                    </ng-container>
        
                                                                                    <ng-container
                                                                                        *ngIf="subItem?.value?.properties?.inPutType==='number'">
        
                                                                                        <input class="form-control input-text"
                                                                                            type="number"
                                                                                            formControlName="{{getFormControl(subItem?.value?.label)}}"
                                                                                            placeholder={{subItem?.value?.values[0]?.actualValue}}
                                                                                            id="location" />
        
                                                                                    </ng-container>
        
                                                                                    <ng-container
                                                                                        *ngIf="subItem?.value?.properties?.inPutType==='dropDown'">
        
                                                                                        <ng-select
                                                                                            formControlName="{{getFormControl(subItem?.value?.label)}}"
                                                                                            placeholder="Choose {{subItem?.value?.label}}"
                                                                                            [addTag]="true" id="boneMineralDensityScan_subitem">
                                                                                            <ng-option
                                                                                                *ngFor="let options of subItem?.value?.values"
                                                                                                [value]="options">
                                                                                                {{options?.actualValue}}
                                                                                            </ng-option>
                                                                                        </ng-select>
        
                                                                                    </ng-container>
        
                                                                                    <!-- <ng-container *ngIf="subItem?.value?.properties?.inPutType==='multiSelect'">
                                                                                    <ng-select placeholder="Select {{subItem?.value?.label}}" [multiple]="true"
                                                                                        bindLabel="actualValue" bindValue="actualValue" [items]="subItem?.value?.values"
                                                                                        [closeOnSelect]="false" clearAllText="Clear"
                                                                                        formControlName="{{getFormControl(subItem?.value?.label)}}" [addTag]="true">
                                                                                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                                                                            <div class="ng-value" *ngFor="let item of items | slice:0:3">
                                                                                                <span class="ng-value-label">{{item || item?.actualValue}}</span>
                                                                                                <span class="ng-value-icon right" (click)="clear(item)"
                                                                                                    aria-hidden="true">×</span>
                                                                                            </div>
                                                                                            <div class="ng-value" *ngIf="items.length > 3">
                                                                                                <span class="ng-value-label">{{items.length
                                                                                                    - 3}}
                                                                                                    more...</span>
                                                                                            </div>
                                                                                        </ng-template>
                                                                                    </ng-select>
                                                                                </ng-container>                                                                 -->
        
                                                                                    <ng-container
                                                                                        *ngIf="subItem?.value?.properties?.inPutType==='radioButton'">
        
                                                                                        <label class="radio-inline"
                                                                                            *ngFor="let options of subItem?.value?.values">
                                                                                            <input type="radio"
                                                                                                id="{{getlabelId(sections?.value?.name)}}_{{getlabelId(answer?.value?.label)}}"
                                                                                                formControlName="{{getFormControl(subItem?.value?.label)}}"
                                                                                                name={{subItem?.value?.label}}
                                                                                                [value]="options?.actualValue"><span
                                                                                                class="desc">{{options.actualValue}}</span>
                                                                                        </label>
        
                                                                                    </ng-container>
        
                                                                                    <ng-container
                                                                                        *ngIf="subItem?.value?.properties?.inPutType==='checkBox'">
        
                                                                                        <ng-container
                                                                                            *ngFor="let item of subItem?.value?.values;let checki = index">
                                                                                            <div
                                                                                                class="form-group text-left list-group">
                                                                                                <div class="form-check">
                                                                                                    <input
                                                                                                        class="form-check-input"
                                                                                                        type="checkbox"
                                                                                                        [value]="item?.actualValue"
                                                                                                        id="{{item[checki]?.actualValue}}{{answer?.value.label}}{{item?.actualValue}}"
                                                                                                        (change)="checkBoneDensityValues($event, answer, item?.actualValue)">
                                                                                                    <label
                                                                                                        for="{{item[checki]?.actualValue}}{{answer?.value.label}}{{item?.actualValue}}"
                                                                                                        class="cr list-name-label">{{item?.actualValue}}</label>
                                                                                                </div>
                                                                                            </div>
        
                                                                                        </ng-container>
        
        
                                                                                    </ng-container>
        
                                                                                    <ng-container
                                                                                        *ngIf="subItem?.value?.additionalText">
        
                                                                                        <textarea class="form-control"
                                                                                            type="text"
                                                                                            formControlName="actualValue"
                                                                                            placeholder="Other / Additional Notes"
                                                                                            id="questionAdditonalText"></textarea>
        
                                                                                    </ng-container>
        
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        
                                                                        <div class="col-1 form-group" *ngIf="answersFormSubSelectedValues(answer) && answersFormSubSelectedValues(answer)?.controls?.length > 1">
                                                                            <div class="delete-addlBp">
                                                                                <i style="font-size: 30px;color: red;margin-top: 5px;cursor: pointer;" id="boneMineralDensityScan_delete" (click)="deleteSelectedRepeaterValue(answer, selctedSubIndex)"
                                                                                    class="lar la-trash-alt"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </ng-container>
                                                                
                                                            </div>


                                                        </div>
                                                    </ng-container>

                                                </div>

                                                <div class="row">
                                                    <div class="col-lg-12 col-md-12 col-sm-12">

                                                        <div class="form-group">
                                                            <ng-container
                                                                *ngIf="answer?.value?.properties?.inPutType === 'textBox'">
                                                                <input class="form-control input-text"
                                                                    formControlName="actualValue" type="text"
                                                                    placeholder={{answer?.value?.values[0]?.actualValue}}
                                                                    id="boneMineralDensityScan_{{getlabelId(answer?.value?.values[0]?.actualValue)}}" />
                                                            </ng-container>

                                                            <ng-container
                                                                *ngIf="answer?.value?.properties?.inPutType === 'number'">
                                                                <input class="form-control input-text"
                                                                    formControlName="actualValue" type="number"
                                                                    placeholder={{answer?.value?.values[0]?.actualValue}}
                                                                    id="boneMineralDensityScan_{{getlabelId(answer?.value?.values[0]?.actualValue)}}" />
                                                            </ng-container>

                                                            <!-- <ng-container
                                                                *ngIf="answer?.value?.properties?.inPutType === 'slideScale' ">
                                                                <ngx-slider [options]="options"
                                                                    formControlName="actualValue">
                                                                </ngx-slider>
                                                            </ng-container> -->

                                                            <ng-container
                                                                *ngIf="answer?.value?.properties?.inPutType === 'autoComplete'">
                                                                <input class="form-control bmi input-text"
                                                                    formControlName="actualValue" type="text"
                                                                    placeholder={{answer?.value?.values[0]?.actualValue}}
                                                                    id="{{getlabelId(answer?.value?.values[0]?.actualValue)}}" />
                                                            </ng-container>

                                                            <ng-container
                                                                *ngIf="answer?.value?.properties?.inPutType === 'dropDown'">
                                                                <ng-select
                                                                    placeholder="Choose {{answer?.value?.values[0]?.actualValue}}"
                                                                    bindValue="actualValue"
                                                                    formControlName="actualValue" [addTag]="true" id="boneMineralDensityScan_actualValue">
                                                                    <ng-option
                                                                        *ngFor="let options of answer?.value?.values"
                                                                        [value]="options?.actualValue">
                                                                        {{options?.actualValue}}
                                                                    </ng-option>
                                                                </ng-select>
                                                            </ng-container>

                                                            <ng-container
                                                                *ngIf="answer?.value?.properties?.inPutType === 'radioButton'">
                                                                <!-- <br /> -->
                                                                <!-- <label class="radio-inline"
                                                                    *ngFor="let options of answer?.value?.values">
                                                                    <input type="radio" formControlName="actualValue"
                                                                        id="{{sections?.value?.name}}{{answer?.value?.label}}"
                                                                        [value]="options?.actualValue"><span
                                                                        class="desc">{{options?.actualValue}}</span>
                                                                </label> -->

                                                                <div class="form-check form-check-inline"
                                                                    *ngFor="let options of answer?.value?.values">
                                                                    <input class="form-check-input" type="radio"
                                                                        formControlName="actualValue"
                                                                        id="{{getlabelId(sections?.value?.name)}}{{getlabelId(answer?.value?.label)}}{{getlabelId(options?.actualValue)}}"
                                                                        (click)="changeRadioCheckNew(sections?.value?.name, answer?.value?.label, options?.actualValue, answer, options?.actualValue)"
                                                                        [value]="options?.actualValue">
                                                                    <label class="form-check-label"
                                                                        for="{{sections?.value?.name}}{{answer?.value?.label}}{{options?.actualValue}}">{{options?.actualValue}}</label>
                                                                </div>

                                                            </ng-container>

                                                            <!-- <ng-container
                                                                *ngIf="answer?.value?.properties?.inPutType === 'multiSelect'">
                                                                <ng-select
                                                                    placeholder="Select {{answer?.value?.label}}"
                                                                    [multiple]="true" bindLabel="actualValue"
                                                                    bindValue="actualValue" [items]="answer?.value?.values"
                                                                    [closeOnSelect]="false" clearAllText="Clear"
                                                                    [addTag]="true" formControlName="actualValue">
                                                                    <ng-template ng-multi-label-tmp let-items="items"
                                                                        let-clear="clear">
                                                                        <div class="ng-value"
                                                                            *ngFor="let item of items | slice:0:3">
                                                                            <span
                                                                                class="ng-value-label">{{item || item?.actualValue}}</span>
                                                                            <span class="ng-value-icon right"
                                                                                (click)="clear(item)"
                                                                                aria-hidden="true">×</span>
                                                                        </div>
                                                                        <div class="ng-value" *ngIf="items.length > 3">
                                                                            <span class="ng-value-label">{{items.length -
                                                                                3}} more...</span>
                                                                        </div>
                                                                    </ng-template>
                                                                </ng-select>
                                                            </ng-container> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <ng-container *ngIf="sections?.value?.additionalText">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <textarea class="form-control" type="text" formControlName="otherNotes"
                                                placeholder="Additional Notes" id="questionAdditonalText"></textarea>
                                        </div>
                                    </ng-container>
                                </ng-container>


                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="sectionsModule?.value?.additionalText">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <textarea class="form-control" type="text" formControlName="otherNotes"
                                placeholder="Additional Notes" id="questionAdditonalText"></textarea>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<!-- spinner -->
<div class="custom-loader justify-content-center" *ngIf="loadSpinner" id="spinnerDisplay">
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<!-- <ng-container *ngFor="let node of boneMineralDensityScanNode;let i=index">
    <ng-container *ngIf="checkGenderPurposeVisit(node?.sectionMetadata)">
    </ng-container>
</ng-container> -->

<!-- Error Message Shown -->
<div id="error-message-page-modal" [style.display]="showErrorMessagePopup ? 'block' : 'none'" class="modal"
    role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-body">
                <h5 style="text-align: center;padding: 15px;">{{messageShown}}</h5>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default ok-book" data-dismiss="modal" id="boneMineralDensityScan_ok"
                    (click)="showErrorMessagePopup = false">Ok</button>
            </div>
        </div>
    </div>
</div>