import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { ScheduleAppointmentComponent } from './components/schedule-appointment/schedule-appointment.component';
import { PatientOutreachComponent } from './components/patient-outreach/patient-outreach.component';
import { CancelAppointmentComponent } from './components/cancel-appointment/cancel-appointment.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { HipaaConsentAuthorizationComponent } from './components/hipaa-consent-authorization/hipaa-consent-authorization.component';
import { HomeComponent } from './components/home/home.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './components/terms-of-service/terms-of-service.component';
import { TermsOfTelemedicineComponent } from './components/terms-of-telemedicine/terms-of-telemedicine.component';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { ProvidersResultComponent } from './components/providers-result/providers-result.component';
import { PatientLifeCycleComponent } from './components/patient-life-cycle/patient-life-cycle.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { AccountVerificationComponent } from './components/account-verification/account-verification.component';
import { ViewpatientdetailsComponent } from './components/viewpatientdetails/viewpatientdetails.component';
import { PatientLaunchPadComponent } from './components/patient-launch-pad/patient-launch-pad.component';
import { PaymentsBillComponent } from './components/payments-bill/payments-bill.component';
import { ConscentformsComponent } from './components/schedule-appointment/conscentforms/conscentforms.component';
//import { ConscentformsDetailsComponent } from './components/schedule-appointment/conscentform-details/conscentforms-details.component';
import { CareEventsComponent } from './components/care-events/care-events.component';
import { TeliHealthComponent } from './components/teli-health/teli-health.component';
import { ReviewOfSystemsComponent } from './components/review-of-systems/review-of-systems.component';
import { ChiefComplaintsComponent } from './components/chief-complaints/chief-complaints.component';
import { BoneMineralDensityScanComponent } from './components/bone-mineral-density-scan/bone-mineral-density-scan.component';
import { MedicalHistoryComponent } from './components/medical-history/medical-history.component';
import { SummittMedicineComponent } from './components/summitt-medicine/summitt-medicine.component';
import { SharedModule } from './shared/shared.module';
import { AgmCoreModule } from '@agm/core';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { NavBarComponent } from './healthworks-portal/nav-bar/nav-bar.component';
import { NavbarLeftComponent } from './healthworks-portal/navbar-left/navbar-left.component';
import { provideUserIdleConfig } from 'angular-user-idle';
import { NewOutreachComponent } from './components/new-outreach/new-outreach.component';
import { ServiceWorkerModule } from '@angular/service-worker';

const maskConfig: Partial<IConfig> = {
  validation: false,
};
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ScheduleAppointmentComponent,
    PatientOutreachComponent,
    CancelAppointmentComponent,
    ContactUsComponent,
    HipaaConsentAuthorizationComponent,
    HomeComponent,
    PrivacyPolicyComponent,
    TermsOfServiceComponent,
    TermsOfTelemedicineComponent,
    TermsOfUseComponent,
    ProvidersResultComponent,
    PatientLifeCycleComponent,
    FeedbackComponent,
    AccountVerificationComponent,
    ViewpatientdetailsComponent,
    PatientLaunchPadComponent,
    // PaymentsBillComponent,
    ConscentformsComponent,
    //ConscentformsDetailsComponent,
    CareEventsComponent,
    TeliHealthComponent,
    ReviewOfSystemsComponent,
    ChiefComplaintsComponent,
    BoneMineralDensityScanComponent,
    MedicalHistoryComponent,
    SummittMedicineComponent,
    NavBarComponent,
    NavbarLeftComponent,
    NewOutreachComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    AgmCoreModule?.forRoot({
      apiKey: 'AIzaSyBmpQFHl_kUFMN5O_wacPBYPfVjKyOvlBU',
      libraries: ['places']
    }),
    NgxMaskModule.forRoot(maskConfig),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    provideUserIdleConfig({ idle: 1800, timeout: 1800, ping: 0 })
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
