import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CancelAppointmentComponent } from './components/cancel-appointment/cancel-appointment.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { HipaaConsentAuthorizationComponent } from './components/hipaa-consent-authorization/hipaa-consent-authorization.component';
import { HomeComponent } from './components/home/home.component';
import { PatientLifeCycleComponent } from './components/patient-life-cycle/patient-life-cycle.component';
import { PatientOutreachComponent } from './components/patient-outreach/patient-outreach.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { ProvidersResultComponent } from './components/providers-result/providers-result.component';
import { ScheduleAppointmentComponent } from './components/schedule-appointment/schedule-appointment.component';
import { TermsOfServiceComponent } from './components/terms-of-service/terms-of-service.component';
import { TermsOfTelemedicineComponent } from './components/terms-of-telemedicine/terms-of-telemedicine.component';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { AccountVerificationComponent } from './components/account-verification/account-verification.component';
import { ViewpatientdetailsComponent } from './components/viewpatientdetails/viewpatientdetails.component';
import { PatientLaunchPadComponent } from './components/patient-launch-pad/patient-launch-pad.component';
import { PaymentsBillComponent } from './components/payments-bill/payments-bill.component';
import { CareEventsComponent } from './components/care-events/care-events.component';
import { TeliHealthComponent } from './components/teli-health/teli-health.component';
import { SummittMedicineComponent } from './components/summitt-medicine/summitt-medicine.component';
import { InvoiceComponent } from './healthworks-portal/invoice/invoice.component';
import { CreateinvoiceComponent } from './healthworks-portal/createinvoice/createinvoice.component';
import { HealthworksPortalComponent } from './healthworks-portal/healthworks-portal.component';
import { AuthGuard } from './shared/auth/auth.guard';
import { NewOutreachComponent } from './components/new-outreach/new-outreach.component';
import { VisorGuard } from './shared/auth/visor.guard';

const routes: Routes = [
  { path: ':invoice/create/:id', component: CreateinvoiceComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: ':facilityId/scheduleappt', component: ScheduleAppointmentComponent },
  { path: ':facilityId/scheduleappt/:id', component: ScheduleAppointmentComponent },
  { path: ':facilityId/appt', component: ScheduleAppointmentComponent },
  { path: ':facilityId/outReach', component: PatientOutreachComponent },
  { path: ':facilityId/outReach/:tokenId', component: PatientOutreachComponent },
  // { path: 'patients', component: PatientsComponent },
  // { path: 'providers', component: ProvidersComponent },
  // { path: 'who-we-are', component: WhoWeAreComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'results-page', component: ProvidersResultComponent },
  // { path: 'results-page-backup', component: ProvidersResultBackupComponent },
  { path: 'foot-hipaaConsentAuthorization', component: HipaaConsentAuthorizationComponent },
  { path: 'foot-privacy-policy', component: PrivacyPolicyComponent },
  { path: 'foot-terms-of-service', component: TermsOfServiceComponent },
  { path: 'foot-terms-of-use', component: TermsOfUseComponent },
  { path: 'foot-terms-of-telemedicine', component: TermsOfTelemedicineComponent },

  { path: 'cancel/:id', component: CancelAppointmentComponent },
  // { path: ':fid/pathwayConfig/:id', component: CarePathwaysConfigComponent },
  // { path: 'feedback', component: FeedbackPostvisitClinicComponent },
  // { path: 'services', component: ServicesComponent },
  { path: ':fId/outReach', component: PatientOutreachComponent },
  { path: ':fId/outReach/:tokenId', component: PatientOutreachComponent },
  // /New outreach url
  { path: ':facilityId/campaign/:tokenId', component: NewOutreachComponent },
  // { path: 'services/city/zipCode/latitude/longitude', component: ServicesComponent },
  // { path: 'CareJourney/:uniqueToken', component: PatientLifeCycleComponent },
  // { path: 'CareJourney/:uniqueToken', component: CareEventsComponent },
  { path: ':facilityId/feedback', component: FeedbackComponent },
  { path: ':facilityId/verifyPatient', component: AccountVerificationComponent },
  { path: ':facilityId/viewPatientDetails/:userInfoId', component: ViewpatientdetailsComponent },
  // { path: ':facilityId/patientPortal', component: PatientLaunchPadComponent },
  { path: ':facilityId/payYourBill', component: PaymentsBillComponent },
  { path: ':facilityId/payYourBill/:patientId', component: PaymentsBillComponent },
  { path: ':facilityId/teleHealth', component: TeliHealthComponent },
  {
    path: ':facilityId/visor',
    loadChildren: () => import('./visor/visor.module').then(module => module.VisorModule)
  },
  // { path: ':facilityId/:facilityName', component: SummittMedicineComponent},
  {
    path: ':facilityId/patientPortal',
    loadChildren: () => import('./patient-portal/patient-portal.module').then(module => module.PatientPortalModule)
  },
  {
    path: 'invoice',canActivate: [AuthGuard],
    loadChildren: () => import('./healthworks-portal/healthworks-portal.module').then(module => module.HealthWorksModule)
  },
  {
    path: '',
    loadChildren: () => import('./care-journey/care-journey.module').then(module => module.CareJourneyModule)
  },
  { path: ':facilityId/:facilityName/:message', component: SummittMedicineComponent },
  { path: '**', redirectTo: ':facilityId/patientPortal' },
  { path: '**', redirectTo: ':facilityId/visor' },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
