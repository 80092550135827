<section class="page-hero footer-sections">
    <!--//End Header-->
    <div class="container">
        <h3 class="terms_title">Privacy Policy</h3>
        <h6>Last Updated: January 6, 2022</h6>

        <div class="terms_content">
            <div class="block">
                <p>This Privacy Policy describes the information collected through this website and the online services
                    that we
                    provide (together, the "Service") and how that information is used and shared. CareEco Corporation
                    doing
                    business as CareEco ("CareEco", CareEco Corp", "we", "us" or "our"), and its suppliers may collect
                    or receive
                    the types of information described below in connection with your access to and use of the Service,
                    including
                    your account registration on the Service (together, "Collected Information").</p>
                <p>By accessing or using the Service or by accepting our Terms of Service, you ("you") agree that you
                    have read
                    and understand this Privacy Policy and that you provide consent to the collection, use, and sharing
                    of
                    information as discussed below. If you do not agree with this Privacy Policy, do not access or use
                    the
                    Service.</p>
                <p>This Privacy Policy is incorporated into and made a part of our Terms of Use and our Terms of
                    Service. We
                    reserve the right to modify this Privacy Policy at any time. All changes will be effective
                    immediately upon
                    posting to the Service and, by accessing or using the Service after changes are posted, you agree to
                    those
                    changes. Material changes will be conspicuously posted on the Service or otherwise communicated to
                    you.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Information Security:</h5>
                </strong>
                <p>CareEco does not guarantee that loss, misuse, or alteration of Collected Information will not occur,
                    but we
                    have security measures in place to help protect against the loss, misuse, and alteration of
                    information under
                    our control, including Secure Socket Layers (SSL) technology, which is used to encrypt information
                    passed
                    between your browser and our systems. The storage and communication of information can never be
                    completely
                    secure, so we do not guarantee that communications or other information that you submit or that are
                    otherwise
                    provided to us will be completely secure. If you become aware of any breach of Service security or
                    this
                    Privacy Policy, please notify us at support@levlivet.net immediately. If our security system is
                    breached, we
                    will notify you of the breach only if and to the extent required under applicable law.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>We Collect Information that You Submit:</h5>
                </strong>
                <p>CareEco may require some of the following information to best meet your needs through our Services:
                </p>
                <ul>
                    <li>Personally identifiable information</li>
                    <li>Personally identifiable information</li>
                    <li>Protected Health Information (PHI)</li>
                    <li>Health care provider appointment information</li>
                    <li>Account and profile information</li>
                    <li>Billing collection and payment information</li>
                    <li>Mailing list information</li>

                </ul>
            </div>

            <div class="block">
                <strong>
                    <h5>Personally Identifiable Information:</h5>
                </strong>
                <p>You can visit the Service without sharing personally identifiable information, but you will be
                    required to
                    provide
                    personally identifiable information in order to use certain Service features. We will collect any
                    personally
                    identifiable information from or about you that you choose to provide to us, which may include your
                    name,
                    email
                    address, mobile telephone number, birthdate, driver’s license or State Id and physical address.
                    Specifically,
                    we
                    collect your personally identifiable information when you use the Service to book appointments with
                    health
                    care
                    providers, when you create an online CareEco account, when you create profiles for individuals in
                    your CareEco
                    account, in receiving and fulfilling information requests, and when you respond to communications
                    from us
                    (e.g.,
                    surveys and promotional offers). If you provide your mobile telephone number to us, you consent to
                    receiving
                    text
                    (SMS) messages from us at that telephone number as set forth below. We will use your personally
                    identifiable
                    information to process your appointment requests, to set up your CareEco account and any profiles
                    you create
                    in that
                    account, to communicate with you in connection with your account, and to provide you with
                    information and
                    communications that you request or that we believe are relevant or may be of interest to you,
                    subject to
                    applicable
                    laws and the terms of this Privacy Policy. We may also share your personally identifiable
                    information with
                    third
                    parties, including suppliers that we engage to provide services in connection with the Service.</p>
            </div>

            <div class="block">
                <strong>
                    <h5>Protected Health Information:</h5>
                </strong>
                <p>If you use the Service to book an appointment with a health care provider, we will provide certain of
                    the
                    information you have provided to CareEco to the health care provider. Unless specifically stated
                    otherwise,
                    references to "Collected Information" in this Privacy Policy do not include Protected Health
                    Information as
                    that
                    term is defined under the Health Insurance Portability and Accountability Act of 1996 ("PHI") and
                    any
                    regulations
                    promulgated thereunder (together, "HIPAA").</p>
                <p>Notwithstanding anything to the contrary elsewhere in this Privacy Policy applying more generally to
                    Collected
                    Information, to the extent the information that we provide to a health care provider about you, or
                    that a
                    health
                    care provider provides to us about you, constitutes PHI, we will protect your PHI and will only use
                    or
                    disclose it
                    as required or permitted by applicable federal and state laws, including HIPAA, and in accordance
                    with any
                    contractual obligations that we may have with your health care provider. It may be necessary for us
                    to share
                    your
                    PHI with third parties, including suppliers that we engage to provide services in connection with
                    the Service.
                    If we
                    share your PHI with any third party, we will share only the minimum necessary information to enable
                    the third
                    party
                    to provide the services for which we have engaged them in connection with the Service, and we will
                    ensure that
                    the
                    third party agrees to use and disclose your PHI only as required or permitted by applicable federal
                    and state
                    laws,
                    including HIPAA.</p>
            </div>

            <div class="block">
                <strong>
                    <h5>Health Care Provider Appointment Information:</h5>
                </strong>
                <p>To search for available appointments with health care providers through the Service you will need to
                    submit
                    your
                    geographic location (or allow us to access your location via your device's location services
                    settings), the
                    reason
                    for your visit, whether you are an adult or a child, and the requested appointment time. To then
                    book an
                    appointment
                    with an available health care provider, you will need to also submit your name, mobile telephone
                    number,
                    birthdate,
                    gender, email address, and possibly other relevant information required to confirm the appointment.
                </p>
            </div>


            <div class="block">
                <strong>
                    <h5>Account and Profile Information:</h5>
                </strong>
                <p>To create an online CareEco account you will need to submit your first and last name, mobile
                    telephone
                    number,
                    birthdate, Driver’s license number or state id and email address. The information you submit when
                    first
                    creating an
                    account will be used to populate a profile within that account. You may also create profiles for
                    other
                    individuals
                    in your account, for example, for your family members. For each additional profile created, you will
                    need to
                    specify
                    your relationship to that person (e.g., "parent" or "child"). You may populate your profile and any
                    additional
                    profiles you create with other types of information that you choose to submit, for example, health
                    insurance
                    information, information about primary care physicians, and preferred pharmacies. To enter health
                    insurance
                    information, you may have the option of uploading an image of a health insurance card. If you choose
                    to upload
                    an
                    image of a health insurance card, the Service will extract information from that image and then use
                    that
                    information
                    to populate the applicable profile.</p>
            </div>


            <div class="block">
                <strong>
                    <h5>Account Activity:</h5>
                </strong>
                <p>If you use the Service while signed into your CareEco account, we will collect information on how you
                    use
                    your
                    account, for example, which health care providers you book appointments with and the reasons for
                    those
                    appointments.
                </p>
            </div>


            <div class="block">
                <strong>
                    <h5>Location Information:</h5>
                </strong>
                <p>We may access your location with your consent in order to enhance your user experience and increase
                    the
                    usefulness
                    of the Service. You may choose to allow us to access your location by granting the Service access to
                    your
                    location
                    when prompted or through your device's location services settings. CareEco may use various
                    technologies to
                    access
                    your location, including GPS and IP addresses. CareEco also uses the Google Maps/Google Earth
                    API(s). Pursuant
                    to
                    the Google Maps/Google Earth APIs Terms of Service, use of this location feature is also subject to
                    Google's
                    privacy
                    policy, which can be found at <a href="http://www.google.com/policies/privacy"
                        target="_blank">http://www.google.com/policies/privacy</a>. Both the Google Maps/Google
                    Earth APIs
                    Terms
                    of Service and the Google Privacy Policy are incorporated into these Terms by this reference.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>IP Addresses and Related Data:</h5>
                </strong>
                <p>The servers used to operate the Service may collect data pertaining to you and the equipment,
                    software, and
                    communication methods you use to access the Internet and the Service, including Internet protocol
                    ("IP")
                    addresses
                    assigned to your computer, your Internet service provider (ISP), your approximate geographic
                    location, your
                    browser
                    type, the pages you access on the Service, the web sites you access before and after visiting the
                    Service, the
                    length of time you spend on the Service, date and time stamps, and clickstream data. CareEco may use
                    this
                    information to administer the Service and its servers, to generate statistical information, to
                    monitor and
                    analyze
                    Service traffic and usage patterns, to monitor and prevent fraud, to investigate complaints and
                    violations of
                    our
                    policies, and to improve the Service's content and the products, services, materials, and other
                    content that
                    we
                    describe or make available through the Service. The suppliers that we use to provide the Service may
                    collect
                    information about your visits to the Service and other web sites.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Cookies:</h5>
                </strong>
                <p>The Service automatically uses cookies to track Service use and to facilitate and enhance the user
                    experience
                    on
                    the Service. "Cookies" are small pieces of information that are stored on your computer's hard drive
                    by your
                    browser. The Service may use both cookies that we implement and cookies implemented by our suppliers
                    and other
                    third
                    parties. We use cookies to track how you access and use the Service, to learn when and how users
                    visit the
                    Service,
                    to learn how popular Service pages are and aren't, to learn which search terms are used to find the
                    Service,
                    and to
                    learn which web sites direct you to the Service. We also use cookies to help display certain
                    information on
                    the
                    Service and to improve your enjoyment of the Service, for example, by remembering your contact and
                    other
                    information
                    when you access or use the Service. Most browsers automatically accept cookies. You can disable this
                    function,
                    but
                    disabling cookies may impact your use and enjoyment of the Service. Our Service suppliers may use
                    cookies and
                    other
                    tracking technologies to track Service visitors across the Internet to understand how you get to the
                    Service.
                    Due to
                    this automatic collection of cookies, we do not honor "do not track" requests.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Advertising Networks and Personalized Advertising:</h5>
                </strong>
                <p>The Service uses Google AdWords, an online advertising service provided by Google Inc., to collects
                    anonymous
                    information about your visits to and interactions with the Service and other web sites. Google Inc.
                    will use
                    this
                    information to target advertisements for goods and services. These targeted advertisements may
                    appear on the
                    Service
                    or on other web sites. The anonymous information is collected through the use of a "pixel tag,"
                    which is
                    industry-standard technology. To opt out of remarketing advertising provided through Google or to
                    customize
                    your ad
                    preferences, visit Google's Ad Settings page at <a href="http://www.google.com/settings/ads"
                        target="_blank">http://www.google.com/settings/ads</a>. Opting out will
                    not affect
                    your
                    use of the Service. To obtain more information about third-party ad networks and online behavioral
                    advertising
                    and
                    to change your preferences with respect to certain online ads, please visit the National Advertising
                    Initiative
                    Consumer Opt-Out web site at <a href="http://www.networkadvertising.org/choices"
                        target="_blank">http://www.networkadvertising.org/choices</a> or the Digital Advertising
                    Alliance
                    Self-Regulatory Program web site at <a href="http://www.aboutads.info"
                        target="_blank">http://www.aboutads.info</a>. Opting out of online ads with one ad
                    network and
                    web
                    browser does not necessarily carry over to any other ad network or web browser. As a result,
                    depending on the
                    opt-outs you request, you may still see our ads from time to time.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Analytics:</h5>
                </strong>
                <p>The Service uses third-party analytics tools (e.g., Google Analytics, including its Advertising
                    Features,
                    which are
                    described here: <a href="https://support.google.com/analytics/answer/3450482?hl=en"
                        target="_blank">https://support.google.com/analytics/answer/3450482?hl=en</a>, to collect
                    information
                    about your
                    use of
                    the Service, which information your web browser automatically sends to those analytics providers.
                    This
                    information
                    includes the URLs of the web sites that you visit and your IP address. Our analytics providers may
                    also set
                    cookies or
                    read preexisting cookies. This information is stored on those analytics providers' servers. Our
                    analytics
                    providers
                    use this information to provide us with reports about Service traffic and your visits to the Service
                    (for
                    example, the
                    domain from which you access the Internet, the web address of the web site from which you linked to
                    the
                    Service, and
                    the date and time of your visit to the web sites that you view and click through). We will use this
                    data to
                    improve
                    the Service's structure and content.</p>
                <p>Google, via Google Analytics, may use this data in accordance with its Privacy Policy, which is
                    located at <a href="http://www.google.com/policies/privacy"
                        target="_blank">http://www.google.com/policies/privacy</a>. For more information on how Google
                    uses your data,
                    visit <a href="http://www.google.com/policies/privacy/partners"
                        target="_blank">http://www.google.com/policies/privacy/partners</a> . You may opt out of our use
                    of certain analytics
                    tools. You
                    may opt
                    out of our use of Google Analytics by visiting the Google Analytics opt-out web site at <a
                        href="http://tools.google.com/dlpage/gaoptout"
                        target="_blank">http://tools.google.com/dlpage/gaoptout </a> and installing the Google Analytics
                    Opt-Out Browser Add-On.
                    Please
                    remember
                    that you will need to opt out for each browser you use. Opting out and otherwise refusing cookies
                    will also
                    limit the
                    collection of analytics data from your use of the Service.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Social Media:</h5>
                </strong>
                <p>The Service may allow you to connect and share information with various social media platforms, for
                    example,
                    Facebook, Instagram, and Twitter. These features may require us to implement cookies, plug-ins, and
                    application protocol interfaces (APIs) provided by those social media platforms. We may share
                    information that
                    you provide us or that we may collect about your use of the Service with those platforms and that
                    information
                    will be subject to their privacy policies. For further information, we encourage you to review those
                    platforms' privacy policies: Facebook's Data Policy at <a
                        href="https://www.facebook.com/full_data_use_policy"
                        target="_blank">https://www.facebook.com/full_data_use_policy</a>
                    ;
                    Instagram's Privacy Policy at <a href="https://www.instagram.com/about/legal/privacy"
                        target="_blank">https://www.instagram.com/about/legal/privacy</a> ; and Twitter's Privacy
                    Policy
                    at <a href="https://twitter.com/privacy?lang=en"
                        target="_blank">https://twitter.com/privacy?lang=en</a> .</p>
                <p>The Service may also use the advertising services and networks offered by those social media
                    platforms to
                    deliver advertising content. Use of these services requires social media platforms to implement
                    cookies or
                    pixel tags to deliver ads to you while you are logged into the platform. If you do not wish a social
                    media
                    platform to collect information relating to you via the Service, we suggest that you: (i) hide
                    social media
                    platform plug-ins using an ad blocker; (ii) always log off completely from the social media platform
                    before
                    visiting other web sites and delete all related cookies; and, (iii) where the option is available,
                    disable the
                    advertising services and networks operated by the social media platform. For example, you may opt
                    out of
                    Twitter's interest-based advertising by notifying Twitter through one of the methods it describes
                    online: <a href="https://support.twitter.com/articles/20170405"
                        target="_blank">https://support.twitter.com/articles/20170405</a>.</p>
                <p>CareEco is active on social media, including Facebook, Instagram, and Twitter. You may have the
                    opportunity
                    to comment on those social media platforms regarding CareEco and the products and services we offer.
                    CareEco
                    reserves the rights to post on its web sites any comments or content you post on our social media
                    platform
                    pages.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>How We Use and Share Collected Information:</h5>
                </strong>
                <p>In addition to the uses described above, CareEco and its suppliers may use and share Collected
                    Information as
                    described below. We will not sell, rent, use, or share Collected Information except as disclosed in
                    this
                    Privacy Policy or as permitted by applicable law and subject to any contractual obligations CareEco
                    may have
                    with healthcare providers and subcontractors.</p>
                <div>
                    <ul>
                        <li> We share Collected Information with the health care providers with which you book
                            appointments.</li>
                        <li> We use Collected Information for the purpose for which it was collected.</li>
                        <li> We use Collected Information to send you text messages.</li>
                        <li> We use Collected Information to communicate with you.</li>
                        <li> We use Collected Information to evaluate and improve the Service.</li>
                        <li> We use and share Collected Information in connection with legal proceedings and to protect
                            our rights.
                        </li>
                        <li> We share Collected Information with the suppliers that provide us with services in
                            connection with the
                            Service.
                        </li>
                        <li> We aggregate Collected Information to create anonymous data on Service users.</li>
                        <li> We share Collected Information if CareEco undergoes a change in control.</li>
                    </ul>
                </div>
            </div>

            <div class="block">
                <strong>
                    <h5>Sharing Collected Information with Health Care Providers:</h5>
                </strong>
                <p>We will share Collected Information with the health care providers with whom you book appointments
                    through
                    the Service only in accordance with any contractual obligations we have in place with the health
                    care
                    providers, our strict privacy and confidentiality policies, and any applicable federal and state
                    laws designed
                    to safeguard your privacy, including, but not limited to, HIPAA. In addition, we collect and
                    disclose only the
                    minimum necessary information to accomplish the intended purpose for which you have provided your
                    information
                    to us.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Public Information and Submissions:</h5>
                </strong>
                <p>If you consent to having your review and/or feedback of a provider to be publicly displayed on
                    careEco, then
                    you agree that any information that you may reveal in a review and/or feedback posting is intended
                    for the
                    public and is not in any way private. Carefully consider whether to disclose any Personal
                    Information in any
                    public posting or forum. Your submissions may be seen and/or collected by third parties and may be
                    used by
                    others in ways we are unable to control or predict.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Purpose Collected:</h5>
                </strong>
                <p>We will use your personally identifiable information for the purpose for which you have provided it
                    to us,
                    including to process requests for health care provider appointments, to communicate with you in
                    connection
                    with those appointment requests (e.g., to send you appointment updates via email and, as described
                    below, via
                    text message), and to provide you with information and communications that you request. If you
                    create an
                    online CareEco account, we will use Collected Information to communicate with you regarding your
                    account. If
                    you contact us for support or assistance in using the Service, we may use Collected Information to
                    determine
                    whether or not your system meets the minimum requirements needed to access and use the Service and
                    otherwise
                    to contact you regarding your request.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Text Messages from careEco:</h5>
                </strong>
                <p>While it is necessary to create an online CareEco account to search for or request appointments with
                    health
                    care providers, you will be required to create an online CareEco account and to provide us with your
                    mobile
                    telephone number in order to save your profile and take advantage of other Service features. If you
                    create an
                    online CareEco account, CareEco will text you OTP (One Time Passcode) for verification purposes. You
                    may
                    choose to stop receiving text messages as described below but opting out of receiving text messages
                    will
                    impact your ability to access your online CareEco account. CareEco will send you text messages
                    pertaining to
                    appointments that you have booked via the Service (e.g., appointment reminders and live waitlist
                    updates) and
                    any follow-up appointments, irrespective of having an online careEco account. We may also send you
                    invitations
                    via text message to review a health care provider that you have visited. Message and data rates may
                    apply. To
                    stop receiving messages at any time, reply "STOP" to any text message you receive.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Text Messages from Health Care Providers Using the Service:</h5>
                </strong>
                <p>Health care providers using the Service may contact you via text messaging or email to remind you of
                    an
                    appointment and to provide general health/medication/insurance reminders and information. We will
                    ask you to
                    consent to receive messages via text message before you receive any messages from a health care
                    provider. By
                    consenting to receive this information via text message or email, you understand there may be some
                    level of
                    risk that the information in the email or text message could be read by a third party. Health care
                    providers
                    have agreed not to send personal detailed health information via text or email, such as the reason
                    for visit.
                    By the same token, patients are advised not to share personal health information via text message.
                    CareEco and
                    health care providers using the Service to send messages are not responsible for unauthorized access
                    of
                    protected health information while in transmission to the individual based on the individual
                    request.
                    Furthermore, CareEco and health care providers are not responsible for safeguarding information once
                    delivered
                    to the patient. By consenting, you agree to receive insecure text messages at your given phone
                    number, or any
                    number forwarded or transferred to that number or insecure emails. The consent to receive text
                    messages or
                    emails will apply to all future appointment reminders/feedback/health/medication information unless
                    you
                    request health care provider stop sending the messages by replying "STOP." If you are not the
                    intended
                    recipient to any text messages or emails sent by CareEco or the healthcare providers using the
                    Service, you
                    are hereby notified that any review, dissemination, distribution, or duplication of this
                    communication is
                    strictly prohibited. Also, if you are not the intended recipient to text messages please reply
                    "STOP" to any
                    text messages you receive.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Communication with You:</h5>
                </strong>
                <p>We may use Collected Information to notify you of changes made to the Service (for example, changes
                    to our
                    Terms of Use and this Privacy Policy) and, if you sign up or otherwise opt in to receive promotions
                    from us,
                    to send you promotional materials and other communications as set forth above.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Service Evaluation and Improvement:</h5>
                </strong>
                <p>We may use Collected Information: to analyze, develop, and improve the content, materials, products,
                    and
                    services that we make available through the Service and the Service's functionality; to inform
                    marketing and
                    communication plans and strategies; to evaluate user needs and customize Service content,
                    promotional emails,
                    and your browsing experience; and for other legitimate and lawful business purposes.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Security and Protection of Rights:</h5>
                </strong>
                <p>We may use Collected Information and share it with third parties to the extent permitted or required
                    by
                    applicable law, including in the event of a subpoena, court order, or law enforcement request.
                    CareEco will
                    use Collected Information and share it with third parties if we believe doing so is necessary to
                    operate the
                    Service or to protect our rights or the rights of others, including disclosing information necessary
                    to
                    identify, contact, or bring legal action in the event of a violation of our contracts, terms, or
                    policies, but
                    we will do so only in accordance with applicable federal and state laws and any contracts we have
                    with health
                    care providers and subcontractors.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Sharing Collected Information with Our Suppliers:</h5>
                </strong>
                <p>We may share Collected Information with our Service management and hosting suppliers, payment
                    processors,
                    marketing and public relations service providers, email service suppliers, text message service
                    providers, and
                    other suppliers as necessary for us to provide the Service and our products and services to you and
                    as may
                    otherwise be permitted by applicable law and in accordance with any contractual obligations we have
                    in place
                    with health care providers and subcontractors, which includes suppliers that we may engage to
                    provide services
                    in connection with the Service. We require our suppliers to comply with all applicable data privacy
                    laws and
                    regulations. Our suppliers are authorized to and may use Collected Information as necessary for them
                    to
                    provide the applicable services to us. If it is necessary for us to share your PHI with any
                    supplier, we will
                    share only the minimum necessary information to enable the supplier to provide the services for
                    which we have
                    engaged them in connection with the Service, and we will ensure that the supplier agrees to use and
                    disclose
                    your PHI only as required or permitted by applicable federal and state laws, including HIPAA.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Aggregate Data:</h5>
                </strong>
                <p>We may use your personally identifiable information and other Collected Information to create
                    anonymous
                    aggregate data on Service users, which describes users as a group but does not reveal the identity
                    of
                    individual users. We may use aggregate data to understand Service users' needs, to determine Service
                    user
                    demographics and usage patterns, to determine what kinds of products and services we can provide,
                    and to
                    improve and enrich our products, services, and the Service.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Business Transactions:</h5>
                </strong>
                <p>All Collected Information is exclusively the property of CareEco but, if CareEco undergoes a change
                    in
                    control, acquisition, merger, reorganization, or asset sale, all information owned by or in the
                    control of
                    CareEco may be transferred or sold to the successor of the transaction. That successor will be bound
                    by this
                    Privacy Policy as it applies to that information.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Access by Children:</h5>
                </strong>
                <p>The Service is not directed at children under 13 years of age. CareEco does not knowingly collect or
                    use
                    information from children under 13 through the Service. If a parent or legal guardian becomes aware
                    that their
                    child has provided CareEco Health with personally identifiable information without his or her
                    consent, the
                    parent or legal guardian should contact us at support@levlivet.net . If we become aware that a child
                    under 13
                    has provided us with information, we will delete that information from our files. If a parent or
                    guardian has
                    created a CareEco account for a minor under the age of 18, the parent or legal guardian represents
                    and
                    warrants to CareEco that he or she is the parent or legal guardian of that minor with a right to
                    provide us
                    with the information of that minor.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Access from Outside the United States:</h5>
                </strong>
                <p>CareEco Services are not intended for and are not directed to, residents of the European Union. Users
                    residing outside of the United States and or accessing the site from outside of the United States,
                    acknowledge
                    and consent to, by doing so, that their information will be stored and processed outside of their
                    country of
                    residence or country from where they access the service and subject to privacy laws that may be
                    different than
                    their country of residence or the local country from which they access the service. The data
                    protection and
                    related laws and regulations of the United States might not be as comprehensive as those of other
                    countries.
                </p>
            </div>
            <div class="block">
                <strong>
                    <h5>How to Access Your Personally Identifiable Information:</h5>
                </strong>
                <p>To access or update your personally identifiable information as it exists in our records please visit
                    the
                    profile you have created for yourself in your online CareEco account, if applicable, or contact us
                    at
                    support@levlivet.net .</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Contact Us:</h5>
                </strong>
                <p>If you have any questions or concerns regarding this Privacy Policy, contact us by email at
                    support@levlivet.net or by mail at CareEco 320 Seven Springs Way, Brentwood, TN 37027</p>
            </div>
        </div>
    </div>
</section>