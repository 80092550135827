import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpService } from 'src/app/shared/http.service';

@Component({
  selector: 'app-patient-outreach',
  templateUrl: './patient-outreach.component.html',
  styleUrls: ['./patient-outreach.component.scss']
})
export class PatientOutreachComponent implements OnInit {

  public facilityId: any;
  public token: any;
  composeMessage: any;

  public getAppointmentId: any;
  public appointmentDetails: any;
  public patientLastName: any;
  public messageShown: any;
  public apiStatus?: boolean;
  public showModalCancel?: boolean;
  public noRegistrations: any;
  public getParamId: any;
  public patientOutreachConfirmForm: UntypedFormGroup;
  public showComposedMessage?: boolean;
  public noAppointmentDetails?: boolean;
  public loadSpinner: boolean = false;

  constructor(private formBuilder: UntypedFormBuilder, private httpService: HttpService, private router: Router, private activeRoute: ActivatedRoute) {
    this.activeRoute.params
      .subscribe(
        (params: Params) => {
          this.facilityId = params['facilityId'] || null;
          sessionStorage?.setItem('headerFacilityId', this.facilityId);
          // console.log(this.facilityId)
          this.token = params['tokenId'] || null;
        }
      );

    this.patientOutreachConfirmForm = this.formBuilder.group({
      "PATIENT_LAST_NAME": [null, Validators.required],
      'CHECK_VALID': [null],
    })

  }

  ngOnInit(): void {
    // this.getLogo();
    this.getOutreachComposeMessage();
  }

  public checkLastName(): void {
    if (this.patientLastName) {
      const name = this.patientLastName.toLowerCase();
      const enterName = this.patientOutreachConfirmForm?.value?.PATIENT_LAST_NAME?.toLowerCase();
      if (name === (enterName && enterName.toLowerCase())) {
        this.patientOutreachConfirmForm?.get('CHECK_VALID')?.setValidators(null);
        this.showComposedMessage = true;
        this.callAnalyticsApi(this.facilityId, 'Patient Outreach');
      } else {
        this.patientOutreachConfirmForm?.get('CHECK_VALID')?.setValidators([Validators.required]);
      }
      this.patientOutreachConfirmForm?.get('CHECK_VALID')?.updateValueAndValidity();
    } else {
      this.patientOutreachConfirmForm?.get('CHECK_VALID')?.setValidators([Validators.required]);
    }
  }

  // user Analytics
  public callAnalyticsApi(facilityId?: number, message?: string, phNumber?: string): void {
    let action = `appointment/registration/appAnalytics?category=${message}`;
    if (phNumber && message === 'Phone Number') {
      action = action + `&phoneNumber=${phNumber}`;
    }
    if(this.token) {
      action = action + `&webToken=${this.token}`;
    }
    if(this.composeMessage?.campaignId) {
      action = action + `&campaignId=${this.composeMessage?.campaignId}`;
    }
    this.httpService?.makeRequestWithAction(action)?.subscribe((data: any) => {
      this.loadSpinner = false;
    }, error => {
      this.loadSpinner = false;
    });
  }

  public openBookAppointment(): void {
    this.router?.navigate([`${this.facilityId}/scheduleappt`], {
      queryParams: {
        outreachId: this.token,
        campaignId : this.composeMessage?.campaignId
      }
    });
  }

  public getOutreachComposeMessage(): void {
    if (!this.token) {
      return;
    }
    const action = `outreach/composeMessage/${this.token}`;
    this.loadSpinner = true;
    this.httpService?.makeGetRequest(action)?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        if(data?.responseObject){
          this.composeMessage = data?.responseObject || null;
          this.patientLastName = data?.responseObject?.lastName || null;
          let message = data?.responseObject?.message || null;
          if (message && typeof message === 'string') {
            if (message?.includes('Thank You,')) {
              const index = message?.indexOf('Thank');
              message = index !== -1 ? message?.substring(0, index) : message;
            }
          }
          this.composeMessage.message = message;
        }
      }
      this.loadSpinner = false;
    },
      (error) => {
        this.loadSpinner = false;
      })
  }

  // replace Phone NUmber hyphens
  public replaceHyphens(phNumber: string): string {
    return phNumber?.replace(/[#_-]/g, '');
  }
}
