import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from 'src/app/shared/http.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {

  public items = ['Easy Check In', 'Good Wifi', 'Friendly Stuff', 'Office Ambiance', 'Paperless Onboarding', 'Conierge'];

  public visitExperience!: UntypedFormGroup;
  public navigationMode: any = null;
  public platformExperience!: UntypedFormGroup;
  public consultationExperience!: UntypedFormGroup;
  public visitExpCheckedValues: any = [];
  public facilityId: any;
  public consultationExpCheckedValues: any = [];
  public platFormExpCheckedValues: any = [];
  valuesGoodList: any;
  valuesUglyList: any;
  public loadSpinner: boolean = false;
  public notifyText?: string;
  public exceptionalModal?: boolean = false;
  public feedbackSentSuccess?: boolean;

  constructor(private fb: UntypedFormBuilder, private activatedRoute: ActivatedRoute, private httpService: HttpService) {
    this.activatedRoute?.params?.subscribe(data => {
      if (data && data['facilityId']) {
        this.facilityId = data['facilityId'] || null;
        sessionStorage?.setItem('headerFacilityId', this.facilityId);
      }
    });
  }

  ngOnInit(): void {
    this.visitExperience = this.fb?.group({
      "negative": [null],
      "visitoutcome": [null, Validators?.required],
      "positive": [null],
      "feedbackResponse": [null]
    });
    this.platformExperience = this.fb?.group({
      "negative": [null],
      "platformoutcome": [null, Validators?.required],
      "positive": [null],
      "feedbackResponse": [null]
    });
    this.consultationExperience = this.fb?.group({
      "negative": [null],
      "consultationoutcome": [null, Validators?.required],
      "positive": [null],
      "feedbackResponse": [null]
    });
  }

  // visit experince related functions
  public changeVisitExpSmiley(): void {
    this.visitExpCheckedValues = [];
    if (this.visitExperience?.value?.visitoutcome === 'POSITIVE') {
      this.visitExperience?.get('positive')?.setValidators([Validators?.required]);
      this.visitExperience?.get('negative')?.reset();
    } else {
      this.visitExperience?.get('negative')?.setValidators([Validators?.required]);
      this.visitExperience?.get('positive')?.reset();
    }
    this.visitExperience?.get('positive')?.updateValueAndValidity();
    this.visitExperience?.get('negative')?.updateValueAndValidity();
  }

  public valueCheckVisitExp(eventValue: any): void {
    if (eventValue?.target?.checked) {
      this.visitExpCheckedValues?.push(eventValue?.target?.value)
    } else {
      this.visitExpCheckedValues = this.visitExpCheckedValues?.filter((item: string) => item != eventValue?.target?.value)
    }
  }

  public checkValidVisit(): void {
    this.visitExperience?.markAllAsTouched();
    console.log(this.visitExperience);
  }

  // Consultation Expe
  public checkValidConsultaton(): void {
    this.consultationExperience?.markAllAsTouched();
  }

  public changeConsultationExpSmiley(): void {
    this.consultationExpCheckedValues = [];
    if (this.consultationExperience?.value?.consultationoutcome === 'POSITIVE') {
      this.consultationExperience?.get('positive')?.setValidators([Validators?.required]);
      this.consultationExperience?.get('negative')?.reset();
    } else {
      this.consultationExperience?.get('negative')?.setValidators([Validators?.required]);
      this.consultationExperience?.get('positive')?.reset();
    }
    this.consultationExperience?.get('positive')?.updateValueAndValidity();
    this.consultationExperience?.get('negative')?.updateValueAndValidity();
  }

  public valueCheckConsultationExp(eventValue: any): void {
    if (eventValue?.target?.checked) {
      this.consultationExpCheckedValues?.push(eventValue?.target?.value)
    } else {
      this.consultationExpCheckedValues = this.consultationExpCheckedValues?.filter((item: string) => item != eventValue?.target?.value)
    }
  }

  // Platform Exp
  public changePlatformExpSmiley(): void {
    this.platFormExpCheckedValues = [];
    if (this.platformExperience?.value?.platformoutcome === 'POSITIVE') {
      this.platformExperience?.get('positive')?.setValidators([Validators?.required]);
      this.platformExperience?.get('negative')?.reset();
    } else {
      this.platformExperience?.get('negative')?.setValidators([Validators?.required]);
      this.platformExperience?.get('positive')?.reset();
    }
    this.platformExperience?.get('positive')?.updateValueAndValidity();
    this.platformExperience?.get('negative')?.updateValueAndValidity();
  }

  public valueCheckPlatformExp(eventValue: any): void {
    if (eventValue?.target?.checked) {
      this.platFormExpCheckedValues?.push(eventValue?.target?.value)
    } else {
      this.platFormExpCheckedValues = this.platFormExpCheckedValues?.filter((item: string) => item != eventValue?.target?.value)
    }
  }

  public callFeedbackQuestions(type: string): void {
    this.getFeedabckSmileyQnA(type);
  }

  // get feedabck q and A
  private getFeedabckSmileyQnA(type: string): void {
    const action = `feedback/questions/${type}?facilityId=${this.facilityId}`;
    this.loadSpinner = true;
    this.httpService?.makeGetRequest(action)?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.valuesGoodList = data?.resultList[0]?.valuesGood || [];
        this.valuesUglyList = data?.resultList[0]?.valuesUgly || [];
      }
      this.loadSpinner = false;
    }, error => {
      this.notifyText = error?.message || 'Something went wrong. Please try again later.';
      this.exceptionalModal = true;
      this.loadSpinner = false;
    });
  }

  public submitYourFeedback(): void {
    const action = `facility/feedback/create`;
    const params = {
      "appointmentId": null,
      "facilityId": this.facilityId,
      "reviewDetails": {
        "consultationExperience": {
          "negative": this.consultationExperience?.value?.negative === true ? this.consultationExpCheckedValues : [],
          "outcome": this.consultationExperience?.value?.consultationoutcome,
          "positive": this.consultationExperience?.value?.positive === true ? this.consultationExpCheckedValues : [],
          "feedbackResponse": this.consultationExperience?.value?.feedbackResponse
        },
        "overallExperience": null,
        "platformExperience": {
          "negative": this.platformExperience?.value?.negative === true ? this.platFormExpCheckedValues : [],
          "outcome": this.platformExperience?.value?.platformoutcome,
          "positive": this.platformExperience?.value?.positive === true ? this.platFormExpCheckedValues : [],
          "feedbackResponse": this.platformExperience?.value?.feedbackResponse
        },
        "visitExperience": {
          "negative": this.visitExperience?.value?.negative === true ? this.visitExpCheckedValues : [],
          "outcome": this.visitExperience?.value?.visitoutcome,
          "positive": this.visitExperience?.value?.positive === true ? this.visitExpCheckedValues : [],
          "feedbackResponse": this.visitExperience?.value?.feedbackResponse
        }
      }
    };
    this.loadSpinner = true;
    this.httpService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
      this.loadSpinner = false;
      this.notifyText = 'Your Feedback has been submitted successfully..!';
      this.exceptionalModal = true;
      this.feedbackSentSuccess = true
    }, error => {
      this.loadSpinner = false;
      this.notifyText = error?.message || 'Something went wrong. Please try again later.';
      this.exceptionalModal = true;
    });
  };

  public reloadLocation(): void {
    location?.reload();
    this.exceptionalModal = false;
  }

  public replaceCareEcoWebsite(): void {
    this.exceptionalModal = false;
    location?.replace("https://www.careeco.net/home");
  }

}
