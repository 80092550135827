import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpService } from '../../shared/http.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-teli-health',
  templateUrl: './teli-health.component.html',
  styleUrls: ['./teli-health.component.scss'],
  encapsulation: ViewEncapsulation?.None
})
export class TeliHealthComponent implements OnInit {

  facilityAddress: any;
  public loadSpinner?: boolean;
  public exceptionalModal?: boolean;
  notifyText: any;
  facilityId: any;
  public valdateDobForm: UntypedFormGroup;
  public selectedProvider: any;
  public selectedProviderDetails: any;
  public typeOfServices = [];
  public errorMessage: any;
  facilityName: any;
  public address: any;
  public maxAppointmentDate?: Date;
  public minDOB = this.convertDate(new Date('01 Jan 1910'));
  private routeSub: Subscription;
  public currentYear = new Date().getFullYear();
  public currentMonth = new Date().getMonth();
  public currentDate = new Date().getDate();
  public meetingUrl: any = '';
  public isMeetingAvailable: boolean = false;
  public isTeliHelath: boolean = false;
  public selectedValidData: any;
  public statusMessage: any = '';
  constructor(private formBuilder: UntypedFormBuilder, private httpService: HttpService,
    private activeRoute: ActivatedRoute,) {
    this.valdateDobForm = this.formBuilder.group({
      'PATIENT_FIRST_NAME': [null, Validators.required],
      'PATIENT_LAST_NAME': [null, Validators.required],
      'DATE_OF_BIRTH': [null, Validators.required],
      'PHONE_NUMBER': [null],

    });
    this.routeSub = this.activeRoute.params.subscribe(params => {
      const facilityId = params['facilityId'];
      sessionStorage?.setItem('headerFacilityId', facilityId);
      this.getProviderInfo(facilityId);
      this.facilityId = facilityId;
    });

    this.maxAppointmentDate = this.httpService?.convertDate(new Date());
  }

  ngOnInit() {

    this.selectedProvider = JSON.parse(sessionStorage.getItem('selectedProvider') || '{}') || null;
    this.selectedProviderDetails = JSON.parse(sessionStorage.getItem('verfiedUserDetails') || '{}') || null;
    if (Object.keys(this.selectedProvider)?.length > 0) {
      this.facilityName = (this.selectedProvider?.facilityName) || null;
      this.facilityAddress = this.selectedProvider?.address || {};
      this.address = (this.selectedProvider?.address) || {};
      this.maxAppointmentDate = this.httpService?.convertDate(new Date());
    }
  }
  public convertDate(dob: string | number | Date): any {
    let currentDate = new Date(dob);
    let date = currentDate.getDate();
    let formatedate = ("0" + date).slice(-2);
    let month = currentDate.getMonth() + 1;
    let formateMonth = ("0" + month).slice(-2);
    let year = currentDate.getFullYear();
    let dateString = `${year}-${formateMonth}-${formatedate}`;
    return dateString;
  }

  public getProviderInfo(facilityId: any): void {
    let action: any = `providerInfo/getProviderInfo`;
    const params = {
      "facilityName": facilityId,
      "id": facilityId,
      "phoneNumber": "",
      "zipCode": 0
    }
    this.loadSpinner = true;
    this.httpService.makeRequestWithAction(action, params)
      .subscribe((data: any) => {
        if (data.status === 'SUCCESS' && data.resultList != null) {
          const getObj = data && data.resultList[0];
          sessionStorage.setItem('selectedProvider', JSON.stringify(getObj));
          this.selectedProvider = JSON.parse(sessionStorage?.getItem('selectedProvider') || '{}');
          this.facilityName = (getObj && getObj.facilityName) || null;
          this.address = getObj?.address || this.selectedProvider?.address;

        } else {
          this.notifyText = data?.message || 'Something went wrong please try again later..!';
          this.exceptionalModal = true;
        }
        this.loadSpinner = false;
      },
        error => {
          this.loadSpinner = false;
          this.notifyText = error?.message || 'Something went wrong please try again later..!';
          this.exceptionalModal = true;
        });
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }
  //This method is to validate manually entered date value
  public validateManualEnterdob(e: any, fieldName: any): void {
    if (e.target.value != '' && e.target.value != null) {
      // const date = new Date(e.target.value);
      // if (date?.getFullYear() <= 1900) {
      //   return;
      // }
      if (this.validateManualEnterdate(e.target.value, this.currentYear, this.currentMonth + 1, this.currentDate)) {
        this.valdateDobForm?.get(fieldName)?.setValue(null);
      }
    }
  }

  //This method is to validate manually entered date value
  public validateManualEnterdate(enteredDate: any, currentYear: any, currentMonth: any, currentDate: any): boolean {
    const date = new Date(enteredDate);
    const toDayDate = new Date();
    if (date != undefined && date != null) {
      // var inputYear = date.getFullYear();
      // var inputMonth = date.getMonth() + 1;
      // var inputDate = date.getDate();
      // if (inputYear >= 1900 && inputYear <= currentYear) {
      //   if (!(inputYear < currentYear || (inputYear == currentYear && inputMonth < currentMonth) ||
      //     (inputYear == currentYear && inputMonth == currentMonth && inputDate <= currentDate))) {
      //     return true
      //   }
      // }
      // else {
      //   return true
      // }
      if (date.getFullYear() < 1910 || date > toDayDate) {
        return true; // Invalid date
      }
      return false
    }
    return false
  }

  // disbale the cointinue butt if not fill all the details
  public checkValidToContinue(): boolean {
    if (this.valdateDobForm?.get('PATIENT_FIRST_NAME')?.invalid ||
      this.valdateDobForm?.get('PATIENT_LAST_NAME')?.invalid ||
      this.valdateDobForm?.get('DATE_OF_BIRTH')?.invalid) {
      this.valdateDobForm?.markAllAsTouched();
      return true
    }
    if (this.valdateDobForm?.get("DATE_OF_BIRTH")?.invalid) {
      this.notifyText = 'Please select the valid DOB';
      this.exceptionalModal = true;
      this.valdateDobForm?.markAllAsTouched();
      return true;
    }
    return false;
  }


  // to getTele Health  Link
  public getTeleHealthDetails(): void {
    if (this.checkValidToContinue()) {
      return;
    }
    const phoneNumber = this.valdateDobForm?.value?.PHONE_NUMBER;
    const firstName = this.valdateDobForm?.value?.PATIENT_FIRST_NAME?.trim();
    const lastName = this.valdateDobForm?.value?.PATIENT_LAST_NAME?.trim();
    const enterDob = this.valdateDobForm?.value?.DATE_OF_BIRTH;
    let action = `appointment/registration/getTeleHealthApptDetails?firstName=${firstName}&lastName=${lastName}&dateofBirth=${enterDob}`;
    if (phoneNumber) {
      action += `&phoneNumber=%2B1${phoneNumber}`
    }
    this.loadSpinner = true;
    this.httpService?.makeGetRequest(action)?.subscribe((data: any) => {
      this.loadSpinner = false;
      this.isTeliHelath = true;
      if (data?.responseObject) {
        this.meetingUrl = data?.responseObject?.appointment?.patientMeetingUrl;
        if (this.meetingUrl && this.meetingUrl != '') {
          this.isMeetingAvailable = true;
        }
      } else {
        this.isMeetingAvailable = false;
        this.statusMessage = data?.message ? data?.message : 'No Meeting Avaialbe';
      }
    },
      (error) => {
        this.notifyText = 'Some thing went wrong please try later..';
        this.exceptionalModal = true;
        this.loadSpinner = false;
      });
  };

  public joinMeeting() {
    window.location.replace(this.meetingUrl);
  }
  public returnToHome() {
    const base_url = window.location.origin;
    location?.replace(`${base_url}/${this.facilityId}/patientPortal`);
    sessionStorage.removeItem('selectedProvider');
  }
  public returnToTeleForm() {
    const base_url = window.location.origin;
    location?.replace(`${base_url}/${this.facilityId}/teleHealth`);
    sessionStorage.removeItem('selectedProvider');
  }


}
