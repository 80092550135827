<div class="patient-life-cycle">
    <div class="container">
        <div class="main-timeline-sec">
            <form [formGroup]="accountVerificationFormGroup">

                <div class="add-appointment-card">
                </div>
                <!-- spinner -->
                <div class="custom-loader justify-content-center" *ngIf="loadSpinner" id="spinnerDisplay">
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div class="add-appointment-card  white-bg" *ngIf="displayOptions">

                    <!-- Appointment and Demographics details -->
                    <section id="demographics-details">

                        <div class="bg-style demographics">
                            <div class="logo-center">
                                <a class="navbar-brand" href="javascript:;" style="display: flex;">
                                    <!-- <img src="../../../assets/images/careeco-logo.svg" alt="CareEco Logo"> -->
                                    <img src="../../../assets/images/careeco-logo.png" alt="CareEco Logo">
                                    <!-- <h5>CareEco</h5> -->
                                </a>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                                    <h5 style="font-weight: bold;">Please verify your account to continue</h5>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12  text-center">
                                    <div class="form-group mb-3">
                                        <label style="font-weight: bold;color:#2B3278;cursor: pointer;"
                                            (click)="navigatToVerifyScreen('Mobile')" id="accVerify_verifyMobile">Verify with mobile </label>

                                    </div>
                                </div>
                                <!--<div class="col-lg-12 col-md-12 col-sm-12  text-center">
                                   
                                    <div class="form-group mb-3">
                                        <label style="font-weight: bold;color:#2B3278;cursor: pointer;" (click)="navigatToVerifyScreen('Email')">Verify with email </label>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </section>
                </div>


                <div class="add-appointment-card  white-bg" *ngIf="displayVerifyEmail">
                    <!-- Appointment and Demographics details -->
                    <section id="demographics-details">
                        <div class="bg-style demographics">
                            <div class="logo-center">
                                <a class="navbar-brand" href="javascript:;" style="display: flex;">
                                    <!-- <img src="../../../assets/images/careeco-logo.svg" alt="CareEco Logo"> -->
                                    <img src="../../../assets/images/careeco-logo.png" alt="CareEco Logo">
                                    <!-- <h5>CareEco</h5> -->
                                </a>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                                    <h5>Verify with Email</h5>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12  text-center">

                                    <label>We'll send a text to verify your email </label>
                                    <div class="form-group mb-4 mt-4">
                                        <input type="email" class="form-control reset-form-input" id="accVerify_email"
                                            formControlName="email" placeholder="Email address">
                                    </div>
                                    <button class="btn btn-primary continue-btn" id="accVerify_next2"
                                        [disabled]="accountVerificationFormGroup.get('phoneNumber') == null">
                                        <span *ngIf="this.btnLoader" class="spinner-border spinner-border-sm"
                                            role="status"></span>
                                        <span *ngIf="this.btnLoader" class="load-text"> Loading...</span>
                                        <span *ngIf="!this.btnLoader" class="btn-text">Next</span>
                                    </button>
                                </div>

                            </div>
                        </div>
                    </section>
                </div>

                <div class="add-appointment-card  white-bg" *ngIf="displayVerifyMobile"
                    [formGroup]="accountVerificationFormGroup">
                    <!-- Appointment and Demographics details -->
                    <section id="demographics-details">
                        <div class="bg-style demographics">

                            <div class="logo-center">
                                <a class="navbar-brand" href="javascript:;" style="display: flex;">
                                    <!-- <img src="../../../assets/images/careeco-logo.svg" alt="CareEco Logo"> -->
                                    <img src="../../../assets/images/careeco-logo.png" alt="CareEco Logo">
                                    <!-- <h5>CareEco</h5> -->
                                </a>
                            </div>
                            <!-- <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                                    <h5>Verify with mobile</h5>
                                </div>
                            </div> -->
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12  text-center">

                                    <label>Verification code will be sent to your mobile number</label>
                                    <div class="form-group mb-4 mt-4">
                                        <input class="form-control input-text" type="text" mask="(000) 000-0000"
                                            formControlName="phoneNumber" placeholder="(xxx) xxx-xxxx"
                                            id="PHONE_NUMBER" />
                                        <span class="required error"
                                            *ngIf="accountVerificationFormGroup.get('phoneNumber')?.touched && accountVerificationFormGroup.get('phoneNumber')?.invalid">Phone
                                            Number is required</span>
                                    </div>
                                    <button class="btn btn-primary  continue-btn" id="accVerify_next1"
                                        [disabled]="accountVerificationFormGroup.get('phoneNumber') == null"
                                        (click)="checkUserExist()">
                                        <span *ngIf="this.btnLoader" class="spinner-border spinner-border-sm"
                                            role="status"></span>
                                        <span *ngIf="this.btnLoader" class="load-text"> Loading...</span>
                                        <span *ngIf="!this.btnLoader" class="btn-text">Next</span>
                                    </button>
                                </div>

                            </div>
                        </div>
                    </section>
                </div>

                <div class="add-appointment-card  white-bg" *ngIf="displayOTP">
                    <!-- Appointment and Demographics details -->
                    <section id="demographics-details">
                        <div class="bg-style demographics">

                            <div class="logo-center">
                                <a class="navbar-brand" href="javascript:;" style="display: flex;">
                                    <!-- <img src="../../../assets/images/careeco-logo.svg" alt="CareEco Logo"> -->
                                    <img src="../../../assets/images/careeco-logo.png" alt="CareEco Logo">
                                    <!-- <h5>CareEco</h5> -->
                                </a>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                                    <h5>Enter Verification Code</h5>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12  text-center">

                                    <label>Verify your account by typing in the 6 digit code we sent </label>
                                    <div class="input-group mb-4 mt-4">
                                        <div class="col-lg-12 col-md-12 col-sm-12  form-group mb-3   text-center">

                                            <input type="text" class="form-control reset-form-input" id="accVerify_otp"
                                                formControlName="OTP" placeholder="One Time Password (OTP)">

                                        </div>
                                    </div>
                                    <button class="btn btn-primary  continue-btn" id="accVerify_next"
                                        [disabled]="accountVerificationFormGroup.get('OTP') == null"
                                        (click)="validateUserOtp()">
                                        <span *ngIf="this.btnLoader" class="spinner-border spinner-border-sm"
                                            role="status"></span>
                                        <span *ngIf="this.btnLoader" class="load-text"> Loading...</span>
                                        <span *ngIf="!this.btnLoader" class="btn-text">Next</span>
                                    </button>
                                </div>

                            </div>
                            <div class="row mt-2">
                                <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                                    <label>Didn't receive the code? </label> &nbsp;<label
                                        style="font-weight: bold;color:#2B3278;cursor: pointer;"
                                        (click)="checkUserExist()"> Resend Code
                                    </label>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <!-- verify if have multple users -->
                <ng-container
                    *ngIf="diplayActiveUserAuth && allActiveUsersWithPhone && (allActiveUsersWithPhone?.length > 1 || false)">
                    <div class="verify-patient-details">
                        <div class="validations white-bg" [formGroup]="validationForm">
                            <section class="auth-user-verify">
                                <div class="bg-style demographics">
                                    <div class="logo-center">
                                        <a class="navbar-brand" href="javascript:;" style="display: flex;">
                                            <!-- <img src="../../../assets/images/careeco-logo.svg" alt="CareEco Logo"> -->
                                            <img src="../../../assets/images/careeco-logo.png" alt="CareEco Logo">
                                            <!-- <h5>CareEco</h5> -->
                                        </a>
                                    </div>

                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                                            <h5>Please confirm your Last Name and Date of Birth</h5>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <div class="form-group mb-3">
                                                <input type="text" class="form-control input-text"
                                                    formControlName="lastName" id="lastName"
                                                    placeholder="Patient Last Name">
                                                <span
                                                    *ngIf="validationForm?.get('lastName')?.errors?.required && validationForm?.get('lastName')?.touched"
                                                    class="error">Patient Last Name is Required</span>
                                            </div>
                                        </div>
                                        <!-- date of brith -->
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <div class="form-group mb-3">
                                                <input type="date" class="form-control input-text"
                                                    formControlName="dateofBirth" id="dateofBirth"
                                                    placeholder="Patient Date of Birth">
                                                <span
                                                    *ngIf="validationForm?.get('dateofBirth')?.errors?.required && validationForm?.get('dateofBirth')?.touched"
                                                    class="error">Patient Date of Birth is Required</span>
                                            </div>
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                                            <span *ngIf="errorValidation" class="errorMessage" class="error">Entered
                                                Last Name and Date of
                                                Birth should match with your data</span>
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <button (click)="checkValidationForCareJourney()"
                                                class="btn btn-primary search-btn cancel-appointment-page-btn" id="accVerify_submit">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </ng-container>
            </form>
        </div>
    </div>

</div>



<!-- Modal -->
<div id="book-appointment-modal" [style.display]="exceptionalModal ? 'block' : 'none'" class="modal" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-body">
                <h5 style="text-align: center;padding: 15px;">{{notifyText}}</h5>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">

                        <button type="button" class="btn btn-primary ok-book" data-dismiss="modal" id="accVerify_ok"
                            (click)="closeModal()">Ok</button>

                    </div>
                </div>

            </div>
        </div>
    </div>
</div>