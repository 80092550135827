import { formatDate } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, HostListener, Inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { HttpService } from '../../shared/http.service';
import { ArchwizardModule, WizardComponent } from 'angular-archwizard';



@Component({
  selector: 'app-viewpatientdetails',
  templateUrl: './viewpatientdetails.component.html',
  styleUrls: ['./viewpatientdetails.component.scss']
})
export class ViewpatientdetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  public radioButtons: string;
  public genderRadioButtons: string;
  public insuranceType: string;
  public menuClass: boolean;
  public collapseStyle: string;
  public appointmentId?: string;
  public facilityId!: string;
  @Output() onNavCollapse = new EventEmitter();
  @Output() onNavHeaderMobCollapse = new EventEmitter();
  dtPatientDetails: any;
  public loginDetails: any;
  public notifyText: any;
  public allAppntHistory: any = [];
  @ViewChild(WizardComponent)
  public detailsWizard!: WizardComponent;

  public emptyString: string = '';
  public disableBillingAddresssFields: boolean = false;
  form = new UntypedFormGroup({
    insuranceDetailsGender: new UntypedFormControl('InsuranceDetailsGender'),
  });
  public patientDetails: any;
  public navigationMode: any = null;
  public downloadedFileType: any;

  public allTitles = [
    { name: 'Demographics', id: "demographics" },
    { name: 'Emergency Contact Details', id: "emergency-contact-infomation" },
    { name: 'Insurance Details', id: "insurance-details" },
    { name: 'Primary Subscriber', id: "primary-subscriber" },
    { name: 'Employer Details', id: "employer-information" },
    { name: 'Billing Address', id: "billing-address" },
    // { name: 'Allergies', id:"allergies" },
    // { name: 'Current Medication', id:"current-medication" },
    { name: 'Primary Complaints', id: "primary-complaints" },
  ]
  public selectedIndex: number = 0;
  public navigationList: Array<any> = [
    {
      breadcrumbs: true,
      title: "Treatment Flow",
      type: "item",
      url: '/hospital/hosp-patient'
    },
    {
      breadcrumbs: true,
      title: "Patient Summary",
      type: "item",
      url: ''
    }
  ]
  public loadSpinner?: boolean;
  public treatmentFlowDataByAppntId: any;
  public subscriberDetails: any;
  public userInfoId: any;
  public exceptionalModal: boolean = false;
  drivingLicenseFile: any;
  public navgationVal: boolean = true;
  public selectedItem: any = 0;

  constructor(private activatedRoute: ActivatedRoute,
    public httpService: HttpService,
    private router: Router,
    @Inject(DOCUMENT) private _document: Document) {
    this.radioButtons = 'm';
    this.genderRadioButtons = 'mw';
    this.insuranceType = 'i';
    this.menuClass = false;
    this.collapseStyle = 'none';

    this.activatedRoute?.params?.subscribe(data => {
      this.appointmentId = data?.id;
      this.userInfoId = data && data?.userInfoId;
      if (data && data['facilityId']) {
        this.facilityId = data['facilityId'] || null;
        sessionStorage?.setItem('headerFacilityId', this.facilityId);
      }
    });
  }

  ngOnInit(): void { }

  //because to show busy loade for API calling
  ngAfterViewInit(): void {
    // this.patientDetails = JSON.parse(sessionStorage?.getItem('verfiedUserDetails') || '');
    if (!sessionStorage?.getItem('isPatientVerified')) {
      this.signout();
      return;
    }
    this.getUserInfoDetails();
    this.getAppointmentHistory(this.userInfoId);

  }

  //  get user ifno details
  private getUserInfoDetails(): void {
    const action = `userInfo/getUserInfo?id=${this.userInfoId}`;
    this.loadSpinner = true;
    this.httpService?.makeGetRequest(action)?.subscribe((data: any) => {
      this.loadSpinner = false;
      if (data?.status === 'SUCCESS') {
        const userInfoData = { ...data?.userInfoResponse };
        if (userInfoData?.primaryPhone?.includes('+1')) {
          userInfoData.primaryPhone = userInfoData?.primaryPhone?.replaceAll('+1', '');
        }
        if (userInfoData?.emergencyDetails?.phone?.includes('+1')) {
          userInfoData.emergencyDetails.phone = userInfoData?.emergencyDetails?.phone?.replaceAll('+1', '');
        }
        userInfoData.usrDateOfBirth = this.httpService?.formatDobTimeZoneWithDisplay(userInfoData?.dateOfBirth || userInfoData?.usrDateOfBirth);
        userInfoData.insuranceDetails = userInfoData?.insuranceDetails || [];
        this.patientDetails = userInfoData;
        if (userInfoData?.idDetails?.imageId) {
          this.drivingLicenseFile = userInfoData?.idDetails?.imageId || null;
          //this.downloadDlCard(userInfoData?.idDetails?.imageId);
		  const documentType = "Identification Record";
		  this.downloadIdentificationCard(userInfoData?.idDetails?.imageId, documentType, userInfoData?.id);
        } else {
          this.drivingLicenseFile = userInfoData?.idDetails?.imageId || null;
        }
      }
    },
      (error) => {
        this.loadSpinner = false;
      });
  }

  // this is for get the FacilityID details..
  public getAppointmentById(): void {
    if (this.appointmentId != '0') {
      // this.viewPatientDetails.cardRefreshShow();
      let action: any = 'appointment/registration/getAppointmentById?id=';
      let params: any = {
        'id': this.appointmentId
      }
      this.httpService.getPatientsByFacilityId(action, params.id)
        .subscribe((data: any) => {
          if (data.status == 'SUCCESS') {
            this.dtPatientDetails = data?.responseObject || null;
            if (this.dtPatientDetails) {
              // getting prod issue
              // this.dtPatientDetails.dob = formatDate(this.dtPatientDetails?.dob, 'MM/dd/yyyy', 'en-US');
              this.dtPatientDetails.dob = this.httpService?.formatDobTimeZoneWithDisplay(this.dtPatientDetails?.dob);
              if (this.dtPatientDetails?.insuranceDetails?.length > 0 && this.dtPatientDetails?.insuranceDetails[0]?.subscriberdateOfBirth) {
                this.dtPatientDetails.insuranceDetails[0].subscriberdateOfBirth = this.httpService?.formatDobTimeZoneWithDisplay(this.dtPatientDetails?.insuranceDetails[0]?.subscriberdateOfBirth);
                this.subscriberDetails = this.dtPatientDetails.insuranceDetails[0] || null;
              }
              // this.getAppointmentHistoryForUser(this.dtPatientDetails.userInfoId);
              this.patientDetails = this.dtPatientDetails;
              // this.viewPatientDetails.cardRefreshHide();
              this.getAppointmentHistory(this.dtPatientDetails.userInfoId);
            }
            // if (this.dtPatientDetails?.lockAppt) {
            //   this.lockAppointment?.show();
            // }
          }
        }, error => {

        });
    }
  }


  public redirectToBack(): void {
    this.router?.navigate(['hospital/hosp-patient']);
  }

  // to set Active class
  public activateClass(index: number) {
    this.selectedIndex = index;
  }

  ngOnDestroy(): void {
    // this.lockUnLocakAppointment(false, true);
  }

  public getAppointmentHistory(userInfoId: any): void {
    const action = `appointment/history?UserInfoId=${userInfoId}`
    // const action = `appointment/history/forUsers?userInfoId=${userInfoId}`;
    this.loadSpinner = true;
    this.httpService?.makeGetRequest(action)?.subscribe((data: any) => {
      this.loadSpinner = false;
      if (data?.status === 'SUCCESS') {
        const allAppointments = data?.responseObject || [];
        const sortedAppointments = allAppointments?.sort((a: any, b: any) => {
          const bDate: any = new Date(b?.appointment?.dateTime);
          const aDate: any = new Date(a?.appointment?.dateTime);
          return bDate - aDate;
        });
        this.allAppntHistory = sortedAppointments || [];
      }
    },
      (error) => {
        this.loadSpinner = false;
      });
  }

  public patientCareJourney(): void {
    if (!this.patientDetails?.uniqueUserToken) {
      this.notifyText = "You don't have Care Journey Events."
      this.exceptionalModal = true;
      return;
    }
    this.router.navigate([`/CareJourney/${this.patientDetails?.uniqueUserToken}`]);
  }

  // date and time format
  public dateFormate(dateTime: string): string {
    return this.httpService?.getAppointmentTime(dateTime);
  }

  public ageCalculate(dateOfBirth: any): any {
    return this.httpService?.calculateAge(dateOfBirth);
  }

  // Insurance / DL Image Card Download
  public downloadDlCard(cardId: string): void {
    const action = `document/user/getIdCard/${cardId}`;

    this.httpService.downloadImage(action).subscribe((data: any) => {
      var file = new Blob([data], { type: 'application/pdf' })
      var fileURL = URL.createObjectURL(file);
      // const fileURL = window.URL.createObjectURL(new Blob([data], {type: 'pdf'}));
      // if you want to open PDF in new tab
      // window.open(fileURL);
      const iframeEle = document.getElementById('dl-card') as HTMLElement;
      iframeEle.setAttribute('src', fileURL);
    },
      (error: any) => {
      });
  }
  
  public downloadIdentificationCard(additionalKey: any, documentType: any, userInfoId: any) {
    const action = `record/listIndividualDocument?documentType=${documentType}&userInfoId=${userInfoId}&additionalKey=${additionalKey}`;
    this.httpService?.makeGetRequest(action)?.subscribe((data: any) => {
    
      if (data?.status === 'SUCCESS') {
        if (data?.responseObject == null || data?.responseObject == '') {
          
        }
        else {
          

          const s3Key = data?.responseObject?.s3Key;
          const s3Path = data?.responseObject?.s3Path;
          const additionalKey = data?.responseObject?.additionalKey;
          
          const fileType = data?.responseObject?.fileType;
          this.downloadedFileType = fileType;
          this.downloadIdFile(userInfoId, s3Key, s3Path, additionalKey, fileType);
        }
      }
      // this.dtTriggerAppointmentDocs?.next();
    }, (error: any) => {
      this.loadSpinner = false;
    });
  }
  
  public downloadIdFile(userInfoId: any, s3Key: any, s3Path: any, additionalKey: any, fileType: any) {
    //alert(fileType);
    const downloadaction = `record/downloadDocument?s3Key=${s3Key}&s3Path=${s3Path}`;
    
    this.httpService.downloadImage(downloadaction).subscribe((dData: any) => {
      var file = new Blob([dData], { type: fileType })
      var fileURL = URL.createObjectURL(file);

      const fileObj = {
        fileURL: fileURL,
        userInfoId: userInfoId,
        s3Key: s3Key,
        additionalKey: additionalKey
      }
      const iframeEle = document.getElementById('dl-card') as HTMLElement;
      iframeEle.setAttribute('src', fileURL);


    }, (error: any) => {
      
    });
  }

  public signout(): void {
    sessionStorage?.clear();
    this.router?.navigate([`${this.facilityId}/patientPortal`]);
  }

  public menuNavigation(val: any) {
    this.selectedItem = val;
    const testContent = document.getElementById('navbarSupportedContent') as HTMLElement;
    testContent.setAttribute("class", "navbar-collapse collapse");
    this.detailsWizard?.goToStep(val);
  }

}