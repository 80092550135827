<section class="page-hero footer-sections">
    <div class="container">
        <h3 class="terms_title">HIPAA Consent Authorization</h3>
        <!-- <h5>Last Updated: March 10, 2020</h5> -->

        <div class="terms_content">

            <div class="block">
                <strong>
                    <h5>CareEco Authorization:</h5>
                </strong>
                <p>CareEco helps patients access convenient health care options, learn about care options, manage health
                    care
                    profiles and reminders, and book appointment at favorite providers or new providers.</p>
                <p>CareEco manages and forwards health and health-related information to share with providers. As part
                    of
                    providing this service, CareEco may collect, use, share, and exchange your health history forms and
                    other
                    health-related information with providers. Under The Health Insurance Portability and Accountability
                    Act
                    (“HIPAA”), some of this health and health-related information may be considered “protected health
                    information”
                    or “PHI” if such information is received from or on behalf of providers who may be classified as
                    Covered
                    Entities. HIPAA protects how and with whom patients PHI can be disclosed and shared.</p>
            </div>

            <div class="block">
                <strong>
                    <h5>PHI Authorization</h5>
                </strong>
                <p>The purpose of this CareEco Authorization (“Authorization”) is to request written permission to allow
                    CareEco
                    to use and disclose your PHI in the same way as we use and disclose Non-PHI. If CareEco is a
                    Business
                    Associate of a Covered Entity, CareEco needs this Authorization to be able to use and disclose PHI
                    in the same
                    way it can currently use and disclose Non-PHI when CareEco is not working on behalf of covered
                    entity, but is
                    instead working on its own behalf. Therefore, when CareEco relies on this Authorization, and uses
                    and
                    discloses PHI as described in this Authorization, it is not working as a Business Associate and the
                    HIPAA
                    requirements that apply to Business Associates will not apply to such uses and disclosures.</p>
                <p>E-signing this Authorization, you give their permission to CareEco to retain PHI and to use and/or
                    disclose
                    PHI in the same way that you have agreed that Non-PHI can be used and disclosed.</p>
                <div>
                    Specifically, CareEco can use PHI to:
                    <ul>
                        <li>Enable and customize the use of the CareEco product;</li>
                        <li>Provide alerts or other CareEco Services regarding future appointments;</li>
                        <li>Send notification regarding providers CareEco believes you may be interested in learning
                            more about;
                        </li>
                        <li>Share information regarding services, products or resources about which CareEco thinks you
                            may be
                            interested in learning more;</li>
                        <li>Provide updates and information about CareEco;</li>
                        <li>Market about CareEco and third-party products and services;</li>
                        <li>Conduct analysis for CareEco’s business purposes;</li>
                        <li>Support the development of the CareEco Services; and</li>
                        <li>Create de-identified information and then use and disclose this information in any way
                            permitted by law,
                            including to third parties in connection with their commercial and marketing efforts.</li>

                    </ul>
                </div>
                <div>The agreement also allows CareEco to disclose PHI to:
                    <ul>
                        <li>Third parties assisting CareEco with any of the uses described above;</li>
                        <li>Health Care providers to enable them to refer you to, and make appointments with, other
                            providers on
                            your behalf, or to perform an analysis on potential health issues or treatments, provided
                            that you choose
                            to use the applicable CareEco Service;</li>
                        <li>A third party as part of a potential merger, sale or acquisition of CareEco;</li>
                        <li>Our business partners who assist us by performing core services (such as hosting, billing,
                            fulfillment,
                            or data storage and security) related to the operation or provision of our services, even
                            when CareEco is
                            no longer working on behalf of Covered Entities;</li>
                        <li>A provider of medical services, in the event of an emergency; and</li>
                        <li>Organizations that collect, aggregate and organize information so they can make it more
                            easily
                            accessible to providers.</li>
                    </ul>
                </div>
            </div>
            <div class="block">
                <strong>
                    <h5>Redisclosure:</h5>
                </strong>
                <p>In case that CareEco discloses PHI, CareEco will require that the person or entity receiving PHI
                    agrees to
                    only use and disclose PHI to carry out its specific business obligations to CareEco or for the
                    permitted
                    purpose of the disclosure (as described above). CareEco cannot, however, guarantee that any such
                    person or
                    entity to which CareEco discloses PHI or other information will not re-disclose it in ways that you
                    or CareEco
                    did not intend or permit.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Expiration and Revocation of Authorization:</h5>
                </strong>
                <p>This Authorization remains in effect until written notice of revocation is provided to CareEco.</p>
                <p><b>YOU CAN CHANGE MIND AND REVOKE THIS AUTHORIZATION AT ANY TIME AND FOR ANY (OR NO) REASON.</b></p>
                <p>You must notify CareEco in writing to revoke this Authorization. Not executing this Authorization or
                    revoking
                    it at any time will not prevent you from using certain CareEco services. A Revocation of
                    Authorization is
                    effective after submitted to CareEco, but it does not have any effect on CareEco’s prior actions
                    taken in
                    reliance on the Authorization before revoked.</p>
                <p>Once CareEco receives the Revocation of Authorization, CareEco can only use and disclose your PHI as
                    permitted in CareEco's agreements with health care providers. Revoking this Authorization does not
                    affect use
                    of non-PHI data.</p>
            </div>
        </div>
    </div>
</section>