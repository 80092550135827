import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class Interceptor implements HttpInterceptor {

    private noNeedHeaders: any = {
        'https://connect.squareupsandbox.com/v2/payments': true,
        'https://connect.squareup.com': true
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (this.noNeedHeaders[req?.url] || req?.url?.includes('https://connect.squareup.com')) {
            const modifiedReq = req.clone({
                headers: req.headers.set('Authorization', `Bearer EAAAEW5bDzhNQHukpZNvHDBf9BUXxI26OLVzzcOI9Ov9c5fAxMny9P7FA0fERg6V`).
                set('Square-Version', '2023-08-16')
            });
            return next?.handle(modifiedReq);
        }

        const providerInfo = JSON.parse(sessionStorage?.getItem('selectedProvider') || '{}');
        const facilityId = sessionStorage?.getItem('headerFacilityId') || providerInfo?.id || 0;
        const modifiedReq = req.clone({
            headers: req.headers.set('id', `0`)
                .set('name', `name`)
                .set('facilityId', `${facilityId === 'null' || facilityId === null || facilityId === undefined || facilityId === 'undefined' ? 0 : facilityId}`)
                .set('userId', `user`)
        });
        return next.handle(modifiedReq);
    }
}