import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CareOutreachService {

  public baseUrl: string = environment?.careOutreachBaseUrl;
  private accessWithOptions: any
  hideCallBacks = [];

  showLoader() { };
  hideLoader() { };
  public visitcheck = new BehaviorSubject(false);
  constructor(
    public http: HttpClient,
  ) {
    this.accessWithOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'x-access-code': localStorage.getItem('token'),
      }),
    }
  }

  makeRequestWithAction(action: string, params?: any) {
    return this.http.post(this.baseUrl + action, params, this.accessWithOptions.headers)
      .pipe(
        map(res => res),
        catchError(error => throwError(error.message || error))
      );
  }

  makeRequestWithActionError(action: string, params?: any) {
    return this.http.post(this.baseUrl + action, params, this.accessWithOptions.headers)
      .pipe(
        map(res => res),
        catchError(error => throwError(error.error || error))
      );
  }

  uploadImages(action: string, formData: any) {
    return this.http.post(this.baseUrl + action, formData, this.accessWithOptions.headers)
      .pipe(
        map(res => res),
        catchError(error => throwError(error.message || error))
      );
  }

  makeGetRequest(action: any) {
    return this.http.get(this.baseUrl + action, this.accessWithOptions.headers)
      .pipe(
        map(res => res),
        catchError(error => throwError(error.message || error))
      );
  }

  public makeInsuranceGetRequest(action: string, request: any) {
    return this.http.get(this.baseUrl + action + request)
      .pipe(
        map(res => res),
        catchError(error => throwError(error.error || error))
      );
  }

  // Show loader
  show() {
    // const ele =  document.getElementById("spinnerDisplay") as HTMLElement;
    // ele.style.display = "block";
  };
  // Hide loader
  hide() {
    // const ele =  document.getElementById("spinnerDisplay") as HTMLElement;
    // ele.style.display = "none";
      // while (this.hideCallBacks.length > 0) {
      //     let callBack = this.hideCallBacks.pop();
      //     callBack()
      // }
  };
  // resisterHideCallback(callBack: any) {
  //   this.hideCallBacks.push(callBack);
  // }

  // showLoaderNonAPI() {
  //     document.getElementById("loader-wrapper-non-api").style.display = "block";
  // }
  // hideLoaderNonAPI() {
  //     document.getElementById("loader-wrapper-non-api").style.display = "none";
  // }

  // Generate download Image
  public downloadImage(action: string): any {
    const accessWithOptions = {
      // headers: new HttpHeaders({
      //   'Content-Type': 'application/octet-stream'
      // }),
      responseType: 'blob' as 'json'
    }
    return this.http.get(this.baseUrl + action, accessWithOptions)
      .pipe(
        map(res => res),
        catchError(error => throwError(error.error || error))
      );
  }

  // Apointment date and time format
  public getAppointmentTime(date: any): string{
    if(date){
      const splitDate = date?.toString()?.split('T');
      const timeSplit = splitDate[1]?.split(':');
      const time = `${timeSplit[0]}:${timeSplit[1]}`;
      const timeConvert = this.timeConvert(time) || '';
      const formateDate = this.convertAppointmentDate(splitDate && splitDate[0]);
      return `${formateDate}, ${timeConvert}`;
    }else{
      return 'N/A'
    }
  }

  // Apointment date and time format
  public formatDateTimeWithoutAmPm(date: any): string{
    if(date){
      const splitDate = date?.toString()?.split('T');
      const timeSplit = splitDate[1]?.split(':');
      const time = `${timeSplit[0]}:${timeSplit[1]}`;
      const timeConvert = this.timeConvert(time) || '';
      const formateDate = this.convertAppointmentDate(splitDate && splitDate[0]);
      return `${formateDate} ${timeConvert}`;
    }else{
      return 'N/A'
    }
  }

  public convertAppointmentDate(appntDate: string): any {
    const dateArray = appntDate && appntDate.split('-');
    let dateString = `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
    return dateString;
  }

  // Time converstion from 24hrs format to 12hrs
  public timeConvert(time: any) {
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

  public makePostRequest(action: string, params?: any) {
    return this.http.post(this.baseUrl + action, params, this.accessWithOptions.headers)
      .pipe(
        map(res => res),
        catchError(error => throwError(error?.error || error))
      );
  }

  // Calculate AGE
  public calculateAge(dateofBirth: any): number {
    const dobDate = dateofBirth?.includes('T') ? dateofBirth?.split('T')[0] : dateofBirth;
    const date = new Date(dobDate);
    var ageDifMs = Date.now() - date.getTime();
    var ageDate = new Date(ageDifMs);
    const age = Math.abs(ageDate.getUTCFullYear() - 1970);
    return age || 0;
  }

  public convertDate(dob: string | number | Date): any {
    let currentDate = new Date(dob);
    let date = currentDate.getDate();
    let formatedate = ("0" + date).slice(-2);
    let month = currentDate.getMonth() + 1;
    let formateMonth = ("0" + month).slice(-2);
    let year = currentDate.getFullYear();
    let dateString = `${year}-${formateMonth}-${formatedate}`;
    return dateString;
  }

  // get insurance list from the auto complete
  public getInsuranceAutoComplete(term: string): Observable<any> {
    if (!term) {
      return of([]).pipe(delay(500));
    }
    const action = 'insurance/getInsuranceList?searchQry=';
    let insuranceListSub = this.makeInsuranceGetRequest(action, term);
    return insuranceListSub;
  }

  makeRequestWithErrorMessage(action: string, params?: any) {
    return this.http.post(this.baseUrl + action, params, this.accessWithOptions.headers)
      .pipe(
        map(res => res),
        catchError(error => throwError(error?.error || error))
      );
  }


  getPatientsByFacilityId(action: string, facilityid: string) {
    return this.http.get(this.baseUrl + action + facilityid)
      .pipe(
        map(res => res),
        catchError(error => throwError(error.error || error))
      );
  }


  // remove timezone from dob
  public formatDobTimeZoneWithDisplay(dob: string): any{
    let split = dob?.includes('T') ? dob?.toString()?.split('T') : dob;
    const date = split &&  Array.isArray(split) ? split[0] : split;
    const dateChange = date?.includes('-') ? date?.toString()?.split('-') : date;
    return  ((dateChange && Array.isArray(dateChange)) ? `${dateChange[1]}/${dateChange[2]}/${dateChange[0]}` : dateChange) ||  '';
  }

  // common validate 24 hours window
  public async validate24HoursWindowCall(appId: any): Promise<any> {
    const action = `${this.baseUrl}common/validate24hourwindow?appointmentId=${appId}`;
    return this.http?.get<any>(action)?.toPromise(); 
  }

  public makePostWithFormData(action:any, params:any) {
    const HttpUploadOptions = {
      headers: new HttpHeaders(
        { "Content-Type": "multipart/form-data", "accept": "application/json" }
      )
    }
    return this.http.post(this.baseUrl + action, params)
      .pipe(
        map(res => res),
        catchError(error => throwError(error.error  || error))
      );
  }

  public squarePaymentGetRequest(action: any, headers?: any) {
    return this.http.get(action, headers?.headers || this.accessWithOptions.headers)
      .pipe(
        map(res => res),
        catchError(error => throwError(error.message || error))
      );
  }

  // Sqauer Payments
  public squarePaymentPostRequest(action: any, params: any, headers?: any) {
    return this.http.post(action, params, headers?.headers || this.accessWithOptions.headers)
      .pipe(
        map(res => res),
        catchError(error => throwError(error.message || error))
      );
  }

  public downloadProfileImage(action: string): any {
    const accessWithOptions = {
      // headers: new HttpHeaders({
      //   'Content-Type': 'application/octet-stream'
      // }),
      responseType: 'text' as 'json'
    }
    return this.http.get(this.baseUrl + action, accessWithOptions)
      .pipe(
        map(res => res),
        catchError(error => throwError(error.error || error))
      );
  }

}
