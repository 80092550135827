import { formatDate } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HttpService } from 'src/app/shared/http.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-care-events',
  templateUrl: './care-events.component.html',
  styleUrls: ['./care-events.component.scss']
})
// export class CareEventsComponent implements OnInit {

//   @Input() notValidationRequire!: boolean;
//   @Input() userUniqueToken: any;
//   constructor() { }

//   ngOnInit(): void {
//   }

// }
export class CareEventsComponent implements OnInit, OnDestroy, OnChanges {
  public allAppointmentsWithMessages: any;
  private getRouterParams: Subscription;
  public loadSpinner?: boolean;
  allAppointmentsWithDates: any;
  public getEventMessages: any;
  public selectedIndex?: number = 0;
  public careJouneyDisplay?: boolean = false;
  public userInfoDetails: any = {};

  public validationForm!: UntypedFormGroup;
  public errorValidation!: boolean;
  public defaultAppointmentDate: any;
  public appointmentDetails: any;
  public facilityId: any;
  public facilityDetails: any;
  public selectedMessage: any;
  public exceptionalModal?: boolean;
  public notifyText?: string;
  public cancelAppointmentModal?: boolean;
  public cancelSelectedAppointment: any;
  public cancelAppointmentForm: UntypedFormGroup;
  @Input() notValidationRequire!: boolean;
  @Input() userUniqueToken: any;

  public eventStartDate: any;
  public eventEndDate: any;
  public eventStatus: any;
  public minEndDate?: Date;
  public filterByDateForm: UntypedFormGroup;
  public requiredStartDate: boolean = false;
  public requiredEndDate: boolean = false;
  public patientPreferenceModal?: boolean = false;

  today = new Date();

  public reasonOfCancel = [
    { desc: 'Time', value: 'Time' },
    { desc: 'Found alternate location / provider', value: 'Found alternate location / provider' },
    { desc: 'Too long a wait', value: 'Too long a wait' },
    { desc: 'No longer needed service', value: 'No longer needed service' },
    { desc: 'Other', value: 'Other' },
    { desc: 'I don’t want to specify', value: 'I don’t want to specify' }
  ];
  public isOtherReason: boolean = false;
  public showModalCancel?: boolean;
  
  constructor(private httpService: HttpService, private activatedRoute: ActivatedRoute, private formBuilder: UntypedFormBuilder, private router: Router) {
    let uniqueToken!: any;
    this.getRouterParams = this.activatedRoute?.params?.subscribe(params => {
      const uniqueToken = params['uniqueToken'];
      if (sessionStorage.getItem('checkValidationPatient')) {
        const valid = JSON.parse(sessionStorage.getItem('checkValidationPatient') || '{}') || null;
        if (valid?.isValid && uniqueToken === valid?.uniqueToken) {
          this.careJouneyDisplay = true;
        } else {
          sessionStorage.removeItem('checkValidationPatient');
        }
      }
      if (uniqueToken) {
        this.getUserDataByUniqueToken(uniqueToken);
        this.getAllthePatientOutreachMessages(uniqueToken);
      }
    });

    this.validationForm = this.formBuilder?.group({
      'lastName': [null, Validators?.required],
      'dateofBirth': [null, Validators?.required]
      // 'zipCode': [null, Validators?.required]
    });
    this.cancelAppointmentForm = this.formBuilder?.group({
      'cancellationReason': [null, Validators?.required]
    });

    this.filterByDateForm = this.formBuilder.group({
      'startDate': [null, Validators?.required],
      'endDate': [null, Validators?.required],
      'eventStatus': ['ALL', '']
    });


  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (this.userUniqueToken) {
        this.getUserDataByUniqueToken(this.userUniqueToken);
        this.getAllthePatientOutreachMessages(this.userUniqueToken);
      }
    }
  }

  // get the all the appointments with messages for the user
  private getAllthePatientOutreachMessages(uniqueToken: string): void {
    // const action = `outreach/getOutreachMessage/${uniqueToken}`;
    const action = `common/getPatientsEvents?uniqueUserToken=${uniqueToken}`;
    this.loadSpinner = true;
    this.httpService?.makeGetRequest(action)?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS' || !data?.status) {
        this.allAppointmentsWithMessages = data?.responseObject || [];
        // let array = data?.responseObject ? [...data?.responseObject] : [];
        let array = data?.responseObject || [];
        // array = array?.map(item => {
        //   item.apptDate = formatDate(item?.apptDate, 'MM/dd/yyyy', 'en-US');
        //   return item;
        // });
        array?.sort((a: any, b: any) => {
          const bDate: any = new Date(b?.apptDate);
          const aDate: any = new Date(a?.apptDate);
          return bDate - aDate;
        });

        let makeMessagesList: any[] = [];

        // array?.forEach((item: any) => {
        //   item.outreach.messageList = item?.outreach?.messageList?.map((msgItem: any) => {
        //     msgItem.appointmentId = item?.outreach?.appointmentId;
        //     msgItem.facilityId = item?.outreach?.facilityId;
        //     msgItem.appointmentDate = item?.outreach?.apptDate;
        //     msgItem.facilityContact = item?.facilityContact;
        //     msgItem.doNotShowAction = item?.doNotShowAction;
        //     return msgItem
        //   });
        //   if (item?.outreach?.messageList && item?.outreach?.messageList?.length > 0) {
        //     makeMessagesList = [...makeMessagesList, ...item?.outreach?.messageList];
        //   }
        // });
        // makeMessagesList?.sort((a: any, b: any) => {
        //   const bDate: any = new Date(b?.appointmentDate);
        //   const aDate: any = new Date(a?.appointmentDate);
        //   return bDate - aDate;
        // });

        this.getEventMessages = array || [];
        if (this.eventStartDate != undefined && this.eventStartDate != null && this.eventStartDate != '' && this.eventEndDate != undefined && this.eventEndDate != null && this.eventEndDate != '') {
          const stDtString = this.eventStartDate.split("T");
          const enDtString = this.eventEndDate.split("T");
          var startDate = new Date(stDtString[0]);
          var endDate = new Date(enDtString[0]);

          this.getEventMessages = this.getEventMessages.filter((item: any) => {
            const appointmentDtString = item?.apptDate?.split("T");
            const eventStatus = item?.latestStatus
            var appointmentDate = new Date(appointmentDtString[0]);
            return ((appointmentDate >= startDate && appointmentDate <= endDate) && (this.eventStatus == eventStatus || this.eventStatus == 'ALL'));
          });
        } else if (this.eventStatus != undefined && this.eventStatus != null) {
          this.getEventMessages = this.getEventMessages.filter((item: any) => {
            const eventStatus = item?.latestStatus
            return ((this.eventStatus == eventStatus || this.eventStatus == 'ALL'));
          });
        }
        else {
          const arrSize = 5;
          this.getEventMessages = this.getEventMessages.slice(0, arrSize);
        }
        this.setIndex(0, this.getEventMessages?.length > 0 && this.getEventMessages[0]);
        this.loadSpinner = false;
      }
    },
      (error) => {
        this.loadSpinner = false;
      });
  }

  public checkValidationForCareJourney(): void {
    if (this.validationForm?.invalid) {
      this.validationForm?.markAllAsTouched();
      return;
    }
    this.errorValidation = false;
    const name = this.userInfoDetails?.lastName?.trim()?.toLowerCase();
    const enterName = this.validationForm?.value?.lastName?.trim()?.toLowerCase();
    if (name === enterName && this.userInfoDetails?.usrDateOfBirth?.includes(this.validationForm?.value?.dateofBirth)) {
      this.careJouneyDisplay = true;
      const sessionValid = {
        isValid: true,
        uniqueToken: this.userInfoDetails?.uniqueUserToken
      }
      sessionStorage?.setItem('checkValidationPatient', JSON?.stringify(sessionValid));
    } else {
      this.errorValidation = true;
    }
  }

  // date and time format
  public dateFormate(dateTime: string): string {
    // return this.httpService?.getAppointmentTime(dateTime);
    return this.httpService?.formatDateTimeWithoutAmPm(dateTime);
  }

  // get the all user by uniqueToken
  private getUserDataByUniqueToken(uniqueToken: string): void {
    const action = `userInfo/getUserByUserToken/${uniqueToken}`;
    this.loadSpinner = true;
    this.httpService?.makeGetRequest(action)?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.userInfoDetails = data?.responseObject || null;
        if (!this.userInfoDetails) {
          this.validationForm?.disable();
        }
      }
      this.loadSpinner = false;
    },
      (error) => {
        this.loadSpinner = false;
      });
  }

  public changeAppointmentDate(event: any): void {
    const filterObj = this.allAppointmentsWithMessages?.find((item: any) => item?.appointmentId === event?.apptId) || null;
    this.getEventMessages = filterObj?.messageList || [];
    this.getEventMessages?.sort((a: any, b: any) => {
      const bDate: any = new Date(b?.messageDate);
      const aDate: any = new Date(a?.messageDate);
      return bDate - aDate;
    });
    this.selectedIndex = undefined;
    if (!event) {
      return;
    }
    this.getAppointment(filterObj?.appointmentId);
    if (this.facilityId !== filterObj?.facilityId) {
      this.facilityId = filterObj?.facilityId;
      this.getProviderInfo(this.facilityId);
    }
  }

  public getAppointment(id: any, checkCancel?: boolean): void {
    let action: any = `appointment/registration/getAppointmentById?id=${id}`;
    this.loadSpinner = true;
    this.httpService.makeGetRequest(action)
      .subscribe((data: any) => {
        this.loadSpinner = false;
        if (data?.status === 'SUCCESS') {
          this.appointmentDetails = data?.responseObject || null;
          if (!checkCancel) {
            this.navigatetoReSchedule();
            // this.statusUpdate(this.appointmentDetails?.id, 're-schedule');
          }
          if (checkCancel) {
            this.canceltheRequest();
            // this.statusUpdate(id, this.cancelAppointmentForm?.value?.cancellationReason);
          }
        }
      },
        (error: any) => {
          this.loadSpinner = false;
        });
  }

  // Cancel Appointment API call
  public canceltheRequest(): void {
    if (this.cancelAppointmentForm?.controls['cancellationReason']?.invalid) {
      this.cancelAppointmentForm?.markAllAsTouched();
      return;
    };
    
    const cancelReason = this.cancelAppointmentForm?.controls['cancellationReason']?.value;
    let action = null;
    if(cancelReason === 'Other'){
       action = `appointment/registration/cancel?id=${this.appointmentDetails?.webToken}&reason=${this.cancelAppointmentForm?.controls['REASON_FOR_CANCEL_OTHER']?.value}`;
    
    }
    else {
      action = `appointment/registration/cancel?id=${this.appointmentDetails?.webToken}&reason=${this.cancelAppointmentForm?.controls['cancellationReason']?.value}`;
    
    }
    this.loadSpinner = true;
    this.httpService.makeRequestWithActionError(action, '')
      .subscribe((data: any) => {
        if (data?.status == 'SUCCESS') {
          this.notifyText = 'Appointment has been Cancelled Successfully..!'
          this.exceptionalModal = true;
          this.cancelAppointmentForm?.reset();
        } else {
          this.notifyText = data?.msg;
          this.exceptionalModal = true;
        }
        this.loadSpinner = false;
        this.cancelAppointmentModal = false;
      },
        error => {
          this.loadSpinner = false;
          this.cancelAppointmentModal = false;
          this.notifyText = error?.message || error?.msg || 'Something went wrong please try again later';
          this.exceptionalModal = true;
        });
  }

  public getProviderInfo(facilityId: any): void {
    let action: any = `providerInfo/getProviderInfo`;
    const params = {
      "facilityName": facilityId,
      "id": facilityId,
      "phoneNumber": "",
      "zipCode": 0
    }
    this.loadSpinner = true;
    this.httpService.makeRequestWithAction(action, params)
      .subscribe((data: any) => {
        if (data.status === 'SUCCESS') {
          const getObj = data?.resultList[0] || null;
          this.facilityDetails = { ...getObj };
        } else {

        }
        this.loadSpinner = false;
      },
        error => {
          this.loadSpinner = false;
        });
  }

  public async actionNavigate(event: any) {
    this.loadSpinner = true;
    const checkIs24hoursWindow = await this.httpService?.validate24HoursWindowCall(event?.appointmentId);
    if (checkIs24hoursWindow?.status === 'FAILED') {
      this.notifyText = checkIs24hoursWindow?.message;
      this.exceptionalModal = true;
      this.loadSpinner = false;
      return;
    }
    this.loadSpinner = false;
    // if (event?.qualifier === 'Reschedule' || event?.qualifier === 'Scheduled') {
    this.getAppointment(event?.appointmentId);
    // }
  }

  public async openCancelAppointment(event: any) {
    this.loadSpinner = true;
    const checkIs24hoursWindow = await this.httpService?.validate24HoursWindowCall(event?.appointmentId);
    if (checkIs24hoursWindow?.status === 'FAILED') {
      this.notifyText = checkIs24hoursWindow?.message;
      this.exceptionalModal = true;
      this.loadSpinner = false;
      return;
    }
    this.loadSpinner = false;
    this.cancelAppointmentModal = true;
    this.cancelAppointmentForm?.reset();
    this.cancelSelectedAppointment = event;
  }

  public cancelAppointment(): void {
    if (this.cancelAppointmentForm?.invalid) {
      this.cancelAppointmentForm?.markAllAsTouched();
      return;
    }
    this.getAppointment(this.cancelSelectedAppointment?.appointmentId, true);
  }

  private navigatetoReSchedule(): void {
    const obj = {
      rescheduleId: this.appointmentDetails?.webToken,
      isReschedule: true
    }
    sessionStorage.setItem('isReschedule', JSON.stringify(obj));
    this.router?.navigate([`${this.appointmentDetails?.facilityId}/scheduleappt`], {
      queryParams: {
        id: this.appointmentDetails?.webToken
      }
    });
  }

  public statusUpdate(appId: any, status: string): void {
    if (this.appointmentDetails?.appointmentStatus?.currentStatus === 'CANCELLED') {
      if (status === 're-schedule') {
        this.navigatetoReSchedule();
      }
      if (status !== 're-schedule') {
        this.cancelAppointmentModal = false;
        this.notifyText = 'The appointment has already been Cancelled..!'
        this.exceptionalModal = true;
      }
      return;
    };
    let action: any = 'appointment/registration/updateStatus';
    let params: any = {
      "apiName": "",
      "appointmentId": appId,
      "buttonClick": 'cancel',
      "cancellationReason": status,
      "email": "",
      "facilityID": this.appointmentDetails?.facilityId
    }
    this.loadSpinner = true;
    this.httpService.makePostRequest(action, params)
      .subscribe((data: any) => {
        if (data?.status == 'SUCCESS') {
          if (status === 're-schedule') {
            this.navigatetoReSchedule();
          }
          if (status !== 're-schedule') {
            this.cancelAppointmentModal = false;
            this.notifyText = 'Your Appointment has cancelled successfully...!'
            this.exceptionalModal = true;
          }
        } else {
          this.exceptionalModal = true;
          this.notifyText = data?.message || 'Something went wrong please try again later';
        }
        this.loadSpinner = false;
      },
        error => {
          this.exceptionalModal = true;
          this.notifyText = error?.message || 'Something went wrong please try again later';
          this.loadSpinner = false;
        });
  }

  public calculateAge(dob: string): any {
    return this.httpService?.calculateAge(dob);
  }

  // click to add actve class
  setIndex(index: number, event: any) {
    this.selectedIndex = index;
    this.selectedMessage = event?.messageList || [];
    console.log("this.selectedMessage***********",this.selectedMessage);
  }

  ngOnDestroy(): void {
    this.getRouterParams?.unsubscribe();
    // sessionStorage.removeItem('checkValidationPatient');
  }

  public getevents() {
    // if (this.filterByDateForm?.invalid) {
    //   this.filterByDateForm?.markAllAsTouched();
    //   return;
    // }

    this.eventStartDate = this.filterByDateForm?.controls['startDate'].value ? this.filterByDateForm?.controls['startDate'].value : null;
    this.eventEndDate = this.filterByDateForm?.controls['endDate'].value ? this.filterByDateForm?.controls['endDate'].value : null;
    this.eventStatus = this.filterByDateForm?.controls['eventStatus'].value;
    if (this.eventStartDate == null && this.eventEndDate != null) {
      this.requiredStartDate = true;
      this.requiredEndDate = false;
      return;
    } else if (this.eventStartDate != null && this.eventEndDate == null) {
      this.requiredStartDate = false;
      this.requiredEndDate = true;
      return;
    }
    this.getRouterParams = this.activatedRoute?.params?.subscribe(params => {
      const uniqueToken = params['uniqueToken'];
      if (sessionStorage.getItem('checkValidationPatient')) {
        const valid = JSON.parse(sessionStorage.getItem('checkValidationPatient') || '{}') || null;
        if (valid?.isValid && uniqueToken === valid?.uniqueToken) {
          this.careJouneyDisplay = true;
        } else {
          sessionStorage.removeItem('checkValidationPatient');
        }
      }
      if (uniqueToken || this.userUniqueToken) {
        this.getUserDataByUniqueToken(uniqueToken || this.userUniqueToken);
        this.getAllthePatientOutreachMessages(uniqueToken || this.userUniqueToken);

      }
    });
  }

  public resetevents() {
    this.eventStartDate = null;
    this.eventEndDate = null;
    this.requiredStartDate = false;
    this.requiredEndDate = false;
    this.filterByDateForm?.reset();
    this.eventStatus = 'ALL';
    this.filterByDateForm.patchValue({
      eventStatus: 'ALL'

    });

    this.getRouterParams = this.activatedRoute?.params?.subscribe(params => {
      const uniqueToken = params['uniqueToken'];
      if (sessionStorage.getItem('checkValidationPatient')) {
        const valid = JSON.parse(sessionStorage.getItem('checkValidationPatient') || '{}') || null;
        if (valid?.isValid && uniqueToken === valid?.uniqueToken) {
          this.careJouneyDisplay = true;
        } else {
          sessionStorage.removeItem('checkValidationPatient');
        }
      }
      if (uniqueToken || this.userUniqueToken) {
        this.getUserDataByUniqueToken(uniqueToken || this.userUniqueToken);
        this.getAllthePatientOutreachMessages(uniqueToken || this.userUniqueToken);

      }
    });
  }


  public validateEndDate() {

    this.filterByDateForm.controls['endDate'].setValue('');
    var dateStr = this.filterByDateForm?.controls['startDate'].value;
    var days = 1;
    var endDate = new Date(new Date(dateStr).setDate(new Date(dateStr).getDate() + days));

    this.minEndDate = this.httpService?.convertDate(endDate);
    this.requiredStartDate = false;
  }
  public validateDateVal() {
    var dateStr = this.filterByDateForm?.controls['endDate'].value;
    if (dateStr) {
      this.requiredEndDate = false;
    }
  }

  // to check is it hyperlinl or not
  public isButtonLinkShown(buttonLink: any): boolean {
    if (buttonLink?.indexOf("http://") === 0 || buttonLink?.indexOf("https://") === 0) {
      return false;
    }
    return true;
  }

  public openPatientPreference() {
    this.patientPreferenceModal = true;
  }

  public cancelReasonSection(event: any) {
    if (event.value === "Other") {
      this.isOtherReason = true;
       this.cancelAppointmentForm.addControl('REASON_FOR_CANCEL_OTHER', new UntypedFormControl(null, Validators.required));
    }
    else {
      this.isOtherReason = false;
      this.cancelAppointmentForm.removeControl('REASON_FOR_CANCEL_OTHER');

    }
  }
  public getDoc(docData:any){
    console.log("docData*******",docData);
    this.loadSpinner = true;
    this.showModalCancel = true;
    //this.s3Key = docData?.s3Key;
    const action = `record/downloadDocument?s3Key=${docData?.s3Key}&s3Path=${docData?.s3Path}`;
    this.httpService?.downloadImage(action)?.subscribe((data: any) => {
      const file = new Blob([data], { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file);
      const iframeEle = document.getElementById('lab-rad-medical-record-pdf') as HTMLElement;
      iframeEle.setAttribute('src', fileURL);
      setTimeout(()=>{
        this.loadSpinner = false;
      }, 3000);
      
    },
      (error:any) => {
        this.loadSpinner = false;
        console.log(error);
      });
  }
  public closeModel(){
    this.showModalCancel = false;
  }

}

