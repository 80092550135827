<section class="page-hero footer-sections">
    <div class="container">
        <h3 class="terms_title">Terms of Service</h3>
        <h6>Last Updated: January 6, 2022</h6>

        <div class="terms_content">
            <p>These Terms of Service ("Terms") govern your access and use of the online service made available to you
                by CareEco
                Corporation, doing business as CareEco ("CareEco," "we," "us," or "our") at a designated web address
                ("Service").</p>
            <p>By accessing or using the Service, you ("you") agree that you have read and understand these Terms and
                our
                Privacy
                Policy. If you do not agree with these Terms or our Privacy Policy, do not access or use the Service.
            </p>
            <p>We reserve the right to modify these Terms at any time. All changes will be effective immediately upon
                posting
                to the
                Service and, by accessing or using the Service after changes are posted, you agree to those changes.
                Material
                changes
                will be conspicuously posted on the Service or otherwise communicated to you.</p>
            <p><b>ARBITRATION NOTICE:</b> These Terms contain a binding arbitration provision. You agree that, except
                for
                certain
                types of disputes described in the arbitration provision below, all disputes between you and CareEco
                will be
                resolved
                by mandatory binding arbitration and you waive any right to participate in a class action lawsuit or
                class-wide
                arbitration.</p>
            <p><b>Privacy Policy.</b> We may collect certain information from your use of the Service as described in
                our
                Privacy
                Policy, which is incorporated into these Terms by this reference. Your use of the Service constitutes
                your
                consent to
                the information collection, use, and sharing as described in our Privacy Policy.</p>
            <div class="block">
                <strong>
                    <h5>License:</h5>
                </strong>
                <p>Provided you comply with these Terms, CareEco grants you a limited, revocable, nontransferable,
                    non-assignable, nonexclusive license to access and use the Service solely for your own personal and
                    noncommercial purposes.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Intellectual Property Rights:</h5>
                </strong>
                <p>The Service, including its text, audio, video, graphics, charts, photographs, interfaces, icons,
                    other
                    content,
                    software, computer code, data, trademarks, logos, slogans, names of products and services,
                    documentation,
                    other
                    components, and the design, selection, and arrangement of content is exclusively the property of
                    CareEco or,
                    as
                    applicable, its suppliers and licensors, and is protected by copyright, trademark, and other
                    intellectual
                    property
                    laws. Any unauthorized use of any trademarks, trade dress, copyrighted materials, or any other
                    intellectual
                    property
                    belonging to CareEco or any third party is strictly prohibited and may be prosecuted to the fullest
                    extent of
                    the law.
                    The Service may contain references to third-party marks and copies of third-party copyrighted
                    materials, which
                    are the
                    property of their respective owners. Except as expressly described in these Terms, no licenses or
                    other
                    rights,
                    express or implied, are granted by CareEco to you under any patent, copyright, trademark, trade
                    secret, or
                    other
                    intellectual property right of CareEco.
                </p>
                <p>Copyright © 2023 CareEco Corporation. All rights reserved.</p>
            </div>

            <div class="block">
                <strong>
                    <h5>Restrictions on Your Use of the Service; Compliance with Laws:</h5>
                </strong>
                <ul>
                    <li>You may download and print one copy of the Service's visible content for your personal and
                        noncommercial
                        use,
                        provided you do not modify or delete any copyright, trademark, or other proprietary notices.
                    </li>
                    <li>You may not otherwise copy, reproduce, display, duplicate, sell, publish, post, license, rent,
                        distribute,
                        modify,
                        translate, adapt, reverse-engineer, or create derivative works of the Service or any part of the
                        Service
                        without the
                        prior written consent of CareEco. You may not use the Service for unlawful purposes. You may not
                        access,
                        use, or copy
                        any portion of the Service or its content using indexing agents, spiders, scrapers, bots, web
                        crawlers, or
                        other
                        automated devices or mechanisms. You may not use the Service to post or transmit any material
                        that contains
                        any
                        viruses, Trojan horses, worms, time bombs, cancelbots, malware, adware or other computer
                        programming
                        routines that may
                        damage, interfere with, surreptitiously intercept, or expropriate any system, data, or personal
                        information.
                        User
                        activities that aim to render the Service or associated services inoperable or to make their use
                        more
                        difficult are
                        prohibited. You may not submit inaccurate information via the Service, commit fraud or falsify
                        information
                        in
                        connection with your use of the Service, or act maliciously against the business interests or
                        reputation of
                        CareEco.
                    </li>
                    <li>You are responsible for complying with all local, state, and federal laws and regulations that
                        apply to
                        your use of
                        the Service.</li>
                </ul>
            </div>

            <div class="block">
                <strong>
                    <h5>Your Account:</h5>
                </strong>
                <p>While you can request appointments with health care providers through the Service without creating a
                    CareEco
                    account, you will be required to create an online CareEco account in order to save your profile and
                    to take
                    advantage of other features of the Service. You will be required to submit certain information in
                    order to set
                    up your account, including your mobile telephone number, which will be used to log in to your
                    account via
                    two-factor authentication with one-time PINs (“Personal Identification Number”) provided by CareEco
                    to you via
                    text message. You are responsible for maintaining the confidentiality of any information you use in
                    connection
                    with the Service, including those PINs. In connection with your access to and use of the Service and
                    that of
                    any person authorized by you to access and use the Service on your behalf, you are responsible for
                    complying
                    with all applicable laws, regulations and policies of all relevant jurisdictions, including all
                    applicable
                    local rules regarding online conduct. You warrant that all information you provide to CareEco in
                    connection
                    with your access to and use of the Service is true, accurate, and complete to the best of your
                    knowledge and
                    belief. If you submit the personal information of any person other than yourself in setting up
                    profiles in
                    your account or otherwise in using the Service, you warrant that you have that person's permission
                    to provide
                    CareEco with that information. CareEco reserves the right, in its sole discretion, to terminate your
                    use of
                    the Service for any reason without notice or liability, including the unauthorized use of your
                    mobile
                    telephone and the PINs provided by CareEco and your breach of these Terms.</p>
            </div>

            <div class="block">
                <strong>
                    <h5>Text Messages:</h5>
                </strong>
                <p>Text messages from CareEco. While it is not necessary to create an online CareEco account to search
                    for or
                    request
                    appointments with health care providers, you will be required to create an online CareEco account
                    and to
                    provide us
                    with your mobile telephone number in order to save your profile and take advantage of other Service
                    features.
                    If you
                    create an online CareEco account, CareEco will text you a PIN each time you log in to your account
                    for
                    security
                    reasons, which PIN will be your password for that session. You may choose to stop receiving text
                    messages as
                    described
                    below but opting out of receiving text messages will impact your ability to access your online
                    CareEco
                    account.
                    CareEco will send you text messages pertaining to appointments that you have booked via the Service
                    (e.g.,
                    appointment
                    reminders and live waitlist updates) and any follow-up appointments, whether or not you have an
                    online CareEco
                    account. We may also send you invitations via text message to review a health care provider that you
                    have
                    visited.
                    Message and data rates may apply. To stop receiving messages at any time, reply "STOP" to any text
                    message you
                    receive.</p>
                <p>Text and email messages from your health care providers. Health care providers using the Service may
                    contact
                    you via
                    text messaging or email to remind you of an appointment and to provide general
                    health/medication/insurance
                    reminders
                    and information. We will ask you to consent to receive messages via text message before you receive
                    any
                    messages from
                    a health care provider. By consenting to receive this information via text message or email, you
                    understand
                    there may
                    be some level of risk that the information in the email or text message could be read by a third
                    party. Health
                    care
                    providers have agreed not to send personal detailed health information via text or email, such as
                    the reason
                    for
                    visit. By the same token, patients are advised not to share personal health information via text
                    message.</p>
                <p>CareEco and health care providers using the Service to send messages are not responsible for
                    unauthorized
                    access of
                    protected health information while in transmission to the individual based on the individual's
                    request.
                    Furthermore,
                    CareEco and health care providers are not responsible for safeguarding information once delivered to
                    the
                    patient. By
                    consenting, you agree to receive insecure text messages at your given phone number, or any number
                    forwarded or
                    transferred to that number or insecure emails. The consent to receive text messages or emails will
                    apply to
                    all future
                    appointment reminders/feedback/health/medication information unless you request health care provider
                    stop
                    sending the
                    messages by replying "STOP".</p>
                <p>If you are not the intended recipient to any text messages or emails sent by CareEco or the
                    healthcare
                    providers
                    using the Service, you are hereby notified that any review, dissemination, distribution, or
                    duplication of
                    this
                    communication is strictly prohibited. Also, if you are not the intended recipient to text messages
                    please
                    reply "STOP"
                    to any text messages you receive.</p>
            </div>


            <div class="block">
                <strong>
                    <h5>Termination:</h5>
                </strong>
                <p>CareEco may terminate these Terms without notice if you breach these Terms, act in an abusive manner,
                    or act
                    in a
                    manner inconsistent with local, state, or federal laws or regulations. Upon any termination of these
                    Terms you
                    must
                    promptly cease accessing and using the Service.</p>
            </div>


            <div class="block">
                <strong>
                    <h5>Appointment Availability:</h5>
                </strong>
                <p>The availability of health care provider appointments that you can request through the Service may
                    change at
                    any
                    time without notice to you. Health care provider locations remain valid while they are listed on the
                    Service.
                    Health
                    care provider services may not be available in your area.</p>
            </div>

            <div class="block">
                <strong>
                    <h5>Content you submit or create:</h5>
                </strong>
                <p>You will have the opportunity to submit reviews and feedback regarding your experiences with
                    Healthcare
                    Providers
                    you find through the Services, to submit inquiries concerning possible medical needs and to
                    participate in the
                    other
                    interactive or community features of the Site (collectively, "Posted Information"). It is important
                    that you
                    act
                    responsibly when providing Posted Information. We reserves the right to investigate and, at our
                    discretion,
                    take
                    appropriate legal action against anyone who violates these Terms of Use, including without
                    limitation,
                    removing any
                    offending communication from the Services and terminating the account of such violators or blocking
                    your use
                    of the
                    Services. By posting Posted Information through the Services, you agree to and hereby do grant, and
                    you
                    represent
                    and warrant that you have the right to grant, to CareEco and its contractors an irrevocable,
                    perpetual,
                    royalty-free, fully sublicensable, fully paid up, worldwide license to use, copy, publicly perform,
                    digitally
                    perform, publicly display, and distribute such Posted Information and to adapt, edit, translate,
                    prepare
                    derivative
                    works of, or incorporate into other works, such Posted Information.</p>
                <br />
                <p>NO WARRANTY. THE SERVICE IS PROVIDED "AS IS," "AS AVAILABLE," AND WITHOUT ANY WARRANTY OF ANY KIND.
                    CAREECO
                    MAKES
                    COMMERCIALLY REASONABLE EFFORTS TO ENSURE THAT ALL DATA, INFORMATION, AND MATERIAL ON THE SERVICE IS
                    ACCURATE
                    AND
                    RELIABLE, BUT ACCURACY CANNOT BE GUARANTEED. CAREECO DOES NOT GUARANTEE THE QUALITY, COMPLETENESS,
                    TIMELINESS,
                    OR
                    AVAILABILITY OF THE SERVICE. CAREECO DOES NOT WARRANT THAT THE SERVICE WILL BE UNINTERRUPTED OR
                    ERROR FREE,
                    THAT ANY
                    DEFECTS IN THE SERVICE WILL BE CORRECTED, OR THAT THE SERVICE OR THE SERVERS THAT MAKE THE SERVICE
                    AVAILABLE
                    ARE FREE
                    OF VIRUSES OR OTHER HARMFUL CONDITIONS OR COMPONENTS. CAREECO IS NOT RESPONSIBLE FOR ANY
                    TYPOGRAPHICAL ERRORS
                    ON THE
                    SERVICE.</p>
                <p>CAREECO IS NOT A HEALTH CARE PROVIDER AND IS NOT A COVERED ENTITY UNDER THE HEALTH INSURANCE
                    PORTABILITY AND
                    ACCOUNTABILITY ACT OF 1996 AND ANY REGULATIONS PROMULGATED THEREUNDER ("HIPAA"). TO THE EXTENT
                    CAREECO
                    CREATES,
                    RECEIVES, MAINTAINS, OR TRANSMITS PROTECTED HEALTH INFORMATION, AS THAT TERM IS DEFINED UNDER HIPAA,
                    CAREECO
                    WILL ONLY
                    ACCESS, USE, OR DISCLOSE PROTECTED HEALTH INFORMATION AS PERMITTED BY APPLICABLE FEDERAL AND STATE
                    LAWS,
                    INCLUDING
                    HIPAA, AND PURSUANT TO ANY CONTRACTUAL OBLIGATIONS CAREECO HAS WITH HEALTH CARE PROVIDERS. CAREECO
                    IS NOT
                    RESPONSIBLE
                    FOR THE ACTIVITIES OR OMISSIONS OF HEALTH CARE PROVIDERS AS IT RELATES TO HOW THEY RETAIN, SECURE,
                    USE, OR
                    DISCLOSE
                    PROTECTED HEALTH INFORMATION. WHILE CAREECO AIMS TO PROVIDE YOU WITH ONLY ACCURATE AND UP-TO-DATE
                    INFORMATION
                    ABOUT
                    AVAILABLE HEALTH CARE PROVIDERS IN YOUR AREA, CAREECO DISCLAIMS ANY IMPLIED WARRANTY OR
                    REPRESENTATION ABOUT
                    THE
                    ACCURACY OR COMPLETENESS OF HEALTH CARE PROVIDER INFORMATION ON THE SERVICE. </p>
                <p>ADDITIONALLY, THE MENTION OF A PARTICULAR HEALTH CARE PROVIDER ON THE SERVICE DOES NOT CONSTITUTE OR
                    IMPLY A
                    RECOMMENDATION OR ENDORSEMENT BY CAREECO, AND THE RATINGS FOR EACH HEALTH CARE PROVIDER ARE
                    AGGREGATED AND
                    AVERAGED
                    THROUGH PATIENT REVIEWS/FEEDBACK AND DO NOT REFLECT THE OPINIONS OF CAREECO. TO THE MAXIMUM EXTENT
                    PERMITTED
                    BY LAW,
                    CAREECO EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND WITH RESPECT TO THE SERVICE, INCLUDING THOSE
                    REGARDING
                    AVAILABILITY, QUALITY, ACCURACY, FITNESS FOR ANY USE OR PURPOSE, COMPATIBILITY WITH ANY STANDARDS OR
                    USER
                    REQUIREMENTS, TITLE, AND NONINFRINGEMENT. CAREECO HAS NO RESPONSIBILITY FOR THE TIMELINESS,
                    DELETION,
                    MISDELIVERY, OR
                    FAILURE TO STORE ANY USER COMMUNICATION.</p>
                <p>YOUR USE OF THE SERVICE IS AT YOUR OWN RISK AND YOU, ALONE, ARE RESPONSIBLE FOR ANY DAMAGE TO YOUR
                    COMPUTER
                    HARDWARE,
                    SOFTWARE, SYSTEMS, AND NETWORKS, ANY LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY INFORMATION
                    FROM THE
                    SERVICE,
                    AND FOR ANY OTHER DAMAGE THAT MAY BE INCURRED. WE MAKE NO REPRESENTATION THAT THE SERVICE IS
                    APPROPRIATE OR
                    AVAILABLE
                    FOR USE IN LOCATIONS OTHER THAN THE UNITED STATES. IF YOU CHOOSE TO ACCESS THE SERVICE FROM
                    LOCATIONS OTHER
                    THAN THE
                    UNITED STATES, YOU DO SO AT YOUR OWN RISK AND YOU ARE RESPONSIBLE FOR COMPLYING WITH APPLICABLE LAWS
                    AND
                    REGULATIONS.
                    NO ADVICE OR INFORMATION, ORAL OR WRITTEN, OBTAINED BY YOU FROM CAREECO OR IN ANY MANNER FROM THE
                    SERVICE
                    CREATES ANY
                    WARRANTY.</p>
                <p>LIMITATION OF LIABILITY. TO THE EXTENT PERMITTED BY LAW, IN NO EVENT WILL CAREECO, ITS AFFILIATES, OR
                    ITS OR
                    THEIR
                    RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, REPRESENTATIVES, CONSULTANTS, AGENTS, SUPPLIERS, OR
                    LICENSORS BE
                    LIABLE FOR
                    ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL LOSS OR DAMAGE, OR
                    ANY OTHER
                    LOSS OR
                    DAMAGE OF ANY KIND, ARISING OUT OF OR IN CONNECTION WITH THE SERVICE OR YOUR USE OF OR INABILITY TO
                    USE THE
                    SERVICE
                    (INCLUDING THE INPUT OF PERSONALLY IDENTIFIABLE AND OTHER INFORMATION INTO THE SERVICE), WHETHER THE
                    CLAIM IS
                    BASED IN
                    CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, WARRANTY, OR OTHERWISE, AND EVEN IF CAREECO
                    HAS
                    EXPRESS
                    KNOWLEDGE OF THE POSSIBILITY OF THE LOSS OR DAMAGE. YOUR SOLE AND EXCLUSIVE REMEDY IS TO STOP
                    ACCESSING AND
                    USING THE
                    SERVICE. WITHOUT LIMITING THE FOREGOING, IN NO EVENT WILL CAREECO'S LIABILITY TO YOU EXCEED $100,
                    EVEN IF THIS
                    REMEDY
                    FAILS OF ITS ESSENTIAL PURPOSE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
                    CERTAIN
                    DAMAGES, SO
                    SOME OF THE ABOVE EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU.</p>
            </div>

            <div class="block">
                <strong>
                    <h5>Indemnification:</h5>
                </strong>
                <p>You will indemnify, defend, and hold harmless CareEco, its affiliates, and its and their respective
                    directors, officers, employees, representatives, consultants, agents, suppliers, and licensors from
                    and
                    against all losses, claims, liabilities, demands, complaints, actions, damages, judgments,
                    settlements, fines,
                    penalties, damages, expenses, and costs (including reasonable attorneys' fees) that arise out of or
                    in
                    connection with your access to or use of the Service, your misuse of any material, data, or other
                    information
                    downloaded or otherwise obtained from the Service, your appointment requests made through the
                    Service, you
                    violation of applicable laws or regulations, or your violation of these Terms. We reserve, and you
                    grant to
                    us, the exclusive right to assume the defense and control of any matter subject to indemnification
                    by you.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Third-Party Web Sites:</h5>
                </strong>
                <p>The Service may link to, or be linked to, web sites not maintained or controlled by CareEco. Those
                    links are
                    provided as a convenience and CareEco is not responsible for examining or evaluating the content or
                    accuracy
                    of, and does not warrant or endorse, any third-party web site or any products or services made
                    available
                    through those web sites. Please take care when leaving the Service to visit a third-party web site.
                    You should
                    read the terms of use and privacy policy for each web site that you visit.</p>
            </div>

            <div class="block">
                <strong>
                    <h5>Force Majeure:</h5>
                </strong>
                <p>CareEco will not be liable to you for any delay or other failure to perform under these Terms that is
                    due to
                    causes beyond CareEco's control, including acts of God, acts of a public enemy, terrorism, civil
                    disorders,
                    acts of the United States of America or any state, territory or political division thereof, fires,
                    floods,
                    earthquakes, blizzards, and other extraordinary elements of nature.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Audit:</h5>
                </strong>
                <p>CareEco and its designated representatives may, at their expense, audit, examine, and make copies of
                    data,
                    documents, information, and other records in your possession or control that relate to or concern
                    the Service
                    in order to determine your compliance with these Terms.</p>
            </div>

            <div class="block">
                <strong>
                    <h5>Feedback:</h5>
                </strong>
                <p>CareEco welcomes comments regarding the Service. If you submit comments or feedback to us regarding
                    the
                    Service, they will not be considered or maintained as confidential. We may use any comments and
                    feedback that
                    you send us in our discretion and without attribution or compensation to you.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Governing Law:</h5>
                </strong>
                <p>These Terms are governed by the laws of the state of Delaware, without regards for its conflict of
                    law
                    principles. Venue is exclusively in the state or federal courts, as applicable, located in San
                    Francisco,
                    California, with respect to any dispute arising under these Terms unless otherwise determined by
                    CareEco in
                    its sole discretion and the parties expressly agree to the exclusive jurisdiction of those courts.
                </p>
            </div>
            <div class="block">
                <strong>
                    <h5>Arbitration Agreement; Class Waiver; Waiver of Trial by Jury:</h5>
                </strong>
                <p>Please read the following paragraphs carefully because they require you to arbitrate disputes with
                    CareEco
                    and limit the manner in which you can seek relief from us.</p>
                <p><b><i>Applicability.</i></b>Any dispute, claim, or controversy arising out of or relating to these
                    Terms or
                    the breach, termination, enforcement, interpretation, or validity of these Terms (together,
                    "Disputes") will
                    be resolved by binding arbitration on an individual basis as described in these Terms (this
                    "Arbitration
                    Agreement"). But, in the event of any actual, alleged, or threatened violation of confidentiality or
                    violation
                    of the CareEco's intellectual property or other proprietary rights, CareEco may immediately resort
                    to court
                    proceedings in a court of competent jurisdiction in order to seek immediate injunctive relief
                    without posting
                    bond, proving damages, or meeting any similar requirement. Any institution of any action for
                    injunctive relief
                    will not constitute a waiver of the right or obligation of either party to submit any claim seeking
                    relief
                    other than injunctive relief to arbitration. This Arbitration Agreement applies to you; CareEco;
                    CareEco's
                    affiliates; respective directors, officers, employees, owners, agents, predecessors in interest,
                    successors in
                    interest, and assigns; authorized and unauthorized users or beneficiaries of the Service; and any
                    third-party
                    beneficiaries.</p>
                <p><b><i>Arbitrator.</i></b>Arbitration proceedings will be administered by the American Arbitration
                    Association
                    ("AAA") before an arbitrator chosen by agreement of the parties. If the parties fail to reach
                    agreement on the
                    arbitrator within 30 days after service of the demand for arbitration, the arbitrator will be chosen
                    by the
                    AAA. The decision of the arbitrator will be final and binding. Any final award or judgment may be
                    filed and
                    enforced in any court of competent jurisdiction. The prevailing party will bear all costs of
                    arbitration,
                    including all attorneys' fees. Any arbitration proceeding may not be consolidated or joined with any
                    other
                    proceeding and will not proceed as a class action. The parties understand that they would have had a
                    right or
                    opportunity to litigate disputes through a court, to have a judge or jury decide their case, and to
                    participate in a class action or other proceeding involving multiple claimants, but they have
                    instead chosen
                    to have all Disputes decided through individual arbitration.</p>
                <p><b><i>Place.</i></b>The place of arbitration will be San Francisco, California, unless otherwise
                    agreed to in
                    writing by all parties to the arbitration. This Arbitration Agreement evidences a transaction
                    involving
                    interstate commerce and the Federal Arbitration Act, 9 U.S.C. Sections 1-16, will govern the
                    interpretation,
                    enforcement, and proceedings pursuant to this Arbitration Agreement. Any and all actions taken under
                    this
                    Arbitration Agreement, including all filings, orders, judgments, and awards made in any arbitration
                    proceeding, are confidential and may not be disclosed to any third party.</p>
                <p><b><i>Time Limitation on Claims.</i></b>Arbitration proceedings must be initiated within one year
                    after any
                    Dispute arises; otherwise, the Dispute is permanently barred.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Assignment:</h5>
                </strong>
                <p>We may assign our rights and delegate our duties under these Terms at any time to any party without
                    notice to
                    you. You may not assign these Terms without our prior written consent.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Third-Party Beneficiaries:</h5>
                </strong>
                <p>These Terms do not confer any rights, remedies, or benefits upon any person other than you and
                    CareEco,
                    except that our affiliates are third-party beneficiaries of these Terms.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Survival:</h5>
                </strong>
                <p>Any provisions of these Terms that are intended to survive termination (including any provisions
                    regarding
                    indemnification, limitation of our liability, or arbitration) will continue in effect beyond any
                    termination
                    of these Terms or of your access to or use of the Service.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Interpretation:</h5>
                </strong>
                <p>These Terms, including our Privacy Policy and our web site's Terms of Use, are the entire agreement
                    between
                    you and CareEco with respect to your access to and use of the Service. CareEco's failure to enforce
                    any
                    provision in these Terms will not constitute a waiver of that provision or any other provision. Any
                    waiver of
                    any provision of these Terms will be effective only if in writing and signed by CareEco. If any
                    provision of
                    these Terms is held invalid, void, or unenforceable, that provision will be considered severable
                    from the
                    remaining provisions and the remaining provisions will remain in full force and effect. The headings
                    in these
                    Terms are for convenience only and do not affect the interpretation of these Terms. These Terms will
                    inure to
                    the benefit of CareEco's successors and assigns.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Notices:</h5>
                </strong>
                <p>All notices, consents, requests, demands, and other communications permitted or required to be given
                    under
                    these Terms must be in writing and addressed to the recipient and will be deemed given: upon
                    delivery if
                    personally delivered with fees prepaid, including by a recognized courier service; upon receipt if
                    delivered
                    by certified or registered United States mail, postage prepaid and return receipt requested, as
                    indicated by
                    the date on the signed receipt; or, where you are the recipient, by electronic mail.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Electronic Communications:</h5>
                </strong>
                <p>These Terms and any other documentation, agreements, notices, or communications between you and
                    CareEco may
                    be provided to you electronically to the extent permissible by law. Please print or otherwise save a
                    copy of
                    all documentation, agreements, notices, and other communications for your reference.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Healthcare Services:</h5>
                </strong>
                <p>None of the Site content (other than information you receive from Healthcare Professionals) should be
                    considered medical advice or an endorsement, representation or warranty that any particular
                    treatment or
                    medication is safe or effective, for you. CareEco. does not practice medicine or any other licensed
                    profession, and does not interfere with the practice of medicine or any other licensed profession by
                    Healthcare Professionals, each of whom is responsible for his or her services and compliance with
                    the
                    requirements applicable to his or her profession and license. Neither CareEco nor any third parties
                    who
                    promote the Services or provide you with a link to the Services shall be liable for any professional
                    advice
                    you obtain from a Healthcare Professional via the Services.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Contact Us:</h5>
                </strong>
                <p>Please direct any questions and concerns regarding these Terms to us by email at support@levlivet.net
                    or by
                    mail at CareEco, 320 Seven Springs way, Brentwood, TN 37027.</p>
            </div>
        </div>
    </div>

</section>