<div class="pcoded-main-container">
    <div [formGroup]="invoiceForm" class="row" style="margin-right: 0px !important; margin-left: 0px !important;">
        <!-- <div class="col-md-2"></div> -->
        <!-- <div class="col-md-2"></div> -->
        <div class="col-md-12" style="padding-left: 0px; padding-right: 0px;">
            <div class="pcoded-content">
                <div class="row breadcrumb-margin">
                    <div class="col-md-12">
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a><span class="material-symbols-outlined home-icon">
                                        home
                                    </span></a>
                            </li>
                            <li class="breadcrumb-item" style="cursor: pointer"
                                [routerLink]="['/invoice/invoiceBoard']">
                                <a>Invoice Board</a>
                            </li>
                            <li class="breadcrumb-item">
                                <a>Create Invoice</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="card">
                    <div class="card-body">
                        <!-- toggle -->
                        <!-- <div class="row toggle-switch">
                            <div class="col-md-12">
                                <label class="sub-app">Submitter</label>
                                <label class="switch">
                                    <input type="checkbox" checked>
                                    <span class="slider round"></span>
                                </label>
                                <label class="sub-app">Approver</label>
                            </div>
                        </div> -->

                        <!-- Organization Info -->
                        <div class="row invoce-head">
                            <div class="col-md-12">
                                <h5>
                                    Organization Info
                                </h5>
                                <hr>
                            </div>
                        </div>

                        <div class="row demographic-form">
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                <div class="form-group">
                                    <input type="text" id="createInvoice_orgName" class="form-control input-text" placeholder="Organization Name"
                                        formControlName="organizationName" readonly>
                                    <label class="form-label">Organization Name<span style="color: red;">*</span></label>
                                    <span class="required"
                                        *ngIf="invoiceForm!.controls['organizationName']?.hasError('required') && invoiceForm!.controls['organizationName']?.touched">Organization
                                        Name should be required</span>

                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                <div class="form-group">
                                    <input type="text" id="createInvoice_submitterName" class="form-control input-text" placeholder="Submitter Name"
                                        formControlName="submitterName"
                                        [readonly]="status !== 'OPEN' && !duplicateInvoice">
                                    <label class="form-label">Submitter Name<span style="color: red;">*</span></label>
                                    <span class="required"
                                        *ngIf="invoiceForm!.controls['submitterName']?.hasError('required') && invoiceForm!.controls['submitterName']?.touched">Submitter
                                        Name is required.</span>

                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                <div class="form-group">
                                    <input type="text" id="createInvoice_invoiceNo" class="form-control input-text" placeholder="Invoice #"
                                        formControlName="invoiceId" [readonly]="status !== 'OPEN' && !duplicateInvoice">
                                    <label class="form-label">Invoice #</label>
                                    <span class="required"
                                        *ngIf="invoiceForm!.controls['invoiceId']?.hasError('required') && invoiceForm!.controls['invoiceId']?.touched">Invoice
                                        Number is required.</span>

                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                <div class="form-group">
                                    <input type="text" id="createInvoice_invoiceName" class="form-control input-text" placeholder="Invoice Name"
                                        formControlName="invoiceName"
                                        [readonly]="status !== 'OPEN' && !duplicateInvoice">
                                    <label class="form-label">Invoice Name</label>
                                    <span class="required"
                                        *ngIf="invoiceForm!.controls['invoiceName']?.hasError('required') && invoiceForm!.controls['invoiceName']?.touched">Invoice
                                        Name is required.</span>

                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                <div class="form-group">
                                    <input type="text" id="createInvoice_refId" class="form-control input-text" placeholder="Reference ID"
                                        formControlName="referenceId" readonly>
                                    <label class="form-label">Reference ID</label>
                                    <!-- <span class="required"
                                        *ngIf="invoiceForm!.controls['referenceId']?.hasError('required') && invoiceForm!.controls['referenceId']?.touched">Invoice
                                        ID should be required</span> -->

                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                <div class="form-group">
                                    <input type="text" id="createInvoice_referralNo" class="form-control input-text" placeholder="NA"
                                        formControlName="referralId"
                                        [readonly]="status !== 'OPEN' && !duplicateInvoice">
                                    <label class="form-label">Referral #</label>
                                    <span class="required"
                                        *ngIf="invoiceForm!.controls['referralId']?.hasError('required') && invoiceForm!.controls['referralId']?.touched">
                                        Referral ID is required</span>

                                </div>
                            </div>
                        </div>
                        <!-- Invoice -->
                        <div class="row invoce-head">
                            <div class="col-md-2">
                                <h5>
                                    Services
                                </h5>
                            </div>
                            <div class="col-md-8"></div>
                            <div class="col-md-2 new-patient">
                                <button [disabled]="status !== 'OPEN' && !duplicateInvoice" id="createInvoice_addNewPatient"
                                    class="btn btn-primary add-new-payment" (click)="addNewPatient()"> + Add New
                                    Patient</button>
                            </div>

                        </div>
                        <hr>
                        <!-- New Invoice Button -->

                        <!-- <button [disabled]="status !== 'OPEN' && !duplicateInvoice"
                            class="btn btn-primary add-new-payment" (click)="addNewPatient()"> + Add New
                            Patient</button> -->

                        <!-- Table -->

                        <div formArrayName="memberActivityDtoList"
                            class="table-responsive new-designed-table create-invoice-table">
                            <table class="table table-striped table-bordered">
                                <thead style="border: none;">
                                    <tr style="border: none;">
                                        <th></th>
                                        <th>Patient Name <span class="required">*</span> </th>
                                        <th>DOB <span class="required">*</span> </th>
                                        <th style="width: 11%;">Patient Account #</th>
                                        <th>Service Date <span class="required">*</span> </th>
                                        <th>DIAGNOSIS CODE</th>
                                        <th>CPT Code</th>
                                        <th>Services <br> Description</th>
                                        <th>Units</th>
                                        <th>Total Cost <span class="required">*</span> </th>
                                        <th>DENIED</th>
                                        <th style="width: 80px;">Actions</th>
                                    </tr>
                                </thead>
                                <tbody style="border-top: none;">
                                    <ng-container *ngFor="let patient of getPatientsControls()?.controls; let i = index"
                                        [formGroupName]="i">
                                        <tr style="border: none;">
                                            <td>
                                                <span class="up-down" (click)="getExpand('less',i)"
                                                    id="expandless_{{i}}" style="display:none"><span
                                                        class="material-symbols-outlined up-icon">
                                                        expand_more
                                                    </span></span>

                                                <span class="up-down" (click)="getExpand('more',i)"
                                                    id="expandmore_{{i}}" style="display:block"><span
                                                        class="material-symbols-outlined up-icon">
                                                        expand_less
                                                    </span></span>

                                            </td>
                                            <td style="width: 168px;">
                                                <div class="form-group table-input">
                                                    <ng-select style="width: 124px;"
                                                        placeholder="Search First or Last Name" id="createInvoice_name"
                                                        title="Search First or Last Name"
                                                        [items]="(allPatientList | async)?.responseObject"
                                                        [addTag]="false" [multiple]="false" [hideSelected]="true"
                                                        [trackByFn]="trackByFnPaientName" [minTermLength]="2"
                                                        [closeOnSelect]="true" [loading]="patientNameLoading"
                                                        [addTag]="true"
                                                        typeToSearchText="Please enter 2 or more characters"
                                                        [typeahead]="patientInput" formControlName="patientName"
                                                        (change)="choosepatient($event, i, patient)"
                                                        clearAllText="Clear">
                                                        <ng-template ng-option-tmp let-item="item" let-index="index"
                                                            let-search="searchTerm">
                                                            <div *ngIf="item?.lastName && item?.firstName"
                                                                title="{{item.firstName}} {{item.lastName}}">
                                                                {{item.firstName}} {{item.lastName}}
                                                            </div>
                                                            <div *ngIf="item?.lastName == null"
                                                                title="{{item.firstName}}">
                                                                {{item.firstName}}
                                                            </div>
                                                        </ng-template>
                                                    </ng-select>
                                                    <span class="required"
                                                        *ngIf="getPatientsControls()?.at(i)?.get('patientName')?.errors?.required && getPatientsControls()?.at(i)?.get('patientName')?.touched">
                                                        Patient Name is required.
                                                    </span>
                                                    <span
                                                        *ngIf="getPatientsControls()?.at(i)?.get('patientName')?.errors?.pattern && getPatientsControls()?.at(i)?.get('patientName')?.touched"
                                                        class="text-danger" id="showError_{{i}}">
                                                        Enter FirstName LastName.</span>
                                                        
                                                    <!--<input (keyup.enter)="contactSearchCall(i);" type="text"
                                                        class="form-control input-text" placeholder="Name"
                                                        formControlName="patientName">-->
                                                    <!-- <ng-container>
                                                        <div class="searh-action-sec drp-user dropdown"
                                                            [ngClass]="{'show': (nameSearchResults.length > 0)}"
                                                            #dropClose="ngbDropdown" ngbDropdown placement="auto">
                                                            <div class="row">
                                                                <div ngbDropdownToggle>
                                                                    <input #searchElement type="text"
                                                                        id="navBarSearchPatient"
                                                                        class="nosubmit form-control input-text searchInput"
                                                                        formControlName="patientName"
                                                                        [readonly]="status !== 'OPEN' && !duplicateInvoice"
                                                                        name="searchElement" minlength="4"
                                                                        placeholder=" Search by Name"
                                                                        (keyup.enter)="contactSearchCall(i);"
                                                                        autocomplete="disabled">
                                                                </div>

                                                                <div *ngIf="nameSearchResults.length>0"
                                                                    class="dropdown-menu-right search-box-right profile-notification search-dropdown-notify"
                                                                    ngbDropdownMenu
                                                                    [ngClass]="{'show dropdown': (nameSearchResults.length > 0)}">

                                                                    <div class="pro-head">
                                                                        <div class="row">
                                                                            <div class="col-12">
                                                                                <h5>Search Results</h5>
                                                                                <div class="redirectIcon">
                                                                                    <a
                                                                                        (click)="onBlurMethod(); dropClose.close();">
                                                                                        <i class="fa fa-times"
                                                                                            aria-hidden="true"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <ul class="pro-body"
                                                                        style="margin-left: 0px; padding-left: 0px;padding-bottom: 0; margin-bottom: 0px;">

                                                                        <div class="error-user-info">
                                                                            <div class="text-danger"
                                                                                style="margin-bottom: 10px;">
                                                                                {{userInfoError}}</div>
                                                                        </div>



                                                                        <div class="table-responsive new-designed-table"
                                                                            style="position:relative;">
                                                                            <table
                                                                                class="table table-hover search-results-table"
                                                                                style="margin-bottom: 2px;">
                                                                                <tbody>
                                                                                    <ng-container>
                                                                                        <tr class="patient-records"
                                                                                            (click)="choosepatient(contact,i, patient)"
                                                                                            *ngFor="let contact of nameSearchResults">
                                                                                            <td class="result-column"
                                                                                                style="padding: 7px;">
                                                                                                <span
                                                                                                    class="user-name-col">{{contact?.lastName
                                                                                                    | titlecase}},
                                                                                                    {{contact?.firstName
                                                                                                    | titlecase}}
                                                                                                </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-container>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </ul>
                                                                </div>
                                                                <span class="required"
                                                                    *ngIf="getPatientsControls()?.at(i)?.get('patientName')?.errors?.required && getPatientsControls()?.at(i)?.get('patientName')?.touched">
                                                                    Patient Name is required.
                                                                </span>
                                                                <span
                                                                    *ngIf="getPatientsControls()?.at(i)?.get('patientName')?.errors?.pattern && getPatientsControls()?.at(i)?.get('patientName')?.touched"
                                                                    class="required" id="showError_{{i}}">
                                                                    Enter FirstName LastName.</span>
                                                            </div>
                                                        </div>
                                                    </ng-container> -->

                                                </div>
                                            </td>
                                            <td style="width: 118px;">
                                                <div class="form-group table-input">
                                                    <input type="date" class="form-control input-text date-width" id="createInvoice_dob"
                                                        placeholder="DOB" formControlName="dob" title="DOB"
                                                        [readonly]="status !== 'OPEN' && !duplicateInvoice"
                                                        (change)="newPatient(i)" onkeydown="return false">
                                                    <span class="required"
                                                        *ngIf="getPatientsControls()?.at(i)?.get('dob')?.errors?.required && getPatientsControls()?.at(i)?.get('dob')?.touched">
                                                        Date of Birth is required
                                                    </span>
                                                    <span class="text-danger"
                                                        *ngIf="getPatientsControls()?.at(i)?.get('dob')?.errors?.invalidDate && getPatientsControls()?.at(i)?.get('dob')?.touched">Please
                                                        Enter Valid Date Of Birth</span>

                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-group table-input">
                                                    <input type="text" class="form-control input-text acc-no" id="createInvoice_patienAccNo"
                                                        title="Patient Account Number" placeholder="Patient Account No"
                                                        formControlName="patientEmrId" min="0"
                                                        [readonly]="status !== 'OPEN' && !duplicateInvoice">

                                                    <span class="required"
                                                        *ngIf="getPatientsControls()?.at(i)?.get('patientEmrId')?.errors?.required && getPatientsControls()?.at(i)?.get('patientEmrId')?.touched">
                                                        Patient Account No is required
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <!-- <p> {{getPatientsControls()?.at(i)?.get('serviceStartDate')?.value || null}}</p> -->
                                                <div class="form-group table-input date-width">
                                                    <input type="date" class="form-control input-text" id="createInvoice_serviceDate"
                                                        title="Service Date" formControlName="serviceStartDate"
                                                        [readonly]="status !== 'OPEN' && !duplicateInvoice"
                                                        placeholder="Service Date" [max]="maxDate">
                                                    <span class="required"
                                                        *ngIf="getPatientsControls()?.at(i)?.get('serviceStartDate')?.errors?.required && getPatientsControls()?.at(i)?.get('serviceStartDate')?.touched">
                                                        Service Date is required
                                                    </span>


                                                </div>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>${{totalCost(i) | number: '.2'}} </td>
                                            <td></td>
                                            <td>
                                                <div class="dropdown-actions" style="width: 70px;">
                                                    <button [disabled]="status !== 'OPEN' && !duplicateInvoice"
                                                        id="claimActions_btn"
                                                        class="icon-queue-events more-actions-hover-icon {{openActions && selectedActionRow === i ?'dropdown-content-actions-selected':''}}"
                                                        (click)="openUniversalActions($event, i);">
                                                        ...
                                                    </button>
                                                    <div class="dropdown-content-actions" (mouseenter)="mouseIn();"
                                                        (mouseleave)="mouseOut();"
                                                        *ngIf="openActions && selectedActionRow === i">
                                                        <!-- (click)="viewDownloadDocumentPatient(i, 'view', viewDownloadDocumentModal)" -->
                                                        <!-- <a href="javascript:;"
                                                            (click)="userLevelDocsView(i, invoicePatientLevalDocumnets);docViewIndex = 1"><span
                                                                class="material-symbols-outlined">
                                                                visibility
                                                            </span>View Document</a> -->
                                                        <a href="javascript:;" id="createInvoice_uploadDoc"
                                                            [class.disabled]="status !== 'OPEN' && !duplicateInvoice"
                                                            (click)="openUploadUniversalDocuments(patient?.value, i)"><span
                                                                class="material-symbols-outlined">
                                                                upload_file
                                                            </span> Upload Document</a>
                                                        <a [class.disabled]="status !== 'OPEN' && !duplicateInvoice" id="createInvoice_delete"
                                                            (click)="status === 'OPEN' && confirmDeletePatient(i)"><span
                                                                class="material-symbols-outlined">
                                                                delete
                                                            </span>Delete</a>

                                                    </div>
                                                    <!-- Actions - vew document icon user level -->
                                                    <ng-container
                                                        *ngIf="patient?.value?.documentKeys && patient?.value?.documentKeys?.length > 0">
                                                        <button class="pdf-btn-available" placement="left"
                                                            id="viewUserDocs_pdf"
                                                            (click)="userLevelDocsView(i, invoicePatientLevalDocumnets);docViewIndex = 1"
                                                            ngbTooltip="Document Available"><i
                                                                class="fa fa-file-pdf"></i></button>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="!patient?.value?.documentKeys || patient?.value?.documentKeys?.length === 0">
                                                        <button class="pdf-btn" disabled placement="left"
                                                            ngbTooltip="Not Available" id="viewUserDocs_pdfDisabled"><i
                                                                class="fa fa-file-pdf disabled"></i></button>
                                                    </ng-container>
                                                </div>

                                            </td>
                                        </tr>
                                        <tr style="border: none;" id="services_{{i}}{{serIndex}}"
                                            formArrayName="serviceLinesInfo"
                                            *ngFor="let service of patientSericesArray(patient)?.controls;let serIndex = index">

                                            <td [formGroupName]="serIndex">
                                                <div [ngClass]="{ 'disabled': status !== 'OPEN' && !duplicateInvoice }"
                                                    (click)="addNewService(patient, i)"
                                                    class="user-avatar cursor-pointer">
                                                    <span class="material-symbols-outlined plus-invoice cursor-pointer"
                                                        *ngIf="serIndex===0">
                                                        add_circle
                                                    </span>
                                                    <div class="profile-tooltip-add-service">
                                                        <p>ADD SERVICE </p>
                                                        <span class="material-symbols-outlined arrow">
                                                            arrow_drop_down
                                                        </span>
                                                    </div>

                                                </div>
                                            </td>
                                            <td>

                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td [formGroupName]="serIndex" class="form-select2 table-input"
                                                style="min-width:107px; max-width: 107px;">
                                                <!-- <div class="form-group table-input">
                                                    <input type="text" class="form-control input-text codes"
                                                        placeholder="Dignosis Code" formControlName="dxCodes">

                                                </div> -->

                                                <ng-select placeholder="Search Dx Codes" title="Search Dx Codes"
                                                    [items]="(allIcdCodesObservebale | async)?.responseObject"
                                                    bindLabel="code" bindValue="code" [addTag]="true"
                                                    [clearable]="false" [multiple]="true" [hideSelected]="true"
                                                    id="assessment_ans" [trackByFn]="trackByFnDxCodes"
                                                    [minTermLength]="3" [closeOnSelect]="false"
                                                    [loading]="icdCodesLoading"
                                                    typeToSearchText="Please enter 3 or more characters"
                                                    [typeahead]="icdInput" formControlName="dxCodes"
                                                    [readonly]="status !== 'OPEN' && !duplicateInvoice"
                                                    clearAllText="Clear">

                                                    <ng-template ng-option-tmp let-item="item" let-index="index"
                                                        let-search="searchTerm">
                                                        <div title="{{item?.code}} {{item.description}}">
                                                            {{item?.code}} {{item.description}}</div>
                                                    </ng-template>

                                                    <!-- <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                                    </ng-template> -->
                                                </ng-select>

                                                <!-- <span class="required"
                                                        *ngIf="service?.get('dxCodes')?.errors?.required && service?.get('dxCodes')?.touched">
                                                        DxCode is required
                                                    </span>-->


                                            </td>
                                            <!-- <td [formGroupName]="serIndex">
                                                <div class="form-group table-input">
                                                    <input type="text" class="form-control input-text codes"
                                                        placeholder="CPT Code" formControlName="cptCode">

                                                </div>
                                            </td> -->
                                            <td [formGroupName]="serIndex" class="form-select2 table-input"
                                                style="min-width:115px; max-width: 115px;">
                                                <ng-select placeholder="Search CPT Codes" title="Search CPT Codes" id="createInvoice_cptCode"
                                                    [items]="(allBillingCodes | async)?.responseObject" [addTag]="true"
                                                    bindLabel="cptCode" [multiple]="false" [hideSelected]="true"
                                                    [trackByFn]="trackByFnCptCodes" [minTermLength]="2"
                                                    [closeOnSelect]="true" [loading]="billingCodesLoading"
                                                    typeToSearchText="Please enter 2 or more characters"
                                                    [typeahead]="billingInput" formControlName="cptCode"
                                                    [readonly]="status !== 'OPEN' && !duplicateInvoice"
                                                    (change)="changeCptCodes($event,i, serIndex)" clearAllText="Clear">

                                                    <ng-template ng-option-tmp let-item="item" let-index="index"
                                                        let-search="searchTerm">
                                                        <div title="{{item.cptCode}} {{item.codeDescription}}">
                                                            {{item.cptCode}} {{item.codeDescription}}
                                                        </div>
                                                    </ng-template>
                                                </ng-select>

                                                <span class="required"
                                                    *ngIf="service?.get('cptCode')?.errors?.required && service?.get('cptCode')?.touched">
                                                    CPT Code is required
                                                </span>

                                                <!-- CPT description -->
                                                <!-- <div style="white-space: inherit;">
                                                    {{service?.get('professionalService')?.value?.description}}
                                                </div> -->
                                            </td>

                                            <td [formGroupName]="serIndex">
                                                <div class="form-group table-input">
                                                    <input type="text" class="form-control input-text service-des" id="createInvoice_seviceDes"
                                                        title="Service Description" placeholder="Service Description"
                                                        formControlName="description"
                                                        [readonly]="status !== 'OPEN' && !duplicateInvoice">
                                                </div>
                                            </td>

                                            <td [formGroupName]="serIndex">
                                                <div class="form-group table-input">
                                                    <input type="number" class="form-control input-text codes"
                                                        title="Units" id="createInvoice_units"
                                                        onKeyPress='return event.charCode >= 48 && event.charCode <= 57'
                                                        placeholder="Units" formControlName="units" min="0"
                                                        [readonly]="status !== 'OPEN'&& !duplicateInvoice">
                                                </div>
                                            </td>
                                            <td [formGroupName]="serIndex">
                                                <div class="form-group table-input">
                                                    <input type="text" mask="separator.2"
                                                        class="form-control input-text cost" title="Cost"
                                                        placeholder="Cost" formControlName="billedAmount" id="createInvoice_cost"
                                                        thousandSeparator=","
                                                        [readonly]="status !== 'OPEN' && !duplicateInvoice"
                                                        (input)="validateDecimal($event,i,serIndex)">

                                                    <span class="required"
                                                        *ngIf="service?.get('billedAmount')?.errors?.required && service?.get('billedAmount')?.touched">
                                                        Cost is required
                                                    </span>

                                                </div>
                                            </td>
                                            <!-- Denied -->
                                            <td class="denied-service-check">
                                                <div class="form-group text-left list-group">
                                                    <div class="form-check">
                                                        <input class="form-check-input invoice-denied" type="checkbox"
                                                            name="invoice-denied-service" disabled
                                                            [checked]="service?.value?.status === 'DENIED'"
                                                            id="invoice-denied-service">
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="dropdown-actions">
                                                    <button [disabled]="status !== 'OPEN' && !duplicateInvoice"
                                                        id="claimActionsService_btn"
                                                        class="icon-queue-events more-actions-hover-icon {{openServiceActions && selectedServiceActionRow === serIndex && selectedActionRow === i ?'dropdown-content-actions-selected':''}}"
                                                        (click)="openService($event, serIndex,i);">
                                                        ...
                                                    </button>
                                                    <div class="dropdown-content-actions"
                                                        (mouseenter)="mouseServiceIn();"
                                                        (mouseleave)="mouseServiceOut();"
                                                        *ngIf="openServiceActions && selectedServiceActionRow === serIndex && selectedActionRow == i">
                                                        <!-- <a class="a-disabled"><span class="material-symbols-outlined">
                                                                visibility
                                                            </span>View Document</a> -->
                                                        <!-- <a class="a-disabled"><span class="material-symbols-outlined">
                                                                upload_file
                                                            </span> Upload Document</a> -->
                                                        <a (click)="confirmDeleteService(i,serIndex)" id="createInvoice_delete1"
                                                            [class.a-disabled]="patientSericesArray(patient)?.controls?.length === 1 || status !== 'OPEN' && !duplicateInvoice"><span
                                                                class="material-symbols-outlined">
                                                                delete
                                                            </span>Delete</a>

                                                    </div>
                                                </div>

                                            </td>


                                        </tr>

                                    </ng-container>
                                </tbody>
                            </table>
                        </div>

                        <!-- upload -->

                        <br>
                        <div class="row mt-4">
                            <div class="col-md-3 mt-2 upload"
                                [ngClass]="{ 'disabled': status !== 'OPEN' && !duplicateInvoice }">
                                <h6>Upload Additional Documents </h6>
                                <form
                                    [ngClass]="status === 'OPEN' && !duplicateInvoice ? 'custom-file-container' : 'custom-file-container-disable'">
                                    <div class="custom-file">
                                        <div class="">
                                            <div class="image-upload">
                                                <label (change)="uploadFileSelect($event)"
                                                    style="cursor: pointer;width: 100%;">
                                                    <img src="../../../assets/images/file-add.svg" class="add-file">
                                                    <h4><input type="file" class="custom-file-input"
                                                            id="create-invoice-documents-upload"
                                                            [disabled]="status !== 'OPEN' && !duplicateInvoice"
                                                            name="create-invoice-documents-upload"
                                                            data-traget-resolution="image_resolution"
                                                            (change)="uploadFileSelect($event)" value=""
                                                            accept="application/pdf, image/*" multiple>
                                                        <label class="custom-file-label"></label>
                                                    </h4>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <ul class="doc-type-list">
                                                <li>
                                                    <a>PDF</a>
                                                </li>
                                                <li>
                                                    <a>PNG</a>
                                                </li>
                                                <li>
                                                    <a>JPEG</a>
                                                </li>
                                                <li>
                                                    <a>
                                                        < below 25MB</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </form>
                                <div class="uploaded-data"
                                    *ngIf="(documentsList && documentsList?.length > 0) || (readDocumentsList && readDocumentsList?.length > 0)">
                                    <h6>Attached - {{(documentsList?.length + readDocumentsList?.length) ||
                                        0}}/{{(documentsList?.length + readDocumentsList?.length) || 0}} files
                                    </h6>
                                    <ul class="uploaded-files row">
                                        <li [class.disabled]="status !== 'OPEN'" class="success col-6 ellipse-text"
                                            title="{{doc?.name}}"
                                            *ngFor="let doc of readDocumentsList; let index = index"
                                            id="fileName{{index}}"
                                            (click)="viewDocumentUploaded(doc, index, invoicePatientLevalDocumnets)">
                                            {{doc?.name}} <span class="material-symbols-outlined"
                                                (click)="$event.stopPropagation();deleteConfirmationDocs(index, doc, 'read', documentDeleteConfirmation)"
                                                style="cursor:pointer;">delete</span>
                                        </li>
                                    </ul>
                                    <ul class="uploaded-files row">
                                        <li [class.disabled]="status !== 'OPEN'" class="success col-6 ellipse-text"
                                            title="{{doc?.name}}" *ngFor="let doc of documentsList; let index = index"
                                            id="fileName{{index}}"
                                            (click)="viewDocumentUploaded(doc, index, invoicePatientLevalDocumnets)">
                                            {{doc?.name}}<span class="material-symbols-outlined"
                                                (click)="$event.stopPropagation();deleteConfirmationDocs(index, doc, 'upload', documentDeleteConfirmation)"
                                                style="cursor:pointer;">delete</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-6 mt-2">
                                <div class="form-group demographic-form textarea-label">

                                    <textarea formControlName="comments" rows="4" class="form-control" id="createInvoice_comment"
                                        placeholder="Add Comments"></textarea>
                                    <label class="form-label">Comments</label>
                                </div>
                            </div>
                            <div class="col-md-3 mt-2 invoice-table">
                                <!-- <ul>
                                    <li>
                                        <span>Total Patients</span>
                                        <span>{{getPatientsControls().length}}</span>
                                    </li>
                                    <li>
                                        <span><b>Invoice Total</b></span>
                                        <span><b>$ {{getInvoiceTotal() | number: '.2'}}</b></span>
                                    </li>
                                </ul> -->
                                <ul>
                                    <li>
                                        <span>Total Patients</span>
                                        <span>{{getPatientsControls().length}}</span>
                                    </li>
                                    <li>
                                        <span>Invoice Total</span>
                                        <span>$ {{getInvoiceTotal() | number: '.2'}}</span>
                                    </li>
                                    <li>
                                        <span>Denied Amount</span>
                                        <span>-$ {{(totalDeniedAmount || 0) | number: '.2'}}</span>
                                    </li>
                                    <li>
                                        <span><b>Approved Total</b></span>
                                        <span><b>$ {{(getInvoiceTotal() - (totalDeniedAmount || 0)) | number:
                                                '.2'}}</b></span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <!-- <div class="row mt-4">
                            <div class="col-md-3">

                                <form class="custom-file-container">
                                    <div class="custom-file">
                                        <div class="">
                                            <div class="image-upload">
                                                <label (change)="uploadFileSelect($event)"
                                                    style="cursor: pointer;width: 100%;">
                                                    <i class="material-symbols-outlined">
                                                        upload_file
                                                    </i>
                                                    <h4><input type="file" class="custom-file-input"
                                                            id="create-invoice-documents-upload"
                                                            name="create-invoice-documents-upload"
                                                            data-traget-resolution="image_resolution"
                                                            (change)="uploadFileSelect($event)" value="" multiple>
                                                        <label class="custom-file-label"></label>
                                                    </h4>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div class="uploaded-data mt-4" *ngIf="documentsList && documentsList?.length > 0">
                                    <h6>Uploading - {{documentsList?.length || 0}}/{{documentsList?.length || 0}} files
                                    </h6>
                                    <ul class="uploaded-files" *ngFor="let doc of documentsList; let index = index">
                                        <li class="success" id="fileName{{index}}" (click)="viewDocumentUploaded(doc)">
                                            {{doc?.name}} <span class="material-symbols-outlined"
                                                (click)="$event.stopPropagation();deleteSelectedFile(index)"
                                                style="cursor:pointer;">delete</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-6 demographic-form">
                                <div class="form-group  textarea-label">
                                    <textarea rows="4" class="form-control" placeholder="Comment"></textarea>
                                    <label class="form-label">Comment</label>
                                </div>
                            </div>
                            <div class="col-md-3 invoice-table">
                                <table>
                                    <tr>
                                        <th class="value">Total Patient</th>
                                        <td class="value">{{getPatientsControls().length}}</td>
                                    </tr>
                                    <tr>
                                        <th>Invoice Total</th>
                                        <td class="total-dollar">$ {{getInvoiceTotal()}}</td>
                                    </tr>

                                </table>
                            </div>
                        </div> -->

                        <!-- save & submit button -->

                        <div class="row mt-3 save-btn">
                            <div class="col-md-12">

                                <button [disabled]="status !== 'OPEN' && !duplicateInvoice"
                                    (click)="!duplicateInvoice ? onCreateInvoiceSubmit('SAVE') : duplicateSaveSubmit('SAVE', duplicateInvoiceConfirm)"
                                    type="button" class="btn btn-primary save" id="createInvoice_save">
                                    <span class="btn-text">Save </span>
                                </button>
                                <button [disabled]="status !== 'OPEN' && !duplicateInvoice" type="button"
                                    (click)="!duplicateInvoice ? onCreateInvoiceSubmit('SAVEANDSUBMIT') : duplicateSaveSubmit('SAVEANDSUBMIT', duplicateInvoiceConfirm)"
                                    class="btn btn-primary" id="createInvoice_saveSubmit">
                                    <span class="btn-text">Save & Submit </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- <div class="table-responsive new-designed-table" style="position:relative;">
    <table class="table table-hover search-results-table">
        <tbody>
            <ng-container>

                <tr class="patient-records" *ngFor="let contact of nameSearchResults">
                    <td class="result-column">
                        <span class="user-name-col">{{contact?.lastName }},
                            {{contact?.firstName }}
                        </span>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table> -->
<div id="cancel-appointment-page-modal" [style.display]="showModalCancel ? 'block' : 'none'" class="modal"
    role="dialog">
    <div class="app-modal-header">
        <h5 class="modal-title text-center"><i class="lar la-question-circle"></i></h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="closeModel()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <h6 style="line-height: 30px;">test..</h6>
    </div>
</div>
<app-load-spinner [loadSpinner]="loadSpinner"></app-load-spinner>
<app-exception-modal [exceptionalModalFlag]="exceptionalModal" [isSuccessNotify]="isSuccessNotify"
    [notifyText]="notifyText" [saveInvoice]="saveInvoice" [saveAndUpdate]="saveAndUpdate"
    (exceptionalModalEmit)="exceptionalModal = false"></app-exception-modal>

<!-- Display the document -->
<div id="invoice-doc-view-page-modal" [style.display]="showDocsViewModal ? 'block' : 'none'" class="modal"
    role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-body">
                <iframe id="docs-upload-pdf-view" frameBorder="0" width="100%" height="500px"></iframe>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary ok-book" data-dismiss="modal" id="exceptional_ok"
                    (click)="showDocsViewModal = false">Ok</button>
            </div>
        </div>
    </div>
</div>

<!-- <app-ui-modal #viewDownloadDocumentModal class="common-modal" [hideHeader]="false" [modalCentered]="true"
    id="viewDownloadDocumentModal">
    <div class="app-modal-header">
        <h5 class="modal-title">{{selectedDocumentName}}</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="viewDownloadDocumentModal.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body pb-0">
        <div class="consent-pdf">
            <iframe id="consent-pdf-view" frameBorder="0" width="100%" height="500px"></iframe>
        </div> 
        <div class="verify-stick-menu">
            <a class="verify-prev" *ngIf="documentsList.length > 1 && this.selectedRecord !==0"
                (click)="callNextPrev('prev', this.selectedRecord, 'view', viewDownloadDocumentModal)"> &lt; &nbsp;
                Previous</a>
            <ul class="stick-menu-list">
                <li class="menu-select">
                    <a class="btn btn-primary" (click)="viewDownloadDocumentModal.hide()" id="close">
                        Close
                    </a>
                </li>
            </ul>
            <a class="verify-next"
                *ngIf="documentsList.length > 1 && this.selectedRecord !== documentsList.length-1"
                (click)="callNextPrev('next', this.selectedRecord, 'view', viewDownloadDocumentModal)"> Next &nbsp;
                &gt;</a>
        </div> 
    </div>
    <div class="app-modal-footer">
        <div class="footer-actions-btns">
            <div class="prev-link text-left" style="width: 25%;">
                <a class="verify-prev" href="javascript:;"
                    *ngIf="documentsList.length > 1 && this.selectedRecord !==0"
                    (click)="callNextPrev('prev', this.selectedRecord, 'view', viewDownloadDocumentModal)"> &lt; &nbsp;
                    Previous</a>
            </div>
            <div class="center-btn text-center" style="width: 50%;">
                <a class="btn btn-primary" href="javascript:;" (click)="viewDownloadDocumentModal?.hide()" id="close">
                    Close
                </a>
            </div>
            <div class="next-link text-right" style="width: 25%;">
                <a class="verify-next" href="javascript:;"
                    *ngIf="documentsList.length > 1 && this.selectedRecord !== documentsList.length-1"
                    (click)="callNextPrev('next', this.selectedRecord, 'view', viewDownloadDocumentModal)"> Next &nbsp;
                    &gt;</a>
            </div>
        </div>
     
    </div>
</app-ui-modal>  -->

<!-- Display the Document PDF -->
<!-- <app-ui-modal #invoicePatientLevalDocumnets [hideHeader]="false" [modalCentered]="true"
    id="invoicePatientLevalDocumnets">
    <div class="app-modal-header">
        <h5 class="modal-title">Document</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="invoicePatientLevalDocumnets.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body">
        <div class="documents mb-3" *ngIf="allPatientDocsSelectedKeys && allPatientDocsSelectedKeys?.length > 1">
            <ng-container *ngFor="let document of allPatientDocsSelectedKeys; let i = 'index+1'">
                <button class="icon-queue-events signoff-action-bg" [class.active]="i === docViewIndex" placement="right"
                    (click)="viewDownloadDocumentMultiple(document, invoicePatientLevalDocumnets, i, allPatientDocsSelectedKeys)"
                    [ngbTooltip]="document?.name"><i style="color: #5ca6db;" class="fa fa-file-pdf"></i></button>
            </ng-container>
        </div>
        <div class="invoice-patient-doc">
            <iframe id="invoice-create-patient-doc" frameBorder="0" width="100%" height="500px"></iframe>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal" id="ok1"
            (click)="invoicePatientLevalDocumnets.hide()">Ok</button>
    </div>
</app-ui-modal> -->

<app-ui-modal #invoicePatientLevalDocumnets class="common-modal" [hideHeader]="false" [loadSpinner]="loadSpinner"
    [modalCentered]="true" id="invoicePatientLevalDocumnets">
    <div class="app-modal-header">
        <div class="d-flex">
            <h5 class="modal-title">{{selectedDocumentName}} &nbsp;&nbsp; </h5> <span *ngIf="isUserLevelDocsView"
                class="material-symbols-outlined" style="cursor:pointer; color: red;"
                (click)="deleteSelectedUserDoc(allPatientDocsSelectedKeys[selectedRecord])">delete</span>
        </div>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="invoicePatientLevalDocumnets.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body pb-0">
        <div class="consent-pdf">
            <iframe id="invoice-create-patient-doc" frameBorder="0" width="100%" height="280px"></iframe>
        </div>
    </div>
    <div class="app-modal-footer">
        <div class="footer-actions-btns">
            <div class="prev-link text-left" style="width: 25%;">
                <a class="verify-prev" href="javascript:;" id="createInvoice_prev"
                    *ngIf="allPatientDocsSelectedKeys?.length > 1 && this.selectedRecord !== 0"
                    (click)="callNextPrev('prev', this.selectedRecord, invoicePatientLevalDocumnets)"> &lt; &nbsp;
                    Previous</a>
            </div>
            <div class="center-btn text-center" style="width: 50%;">
                <a class="btn btn-primary" href="javascript:;" (click)="invoicePatientLevalDocumnets?.hide()"
                    id="close">
                    Close
                </a>
            </div>
            <div class="next-link text-right" style="width: 25%;">
                <a class="verify-next" href="javascript:;" id="createInvoice_next"
                    *ngIf="allPatientDocsSelectedKeys?.length > 1 && this.selectedRecord !== allPatientDocsSelectedKeys?.length-1"
                    (click)="callNextPrev('next', this.selectedRecord, invoicePatientLevalDocumnets)"> Next &nbsp;
                    &gt;</a>
            </div>
        </div>
    </div>
</app-ui-modal>

<!-- confirmation popup on delete of the documents invoice and account level -->
<app-ui-modal #documentDeleteConfirmation [hideHeader]="false" [modalCentered]="true" id="documentDeleteConfirmation">
    <div class="app-modal-header">
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="documentDeleteConfirmation?.hide();"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-2">
                <h5 class="modal-title text-info text-center" style="font-size: 35px;">
                    <!-- <i class="las la-exclamation-triangle"></i> -->
                    <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>
                </h5>
            </div>
            <div class="col-lg-10 col-md-10 col-sm-10">
                <h5 class="modal-title" style=" text-align: left;">Do you want to delete the {{deleteSelectedDoc?.name}}
                    Document?</h5>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" id="createInvoice_no" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            (click)="documentDeleteConfirmation?.hide()">No</button>
        <button type="button" id="createInvoice_yes" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            (click)="documentDeleteConfirmation?.hide();deleteDocFromConfirm(documentDeleteConfirmation)">Yes</button>
    </div>
</app-ui-modal>


<app-ui-modal #confirmPatientDelete [hideHeader]="false" [modalCentered]="true" id="confirmPatientDelete">
    <div class="app-modal-header">
        <!-- <h5 class="modal-title">Are you sure want to delete Emergency Contact Details?</h5> -->
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="confirmPatientDelete?.hide();"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-2">
                <h5 class="modal-title text-info text-center" style="font-size: 50px;">
                    <!-- <i class="las la-exclamation-triangle"></i> -->
                    <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>
                </h5>
            </div>
            <div class="col-lg-10 col-md-10 col-sm-10" style="margin-top: 4px;">
                <h5 class="modal-title" style=" text-align: left;">Are you sure want to delete Patient
                    Details?</h5>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" id="emergency-contact-details-yes" class="btn btn-primary dash-actions"
            style="margin-right: 15px;" data-dismiss="modal"
            (click)="confirmPatientDelete?.hide(); deletePatient(patientIndex)">Yes</button>
        <button type="button" id="emergency-contact-details-no" class="btn btn-primary dash-actions"
            style="margin-right: 15px;" data-dismiss="modal" (click)="confirmPatientDelete.hide()">No</button>
    </div>
</app-ui-modal>

<app-ui-modal #confirmServiceDelete [hideHeader]="false" [modalCentered]="true" id="confirmServiceDelete">
    <div class="app-modal-header">
        <!-- <h5 class="modal-title">Are you sure want to delete Emergency Contact Details?</h5> -->
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="confirmServiceDelete?.hide();"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-2">
                <h5 class="modal-title text-info text-center" style="font-size: 50px;">
                    <!-- <i class="las la-exclamation-triangle"></i> -->
                    <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>
                </h5>
            </div>
            <div class="col-lg-10 col-md-10 col-sm-10" style="margin-top: 16px;">
                <h5 class="modal-title" style=" text-align: left;">Are you sure want to delete Service Details?</h5>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" id="emergency-contact-details-yes" class="btn btn-primary dash-actions"
            style="margin-right: 15px;" data-dismiss="modal"
            (click)="confirmServiceDelete?.hide(); deleteService(patientIndex,serviceIndex)">Yes</button>
        <button type="button" id="emergency-contact-details-no" class="btn btn-primary dash-actions"
            style="margin-right: 15px;" data-dismiss="modal" (click)="confirmServiceDelete.hide()">No</button>
    </div>
</app-ui-modal>


<app-ui-modal #noPatientDetailsModal [hideHeader]="false" [modalCentered]="true" id="noPatientDetailsModal">
    <div class="app-modal-header">
        <!-- <h5 class="modal-title">Are you sure want to delete Emergency Contact Details?</h5> -->
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="noPatientDetailsModal?.hide();"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-2">
                <h5 class="modal-title text-info text-center" style="font-size: 50px;">
                    <!-- <i class="las la-exclamation-triangle"></i> -->
                    <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>
                </h5>
            </div>
            <div class="col-lg-10 col-md-10 col-sm-10" style="margin-top: 16px;">
                <h5 class="modal-title" style=" text-align: left;">Patient Information is required.</h5>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" id="emergency-contact-details-yes" class="btn btn-primary dash-actions"
            style="margin-right: 15px;" data-dismiss="modal" (click)="noPatientDetailsModal?.hide(); ">OK</button>

    </div>
</app-ui-modal>


<!-- Upload additonal documents -->
<app-upload-documents (invoiceUsersDocs)="getS3Path($event)"></app-upload-documents>

<!-- Confirmation popup for duplciate invoice -->
<app-ui-modal #duplicateInvoiceConfirm [hideHeader]="false" [modalCentered]="true" id="duplicateInvoiceConfirm">
    <div class="app-modal-header">
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="duplicateInvoiceConfirm?.hide();"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-2">
                <h5 class="modal-title text-info text-center" style="font-size: 35px;">
                    <!-- <i class="las la-exclamation-triangle"></i> -->
                    <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>
                </h5>
            </div>
            <div class="col-lg-10 col-md-10 col-sm-10">
                <h5 class="modal-title" style=" text-align: left;">Please ensure correct units and costs are entered.
                </h5>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" id="createInvoice_ok" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            (click)="duplicateInvoiceConfirm?.hide();onCreateInvoiceSubmit(isDupSaveSubmitFlag)">OK</button>
        <button type="button" id="createInvoice_cancel" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            (click)="duplicateInvoiceConfirm?.hide()">Cancel</button>
    </div>
</app-ui-modal>