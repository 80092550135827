<!-- <div *ngIf="enableStep1" class="container">
    <div class="feedback-form text-center">
        <div class="card borderless main-card">
            <h5 class="heading">How is the Visit Experience ?</h5>
            <div class="card borderless card-content">
                <form [formGroup]="feedbackForm">
                    <div class="row smile" style="padding:20px">
                        <div class="col-6">
                            <input type="radio" class="btn-check" value="smile1" id="btn-check" name="smiley"
                                autocomplete="off" (change)="smile1Click($event)">
                            <label class="btn btn-light btn-lg" for="btn-check"><img *ngIf="black"
                                    src="../../../assets/images/smile-black.svg" alt=""><img *ngIf="green"
                                    src="../../../assets/images/smile.svg" alt=""></label>
                        </div>
                        <div class="col-6">
                            <input type="radio" class="btn-check" name="smiley" value="smile2" id="btn-check2"
                                autocomplete="off" (change)="smile2Click($event)">
                            <label class="btn btn-light btn-lg" for="btn-check2"><img *ngIf="black1"
                                    src="../../../assets/images/smile_1.svg" alt="">
                                <img *ngIf="red" src="../../../assets/images/smile_1_red.svg" alt=""></label>
                        </div>
                    </div>

                    <div class="btn-group response-content" *ngFor="let item of items">
                        <input type="checkbox" class="btn-check" id={{item}} autocomplete="off" value={{item}}
                            (change)="responseClick($event)">
                        <label class="btn btn-light" for={{item}}>{{item}}</label>
                    </div>
                    <div class="additional-data">
                        <h5>Tell Us What we need to improve ? (optional)</h5>
                        <textarea class="textarea" name="feedback" id="feedback" cols="30" rows="5"
                            placeholder="Enter your feedback here"></textarea>
                    </div>
                    <div class="row button">
                        <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                            <button type="button" class="btn btn-primary continue-btn"
                                (click)="step1Continue()">Continue</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>
</div>
<div *ngIf="enableStep2" class="container">
    <div class="feedback-form text-center">
        <div class="card borderless main-card">
            <h5 class="heading">How is the Consultation Experience ?</h5>
            <div class="card borderless card-content">
                <form [formGroup]="feedbackForm">
                    <div class="row" style="padding:20px">
                        <div class="col-6">
                            <input type="radio" class="btn-check" value="smile1" id="btn-check" name="smiley"
                                autocomplete="off" (change)="smile1Click($event)">
                            <label class="btn btn-light btn-lg" for="btn-check"><img *ngIf="black"
                                    src="../../../assets/images/smile-black.svg" alt=""><img *ngIf="green"
                                    src="../../../assets/images/smile.svg" alt=""></label>
                        </div>
                        <div class="col-6">
                            <input type="radio" class="btn-check" name="smiley" value="smile2" id="btn-check2"
                                autocomplete="off" (change)="smile2Click($event)">
                            <label class="btn btn-light btn-lg" for="btn-check2"><img *ngIf="black1"
                                    src="../../../assets/images/smile_1.svg" alt="">
                                <img *ngIf="red" src="../../../assets/images/smile_1_red.svg" alt=""></label>
                        </div>
                    </div>
                    <div class="response-content">
                        <tag-input [(ngModel)]="items" [ngModelOptions]="{standalone: true}" [removable]="false"
                            [modelAsStrings]="true">
                        </tag-input>


                    </div>
                    <div class="additional-data">
                        <h5>Tell Us What we need to improve ? (optional)</h5>
                        <textarea class="textarea" name="feedback" id="feedback" cols="30" rows="5"
                            placeholder="Enter your feedback here"></textarea>
                    </div>
                    <div class="row button">
                        <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                            <button type="button" class="btn btn-secondary continue-btn" style="margin-right: 75px;"
                                (click)="step2Back()">Back</button>
                            <button type="button" class="btn btn-primary continue-btn"
                                (click)="step2Continue()">Continue</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>
</div>
<div *ngIf="enableStep3" class="container">
    <div class="feedback-form text-center">
        <div class="card borderless main-card">
            <h5 class="heading">How is the Platform Experience ?</h5>
            <div class="card borderless card-content">
                <form [formGroup]="feedbackForm">
                    <div class="row" style="padding:20px">
                        <div class="col-6">
                            <input type="radio" class="btn-check" value="smile1" id="btn-check" name="smiley"
                                autocomplete="off" (change)="smile1Click($event)">
                            <label class="btn btn-light btn-lg" for="btn-check"><img *ngIf="black"
                                    src="../../../assets/images/smile-black.svg" alt=""><img *ngIf="green"
                                    src="../../../assets/images/smile.svg" alt=""></label>
                        </div>
                        <div class="col-6">
                            <input type="radio" class="btn-check" name="smiley" value="smile2" id="btn-check2"
                                autocomplete="off" (change)="smile2Click($event)">
                            <label class="btn btn-light btn-lg" for="btn-check2"><img *ngIf="black1"
                                    src="../../../assets/images/smile_1.svg" alt="">
                                <img *ngIf="red" src="../../../assets/images/smile_1_red.svg" alt=""></label>
                        </div>
                    </div>
                    <div class="response-content">
                        <tag-input [(ngModel)]="items" [ngModelOptions]="{standalone: true}" [removable]="false"
                            [modelAsStrings]="true">
                        </tag-input>


                    </div>
                    <div class="additional-data">
                        <h5>Tell Us What we need to improve ? (optional)</h5>
                        <textarea class="textarea" name="feedback" id="feedback" cols="30" rows="5"
                            placeholder="Enter your feedback here"></textarea>
                    </div>
                    <div class="row button">
                        <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                            <button type="button" class="btn btn-secondary continue-btn" style="margin-right: 75px;"
                                (click)="step3Back()">Back</button>
                            <button type="button" class="btn btn-primary continue-btn">Continue</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>
</div> -->

<div class="container" style="height: 100%;
display: flex;
align-items: center;
vertical-align: middle;">
    <div class="feedback-check">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <aw-wizard #feedbackWizard class="arc-wizard new-designed-left-arcs feedback-forms" navBarLocation="top"
                    [awNavigationMode]="navigationMode" navigateBackward="allow" navigateForward="allow">

                    <!-- Visit Experince -->
                    <aw-wizard-step (stepEnter)="callFeedbackQuestions('Clinic')">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 colsm-12 text-center mb-3">
                                <h5 class="heading">How is the Visit Experience?</h5>
                            </div>
                        </div>

                        <form class="visit-exp-form" [formGroup]="visitExperience">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 colsm-12">
                                    <div class="row smile text-center">
                                        <div class="col-6">
                                            <input type="radio" class="btn-check" (change)="changeVisitExpSmiley()"
                                                formControlName="visitoutcome" value="POSITIVE" id="btn-check-visit"
                                                name="visitoutcome">

                                            <label class="btn btn-light btn-lg smiley-check" for="btn-check-visit">
                                                <img *ngIf="!visitExperience?.value?.visitoutcome || visitExperience?.value?.visitoutcome !== 'POSITIVE'"
                                                    src="../../../assets/images/smile-black.svg" alt="">
                                                <img *ngIf="visitExperience?.value?.visitoutcome === 'POSITIVE'"
                                                    src="../../../assets/images/smile.svg" alt="">
                                            </label>
                                        </div>
                                        <div class="col-6">
                                            <input type="radio" class="btn-check" (change)="changeVisitExpSmiley()"
                                                name="visitoutcome" formControlName="visitoutcome" value="NEGATIVE"
                                                id="btn-check2-visit">
                                            <label class="btn btn-light btn-lg smiley-check" for="btn-check2-visit"><img
                                                    *ngIf="visitExperience?.value?.visitoutcome !== 'NEGATIVE'"
                                                    src="../../../assets/images/smile_1.svg" alt="">
                                                <img *ngIf="visitExperience?.value?.visitoutcome === 'NEGATIVE'"
                                                    src="../../../assets/images/smile_1_red.svg" alt=""></label>
                                        </div>
                                        <span class="error required"
                                            *ngIf="visitExperience?.get('visitoutcome')?.errors?.required && visitExperience?.get('visitoutcome')?.touched">Choose
                                            your reaction to continue</span>
                                    </div>
                                </div>

                                <!-- Posisitive -->
                                <div class="col-lg-12 col-md-12 colsm-12 text-center mt-4"
                                    *ngIf="visitExperience?.value?.visitoutcome === 'POSITIVE'">
                                    <div class="btn-group response-content" *ngFor="let item of valuesGoodList">
                                        <input type="checkbox" formControlName="positive"
                                            (change)="valueCheckVisitExp($event)" value="{{item}}" class="btn-check"
                                            id=visit{{item}} autocomplete="off" value={{item}}>
                                        <label class="btn btn-light" for=visit{{item}}>{{item}}</label>
                                    </div>
                                    <span class="error required"
                                        *ngIf="visitExperience?.get('positive')?.errors?.required && visitExperience?.get('positive')?.touched">Choose
                                        any one to continue</span>
                                </div>

                                <!-- Negative -->
                                <div class="col-lg-12 col-md-12 colsm-12 text-center mt-4"
                                    *ngIf="visitExperience?.value?.visitoutcome === 'NEGATIVE'">
                                    <div class="btn-group response-content" *ngFor="let item of valuesUglyList">
                                        <input type="checkbox" formControlName="negative"
                                            (change)="valueCheckVisitExp($event)" value="{{item}}" class="btn-check"
                                            id=visitNeg{{item}} autocomplete="off" value={{item}}>
                                        <label class="btn btn-light" for=visitNeg{{item}}>{{item}}</label>
                                    </div>
                                    <span class="error required"
                                        *ngIf="visitExperience?.get('negative')?.errors?.required && visitExperience?.get('negative')?.touched">Choose
                                        any one to continue</span>
                                </div>

                                <div class="col-lg-12 col-md-12 colsm-12 mt-3">
                                    <div class="additional-data">
                                        <label>Tell us what we need to improve? (optional)</label>
                                        <textarea class="input-text" name="feedback" formControlName="feedbackResponse"
                                            id="feedback" rows="6" placeholder="Enter your feedback here"></textarea>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                                    <button type="button" class="btn btn-primary continue-btn" id="feedback_continue2"
                                        (click)="checkValidVisit()" awNextStep>Continue</button>
                                </div>

                            </div>
                        </form>
                    </aw-wizard-step>

                    <aw-wizard-step [canEnter]="visitExpCheckedValues?.length > 0 || false"
                        (stepEnter)="callFeedbackQuestions('Provider')">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 colsm-12 text-center mb-3">
                                <h5 class="heading">How is the Consultation Experience?</h5>
                            </div>
                        </div>

                        <form class="visit-exp-form" [formGroup]="consultationExperience">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 colsm-12">
                                    <div class="row smile text-center">
                                        <div class="col-6">
                                            <input type="radio" class="btn-check"
                                                (change)="changeConsultationExpSmiley()"
                                                formControlName="consultationoutcome" value="POSITIVE"
                                                id="btn-check-consultation" name="consultationoutcome">

                                            <label class="btn btn-light btn-lg smiley-check"
                                                for="btn-check-consultation">
                                                <img *ngIf="!consultationExperience?.value?.consultationoutcome || consultationExperience?.value?.consultationoutcome !== 'POSITIVE'"
                                                    src="../../../assets/images/smile-black.svg" alt="">
                                                <img *ngIf="consultationExperience?.value?.consultationoutcome === 'POSITIVE'"
                                                    src="../../../assets/images/smile.svg" alt="">
                                            </label>
                                        </div>
                                        <div class="col-6">
                                            <input type="radio" class="btn-check"
                                                (change)="changeConsultationExpSmiley()" name="consultationoutcome"
                                                formControlName="consultationoutcome" value="NEGATIVE"
                                                id="btn-check2-consultation">
                                            <label class="btn btn-light btn-lg smiley-check"
                                                for="btn-check2-consultation"><img
                                                    *ngIf="consultationExperience?.value?.consultationoutcome !== 'NEGATIVE'"
                                                    src="../../../assets/images/smile_1.svg" alt="">
                                                <img *ngIf="consultationExperience?.value?.consultationoutcome === 'NEGATIVE'"
                                                    src="../../../assets/images/smile_1_red.svg" alt=""></label>
                                        </div>
                                        <span class="error required"
                                            *ngIf="consultationExperience?.get('consultationoutcome')?.errors?.required && consultationExperience?.get('consultationoutcome')?.touched">Choose
                                            your reaction to continue</span>
                                    </div>
                                </div>

                                <!-- Posisitive -->
                                <div class="col-lg-12 col-md-12 colsm-12 text-center mt-4"
                                    *ngIf="consultationExperience?.value?.consultationoutcome === 'POSITIVE'">
                                    <div class="btn-group response-content" *ngFor="let item of valuesGoodList">
                                        <input type="checkbox" formControlName="positive"
                                            (change)="valueCheckConsultationExp($event)" value="{{item}}"
                                            class="btn-check" id=consultation{{item}} autocomplete="off" value={{item}}>
                                        <label class="btn btn-light" for=consultation{{item}}>{{item}}</label>
                                    </div>
                                    <span class="error required"
                                        *ngIf="consultationExperience?.get('positive')?.errors?.required && consultationExperience?.get('positive')?.touched">Choose
                                        any one to continue</span>
                                </div>

                                <!-- Negative -->
                                <div class="col-lg-12 col-md-12 colsm-12 text-center mt-4"
                                    *ngIf="consultationExperience?.value?.consultationoutcome === 'NEGATIVE'">
                                    <div class="btn-group response-content" *ngFor="let item of valuesUglyList">
                                        <input type="checkbox" formControlName="negative"
                                            (change)="valueCheckConsultationExp($event)" value="{{item}}"
                                            class="btn-check" id=consultationNeg{{item}} autocomplete="off"
                                            value={{item}}>
                                        <label class="btn btn-light" for=consultationNeg{{item}}>{{item}}</label>
                                    </div>
                                    <span class="error required"
                                        *ngIf="consultationExperience?.get('negative')?.errors?.required && consultationExperience?.get('negative')?.touched">Choose
                                        any one to continue</span>
                                </div>

                                <div class="col-lg-12 col-md-12 colsm-12 mt-3">
                                    <div class="additional-data">
                                        <label>Tell us what we need to improve? (optional)</label>
                                        <textarea class="input-text" name="feedback" formControlName="feedbackResponse"
                                            id="feedback" rows="6" placeholder="Enter your feedback here"></textarea>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                                    <button type="button" class="btn btn-primary continue-btn" id="careEvents_continue"
                                        (click)="checkValidConsultaton()" awNextStep>Continue</button>
                                </div>

                            </div>
                        </form>
                    </aw-wizard-step>

                    <aw-wizard-step (stepEnter)="callFeedbackQuestions('Platform')"
                        [canEnter]="consultationExpCheckedValues?.length > 0 || false">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 colsm-12 text-center mb-3">
                                <h5 class="heading">How is the Platform Experience?</h5>
                            </div>
                        </div>
                        <form class="visit-exp-form" [formGroup]="platformExperience">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 colsm-12">
                                    <div class="row smile text-center">
                                        <div class="col-6">
                                            <input type="radio" class="btn-check" (change)="changePlatformExpSmiley()"
                                                formControlName="platformoutcome" value="POSITIVE"
                                                id="btn-check-platform" name="platformoutcome">

                                            <label class="btn btn-light btn-lg smiley-check" for="btn-check-platform">
                                                <img *ngIf="!platformExperience?.value?.platformoutcome || platformExperience?.value?.platformoutcome !== 'POSITIVE'"
                                                    src="../../../assets/images/smile-black.svg" alt="">
                                                <img *ngIf="platformExperience?.value?.platformoutcome === 'POSITIVE'"
                                                    src="../../../assets/images/smile.svg" alt="">
                                            </label>
                                        </div>
                                        <div class="col-6">
                                            <input type="radio" class="btn-check" (change)="changePlatformExpSmiley()"
                                                name="platformoutcome" formControlName="platformoutcome"
                                                value="NEGATIVE" id="btn-check2-platform">
                                            <label class="btn btn-light btn-lg smiley-check"
                                                for="btn-check2-platform"><img
                                                    *ngIf="platformExperience?.value?.platformoutcome !== 'NEGATIVE'"
                                                    src="../../../assets/images/smile_1.svg" alt="">
                                                <img *ngIf="platformExperience?.value?.platformoutcome === 'NEGATIVE'"
                                                    src="../../../assets/images/smile_1_red.svg" alt=""></label>
                                        </div>
                                        <span class="error required"
                                            *ngIf="platformExperience?.get('platformoutcome')?.errors?.required && platformExperience?.get('platformoutcome')?.touched">Choose
                                            your reaction to continue</span>
                                    </div>
                                </div>

                                <!-- Posisitive -->
                                <div class="col-lg-12 col-md-12 colsm-12 text-center mt-4"
                                    *ngIf="platformExperience?.value?.platformoutcome === 'POSITIVE'">
                                    <div class="btn-group response-content" *ngFor="let item of valuesGoodList">
                                        <input type="checkbox" formControlName="positive"
                                            (change)="valueCheckPlatformExp($event)" value="{{item}}" class="btn-check"
                                            id=platform{{item}} autocomplete="off" value={{item}}>
                                        <label class="btn btn-light" for=platform{{item}}>{{item}}</label>
                                    </div>
                                    <span class="error required"
                                        *ngIf="platformExperience?.get('positive')?.errors?.required && platformExperience?.get('positive')?.touched">Choose
                                        any one to continue</span>
                                </div>

                                <!-- Negative -->
                                <div class="col-lg-12 col-md-12 colsm-12 text-center mt-4"
                                    *ngIf="platformExperience?.value?.platformoutcome === 'NEGATIVE'">
                                    <div class="btn-group response-content" *ngFor="let item of valuesUglyList">
                                        <input type="checkbox" formControlName="negative"
                                            (change)="valueCheckPlatformExp($event)" value="{{item}}" class="btn-check"
                                            id=platformNeg{{item}} autocomplete="off" value={{item}}>
                                        <label class="btn btn-light" for=platformNeg{{item}}>{{item}}</label>
                                    </div>
                                    <span class="error required"
                                        *ngIf="platformExperience?.get('negative')?.errors?.required && platformExperience?.get('negative')?.touched">Choose
                                        any one to continue</span>
                                </div>

                                <div class="col-lg-12 col-md-12 colsm-12 mt-3">
                                    <div class="additional-data">
                                        <label>Tell us what we need to improve? (optional)</label>
                                        <textarea class="input-text" name="feedback" formControlName="feedbackResponse"
                                            id="feedback" rows="6" placeholder="Enter your feedback here"></textarea>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                                    <button type="button" class="btn btn-primary continue-btn" id="feedback_submitFeedback"
                                        (click)="submitYourFeedback()" awNextStep>Submit Your Feedback</button>
                                </div>

                            </div>
                        </form>
                    </aw-wizard-step>
                </aw-wizard>
            </div>
        </div>
        <!-- spinner -->
        <div class="custom-loader justify-content-center" *ngIf="loadSpinner" id="spinnerDisplay">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div id="book-appointment-modal" [style.display]="exceptionalModal ? 'block' : 'none'" class="modal" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-body">
                <h5 style="text-align: center;padding: 15px;">{{notifyText}}</h5>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <button type="button" *ngIf="!feedbackSentSuccess" class="btn btn-default ok-book" id="feedback_ok2"
                            data-dismiss="modal" (click)="reloadLocation()">Ok</button>
                        <button type="button" *ngIf="feedbackSentSuccess" class="btn btn-default ok-book" id="feedback_ok1"
                            data-dismiss="modal" (click)="replaceCareEcoWebsite()">Ok</button>

                    </div>
                </div>

            </div>
        </div>
    </div>
</div>