import { Component, HostListener, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Location } from '@angular/common';
import { Subject } from 'rxjs/internal/Subject';
import { UiModalComponent } from './shared/components/ui-modal/ui-modal.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'userweb-redesign';
  public showFooter:boolean  = false;
  public invoiceBoard:any;

  private userInactive: Subject<any> = new Subject();
  @ViewChild('sessionExpiredInActivity') inActivityExpire!: UiModalComponent;

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander(event: any) {
    window.scrollTo(0, 0);
  }

  routerEvents: any;
  constructor(private router: Router,private Location: Location) {
    this.routerEvents = this.router.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {
          this.getRedirectUrl(event.url);
        }
      }
    )
    let routerUrl:any  =  this.Location.path()
    let routerPath:any = routerUrl?.split('/');
      if(routerPath.includes('invoiceBoard') || routerPath.includes('create')){
        this.showFooter = false;
       }else{
        this.showFooter= true;
       }
  }

  private getRedirectUrl(url: string): void {
    let newUrl: string = '';
    if (url?.includes('bookappt')) {
      newUrl = url?.replace('bookappt', 'scheduleappt');
      this.router?.navigate([newUrl]);
      return;
    }
    if (url?.includes('patientOutreach')) {
      newUrl = url?.replace('patientOutreach', 'outReach');
      this.router?.navigate([newUrl]);
      return;
    }
    if (url?.includes('paymentBill')) {
      newUrl = url?.replace('paymentBill', 'payYourBill');
      this.router?.navigate([newUrl]);
      return;
    }
    if (url?.includes('teliHealth')) {
      newUrl = url?.replace('teliHealth', 'teleHealth');
      this.router?.navigate([newUrl]);
      return;
    }
    if(url?.includes('invoiceBoard') || url?.includes('create') || 
    url?.includes('view')|| url?.includes('duplicate')){
      this.showFooter = false;
     }else{
      this.showFooter= true;
      sessionStorage.setItem('isLogin', 'false');
      return;
     }
  }

}
