<section class="page-hero footer-sections">
    <div class="container">
        <h3 class="terms_title">Terms of Use</h3>
        <h6>Last Updated: January 06, 2022</h6>

        <div class="terms_content">
            <p>These Terms of Use ("Terms") govern your access to and use of this application or website ("Site"),
                made available to you by CareEco Corporation, doing business as CareEco ("CareEco," "we," "us," or
                "our").</p>
            <p>By accessing or using the Site, you ("you") that you have read and understand these Terms and our Privacy
                Policy.
                If you do not agree with these Terms or our Privacy Policy, do not access or use the Site or mobile app.
            </p>
            <p>We reserve the right to modify these Terms at any time. All changes will be effective immediately upon
                posting
                to the Site and,
                by accessing or using the Site after changes are posted,
                you agree to those changes. Material changes will be conspicuously posted on the Site.</p>

            <div class="block">
                <strong>
                    <h5>Privacy Policy:</h5>
                </strong>
                <p>We may collect certain information from your use of the Site as set forth in our Privacy Policy,
                    which is incorporated into these Terms by this reference.
                    Your use of the Site constitutes your consent to the information collection, use, and sharing as set
                    forth in
                    our Privacy Policy.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Intellectual Property Rights:</h5>
                </strong>
                <p>The Site, including its text, audio, video, graphics, charts, photographs, interfaces, icons, other
                    content,
                    software, computer code,
                    data, trademarks, logos, slogans, names of products and services, documentation, other components,
                    and the
                    design, selection,
                    and arrangement of content is exclusively the property of CareEco or, as applicable, its suppliers
                    and
                    licensors, and
                    is protected by copyright, trademark, and other intellectual property laws. Any unauthorized use of
                    any
                    trademarks, trade dress,
                    or any other intellectual property belonging to CareEco or any third party is strictly prohibited
                    and may
                    fully be prosecuted of the law.
                    The Site may contain references to third-party marks and copies of third-party copyrighted
                    materials,
                    which are the property of their respective owners.
                </p>
                <p>Copyright © 2022 CareEco Corporation. All rights reserved.</p>
            </div>

            <div class="block">
                <strong>
                    <h5>Use of the Site; Compliance with Laws:</h5>
                </strong>
                <ul>
                    <li>You may download and print one copy of the Site's visible content for your personal and
                        noncommercial use,
                        provided you do not modify or delete any copyright, trademark, or other proprietary notices.
                    </li>
                    <li>You may not otherwise copy, reproduce, display, duplicate, sell, publish, post, license,
                        distribute, or
                        create derivative works of the Site or any part of the Site without the prior written consent of
                        CareEco.
                        You may not use the Site for unlawful purposes. You may not access, use, or copy any portion of
                        the Site or
                        its content
                        through the use of indexing agents, spiders, scrapers, bots, web crawlers, or other automated
                        devices or
                        mechanisms.
                        You may not use the Site to post or transmit any material that contains any viruses, Trojan
                        horses, worms,
                        time bombs,
                        cancelbots, malware, adware, or other computer programming routines that may damage, interfere
                        with,
                        surreptitiously intercept,
                        or expropriate any system, data, or personal information. User activities that aim to render the
                        Site or
                        associated services
                        inoperable or to make their use more difficult are prohibited. You may not submit inaccurate
                        information via
                        the Site, commit
                        fraud or falsify information in connection with your use of the Site, or act maliciously against
                        the
                        business interests or
                        reputation of CareEco.</li>
                    <li>You are responsible for complying with all local, state, and federal laws and regulations that
                        apply to
                        your use of the Site.</li>
                </ul>
            </div>

            <div class="block">
                <strong>
                    <h5>CareEco Service:</h5>
                </strong>
                <p> The Site or mobile app provides access to the CareEco Service, made available to you by CareEco.
                    All access to and use of the CareEco Service is governed by our Terms of Service, these Terms, and
                    our Privacy Policy.
                </p>
                <p>NO WARRANTY. THE SITE IS PROVIDED "AS IS," "AS AVAILABLE," AND WITHOUT ANY WARRANTY OF ANY KIND.
                    CAREECO MAKES
                    COMMERCIALLY REASONABLE EFFORTS TO ENSURE THAT ALL MATERIAL, INFORMATION, AND DATA ON THE SITE IS
                    ACCURATE AND
                    RELIABLE, BUT NEITHER ACCURACY NOR RELIABILITY CAN BE GUARANTEED. CAREECO DOES NOT GUARANTEE THE
                    QUALITY,
                    COMPLETENESS, TIMELINESS, OR AVAILABILITY OF THE SITE. CAREECO DOES NOT WARRANT THAT THE SITE WILL
                    BE UNINTERRUPTED OR
                    ERROR-FREE, THAT ANY DEFECTS IN THE SITE WILL BE CORRECTED, OR THAT THE SITE OR THE SERVERS THAT
                    MAKE THE SITE
                    AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL CONDITIONS OR COMPONENTS. CAREECO IS NOT RESPONSIBLE
                    FOR ANY
                    TYPOGRAPHICAL ERRORS ON THE SITE.</p>
                <p>TO THE MAXIMUM EXTENT PERMITTED BY LAW, CAREECO EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND WITH
                    RESPECT TO THE
                    SITE, INCLUDING THOSE REGARDING AVAILABILITY, QUALITY, ACCURACY, MERCHANTABILITY, FITNESS FOR ANY
                    USE OR PURPOSE,
                    COMPATIBILITY WITH ANY STANDARDS OR USER REQUIREMENTS, TITLE, AND NONINFRINGEMENT. CAREECO HAS NO
                    RESPONSIBILITY FOR
                    THE TIMELINESS, DELETION, MISDELIVERY, OR FAILURE TO STORE ANY USER COMMUNICATION.</p>
                <p>YOUR USE OF THE SITE IS AT YOUR OWN RISK AND YOU, ALONE, ARE RESPONSIBLE FOR ANY DAMAGE TO YOUR
                    COMPUTER HARDWARE,
                    SOFTWARE, SYSTEMS, AND NETWORKS, ANY LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY INFORMATION
                    FROM THE SITE, AND
                    FOR ANY OTHER DAMAGE THAT MAY BE INCURRED. WE MAKE NO REPRESENTATION THAT THE SITE IS APPROPRIATE OR
                    AVAILABLE FOR USE
                    IN LOCATIONS OTHER THAN THE UNITED STATES. IF YOU CHOOSE TO ACCESS THE SITE FROM LOCATIONS OTHER
                    THAN THE UNITED
                    STATES, YOU DO SO AT YOUR OWN RISK AND YOU ARE RESPONSIBLE FOR COMPLYING WITH APPLICABLE LAWS AND
                    REGULATIONS. NO
                    ADVICE OR INFORMATION, ORAL OR WRITTEN, OBTAINED BY YOU FROM CAREECO OR IN ANY MANNER FROM THE SITE
                    CREATES ANY
                    WARRANTY.</p>
                <p>LIMITATION OF LIABILITY. TO THE EXTENT PERMITTED BY LAW, IN NO EVENT WILL CAREECO, ITS AFFILIATES, OR
                    ITS OR THEIR
                    RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, REPRESENTATIVES, CONSULTANTS, AGENTS, SUPPLIERS, OR
                    LICENSORS BE LIABLE FOR
                    ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL LOSS OR DAMAGE, OR
                    ANY OTHER LOSS OR
                    DAMAGE OF ANY KIND, ARISING OUT OF OR IN CONNECTION WITH THE SITE OR YOUR USE OF THE SITE (INCLUDING
                    THE INPUT OF
                    PERSONALLY IDENTIFIABLE AND OTHER INFORMATION INTO THE SITE), WHETHER THE CLAIM IS BASED IN
                    CONTRACT, TORT (INCLUDING
                    NEGLIGENCE), STRICT LIABILITY, WARRANTY, OR OTHERWISE, AND EVEN IF CAREECO HAS EXPRESS KNOWLEDGE OF
                    THE POSSIBILITY OF
                    THE LOSS OR DAMAGE. YOUR SOLE AND EXCLUSIVE REMEDY IS TO STOP ACCESSING AND USING THE SITE. WITHOUT
                    LIMITING THE
                    FOREGOING, IN NO EVENT WILL CAREECO'S LIABILITY TO YOU EXCEED $100, EVEN IF THIS REMEDY FAILS OF ITS
                    ESSENTIAL
                    PURPOSE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO SOME OF
                    THE ABOVE
                    EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU. IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE YOUR
                    RIGHTS WITH RESPECT
                    TO CALIFORNIA CIVIL CODE SECTION 1542, WHICH SAYS “GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH
                    THE CREDITOR DOES
                    NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH, IF KNOWN BY
                    HIM MUST HAVE
                    MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR”</p>
            </div>

            <div class="block">
                <strong>
                    <h5>Third-Party Web Sites:</h5>
                </strong>
                <p>The Site may link to, or be linked to, web sites not maintained or controlled by CareEco. Those links
                    are provided as
                    a convenience and CareEco is not responsible for examining or evaluating the content or accuracy of,
                    and does not
                    warrant or endorse, any third-party web site or any products or services made available through
                    those web sites.
                    Please take care when leaving the Site to visit a third-party web site. You should read the terms of
                    use and privacy
                    policy for each web site that you visit.</p>
            </div>


            <div class="block">
                <strong>
                    <h5>Feedback:</h5>
                </strong>
                <p>CareEco welcomes comments regarding the Site. If you submit comments or feedback to us regarding the
                    Site, they
                    will not be considered or treated as confidential. We may use any comments and feedback that you
                    send us in our
                    discretion and without attribution or compensation to you.</p>
            </div>


            <div class="block">
                <strong>
                    <h5>Disputes:</h5>
                </strong>
                <p>These Terms are governed by the laws of the state of Delaware, without regards for its conflict of
                    law principles.
                    Venue is exclusively in the state or federal courts, as applicable, located in San Francisco,
                    California, with
                    respect to any dispute arising under these Terms unless otherwise determined by CareEco in its sole
                    discretion and
                    the parties expressly agree to the exclusive jurisdiction of those courts. Any cause of action or
                    other claim with
                    respect to the Site must be commenced within one year after the cause of action or claim arises.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Assignment:</h5>
                </strong>
                <p>We may assign our rights and delegate our duties under these Terms at any time to any party without
                    notice to you.
                    You may not assign these Terms without our prior written consent.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Third-Party Beneficiaries:</h5>
                </strong>
                <p>These Terms do not confer any rights, remedies, or benefits upon any person other than you, except
                    that our
                    affiliates are third-party beneficiaries of these Terms.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Interpretation:</h5>
                </strong>
                <p>These Terms, including our Privacy Policy, and, if applicable, our Terms of Service, are the entire
                    agreement
                    between you and CareEco with respect to your access to and use of the Site. CareEco's failure to
                    enforce any
                    provision in these Terms will not constitute a waiver of that provision or any other provision. Any
                    waiver of any
                    provision of these Terms will be effective only if in writing and signed by CareEco. If any
                    provision of these Terms
                    is held invalid, void, or unenforceable, that provision will be considered severable from the
                    remaining provisions
                    and the remaining provisions will remain in full force and effect. The headings in these Terms are
                    for convenience
                    only and do not affect the interpretation of these Terms. These Terms will inure to the benefit of
                    CareEco's
                    successors and assigns.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Survival:</h5>
                </strong>
                <p>Any provisions of these Terms that are intended to survive termination (including any provisions
                    regarding
                    indemnification or limitation of our liability) will continue in effect beyond any termination of
                    these Terms or of
                    your access to the Site.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Electronic Communications:</h5>
                </strong>
                <p>These Terms and any other documentation, agreements, notices, or communications between you and
                    CareEco may be
                    provided to you electronically to the extent permissible by law. Please print or otherwise save a
                    copy of all
                    documentation, agreements, notices, and other communications for your reference.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Healthcare Services:</h5>
                </strong>
                <p>None of the Site content (other than information you receive from Healthcare Professionals) should be
                    considered
                    medical advice or an endorsement, representation or warranty that any treatment or medication is
                    safe or effective,
                    for you. CareEco, does not practice medicine or any other licensed profession, and does not
                    interfere with the
                    practice of medicine or any other licensed profession by Healthcare Professionals, each of whom is
                    responsible for
                    his or her services and compliance with the requirements applicable to his or her profession and
                    license. Neither
                    CareEco nor any third parties who promote the Services or provide you with a link to the Services
                    shall be liable
                    for any professional advice you obtain from a Healthcare Professional via the Services.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Contact Us:</h5>
                </strong>
                <p>Please direct any questions and concerns regarding these Terms to us by email at support@levlivet.net
                </p>
            </div>
        </div>
    </div>
</section>