<section class="page-hero footer-sections">
    <div class="container">
        <h3 class="terms_title">Informed Consent for Tele-Health Services</h3>
        <h6>Last Updated: January 06, 2022</h6>

        <div class="terms_content">
            <p>Tele-Health involves the use of electronic communications to enable health care providers at different
                locations to
                share individual patient medical information for the purpose of improving patient care. The information
                may be used
                for diagnosis, therapy, follow-up and/or education, and may include any of the following:</p>
            <ul>
                <li> Patient medical records</li>
                <li> Medical images</li>
                <li> Live two-way audio and video</li>
                <li> Output data from medical devices and sound and video files</li>

            </ul>
            <p>Electronic systems used will incorporate network and software security protocols to protect the
                confidentiality of
                patient identification and imaging data and will include measures to safeguard the data and to ensure
                its integrity
                against intentional or unintentional corruption.</p>

            <div class="block">
                <strong>
                    <h5>Expected Benefits:</h5>
                </strong>
                <p>Improved access to medical care by enabling a patient to initiate a visit and consult a healthcare
                    practitioner at a distant/other sites.</p>
                <p>More efficient medical evaluation and management.</p>
                <p>Obtaining expertise of a distant specialist.</p>
            </div>
            <div class="block">
                <strong>
                    <h5>Possible Risks:</h5>
                </strong>
                <p>As with any medical procedure, there are potential risks associated with the use of Tele-Health.
                    These risks include, but may not be limited to:
                </p>
                <p>In rare cases, information transmitted may not be sufficient (e.g. poor resolution of images) to
                    allow for appropriate medical decision making by the physician and consultant(s);</p>
                <p>Delays in medical evaluation and treatment could occur due to deficiencies or failures of the
                    equipment;</p>
                <p>In very rare instances, security protocols could fail, causing a breach of privacy of personal
                    medical information;</p>
                <p>In rare cases, a lack of access to complete medical records may result in adverse drug interactions
                    or allergic reactions or other judgment errors;</p>
            </div>

            <div class="block">
                <strong>
                    <h5>By agreeing to this form, I understand the following:</h5>
                </strong>
                <ul>
                    <li> I understand that the laws that protect privacy and the confidentiality of medical information
                        also
                        apply to Tele-Health, and that no information obtained in the use of Tele-Health, which
                        identifies
                        me, will be disclosed to researchers or other entities without my consent.
                    </li>
                    <li> I understand that I have the right to withhold or withdraw my consent to the use of
                        Tele-Health in
                        the course of my care at any time, without affecting my right to future care or treatment. </li>
                    <li> I understand that I have the right to inspect all information obtained and recorded in the
                        course of
                        a Tele-Health interaction, and may receive copies of this information for a reasonable fee.
                    </li>
                    <li> I understand that a variety of alternative methods of medical care may be available to me, and
                        that
                        I may choose one or more of these at any time. My provider has explained the alternatives to my
                        satisfaction. </li>
                    <li> I understand that Tele-Health may involve electronic communication of my personal medical
                        information to other medical practitioners who may be located in other areas, including out of
                        state. </li>
                    <li> I understand that it is my duty to inform my provider of electronic interactions regarding my
                        care
                        that I may have with other healthcare providers. </li>
                    <li> I understand that I may expect the anticipated benefits from the use of Tele-Health in my
                        care, but
                        that no results can be guaranteed or assured. </li>
                </ul>

            </div>

            <div class="block">
                <strong>
                    <h5>Patient Consent To The Use of Tele-Health</h5>
                </strong>
                <p>I have read and understand the information provided above regarding Tele-Health, have discussed it
                    with my physician or such assistants as may be designated, and all of my questions have been
                    answered to my satisfaction. I hereby give my informed consent for the use of Tele-Health in my
                    medical care.</p>
            </div>
        </div>
    </div>
</section>