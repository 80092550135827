import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UiModalComponent } from '../ui-modal/ui-modal.component';
import { HttpService } from '../../http.service';

@Component({
  selector: 'app-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrls: ['./upload-documents.component.scss']
})
export class UploadDocumentsComponent implements OnInit, AfterViewInit {
  public allAppntHistory = [];
  public loginDetails: any;
  public waitTimeDetails: any;
  public notifyText!: string;
  public isSuccessNotify!: boolean;
  @ViewChild('fileUpload') public fileUpload!: ElementRef;
  public loadSpinner!: boolean;
  public selectedAppointmentUser: any;
  @ViewChild('files') public files!: ElementRef;
  public documentForm: FormGroup;
  @ViewChild('uploadDocuments') uploadDocumentsModal!: UiModalComponent;
  public allDocumentTypes = ["Lab Results", "Radiology Results", "Referral", "Wellness Forms", "Other"];
  @Output() notifyDocUpload = new EventEmitter<any>();
  @Output() DocIframeUpdate = new EventEmitter<any>();

  public addCollectionTypes = [
    { value: 'Encounter', desc: 'Encounter' },
    { value: 'Account', desc: 'Account' },
    { value: 'Facility', desc: 'Facility' }
  ];
  public addDocumentTypes = [];
  public uploadAdditioinalDocsForm: FormGroup;
  public isReadonly: boolean = false;
  @Input() collection: any;
  @Input() documentSelected: any;

  @Input() hideInsurance!: boolean;
  @Input() userInfoDetailsForm!: FormGroup;
  @Input() uploadIdForm!: FormGroup;
  @Input() patientDetailsInfo: any;
  @Input() userdataupdate!: boolean;
  public drivingLicenseFile!: string;
  public insuranceCardFile!: string;
  public getSelectedInsuValue!: any;
  @Input() insuranceDetails!: FormGroup;
  public documentdetails = [];
  public insuranceCardDetails!: FormArray;
  public UploadedFiletype: any;
  public documentsList: any;
  public isFileUploaded: boolean = false;
  public uploadedFileInformation: any;
  @Output() onDocumentUploadChange = new EventEmitter<boolean>();
  public lateNotifyPatient: any;
  @Output() invoiceUsersDocs = new EventEmitter<any>();
  public exceptionalModal!: boolean;
  private isDocsUploadUser!: boolean;

  constructor(private httpService: HttpService, private router: Router, private formBuilder: FormBuilder, private activateRoute: ActivatedRoute) {
    this.documentForm = formBuilder?.group({
      'documentType': [null, Validators?.required],
      'documentName': [null, Validators?.required],
      'multipartFile': [null, Validators?.required],
    });

    this.uploadAdditioinalDocsForm = this.formBuilder?.group({
      'documentType': [null, Validators?.required],
      'documentName': [null, Validators?.required],
      'multipartFile': [null, Validators?.required],
      'collectionType': [null],
    });

  }

  ngOnInit(): void {
    this.uploadAdditioinalDocsForm?.get('collectionType')?.setValue(this.collection);
    if (this.documentSelected !== '') {
      this.uploadAdditioinalDocsForm?.get('documentType')?.setValue(this.documentSelected);
      this.isReadonly = true;
    }
  }

  ngAfterViewInit(): void {
    //this.resetDocumentsPopup();
  }

  // Invoice level documents open
  public invoiceDocumentsOpen(user: any, collection: any, documentSelected: any): void {
    this.isDocsUploadUser = true;
    this.openUploadDocument(user, collection, documentSelected);
  }

  public openUploadDocument(user: any, collection: any, documentSelected: any): void {
    this.resetDocumentsPopup();
    this.collection = collection;
    this.documentSelected = documentSelected;
    this.uploadAdditioinalDocsForm?.get('collectionType')?.setValue(this.collection);

    if (this.documentSelected !== '') {
      this.uploadAdditioinalDocsForm?.get('documentType')?.setValue(this.documentSelected);
      this.isReadonly = true;
    }
    else {
      this.uploadAdditioinalDocsForm?.get('documentType')?.setValue(null);
      this.isReadonly = false;
    }

    this.selectedAppointmentUser = user;
    this.getDocumenttypeList(this.isDocsUploadUser);
    this.uploadDocumentsModal?.show();
  }

  public openNotificationJournalNotesAdd(): void {
    const uploadObj = {
      id: this.collection === 'Encounter' ? this.selectedAppointmentUser?.id : null,
      documentType: this.uploadAdditioinalDocsForm?.value?.documentType
    }
    //console.log(uploadObj);
    this.notifyDocUpload?.emit(uploadObj);
    this.resetDocumentsPopup();
  }

  public resetDocumentsPopup() {
    this.uploadAdditioinalDocsForm?.reset();
    this.uploadAdditioinalDocsForm?.get('multipartFile')?.setValue(null);
    this.fileUpload.nativeElement.value = null;
    this.documentsList = null;
  }

  public getDocumenttypeList(isDocsUploadUser?: any) {
    // const collectionType = event.value;
    const collectionType = this.uploadAdditioinalDocsForm?.get('collectionType')?.value;
    const action = `record/listDocumentTypes?collectionType=${collectionType}`;
    this.addDocumentTypes = [];
    this.httpService?.makeGetRequest(action)?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.addDocumentTypes = data?.responseObject;
        if(isDocsUploadUser) {
          this.addDocumentTypes = this.addDocumentTypes?.filter((docItem: any) => docItem === 'Clinical Documents' || docItem === 'Referral' || docItem === 'Other' || docItem === 'Invoice') || [];
          return;
        }
        if (collectionType == 'Account' && this.documentSelected == '') {
          this.addDocumentTypes?.forEach((formList: any, index: number) => {
            if (formList == 'Image') {
              this.addDocumentTypes = this.addDocumentTypes?.filter((listItem: any) => listItem !== 'Image') || [];
            }
            else if (formList == 'Identification Record') {
              this.addDocumentTypes = this.addDocumentTypes?.filter((listItem: any) => listItem !== 'Identification Record') || [];
            }

            else if (formList == 'Insurance') {
              this.addDocumentTypes = this.addDocumentTypes?.filter((listItem: any) => listItem !== 'Insurance') || [];
            }
            else if (formList == 'Vaccination Record') {
              this.addDocumentTypes = this.addDocumentTypes?.filter((listItem: any) => listItem !== 'Vaccination Record') || [];
            }
          });
        }
        else if (collectionType == 'Encounter' && this.documentSelected == '') {
          this.addDocumentTypes?.forEach((formList: any, index: number) => {

            if (formList == 'Claim Journal') {
              this.addDocumentTypes = this.addDocumentTypes?.filter((listItem: any) => listItem !== 'Claim Journal') || [];
            }
          });
        }
      }
    },
      error => {
        this.notifyText = error?.message || error?.msg || 'Something went wrong please try again.'
        this.isSuccessNotify = false;
        this.loadSpinner = false;
        this.exceptionalModal = true
      })
  }

  public checkDocumentType(): void {
    if (this.uploadAdditioinalDocsForm?.get('documentType')?.invalid) {
      this.uploadAdditioinalDocsForm?.markAllAsTouched();
      this.notifyText = 'Document Type is required to upload document';
      this.isSuccessNotify = false;
      this.exceptionalModal = true;
      this.fileUpload.nativeElement.value = "";
      return;
    }
  }

  // Delete  Uploaded File
  public deleteSelectedFile(index?: number): void {
    this.documentsList = null;
    this.clearFileUpload();
  }

  // on upload get the file
  public uploadFileSelect(event: any): void {
    this.documentsList = null;
    if (event.target.files.length > 0) {
      const files = event.target.files[0];
      const fileSize = files.size / 1024 / 1024;
      this.documentsList = files;
      if (fileSize <= 25 && (files.type === 'application/pdf' || files.type === 'image/png' || files.type === 'image/jpeg')) {
        this.uploadAdditioinalDocsForm?.get('documentName')?.setValue(files.name);
        this.uploadAdditioinalDocsForm?.get('multipartFile')?.setValue(files);
        this.UploadedFiletype = files.type;
      }
      else {
        this.uploadAdditioinalDocsForm?.get('multipartFile')?.setValue(null);
        this.fileUpload.nativeElement.value = null;
        this.notifyText = "Supports only PDF, PNG and JPEG files and Files larger than 25MB cannot be uploaded";
        this.isSuccessNotify = false;
        this.exceptionalModal = true;
      }
    }
    this.clearFileUpload();
  }

  // Clear File Upload
  public clearFileUpload(): void {
    this.fileUpload.nativeElement.value = null;
  }

  public getCurrentTimeStamp() {
    const current = new Date();
    current.setHours(0)
    current.setMinutes(0)
    current.setSeconds(0)
    current.setMilliseconds(0)
    const timestamp = current.getTime();
    return timestamp;
  }

  public uploadAdditionalDocuments(documentUpload?: UiModalComponent): void {
    if (this.uploadAdditioinalDocsForm?.invalid) {
      this.uploadAdditioinalDocsForm?.markAllAsTouched();
      return;
    }
    const formData = new FormData();
    const timestamp = this.getCurrentTimeStamp();
    const documentType = this.uploadAdditioinalDocsForm?.get('documentType')?.value;
    const nameValue = this.uploadAdditioinalDocsForm?.get('documentName')?.value;
    const isFile = true;
    const documentName = documentType + "_" + timestamp + "_" + nameValue;
    this.uploadAdditioinalDocsForm?.get('documentName')?.setValue(documentName);
    let actionAppend = '';
    // formData.append('idFile', this.uploadAdditioinalDocsForm?.value?.multipartFile);
    if (this.collection == 'Encounter') {
      if (documentType == 'Consent Form') {
        formData.append('file', this.uploadAdditioinalDocsForm?.value?.multipartFile);
        actionAppend = `SignForm/uploadSignedConsentForm?appointmentId=${this.selectedAppointmentUser?.id}&documentName=${documentName}`;
      }
      else {
        formData.append('idFile', this.uploadAdditioinalDocsForm?.value?.multipartFile);
        actionAppend = `record/addDocument?appointmentId=${this.selectedAppointmentUser?.id}&documentName=${documentName}&documentType=${this.uploadAdditioinalDocsForm?.value?.documentType}&isFile=${isFile}&fileType=${this.UploadedFiletype}`;

      }
    }
    else if (this.collection == 'Account') {
      formData.append('idFile', this.uploadAdditioinalDocsForm?.value?.multipartFile);
      actionAppend = `record/addDocument?userInfoId=${this.selectedAppointmentUser?.id}&documentName=${documentName}&documentType=${this.uploadAdditioinalDocsForm?.value?.documentType}&isFile=${isFile}&fileType=${this.UploadedFiletype}`;
    }

    const action = actionAppend;
    this.loadSpinner = true;
    this.httpService?.makePostWithFormData(action, formData)?.subscribe((data: any) => {
      this.loadSpinner = false;
      if (data?.status === 'SUCCESS') {
        this.isFileUploaded = true;
        this.uploadedFileInformation = data?.fileName || null;
        documentUpload?.hide();
        this.notifyText = data?.msg || data?.message || 'Document uploaded successfully.';
        data.documentType = this.uploadAdditioinalDocsForm?.value?.documentType;
        data.userInfoId = this.selectedAppointmentUser?.id;
        data.successMessage = this.notifyText;
        this.isSuccessNotify = true;
      } else {
        this.notifyText = data?.msg || data?.message;
        data.errorMessage = this.notifyText;
      }
      this.invoiceUsersDocs?.emit(data);
      // this.exceptionalModal = true;
    },
      (error) => {
        this.resetDocumentsPopup();
        documentUpload?.hide();
        this.notifyText = error?.msg || error?.message;
        this.isSuccessNotify = false;
        this.exceptionalModal = true;
        this.loadSpinner = false;
      });
  }


  public updateDocStatus(docName: any) {
    const action = `changeHealth/updateReviewNotes?appointmentId=${this.selectedAppointmentUser?.id}&notes=&providerName=${this.loginDetails?.firstName + " " + this.loginDetails?.lastName || null}&reportS3Key=${docName}&type=${this.uploadAdditioinalDocsForm?.value?.documentType}&addToProgressNotes=false`;
    //&reportId=${this.userDetails?.id}
    this.loadSpinner = true;
    this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
      this.loadSpinner = false;
      if (data?.body?.status === 'SUCCESS') {
        //reviewLabRecord?.hide();
        //  this.notifyText = data?.body?.message || data?.body?.msg || 'Selected Patient Review Notes has been updated successfully.';
        // this.exceptionalModal = true;    
      } else {
        // this.notifyText = data?.body?.message || data?.body?.msg || AppConstantsListConfig?.uiErrorException;
        // this.exceptionalModal = true;

      }
    }, (error) => {
      // this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      // this.exceptionalModal = true;
      // this.loadSpinner = false;
    })
  }

}
