<app-ui-modal #uploadDocuments [hideHeader]="false" [modalCentered]="true"
    id="uploadDocuments">
    <div class="app-modal-header">
        <h5 class="modal-title">{{this.collection}} Documents</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="resetDocumentsPopup();uploadDocuments?.hide()"><span
                aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body">
        <div class="docs-form" [formGroup]="uploadAdditioinalDocsForm">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <input type="hidden" formControlName="collectionType" value="Encounter">
                    <div class="form-group">
                        <label for="documentType">Document Collection <span class="text-danger">*</span></label>
                        <ng-select placeholder="Choose Document Type" id="uploadAdditionDoc_doccollection"
                            bindLabel="documentType" bindValue="documentType" [items]="addDocumentTypes"
                            [readonly]="isReadonly" formControlName="documentType"></ng-select>

                        <span class="text-danger"
                            *ngIf="uploadAdditioinalDocsForm?.get('documentType')?.errors?.required && uploadAdditioinalDocsForm?.get('documentType')?.touched">Document
                            Type is Required</span>
                    </div>
                </div>
            </div>
            <!-- Document New Design - Start -->
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <form class="custom-file-container">
                        <div class="custom-file">
                            <div class="">
                                <div class="image-upload">
                                    <label style="cursor: pointer;width: 100%;">
                                        <i class="material-symbols-outlined">
                                            upload_file
                                        </i>
                                        <h4><input type="file" (change)="uploadFileSelect($event)"
                                                class="custom-file-input" id="chart-acc-additonal-documents-upload"
                                                #fileUpload name="chart-acc-additonal-documents-upload"
                                                accept="application/pdf, image/*" value="">
                                            <label class="custom-file-label"></label>
                                        </h4>
                                        <p> Supports only PDF, PNG and JPEG files and Files larger than 25MB cannot be
                                            uploaded
                                        </p>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="col-lg-12 col-md-12 uploaded-data" *ngIf="documentsList?.name">
                    <ul class="uploaded-files">
                        <li class="success" id="fileName-delete"> {{documentsList?.name}} <span
                                class="material-symbols-outlined" (click)="deleteSelectedFile()"
                                style="cursor:pointer;">delete</span></li>
                    </ul>
                </div>
            </div>
            <!-- Document New Design - END -->
        </div>
    </div>
    <div class="app-modal-footer text-center mt-3">
        <div class="text-left">
            <button class="btn btn-primary" [disabled]="!documentsList?.name" id="uploadAdditionDoc_submit"
                (click)="uploadAdditionalDocuments(uploadDocuments)">Submit</button>
        </div>
    </div>
    <!-- Spinner -->
    <div class="custom-loader justify-content-center" *ngIf="loadSpinner">
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</app-ui-modal>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [exceptionalModalFlag]="exceptionalModal"></app-exception-modal>