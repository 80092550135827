<div class="pre-appointment-questions review-of-systems-compt-questions">
    <ng-container *ngIf="ROSNode && ROSNode?.length > 0">
        <ng-container *ngFor="let node of ROSNode;let i=index">
            <ng-container *ngIf="checkGenderPurposeVisit(node?.sectionMetadata)">
                <div class="row">
                    <div *ngFor="let sections of node?.sections;let j=index" class="col-sm-12">
                        <ng-container *ngIf="node?.name !== 'Additional Notes' && sections?.name !== 'Additional Notes' && checkAnnual(node?.name)">
                            <h5>{{sections?.name}}</h5>
                            <hr />
                            <div class="row">
                                <div *ngFor="let question of sections?.questions"
                                    class="col-lg-12 col-md-12 col-sm-12 otherRos">
                                    <div class="categories">
                                        <h5>{{question?.label}}</h5>
                                        <div class="row">
                                            <ng-container *ngIf="question?.properties?.inPutType==='checkBox'">
                                                <ng-container *ngFor="let item of question?.values;let checki = index">
                                                    <div
                                                        class="col-lg-4 col-md-4 col-sm-12 form-group text-left list-group">

                                                        <div class="form-group text-left list-group">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox"
                                                                    [value]="item?.actualValue"
                                                                    name="{{item[checki]?.actualValue}}"
                                                                    (change)="changeReviewOfSystems($event, node, question)"
                                                                    id="{{item[checki]?.actualValue}}{{question.label}}{{item?.actualValue}}">
                                                                <label
                                                                    for="{{item[checki]?.actualValue}}{{question.label}}{{item?.actualValue}}"
                                                                    class="cr list-name-label">{{item?.actualValue}}</label>
                                                            </div>
                                                        </div>

                                                        <!-- <div class="checkbox checkbox-primary d-flex">
    
                                                            <input class="check-ros-comp" type="checkbox"
                                                                name="{{item[checki]?.actualValue}}"
                                                                [value]="item?.actualValue" [checked]="item?.isChecked"
                                                                (change)="changeReviewOfSystems($event, node, question)"
                                                                id="{{item[checki]?.actualValue}}{{question.label}}">
                                                            <label for="{{item[checki]?.actualValue}}{{question.label}}"
                                                                class="cr list-name-label">{{item?.actualValue}}</label>
                                                        </div> -->
                                                    </div>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                    </div>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
</div>

<!-- spinner -->
<div class="custom-loader justify-content-center" *ngIf="loadSpinner" id="spinnerDisplay">
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>