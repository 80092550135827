<!-- Modal -->
<div id="exceptional-error-modal" [style.display]="updateFlag ? 'block' : 'none'" class="modal" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <!-- Modal content-->
        <div class="modal-content"  [ngClass]="facilityId == 95 ? 'visor-popup' : '' " >
            <div class="modal-body visor-popup">
                <div class="row">
                    <div class="col-2 col-sm-2 col-md-2 col-lg-2" style="margin-top: 8px;">
                        <!-- <h5 style="text-align: center; padding: 15px;">
                            <i class="fa fa-check-circle" style="color:#2ecc71; font-size: 40px; margin: 0px;"></i>
                        </h5> -->
                        <h5 *ngIf="isSuccessNotify" class="modal-title text-success text-center" style="font-size: 50px;"><i class="fa fa-check-circle" aria-hidden="true"></i>
                        </h5>
                        <h5 *ngIf="!isSuccessNotify" class="modal-title text-danger text-center" style="font-size: 40px;">
                          <i class="las la-minus-circle"></i>
                        </h5>
                    </div>
                    <div class="col-10 col-sm-10 col-md-10 col-lg-10" style="margin-top: 15px;">
                       <h5 class="visor-text"> {{ notifyText }}</h5>
                    </div>

                </div>
               
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary ok-book visor-btn" data-dismiss="modal" id="exceptional_ok"
                    (click)="closeModal()">Ok</button>
            </div>
        </div>
    </div>
</div>