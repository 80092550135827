import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpService } from '../../shared/http.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-account-verification',
  templateUrl: './account-verification.component.html',
  styleUrls: ['./account-verification.component.scss']
})
export class AccountVerificationComponent implements OnInit {

  public accountVerificationFormGroup: UntypedFormGroup;

  public noPatientDetailsMsg: any;
  public exceptionalModal?: boolean;
  public loadSpinner?: boolean;
  notifyText: any;
  public displayOptions: boolean = true;
  public btnLoader?: boolean;
  public displayVerifyEmail: boolean = false;
  public displayVerifyMobile: boolean = false;
  public displayOTP: boolean = false;
  public otpSentSucessfully: boolean = false;
  public otpVerified: boolean = false;
  public diplayActiveUserAuth: boolean = false;
  facilityId: any;
  public allActiveUsersWithPhone: any = [];
  public validationForm!: UntypedFormGroup;
  public errorValidation!: boolean;

  constructor(private formBuilder: UntypedFormBuilder, private httpService: HttpService,
    private router: Router, private activatedRoute: ActivatedRoute) {
    this.accountVerificationFormGroup = this.formBuilder.group({
      'phoneNumber': [null, Validators?.compose([Validators?.required, Validators?.minLength(10), Validators?.maxLength(10)])],
      'email': [null, Validators.required],
      "OTP": [null, Validators.required],
    });

    this.validationForm = this.formBuilder?.group({
      'lastName': [null, Validators?.required],
      'dateofBirth': [null, Validators?.required]
      // 'zipCode': [null, Validators?.required]
    });

    this.activatedRoute?.params?.subscribe(data => {
      if (data && data['facilityId']) {
        this.facilityId = data['facilityId'] || null;
        sessionStorage?.setItem('headerFacilityId', this.facilityId);
      }
    });
  }

  ngOnInit(): void {
  }

  navigatToVerifyScreen(action: any) {
    if (action == 'Mobile') {
      this.displayOptions = false;
      this.displayVerifyMobile = true;
    }
    else if (action == 'Email') {
      this.displayOptions = false;
      this.displayVerifyEmail = true;
    }
  }

  public checkUserExist(): void {
    if (this.accountVerificationFormGroup.get('phoneNumber')?.invalid) {
      this.accountVerificationFormGroup.get('phoneNumber')?.markAllAsTouched();
      return;
    }
    this.loadSpinner = true;
    const action = `userInfo/getActiveUsers/+1${this.accountVerificationFormGroup.get('phoneNumber')?.value}`;
    this.httpService?.makeGetRequest(action)?.subscribe((data: any) => {
      this.loadSpinner = false;
      if (data?.status === 'SUCCESS') {
        this.allActiveUsersWithPhone = data?.responseObject || [];
        if (data?.responseObject?.length > 0) {
          this.sendUserOtp();
        } else if (data?.responseObject?.length === 0) {
          this.notifyText = 'Not a valid user. Please try to enter registered phone number.';
          this.exceptionalModal = true;
        }
      } else {
        this.notifyText = data?.msg || data?.message || 'Something went wrong please try again later..!';
        this.exceptionalModal = true;
      }
    }, (error) => {
      this.notifyText = error?.msg || error?.message || 'Something went wrong please try again later..!';
      this.exceptionalModal = true;
      this.loadSpinner = false;
    });
  };

  sendUserOtp() {
    if (this.accountVerificationFormGroup.get('phoneNumber')?.invalid) {
      this.accountVerificationFormGroup.get('phoneNumber')?.markAllAsTouched();
      return;
    }
    this.otpSentSucessfully = false;
    var phoneNumber = this.accountVerificationFormGroup.get('phoneNumber')?.value;
    let action: any = `userotp/sendUserOtp?phoneNumber=%2B1${phoneNumber}`;
    this.loadSpinner = true;
    this.httpService.makePostRequest(action, [])
      .subscribe((data: any) => {
        if (data.status === 'SUCCESS') {
          const getResponse = data && data?.responseObject;
          this.displayOTP = true;
          this.displayVerifyMobile = false;
          this.notifyText = data?.msg || 'Something went wrong please try again later..!';
          this.exceptionalModal = true;
          this.otpSentSucessfully = true;
        } else {
          this.notifyText = data?.msg || 'Something went wrong please try again later..!';
          this.exceptionalModal = true;
          this.displayOTP = false;
          this.displayVerifyMobile = true;
          this.otpSentSucessfully = false;
        }
        this.loadSpinner = false;
      },
        error => {
          this.loadSpinner = false;
          this.otpSentSucessfully = false;
          this.notifyText = error?.msg || 'Something went wrong please try again later..!';
          this.exceptionalModal = true;
        });
  }

  validateUserOtp() {
    this.otpVerified = false;
    var uniqueId = 81;
    var phoneNumber = this.accountVerificationFormGroup.get('phoneNumber')?.value;
    var otp = this.accountVerificationFormGroup.get('OTP')?.value;
    let action: any = `userotp/validateUserOtp?otpToken=${otp}&phoneNumber=%2B1${phoneNumber}`;
    this.loadSpinner = true;
    this.httpService.makePostRequest(action, [])
      .subscribe((data: any) => {
        if (data.status === 'SUCCESS') {
          const getResponse = data && data?.responseObject;
          this.otpVerified = true;
          this.displayOTP = false;
          this.displayOptions = false;
          this.displayVerifyMobile = false;
          if (this.allActiveUsersWithPhone?.length > 1) {
            if (sessionStorage?.getItem('typeOfVisit') == 'teli') {
              sessionStorage?.setItem('verfiedUserDetails', JSON?.stringify(this.allActiveUsersWithPhone));
              sessionStorage?.setItem('isPatientVerified', 'Yes');
              this.router.navigate([`${this.facilityId}/teleHealth/${getResponse?.id}`]);

            } else {
              this.diplayActiveUserAuth = true;
            }
          } else {
            sessionStorage?.setItem('verfiedUserDetails', JSON?.stringify(getResponse));
            sessionStorage?.setItem('isPatientVerified', 'Yes');
            console.log("get Response", sessionStorage?.getItem('typeOfVisit'));
            if (sessionStorage?.getItem('typeOfVisit') == 'teli') {
              this.router.navigate([`${this.facilityId}/teleHealth/${getResponse?.id}`]);
            } else {

              this.router.navigate([`${this.facilityId}/viewPatientDetails/${getResponse?.id}`]);
            }
          }
          // this.router.navigate([`/CareJourney/${getResponse?.uniqueUserToken}`]);
        } else {
          this.notifyText = data?.msg || data?.message || 'Something went wrong please try again later..!';
          this.exceptionalModal = true;
          this.otpVerified = false;
          this.displayOTP = true;
          this.displayOptions = false;
          this.displayVerifyMobile = false;
        }
        this.loadSpinner = false;
      },
        error => {
          this.loadSpinner = false;
          this.notifyText = error?.msg || error?.message || 'Something went wrong please try again later..!';
          this.exceptionalModal = true;
          this.otpVerified = false;
          this.displayOTP = true;
          this.displayOptions = false;
          this.displayVerifyMobile = false;
        });
  }

  closeModal() {
    this.exceptionalModal = false
    this.notifyText = '';
    if (this.otpSentSucessfully) {
      this.displayOTP = true;
      this.displayVerifyMobile = false;
    }
    else {
      this.displayOTP = false;
      this.displayVerifyMobile = true;
    }
  }

  public checkValidationForCareJourney(): void {
    if (this.validationForm?.invalid) {
      this.validationForm?.markAllAsTouched();
      return;
    }
    this.errorValidation = false;

    const enterName = this.validationForm?.value?.lastName?.trim()?.toLowerCase();
    const enterDob = this.validationForm?.value?.dateofBirth;

    const checkUserAuth = this.allActiveUsersWithPhone?.find((item: any) => item?.lastName?.trim()?.toLowerCase() === enterName && item?.usrDateOfBirth?.includes(enterDob)) || null

    if (checkUserAuth) {
      sessionStorage?.setItem('verfiedUserDetails', JSON?.stringify(checkUserAuth));
      sessionStorage?.setItem('isPatientVerified', 'Yes');
      this.router.navigate([`${this.facilityId}/viewPatientDetails/${checkUserAuth?.id}`]);
    } else {
      this.errorValidation = true;
    }
  }

}