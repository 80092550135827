<section class="confirm-last-name" *ngIf="!showComposedMessage">
    <div class="container">
        <div class="compose-message-sec bg-style">
            <div class="logo">
            </div>
            <div class="row book-appnt-sec-confim">

                <div class="col-lg-12 col-md-12 col-sm-12 search-main" *ngIf="composeMessage">
                    <h5>Please confirm your Last Name</h5>
                    <div class="cancel-form">
                        <ng-container *ngIf="composeMessage">
                            <form [formGroup]="patientOutreachConfirmForm">
                                <div class="" style="margin-top: 30px;">
                                    <!-- <label>Patient Last Name</label> -->
                                    <input type="text" class="form-control" (keyup)="checkLastName()"
                                        formControlName="PATIENT_LAST_NAME" id="PATIENT_LAST_NAME"
                                        placeholder="Confirm Last Name">
                                    <span
                                        *ngIf="patientOutreachConfirmForm?.get('PATIENT_LAST_NAME')?.errors?.required && patientOutreachConfirmForm?.get('PATIENT_LAST_NAME')?.touched"
                                        class="error">Last Name confirmation is Required</span>

                                    <span
                                        *ngIf="patientOutreachConfirmForm?.get('CHECK_VALID')?.errors?.required && !patientOutreachConfirmForm?.get('PATIENT_LAST_NAME')?.errors?.required"
                                        class="errorMessage" class="error">Entered Last Name should be matched with your
                                        registered last name.</span>
                                </div>
                            </form>
                        </ng-container>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 search-main" *ngIf="!composeMessage">
                    <div style="margin-top: 30px;" class="error text-center">No details found</div>
                </div>
            </div>

            <!-- spinner -->
            <div class="custom-loader justify-content-center" *ngIf="loadSpinner" id="spinnerDisplay">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>

        </div>
    </div>
</section>

<section class="composed-mesage" *ngIf="showComposedMessage">
    <div class="container">
        <div class="compose-message-sec">
            <div class="row book-appnt-sec">
                <div class="col-lg-12 col-md-12 col-sm-12 search-main">
                    <div id="user-sms-book-appnt">
                        <div class="user-sms">
                            <img src="{{composeMessage?.s3Logo}}" alt="Facility Logo"
                                [class.awhLogo]="facilityId === '83'" class="logo-img">
                            <div class="title">
                                <h4>Doctor's Office Outreach</h4>
                            </div>
                            <div class="contact mt-4">
                                <span class="contact-label label">Contact:</span> <a
                                    (click)="callAnalyticsApi(facilityId, 'Phone Number', replaceHyphens(composeMessage?.facilityContactNumber))"
                                    href="tel:+1{{replaceHyphens(composeMessage?.facilityContactNumber)}}"
                                    rel="nofollow"><span
                                        class="contact-num">{{composeMessage?.facilityContactNumber}}</span></a>
                            </div>
                            <div class="visit-location mt-4">
                                <span class="label visitLocation">Visit Location:</span>
                                <span class="address value"
                                    *ngIf="composeMessage?.address?.street">{{composeMessage?.address?.street}}</span>
                                <span class="address value" *ngIf="composeMessage?.address?.city">,
                                    {{composeMessage?.address?.city}}</span>
                                <span *ngIf="composeMessage?.address?.state" class="address value">,
                                    {{composeMessage?.address?.state}}</span>
                                <span *ngIf="composeMessage?.address?.zipCode" class="address value"> -
                                    {{composeMessage?.address?.zipCode}}</span>
                            </div>

                            <div class="sms-message mt-4">
                                <p>{{composeMessage?.message}}</p>
                            </div>
                            <hr>
                            <div class="schedule-btn mt-4">
                                <button type="submit" *ngIf="facilityId !== '1001'" class="btn btn-primary dash-actions mb-2 mr-2" id="patientOutreach_scheduleApp1">
                                    <a style="color: #fff; text-decoration: none;" (click)="callAnalyticsApi(facilityId, 'Phone Number', replaceHyphens(composeMessage?.facilityContactNumber))" href="tel:+1{{replaceHyphens(composeMessage?.facilityContactNumber)}}" rel="nofollow"><span class="contact-num">CALL TO SCHEDULE APPOINTMENT</span></a>
                                </button>
                                <button *ngIf="facilityId !== '1001'" type="submit" class="btn btn-primary mb-2 dash-actions"
                                    id="patientOutreach_scheduleApp" (click)="openBookAppointment();">SCHEDULE
                                    APPOINTMENT</button>
                                <button type="submit" *ngIf="facilityId === '1001'" class="btn btn-primary mb-2 dash-actions"
                                    id="patientOutreach_scheduleApp1">
                                    <a style="color: #fff; text-decoration: none;"
                                        (click)="callAnalyticsApi(facilityId, 'Phone Number', replaceHyphens(composeMessage?.facilityContactNumber))"
                                        href="tel:+1{{replaceHyphens(composeMessage?.facilityContactNumber)}}"
                                        rel="nofollow"><span class="contact-num">SCHEDULE
                                            APPOINTMENT</span></a>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>