<!-- <footer id="careeco-footer">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 text-center mb-3">
                <a alt="https://www.careeco.net" class="companysite-url" style="cursor: pointer;" (click)="openCareeco()">CareEco</a>
            </div>

            <div id="footer_copyright" class="col-lg-5 col-md-12 col-sm-12 mt-4 mb-4">Copyright © 2023 CareEco
                Corporation</div>
            <div class="col-lg-7 col-md-12 col-sm-12 text-right mt-4 mb-4">
                <div class="footer-terms-links">
                    <a class="terms_link" href="/foot-privacy-policy" target="_blank">Privacy Policy</a>

                    <a class="terms_link" href="/foot-hipaaConsentAuthorization" target="_blank">HIPAA Consent
                        Authorization</a>
                    <a class="terms_link" href="/foot-terms-of-service" target="_blank">Terms of Service</a>
    
                    <a class="terms_link" href="/foot-terms-of-use" target="_blank">Terms of Use</a>
    
                    <a class="terms_link" href="/foot-terms-of-telemedicine" target="_blank">Terms of Telemedicine</a>
                </div>
            </div>
        </div>
    </div>
</footer> -->


<footer>
    <div class="footer">
        <div class="row">
            <div class="col-md-12 col-lg-6">
                <p>Copyright © {{ currentYear }} CareEco Corporation</p>
            </div>
            <div class="col-md-12 col-lg-6 footer-links">
                <ul>
                    <li>
                        <a class="terms_link" href="/foot-privacy-policy" target="_blank" id="footer_privacy">Privacy Policy </a>
                    </li>
                    <li>
                        <a class="terms_link" href="/foot-hipaaConsentAuthorization" target="_blank" id="footer_Hippa"> HIPAA Consent
                            Authorization</a>
                    </li>
                    <li>
                        <a class="terms_link" href="/foot-terms-of-service" target="_blank" id="footer_termsOfSErvice"> Terms of Service</a>
                    </li>
                    <li>
                        <a class="terms_link" href="/foot-terms-of-use" target="_blank" id="footer_termsOfUse">Terms of Use </a>
                    </li>
                    <li>
                        <a class="terms_link" href="/foot-terms-of-telemedicine" target="_blank" id="footer_termsOfTelemedicine"> Terms of
                            Tele-Health</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</footer>