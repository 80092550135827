import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpService } from './http.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Interceptor } from './interceptors';
import { TagInputModule } from 'ngx-chips';
import { ArchwizardModule } from 'angular-archwizard';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule, LazyMapsAPILoaderConfigLiteral } from '@agm/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { WebcamModule } from 'ngx-webcam';
import { ExceptionModalComponent } from './components/exception-modal/exception-modal.component';
import { LoadSpinnerComponent } from './components/load-spinner/load-spinner.component';
import { PaymentsBillComponent } from '../components/payments-bill/payments-bill.component';
import { CareOutreachService } from './careoutreach.service';
import { AuthGuard } from './auth/auth.guard';
import { UiModalComponent } from './components/ui-modal/ui-modal.component';
import { UploadDocumentsComponent } from './components/upload-documents/upload-documents.component';
import { NgSelectDirective } from './directives/ng-select.directive';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [ExceptionModalComponent, LoadSpinnerComponent, PaymentsBillComponent, UiModalComponent, UploadDocumentsComponent,NgSelectDirective],
  imports: [
    CommonModule,
    TagInputModule,
    ArchwizardModule,
    NgxMaskModule.forRoot(maskConfig),
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBmpQFHl_kUFMN5O_wacPBYPfVjKyOvlBU',
      libraries: ['places']
    }),
    NgSelectModule,
    WebcamModule
  ],
  exports: [
    TagInputModule,
    ArchwizardModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgSelectModule,
    WebcamModule,
    ExceptionModalComponent,
    LoadSpinnerComponent,
    PaymentsBillComponent,
    UiModalComponent,
    UploadDocumentsComponent,
    NgSelectDirective
  ],
  providers: [HttpService, CareOutreachService,
     { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
     { provide: AuthGuard, useClass: AuthGuard },]
})
export class SharedModule {
  static forRoot(lazyMapsAPILoaderConfig?: LazyMapsAPILoaderConfigLiteral): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule
    };
  }
}
