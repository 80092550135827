import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { CareOutreachService } from './careoutreach.service';
import { ExternalGatewayService } from './external-gateway.service';


@Injectable({
  providedIn: 'root'
})
export class ApisService {
  constructor(public http: HttpClient, private httpService: HttpService, private careOutreachService: CareOutreachService, private externalGatewayService: ExternalGatewayService) { }
  // External Gateway Payment API's - New Repo
  // Create NON POS Payment
  public createNonPosPaymentExtrGateway(reqAction?: any): Observable<any> {
    const action = `payment/createPayment`;
    return this.externalGatewayService?.makeRequestWithAction(action, reqAction);
  }

  // Cancel NO POS Create Payment
  public cancelNonPosPaymentExtrGateway(paymentId?: any): Observable<any> {
    const action = `payment/cancelPayment/${paymentId}`;
    return this.externalGatewayService?.makeRequestWithAction(action, '');
  }

  // read Status of NO POS Create Payment
  public getStatusNonPosPaymentExtrGateway(paymentId?: any): Observable<any> {
    const action = `payment/getPayment/${paymentId}`;
    return this.externalGatewayService?.makeGetRequest(action);
  }

  // Create Terminal Payment
  public createTerminalPaymentExtrGateway(reqAction?: any): Observable<any> {
    const action = `payment/createTerminalPayment`;
    return this.externalGatewayService?.makeRequestWithAction(action, reqAction);
  }

  // Cancel Terminal Payment
  public cancelTerminalPaymentExtrGateway(checkoutId?: any): Observable<any> {
    const action = `payment/cancelTerminalPayment/${checkoutId}`;
    return this.externalGatewayService?.makeRequestWithAction(action, '');
  }

  // read Status of Terminal Payment
  public getStatusTerminalPaymentExtrGateway(checkoutId?: any): Observable<any> {
    const action = `payment/getTerminalPayment/${checkoutId}`;
    return this.externalGatewayService?.makeGetRequest(action);
  }

  // Create Cash Payment
  public createCashPaymentExtrGateway(reqAction?: any): Observable<any> {
    const action = `payment/createCashPayment`;
    return this.externalGatewayService?.makeRequestWithAction(action, reqAction);
  }

  // Create Credit Payment
  public createCreditPaymentExtrGateway(reqAction?: any): Observable<any> {
    const action = `payment/createCreditPayment`;
    return this.externalGatewayService?.makeRequestWithAction(action, reqAction);
  }

  // Create ARInsurance Payment
  public createARInsurancePaymentExtrGateway(reqAction?: any): Observable<any> {
    const action = `payment/createARInsurancepayment`;
    return this.externalGatewayService?.makeRequestWithAction(action, reqAction);
  }

  // Update Patient Responsibility
  public updatePatientResponsibilityExtrGateway(reqAction?: any): Observable<any> {
    const action = `payment/updatePatientResponsibility`;
    return this.externalGatewayService?.makeRequestWithAction(action, reqAction);
  }

  // Save Cost of care
  public saveCostOfCareExtrGateway(reqAction?: any): Observable<any> {
    const action = `payment/saveCostOfCare`;
    return this.externalGatewayService?.makeRequestWithAction(action, reqAction);
  }

  // Retreive Cost of care
  public retreiveAppntCostOfCareExtrGateway(appId?: any): Observable<any> {
    const action = `costOfCare/getByAppointmentId?appointmentId=${appId}`;
    return this.externalGatewayService?.makeGetRequest(action);
  }

  // Get Patient Responsibility and Credits
  public getPatientRespNCreditsCall(patientAccId?: string, userInfoId?: string): Observable<any> {
    // const action = `payment/getPatientResponsibilityNCredits?userInfoId=${userInfoId || ''}&patientAccountId=${patientAccId || ''}`;
    const action = `payment/getPatientResponsibilityNCredits?patientAccountId=${patientAccId || ''}`;
    return this.externalGatewayService?.makeGetRequest(action);
  }

  // Get Payment Config
  public getPaymentConfig(facilityId?: string): Observable<any> {
    const action = `payment/getPaymentConfig?facilityId=${facilityId}`;
    return this.externalGatewayService?.makeGetRequest(action);
}

}