<section class="hero results-hero">
    <div class="container">
        <div id="cancel-appointment-page">
            <div class="row book-appnt-sec">
                <div class="col-12">
                    <div class="logo">
                    </div>
                </div>
                <div class="cancel-head">
                    <h5>Appointment Reschedule / Cancellation</h5>
                    <!-- <h6 style="font-size: 17px; margin-top: 12px;">You can cancel your visit for any reason.</h6> -->
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 search-main">
                    <div class="cancel-form">
                        <div *ngIf="!appointmentDetails" style="margin-left: 15px;margin-bottom: 15px;" class="error">
                            {{noRegistrations}}</div>
                        <ng-container *ngIf="appointmentDetails">
                            <form [formGroup]="cancelAppointmentForm">

                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-group mb-3">
                                        <input type="text" class="form-control input-text"
                                            formControlName="REGISTRATION_CODE" id="REGISTRATION_CODE"
                                            placeholder="Registration Code">
                                        <span
                                            *ngIf="cancelAppointmentForm?.get('REGISTRATION_CODE')?.errors?.required && cancelAppointmentForm?.get('REGISTRATION_CODE')?.touched"
                                            class="error">Registration Code is Required</span>
                                    </div>

                                </div>

                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <!-- <label>Patient Last Name</label> -->
                                    <div class="form-group mb-3">
                                        <input type="text" class="form-control input-text" (keyup)="checkLastName()"
                                            formControlName="PATIENT_LAST_NAME" id="PATIENT_LAST_NAME"
                                            placeholder="Patient Last Name">
                                        <span
                                            *ngIf="cancelAppointmentForm?.get('PATIENT_LAST_NAME')?.errors?.required && cancelAppointmentForm?.get('PATIENT_LAST_NAME')?.touched"
                                            class="error">Patient Last Name is Required</span>

                                        <span
                                            *ngIf="cancelAppointmentForm?.get('PATIENT_LAST_NAME')?.touched && cancelAppointmentForm?.get('CHECK_VALID')?.errors?.required && !cancelAppointmentForm?.get('PATIENT_LAST_NAME')?.errors?.required"
                                            class="errorMessage" class="error">Entered Last Name should be match with
                                            your
                                            last name.</span>
                                    </div>
                                </div>
                                <!-- check type -->
                                <div class="form-group col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-group mb-3">
                                        <ng-container *ngFor="let item of cancelType">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" formControlName="REASON_TYPE"
                                                    type="radio" (change)="checkCancelType()" name="REASON_TYPE"
                                                    [value]="item.value" id="{{item?.desc}}">
                                                <label class="form-check-label"
                                                    for="{{item?.desc}}">{{item?.desc}}</label>
                                            </div>
                                        </ng-container>
                                        <span
                                            *ngIf="cancelAppointmentForm?.get('REASON_TYPE')?.errors?.required && cancelAppointmentForm?.get('REASON_TYPE')?.touched"
                                            class="error">Type of Cancel Reason is Required</span>
                                    </div>

                                </div>

                                <div *ngIf="cancelAppointmentForm?.get('REASON_TYPE')?.value === 'Cancel' || cancelAppointmentForm?.get('REASON_TYPE')?.disabled"
                                    class="col-lg-12 col-md-12 col-sm-12">

                                    <div class="form-group mb-3">
                                        <ng-select placeholder="Choose Reason for Cancel"
                                            formControlName="REASON_FOR_CANCEL" id="REASON_FOR_CANCEL"
                                            [items]="reasonOfCancel" bindLabel="desc" autofocus bindValue="value"
                                            (change)="cancelReasonSection($event)">
                                        </ng-select>
                                    </div>

                                    <!-- <select class="form-control" formControlName="REASON_FOR_CANCEL"
                                        id="REASON_FOR_CANCEL">
                                        <option disabled [selected]="true">Select Reason for Cancel</option>
                                        <option *ngFor="let item of reasonOfCancel" value="{{item}}">{{item}}</option>
                                    </select> -->
                                    <span
                                        *ngIf="cancelAppointmentForm?.get('REASON_FOR_CANCEL')?.errors?.required && cancelAppointmentForm?.get('REASON_FOR_CANCEL')?.touched"
                                        class="error">Reason for cancel is Required</span>
                                </div>

                                <div *ngIf="isOtherReason" class="col-lg-12 col-md-12 col-sm-12">

                                    <div class="form-group mb-3">
                                        <textarea rows="5" formControlName="REASON_FOR_CANCEL_OTHER"
                                            class="form-control" id="cancelReason"
                                            placeholder="Enter cancellation reason..."></textarea>
                                    </div>


                                    <span
                                        *ngIf="cancelAppointmentForm?.get('REASON_FOR_CANCEL_OTHER')?.errors?.required && cancelAppointmentForm?.get('REASON_FOR_CANCEL_OTHER')?.touched"
                                        class="error">Cancellation reason is Required</span>
                                </div>

                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <button [disabled]="cancelAppointmentForm?.invalid || !appointmentDetails"
                                        (click)="cancelAppointment()" id="cancel_submit"
                                        class="btn btn-primary search-btn cancel-appointment-page-btn">Submit</button>
                                </div>

                            </form>
                        </ng-container>
                        <ng-container *ngIf="!appointmentDetails">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <button (click)="cancelAppointment()" id="cancel_reschedule"
                                    class="btn btn-primary search-btn cancel-appointment-page-btn">Re-Schedule</button>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <!-- spinner -->
                <div class="custom-loader justify-content-center" *ngIf="loadSpinner" id="spinnerDisplay">
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>


<!-- Modal -->
<div id="cancel-appointment-page-modal" [style.display]="showModalCancel ? 'block' : 'none'" class="modal"
    role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-body">
                <h5 style="text-align: center;padding: 15px;">{{messageShown}}</h5>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default ok-book" data-dismiss="modal" id="cancel_ok"
                    (click)="navigateHomePage()">Ok</button>
            </div>
        </div>
    </div>
</div>