import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { WizardComponent } from 'angular-archwizard';
import { HttpService } from 'src/app/shared/http.service';

@Component({
  selector: 'app-chief-complaints',
  templateUrl: './chief-complaints.component.html',
  styleUrls: ['./chief-complaints.component.scss']
})
export class ChiefComplaintsComponent implements OnInit, AfterViewInit {

  @Input() appointmentDetails: any;
  public chiefCompNode: any;
  public selectedChiefComp: any[] = [];
  public otherComplaints?: string
  public displayReasonForVisitErrMsg?: boolean;
  public loadSpinner?: boolean;
  @Input() previsitDocsWizard!: WizardComponent;

  constructor(private httpService: HttpService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.getModuleInfo();
  }

  public checkGenderPurposeVisit(modeMetaData: any): boolean {
    if ((modeMetaData?.gender === 'All' || modeMetaData?.gender === this.appointmentDetails?.gender) && (modeMetaData?.purposeOfVisit?.includes('All') || modeMetaData?.purposeOfVisit?.includes(this.appointmentDetails?.appointment?.purposeOfVisit))) {
      return true;
    }
    return false;
  }
  // facilityId=${this.appointmentDetails?.facilityId}&
  public getModuleInfo(): void {
    const action = `careFlow/getCareFlowModuleAllInfo?module=Chief Complaints&name=Cad-ModuleDetails`;
    this.loadSpinner = true;
    this.httpService.makeGetRequest(action).subscribe(
      (data: any) => {
        if (data?.status === 'SUCCESS') {
          this.chiefCompNode = data?.responseObject?.resourceType || [];
        }
        this.loadSpinner = false;
      },
      (error) => {
        this.loadSpinner = false;
      }
    );
  }

  // check if Other Complaint have selected 
  get isOtherComplaintSelected(): boolean {
    if (this.selectedChiefComp?.includes('Other')) {
      return true;
    }
    return false;
  }

  // Value set if check and uncheck
  public checkChiefComp(event: any, name: string): void {
    if (event?.target?.checked) {
      this.selectedChiefComp.push(name);
    } else {
      this.selectedChiefComp = this.selectedChiefComp?.filter(
        (e: any) => e !== name
      );
    }
  }

  public checkedValuesParams(): any {
    if(!this.selectedChiefComp || this.selectedChiefComp?.length === 0) {
      this.displayReasonForVisitErrMsg = true;
      return null;
    };

    this.displayReasonForVisitErrMsg = false;
    let arr: any[] = []
    this.selectedChiefComp.forEach((item: any, index: number) => {
      if (item === 'Other') {
        arr.push({ actualValue: [{ answer: item, notes: this.otherComplaints || null }] });
      } else {
        arr.push({ actualValue: [{ answer: item }] });
      }
    });
    const params = {
      appointmentId: this.appointmentDetails?.id,
      facilityId: this.appointmentDetails?.facilityId,
      userInfoId: this.appointmentDetails?.userInfoId,
      moduleName: 'Chief Complaints',
      careFlowModule: [
        {
          auditInfo: {
            "createdBy": "",
            "createdDate": "",
            "updatedBy": "",
            "updatedDate": ""
          },
          completed: false,
          module: 'Chief Complaints',
          otherNotes: null,
          resourceType: 'Chief Complaints',
          sections: [
            {
              answers: arr,
              name: "Chief Complaints",
            },
          ],
        },
      ],
    };
    return params;
  }

}
