<div id="schedule-appointment">
    <div class="container">
        <div class="new-appointment-sec">
            <!-- <ng-container *ngIf="!ifNoPatientDetails">
                <div class="no-patient-details-error">
                    <span class="error">{{noPatientDetailsMsg}}</span>
                </div>
            </ng-container> -->
            <div class="add-appointment-card">
                <!-- facility Name and facilityAddress -->
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="facilityHeader" *ngIf="facilityName && (address?.street || address?.zipCode)">
                            <h4 class="book-head">{{facilityName || ''}}</h4>
                            <div class="facilityAddress">
                                <span class="fa fa-map-marker"></span>&nbsp;&nbsp;
                                <span class="book-head">{{address?.street || ' '}}, {{ address?.city ||
                                    ' ' }},
                                    {{address?.state || ' ' }} - {{address?.zipCode || ' '}}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- DOB Validation -->
                <section id="demographics-details" *ngIf="!isTeliHelath">
                    <form [formGroup]="valdateDobForm">
                        <div class="bg-style demographics">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <h5>Please Enter Your Details</h5>
                                </div>
                            </div>
                            <!-- Teli Health row start -->
                            <div class="row">
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <div class="form-group mb-3">
                                        <label>First Name <span class="required">*</span></label>
                                        <input type="text" class="form-control input-text"
                                            formControlName="PATIENT_FIRST_NAME" id="PATIENT_FIRST_NAME"
                                            placeholder="First Name">
                                        <span
                                            *ngIf="valdateDobForm?.get('PATIENT_FIRST_NAME')?.errors?.required && valdateDobForm?.get('PATIENT_FIRST_NAME')?.touched"
                                            class="error">Patient First Name is Required</span>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <div class="form-group mb-3">
                                        <label>Last Name <span class="required">*</span></label>
                                        <input type="text" class="form-control input-text"
                                            formControlName="PATIENT_LAST_NAME" id="PATIENT_LAST_NAME"
                                            placeholder="Last Name">
                                        <span
                                            *ngIf="valdateDobForm?.get('PATIENT_LAST_NAME')?.errors?.required && valdateDobForm?.get('PATIENT_LAST_NAME')?.touched"
                                            class="error">Patient Last Name is Required</span>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <div class="form-group mb-3">
                                        <label>Date of Birth<span class="required">*</span></label><br />
                                        <input type="date" id="datemax" name="datemax" [max]="maxAppointmentDate"
                                            class="form-control input-text" formControlName="DATE_OF_BIRTH"
                                            (keyup.enter)="validateManualEnterdob($event, 'DATE_OF_BIRTH')" (mouseout)="validateManualEnterdob($event, 'DATE_OF_BIRTH')" (blur)="validateManualEnterdob($event, 'DATE_OF_BIRTH')" id="DATE_OF_BIRTH"
                                            placeholder="Date of Birth">
                                        <span
                                            *ngIf="valdateDobForm?.get('DATE_OF_BIRTH')?.errors?.required && valdateDobForm?.get('DATE_OF_BIRTH')?.touched"
                                            class="error">Date of Birth is Required</span>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <div class="form-group mb-3">
                                        <label>Mobile Number</label><br />
                                        <input class="form-control input-text" type="text" mask="(000) 000-0000"
                                            formControlName="PHONE_NUMBER" placeholder="(xxx) xxx-xxxx"
                                            id="PHONE_NUMBER" />
                                    </div>
                                </div>


                            </div>
                            <!-- Teli Health row end -->
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <div class="mt-10">
                                    <button type="button" class="btn btn-primary continue-btn" id="teleHealth_back" (click)="returnToHome()"
                                    >Back</button>
                                    

                                    <button type="button" class="btn btn-primary continue-btn pull-right" id="teleHealth_continue"
                                        (click)="getTeleHealthDetails()">Continue</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </section>


                <!-- Telihealth details -->
                <section id="teliHealth-details" *ngIf="isTeliHelath">

                    <div class="bg-style insurances">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <h5>Tele health visit consultation </h5>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12 text-center" *ngIf="isMeetingAvailable">
                                <button type="button" class="btn btn-primary continue-btn" (click)="joinMeeting()" id="teleHealth_joinMeeting">Join
                                    Meeting</button>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="!isMeetingAvailable">

                                <span class="label-error">{{statusMessage}}</span>

                            </div>
                        </div>



                    </div>

                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 ">
                            <div class="pull-right">
                                <button type="button" class="btn btn-primary continue-btn mt-4" id="teleHealth_back1"
                                    (click)="returnToTeleForm()">Back</button>
                            </div>
                        </div>
                    </div>


                </section>


            </div>

            <!-- spinner -->
            <div class="custom-loader justify-content-center" *ngIf="loadSpinner" id="spinnerDisplay">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>

        </div>

    </div>

</div>

<!-- Modal -->
<div id="teli-health-modal" [style.display]="exceptionalModal ? 'block' : 'none'" class="modal" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-body">
                <h5 style="text-align: center;padding: 15px;">{{notifyText}}</h5>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default ok-book" data-dismiss="modal" id="teleHealth_ok"
                    (click)="exceptionalModal = false">Ok</button>
            </div>
        </div>
    </div>
</div>