<div class="patient-life-cycle">
    <div class="container">
        <div class="main-timeline-sec" [class.viewDetailsSec]="notValidationRequire">
            <ng-container *ngIf="!careJouneyDisplay">

                <div class="validations white-bg" [formGroup]="validationForm">
                    <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                        <h5>Please confirm your Last Name and Date of Birth</h5>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group mb-3">
                            <input type="text" class="form-control input-text" formControlName="lastName" id="lastName"
                                placeholder="Patient Last Name">
                            <span
                                *ngIf="validationForm?.get('lastName')?.errors?.required && validationForm?.get('lastName')?.touched"
                                class="error">Patient Last Name is Required</span>
                        </div>
                    </div>
                    <!-- date of brith -->
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group mb-3">
                            <input type="date" class="form-control input-text" formControlName="dateofBirth"
                                id="dateofBirth" placeholder="Patient Date of Birth"
                                max="{{today | date: 'yyyy-MM-dd'}}">
                            <span
                                *ngIf="validationForm?.get('dateofBirth')?.errors?.required && validationForm?.get('dateofBirth')?.touched"
                                class="error">Patient Date of Birth is Required</span>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                        <span *ngIf="errorValidation" class="errorMessage" class="error">Entered Last Name and Date of
                            Birth should match with your data</span>
                    </div>

                    <!-- <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group mb-3"> 
                            <input class="form-control input-text" formControlName="zipCode" id="zipCode"
                                minlength="5" maxlength="5" placeholder="ZIP Code">
                            <span
                                *ngIf="validationForm?.get('ZIPCODE')?.errors?.required && validationForm?.get('ZIPCODE')?.touched"
                                class="error">ZIP Code is Required</span>
                            <span *ngIf="validationForm?.get('ZIPCODE')?.invalid && !validationForm?.get('ZIPCODE')?.errors?.required"
                                class="error">Please enter valid ZIP Code, it accepts five digit number</span>
                                 
                        </div>
                    </div> -->

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <button [disabled]="!userInfoDetails" (click)="checkValidationForCareJourney()" id="careEvents_submit2"
                            class="btn btn-primary search-btn cancel-appointment-page-btn">Submit</button>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12 mt-4">
                        <span *ngIf="!userInfoDetails" class="errorMessage" class="error">Invalid token or user details
                            are not found</span>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="careJouneyDisplay">
                <!-- Header section -->
                <div class="row">
                    <!-- <div class="col-lg-12 col-md-12 col-sm-12">
                        <ng-container *ngIf="facilityDetails?.smsLogoS3Link">
                            <img class="facility-logo" style="float: right;width: 80px;" src="{{facilityDetails?.smsLogoS3Link}}" alt="Facility Logo">
                        </ng-container>
                    </div> -->
                    <div class="col-lg-12 col-md-12 col-sm-12 mb-4" *ngIf="!notValidationRequire">
                        <h5 class="main-title">Care Journey</h5>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 text-center" *ngIf="!notValidationRequire">
                        <div class="pName">Patient Name: <span class="name-value">{{userInfoDetails?.lastName }},
                                {{userInfoDetails?.firstName}}</span></div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 text-center" *ngIf="!notValidationRequire">
                        <div class="pName">Gender: <span class="name-value">{{userInfoDetails?.gender}}</span></div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 text-center" *ngIf="!notValidationRequire">
                        <div class="pName">Age: <span class="name-value">{{calculateAge(userInfoDetails?.usrDateOfBirth)
                                || 'NA'}}</span> <span
                                *ngIf="calculateAge(userInfoDetails?.usrDateOfBirth) > 0">&nbsp;yrs</span>
                        </div>
                    </div>
                    <!-- <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="pName text-right">Doctor Name: <span class="name-value">{{appointmentDetails?.appointment?.authorized_official_first_name}} {{appointmentDetails?.appointment?.authorized_official_last_name}}</span></div>
                    </div> -->
                </div>


                <div class="date-filters mt-4">
                    <form [formGroup]="filterByDateForm">
                        <h5 class="filter-title">Appointment Filters</h5>
                        <div class="row">
                            <div
                                [ngClass]="!notValidationRequire ? 'col-lg-2 col-md-2 col-sm-12' : 'col-lg-4 col-md-4 col-sm-12'">
                                <div class="pName">

                                    <label for="datemin" class="form-label">Appointment Start Date :</label>
                                    <span class="name-value"> <input type="date" id="datemin" onkeydown="return false"
                                            name="datemin" class="form-control input-text" formControlName="startDate"
                                            id="startDate" placeholder="Appointment Start Date"
                                            (blur)="validateEndDate()"></span>

                                    <!-- <span
                                        *ngIf="filterByDateForm?.get('startDate')?.errors?.required && filterByDateForm?.get('startDate')?.touched"
                                        class="error">Start Date is Required</span> -->
                                    <span *ngIf="requiredStartDate" class="error">Start Date is Required</span>
                                </div>
                            </div>
                            <div
                                [ngClass]="!notValidationRequire ? 'col-lg-2 col-md-2 col-sm-12' : 'col-lg-4 col-md-4 col-sm-12'">
                                <div class="pName"><label for="datemax" class="form-label"> Appointment End Date
                                        :</label>
                                    <span class="name-value"><input type="date" id="datemax" onkeydown="return false"
                                            name="datemax" class="form-control input-text" formControlName="endDate"
                                            id="endDate" placeholder="Appointment End Date" [min]="minEndDate"
                                            (blur)="validateDateVal()"></span>
                                    <!-- <span
                                        *ngIf="filterByDateForm?.get('endDate')?.errors?.required && filterByDateForm?.get('endDate')?.touched"
                                        class="error">End Date is Required</span> -->
                                    <span *ngIf="requiredEndDate" class="error">End Date is Required</span>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <label for="selectstatus" class="form-label status-txt">Status:</label>
                                <select class="form-control" id="selectstatus" formControlName='eventStatus'>
                                    <option>ALL</option>
                                    <option>Cancelled</option>
                                    <option>Completed </option>
                                    <!-- <option>Confirmed</option> -->
                                    <option>Scheduled</option>
                                </select>
                            </div>

                        </div>
                        <div class="row text-center">
                            <div [ngClass]="!notValidationRequire ? 'col-lg-8 col-md-8 col-sm-12' : 'col-lg-4 col-md-4 col-sm-12'"
                                class="full-width" style="margin-top:10px;">
                                <div class="pName"> <span class="name-value"><button type="button"
                                            class="btn btn-primary-careeco resetbtn" id="careEvents_submit1"
                                            (click)="getevents()">Submit</button></span>
                                    <span class="name-value"><button type="button"
                                            class="btn btn-primary-careeco resetbtn" id="careEvents_reset"
                                            (click)="resetevents()">Reset</button></span>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>



                <!-- <div class="row">
                    <form [formGroup]="filterByDateForm">
                        <div class="col-lg-12 col-md-12 col-sm-12 mb-4" style="display:inline-flex; margin-top:10px;">
                            <div class="col-lg-4 col-md-4 col-sm-12 text-center"> Start Date : <input type="date" id="datemin" onkeydown="return false" name="datemin"
                                    class="form-control input-text" formControlName="startDate" id="startDate"
                                    placeholder="Start Date" (blur)="validateEndDate()">

                                <span
                                    *ngIf="filterByDateForm?.get('startDate')?.errors?.required && filterByDateForm?.get('startDate')?.touched"
                                    class="error">Start Date is Required</span>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-12 text-center" style="margin-left:5px;"> End Date : <input type="date" id="datemax"
                                    onkeydown="return false" name="datemax" class="form-control input-text"
                                    formControlName="endDate" id="endDate" placeholder="End Date" [min]="minEndDate">
                                <span
                                    *ngIf="filterByDateForm?.get('endDate')?.errors?.required && filterByDateForm?.get('endDate')?.touched"
                                    class="error">End Date is Required</span>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12 text-center" style="margin-top:20px;">
							<button type="button"
                                    class="btn btn-primary  mb-3 resetbtn"
                                    (click)="getevents()" style="background-color:#5EBA89">Submit</button>
									
									<button type="button"
                                    class="btn btn-info   mb-3 resetbtn"
                                    (click)="resetevents()" style="">Reset</button>
									</div>


                        </div>
                    </form>
                </div>-->
                <!-- eventss and Patient reference sections -->
                <div class="row">
                    <div
                        [ngClass]="!notValidationRequire ? 'col-lg-6 col-md-6 col-sm-12' : 'col-lg-8 col-md-12 col-sm-12'">
                        <div class="events-timeline white-bg">
                            <h5>EVENTS TIMELINE</h5>
                            <!-- <div class="form-group mb-3">
                                <label>Appointment Date</label>
                                <ng-select placeholder="Choose Appointment Date" [items]="allAppointmentsWithDates"
                                    (change)="changeAppointmentDate($event)" [(ngModel)]="defaultAppointmentDate" bindLabel="dateTime" bindValue="apptId">
                                </ng-select>
                            </div> -->
                            <div class="all-events overflow-scroll-style">
                                <div class="event mt-3" [class.active]="selectedIndex === i"
                                    *ngFor="let event of getEventMessages; let i = index" (click)="setIndex(i, event)">
                                    <div class="event-header-sec event-highlight mt-2">
                                        <h5 class="event-title">Doctor's Office Visit <span class="event-status"
                                                [ngClass]="{'cancel': event?.latestStatus === 'Cancelled', 'reschedule': event?.latestStatus === 'Scheduled' }">{{event?.latestStatus}}</span>
                                        </h5>

                                        <span class="phone"><i class="las la-phone"></i> &nbsp;
                                            {{event?.facilityContact || 'N/A'}}</span>
                                        <span class="visit-date text-right">Visit Date: <span
                                                class="value">{{dateFormate(event?.apptDate)}}</span></span>
                                    </div>
                                    <!-- Facility Address -->
                                    <div class="addess mt-2" *ngIf="event?.address">
                                        <span *ngIf="event?.address?.street">{{event?.address?.street}}, </span>
                                        <span *ngIf="event?.address?.city">{{event?.address?.city}}, </span>
                                        <span *ngIf="event?.address?.state">{{event?.address?.state}}, </span>
                                        <span *ngIf="event?.address?.zipCode"> {{event?.address?.zipCode}}</span>
                                    </div>
                                    <!-- Facility and Doctor name -->
                                    <div class="event-header-sec mt-2">
                                        <h5 class="event-title" *ngIf="event?.doctorName">Doctor Name:&nbsp;
                                            <b>{{event?.doctorName}}</b>
                                        </h5>
                                        <h5 class="event-title" *ngIf="event?.facilityName">Facility Name:&nbsp;
                                            <b>{{event?.facilityName}}</b>
                                        </h5>
                                    </div>

                                    <ng-container *ngFor="let message of event?.messageList; let msgi = index">

                                        <ng-container
                                            *ngIf="(message?.qualifier === 'Reschedule' || message?.qualifier === 'Scheduled') && (msgi === 0)">
                                            <div class="what-to-expect mt-4 hidden-xs">
                                                <div class="whatexpect-value">{{message?.messageInformation}}</div>
                                                <div class="whatexpect-value mt-2"
                                                    *ngIf="isButtonLinkShown(message?.buttonLink)">
                                                    {{message?.buttonLink}}</div>
                                            </div>
                                            <!-- <div class="vissible-xs btn btn-primary btn-default" data-toggle="modal"
                                                data-target="#patient-refference"
                                                (click)="openPatientPreference()">
                                                View Details111
                                            </div> -->
                                            <div class="dynamic-btns mt-4 text-right" *ngIf="!message?.doNotShowAction">
                                                <button type="button" class="btn btn-primary btn-primary-careeco mb-2" id="careEvents_cancel"
                                                    (click)="openCancelAppointment(event)">Cancel</button>
                                                <button type="button" style="margin-left: 15px;"
                                                    class="btn btn-primary btn-primary-careeco mb-2" id="careEvents_reschedule"
                                                    (click)="actionNavigate(event)">Reschedule</button>
                                            </div>
                                        </ng-container>

                                        <ng-container
                                            *ngIf="(message?.qualifier !== 'Reschedule' && message?.qualifier !== 'Scheduled') && (msgi === 0)">
                                            <div class="vissible-xs btn btn-primary btn-default" data-toggle="modal"
                                                data-target="#patient-refference" (click)="openPatientPreference()"
                                                *ngIf="(message?.qualifier === 'Notification' || message?.qualifier !== 'Notification' )">
                                                View Details
                                            </div>
                                            <div class="what-to-expect mt-4 hidden-xs">

                                                <div class="whatexpect-value"
                                                    *ngIf="message?.qualifier === 'Notification'">Notification from your
                                                    Doctor's office, Please check under patient reference </div>
                                                <div class="whatexpect-value"
                                                    *ngIf="message?.qualifier !== 'Notification'">
                                                    {{message?.messageInformation}}</div>
                                                <!-- <div class="whatexpect-value mt-2" *ngIf="isButtonLinkShown(message?.buttonLink)">{{message?.buttonLink}}</div> -->
                                            </div>
                                            <div class="dynamic-btns mt-4 text-right" *ngIf="!message?.doNotShowAction">
                                                <button type="button"
                                                    class="btn btn-primary btn-primary-careeco mb-2">{{message?.qualifier}}</button>
                                            </div>
                                        </ng-container>

                                    </ng-container>
                                </div>
                                <p *ngIf="getEventMessages && getEventMessages.length === 0">No events found</p>
                            </div>
                        </div>
                    </div>

                    <div
                        [ngClass]="!notValidationRequire ? 'col-lg-6 col-md-6 col-sm-12' : 'col-lg-4 col-md-4 col-sm-12'">

                        <div class="patient-reference white-bg hidden-xs">
                            <h5 class="mb-3">PATIENT REFERENCE</h5>
                            <!-- <ng-container *ngIf="selectedMessage?.qualifier === 'Discharge'">
                                <h5 class="mb-3">Post-Visit Summary</h5>
                                <p>{{selectedMessage?.buttonLink || 'N/A'}}</p>
                            </ng-container>
                            <ng-container *ngIf="selectedMessage?.qualifier === 'Test Results'">
                                <h5 class="mb-3">Test Results</h5>
                                <p>{{selectedMessage?.buttonLink || 'N/A'}}</p>
                            </ng-container>
                            <ng-container *ngIf="selectedMessage?.qualifier === 'Notification'">
                                <h5 class="mb-3">Notification</h5>
                                <p [innerHTML]="selectedMessage?.buttonLink"></p>
                            </ng-container> -->

                            <ng-container *ngFor="let refMsg of selectedMessage">
                                <ng-container
                                    *ngIf="(refMsg?.qualifier !== 'Reschedule' && refMsg?.qualifier !== 'Scheduled')">
                                    <h5 class="mt-3" *ngIf="refMsg?.qualifier === 'Notification'">Notification from
                                        Doctor's Office - <b>{{dateFormate(refMsg?.messageDate)}}</b></h5>
                                    <h5 class="mt-3" *ngIf="refMsg?.qualifier !== 'Notification'">{{refMsg?.qualifier}}
                                        - <b>{{dateFormate(refMsg?.messageDate)}}</b></h5>
                                    <!-- <div style="padding-left: 15px;" class="mb-2">{{refMsg?.messageInformation}}</div> -->
                                    <!-- <div *ngIf="refMsg?.patientReferenceDocs.length>0">
                                        <h6 *ngFor="let docVal of refMsg?.patientReferenceDocs;let i=index"
                                            (click)="getDoc(docVal)"><b>Document{{i+1}}<br></b></h6>
                                    </div> -->
                                    <p> 
                                        {{refMsg?.messageInformation}}
                                    </p>

                                    <div class="doc-list" *ngIf="refMsg?.patientReferenceDocs && refMsg?.patientReferenceDocs.length>0">
                                        <h6>Download Records below</h6>
                                        <i class="lar la-file-pdf" *ngFor="let docVal of refMsg?.patientReferenceDocs;let i=index"
                                        (click)="getDoc(docVal)"></i>

                                        <!-- <i class="lar la-file-pdf"></i>

                                        <i class="lar la-file-pdf"></i>

                                        <i class="lar la-file-pdf"></i> -->

                                    </div>
                                    <div style="padding-left: 15px;" *ngIf="isButtonLinkShown(refMsg?.buttonLink)"
                                        [innerHTML]="refMsg?.buttonLink"></div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>

                    <!-- <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="academy-images text-center">
                            <img  src="assets/images/academy-footer-image.png" alt="Academy Image">
                        </div>
                    </div> -->
                </div>
            </ng-container>
        </div>
    </div>

    <!-- spinner -->
    <div class="custom-loader justify-content-center" *ngIf="loadSpinner" id="spinnerDisplay">
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>
<!-- Modal -->
<div id="book-appointment-modal" [style.display]="exceptionalModal ? 'block' : 'none'" class="modal" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-body">
                <h5 style="text-align: center;padding: 15px;">{{notifyText}}</h5>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <button type="button" class="btn btn-default ok-book" data-dismiss="modal" id="careEvents_ok"
                        (click)="exceptionalModal = false">Ok</button>
                    </div>
                </div>
               
            </div>
        </div>
    </div>
</div>

<!-- Cancel Appointment -->
<div id="book-appointment-modal" [style.display]="cancelAppointmentModal ? 'block' : 'none'" class="modal"
    role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h5>Cancel Appointment</h5>
            </div>
            <div class="modal-body" [formGroup]="cancelAppointmentForm">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group mb-3">
                            <label>Cancellation Reason<span class="error">*</span></label>
                            <ng-select placeholder="Choose Reason for Cancel" formControlName="cancellationReason"
                                id="REASON_FOR_CANCEL" [items]="reasonOfCancel" bindLabel="desc" autofocus
                                bindValue="value" (change)="cancelReasonSection($event)">
                            </ng-select>
                            <span
                                *ngIf="cancelAppointmentForm?.get('cancellationReason')?.errors?.required && cancelAppointmentForm?.get('cancellationReason')?.touched"
                                class="error">Cancellation Reason is Required</span>
                        </div>
                    </div>
                </div>
                <div class="row">

                    <div *ngIf="isOtherReason" class="col-lg-12 col-md-12 col-sm-12">

                        <div class="form-group mb-3">
                            <textarea rows="5" formControlName="REASON_FOR_CANCEL_OTHER" class="form-control"
                                id="cancelReason" placeholder="Enter cancellation reason..."></textarea>
                        </div>


                        <span
                            *ngIf="cancelAppointmentForm?.get('REASON_FOR_CANCEL_OTHER')?.errors?.required && cancelAppointmentForm?.get('REASON_FOR_CANCEL_OTHER')?.touched"
                            class="error">Cancellation reason is Required</span>
                    </div>
                </div>

                <!-- <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group mb-3">
                            <label>Cancellation Reason 111<span class="error">*</span></label>
                            <textarea class="form-control input-text" formControlName="cancellationReason"
                                id="cancellationReason" placeholder="Cancellation Reason"></textarea>
                            <span
                                *ngIf="cancelAppointmentForm?.get('cancellationReason')?.errors?.required && cancelAppointmentForm?.get('cancellationReason')?.touched"
                                class="error">Cancellation Reason is Required</span>
                        </div>
                    </div>
                </div>
            -->

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-primary-careeco" style="margin-right: 15px;" id="careEvents_close1"
                    data-dismiss="modal" (click)="cancelAppointmentModal = false">Close</button>
                <button type="button" class="btn btn-primary btn-primary-careeco" data-dismiss="modal" id="careEvents_submit"
                    (click)="cancelAppointment()">Submit</button>
            </div>
        </div>
    </div>
</div>
<!--Patient preference Model for mobile-->
<div class="modal" id="patient-refference" role="dialog" [style.display]="patientPreferenceModal ? 'block' : 'none'">
    <div class="modal-dialog">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h5 class="mb-3">PATIENT REFERENCE</h5>
                <button type="button" class="close pull-right text-right" data-dismiss="modal"
                    (click)="patientPreferenceModal = false">&times;</button>
            </div>
            <div class="modal-body">
                <div>

                    <div class="patient-reference white-bg">



                        <ng-container *ngFor="let refMsg of selectedMessage">
                            <ng-container
                                *ngIf="(refMsg?.qualifier !== 'Reschedule' && refMsg?.qualifier !== 'Scheduled')">
                                <h5 class="mt-3" *ngIf="refMsg?.qualifier === 'Notification'">
                                    Notification from
                                    Doctor's Office -
                                    <b>{{dateFormate(refMsg?.messageDate)}}</b>
                                </h5>
                                <h5 class="mt-3" *ngIf="refMsg?.qualifier !== 'Notification'">
                                    {{refMsg?.qualifier}}
                                    -
                                    <b>{{dateFormate(refMsg?.messageDate)}}</b>
                                </h5>
                                <!-- <div style="padding-left: 15px;" class="mb-2">{{refMsg?.messageInformation}}</div> -->
                                <div style="padding-left: 15px;" *ngIf="isButtonLinkShown(refMsg?.buttonLink)"
                                    [innerHTML]="refMsg?.buttonLink"></div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="cancel-appointment-page-modal" [style.display]="showModalCancel ? 'block' : 'none'" class="modal"
    role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-body">

                <iframe id="lab-rad-medical-record-pdf" frameBorder="0" width="100%" height="500px"></iframe>

            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <button type="button" class="btn btn-default ok-book" data-dismiss="modal" id="careEvents_close"
                    (click)="closeModel()">Close</button>

                    </div>
                </div>
                
            </div>
        </div>
    </div>
</div>