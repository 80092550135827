import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar-left',
  templateUrl: './navbar-left.component.html',
  styleUrls: ['./navbar-left.component.scss']
})
export class NavbarLeftComponent {
  @ViewChild('userProfile', { static: false }) userProfile!: ElementRef;

  openSidebar: boolean = false;

  menuSidebar = [
    {
      link_name: "Invoice Board",
      link: "invoice/invoiceBoard",
      icon: "bx bx-grid-alt",
      sub_menu: []
    }
  ]

  public organizationDetails: any;

  public signout_profile = false;
  private facilityId: string | null;
  //  @HostListener('document:click', ['$event'])
  //  clickInside(event?: any) {
  //    const eventId = (event.target as HTMLInputElement).id;
  //    if ( eventId !== 'navBarLeft_profile') {
  //      this.signout_profile = false;
  //    }
  //  }



  constructor(public router: Router) {
    this.facilityId = sessionStorage?.getItem('headerFacilityId');
  }

  ngOnInit() {
    if (sessionStorage?.getItem('submitterOrgDetails')) {
      this.organizationDetails = JSON?.parse(sessionStorage?.getItem('submitterOrgDetails') || '')
    }
  }

  public getSubmitterDetails(): any {
    return sessionStorage?.getItem('submitterOrgDetails') ? JSON?.parse(sessionStorage?.getItem('submitterOrgDetails') || '{}') : {};
  }

  openProfile() {
    if (this.signout_profile == false) {
      this.signout_profile = true;
    } else {
      this.signout_profile = false;
    }

  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event) {
    const clickedInside = this.userProfile.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.signout_profile = false;
    }
  }
  showSubmenu(itemEl: HTMLElement) {
    itemEl.classList.toggle("showMenu");
  }

  signOut() {
    sessionStorage.setItem('isLogin', 'false');
    const facilityId = sessionStorage?.getItem('headerFacilityId');
    this.router?.navigate([`${this.facilityId || facilityId}/providerPortal`]);
    // this.router.navigate([`${this.organizationDetails?.facilityId}/providerPortal`]);
  }
}

