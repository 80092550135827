import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NavigationStart, Router, ActivatedRoute, Params } from '@angular/router';
import { EMPTY, Observable, Subject, Subscription, concat, forkJoin, of } from 'rxjs';
import { catchError, distinctUntilChanged, expand, switchMap, tap } from 'rxjs/operators';
import { ExternalGatewayService } from 'src/app/shared/external-gateway.service';
import { HttpService } from 'src/app/shared/http.service';
import { Location } from '@angular/common';
import { AppRegExpressionsConfig } from 'src/app/shared/app.regularexpressions';
import { UiModalComponent } from 'src/app/shared/components/ui-modal/ui-modal.component';
import { UploadDocumentsComponent } from 'src/app/shared/components/upload-documents/upload-documents.component';
import { catalogInfoResponseLimitsSchema } from 'square/dist/types/models/catalogInfoResponseLimits';

@Component({
  selector: 'app-createinvoice',
  templateUrl: './createinvoice.component.html',
  styleUrls: ['./createinvoice.component.scss']
})
export class CreateinvoiceComponent {

  public invoiceForm: FormGroup;
  public nameSearchResults: any[] = [];
  public showModalCancel: boolean = false;
  public contactSearchResults: any[];
  public searchForm: FormGroup;
  public userInfoError: string;
  public enableAction: boolean = false;
  public notValidDob: boolean = false;
  public isDateFormat: boolean = false;
  public isNumber: boolean = false;
  public billingInput = new Subject<string>();
  public allBillingCodes: Observable<any> | undefined;
  public billingCodesLoading = false;
  facilityId: number;
  services: any;
  public allIcdCodesObservebale!: Observable<any>;
  public icdCodesLoading = false;
  public icdInput = new Subject<string>();
  public icdCodesSelected!: any[];
  public loadSpinner!: boolean;
  public notifyText!: string;
  public exceptionalModal!: boolean;
  public invoiceId: any;
  public duplicateInvoice: any;
  public status: string = "OPEN";

  navigationSubscription: Subscription = new Subscription();

  @ViewChild('fileUpload') public fileUpload!: ElementRef;
  @ViewChild(UiModalComponent) modalComponent !: UiModalComponent;

  public documentsList: any = [];
  public showDocsViewModal!: boolean;
  private selectedUserForAddDocs!: FormGroup<any>;
  @ViewChild(UploadDocumentsComponent) uploadDocument!: UploadDocumentsComponent;
  public saveInvoice: boolean = false;
  public saveAndUpdate: boolean = false;
  @ViewChild('invoicePatientLevalDocumnets') invoicePatientLevalDocumnets!: UiModalComponent;
  public docViewIndex!: number;
  public allPatientDocsSelectedKeys!: any;
  public readDocumentsList: any = [];
  public invoiceReferralId = null;
  public patientNameLoading: boolean = false;
  public allPatientList: Observable<any> | undefined;
  public patientInput = new Subject<string>();

  @ViewChild('confirmPatientDelete') confirmPatientDelete!: UiModalComponent;
  @ViewChild('confirmServiceDelete') confirmServiceDelete!: UiModalComponent;
  @ViewChild('noPatientDetailsModal') noPatientDetailsModal!: UiModalComponent;


  public patientIndex: any;
  public serviceIndex: any;
  public maxDate: Date;
  public selectedRecord: any;
  public selectedDocumentName: any;
  public deletedDocIndex!: number;
  public deleteSelectedDoc: any;
  public deleteDocsFlag!: string;
  public selectedViewPatientDocsForm!: FormGroup<any>;
  public isUserLevelDocsView!: boolean;
  public isDupSaveSubmitFlag!: string;
  public openActions: boolean = false;
  public enableActions: boolean = false;
  public enableServiceActions: boolean = false;
  public selectedActionRow: any;
  public openServiceActions: boolean = false;
  public selectedServiceActionRow: any;
  public totalDeniedAmount!: number;
  public isSuccessNotify: boolean = true;

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const eventId = (event.target as HTMLInputElement).id;
    if (!this.enableActions && eventId !== 'claimActions_btn') {
      this.openActions = false;
    }
    if (!this.enableServiceActions && eventId !== 'claimActionsService_btn') {
      this.openServiceActions = false;
    }
  }
  constructor(private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder, private httpService: HttpService,
    private externalService: ExternalGatewayService, public router: Router, private Location: Location) {
    this.maxDate = this.httpService.convertDate(new Date());

    let routerUrl: any = this.Location.path();
    //if(routerUrl.indexOf("create") > -1) {
    // console.log(11);
    //}
    console.log(routerUrl);
    this.activatedRoute?.params?.subscribe(data => {
      if (routerUrl.indexOf("create") > -1) {
        this.invoiceReferralId = data?.id;
      }
    });

    this.activatedRoute?.queryParams?.subscribe(
      (params: Params) => {
        if (Object.keys(params).length > 0) {
          if (params && params['invoiceId']) {
            this.invoiceId = params['invoiceId'] || null;
            this.getInvoiceDetails(this.invoiceId)
          }
          if (params && params['duplicate'] === 'yes') {
            this.duplicateInvoice = 'duplicate';
          }
        }
      }
    );

    // let routerPath: any = routerUrl?.split('/');
    // if (routerPath && routerPath[3] && routerPath[2] != 'duplicate') {
    //   this.invoiceId = routerPath[3]
    //   this.getInvoiceDetails(this.invoiceId)
    // }
    // if (routerPath && routerPath[2] && routerUrl.indexOf("view") > -1) {
    //   if (routerPath[2] == 'duplicate') {
    //     this.duplicateInvoice = routerPath[2];
    //     this.invoiceId = routerPath[3];
    //     this.getInvoiceDetails(this.invoiceId)
    //   }
    // }
    this.facilityId = parseInt(sessionStorage?.getItem('headerFacilityId') || '');
    // sessionStorage?.setItem('headerFacilityId', JSON?.stringify(this.facilityId))
    this.userInfoError = '';
    this.contactSearchResults = [];
    this.searchForm = this.formBuilder?.group({
      'SEARCH_PATIENT_FIELD': [null],
    });

    this.invoiceForm = this.formBuilder.group({
      "billedAmount": [null], // number
      "comments": [null],
      "documentKeys": [null],
      "email": [null],
      "facilityId": [null],
      "id": [null],
      "invoiceCreatedDate": [null],
      "invoiceId": [null],
      "invoiceName": [null],
      "invoiceStatus": [null],
      "memberActivityDtoList": this.formBuilder.array([]),
      "orgAddress": this.addressFormGroup(),
      "orgId": [null],
      "organizationName": [null, Validators?.required],
      "referenceId": [null],
      "referralId": [null],
      "referralStatus": [null],
      "title": [null],
      "totalPatientCount": [null],
      "totalUnits": [null],
      submitterName: [null, Validators?.required], // ask API team abount this field, this field is not modal creation
      // organizationName: [null],
      // invoiceNumber: [null],
      // invoiceName: [null],
      // referenceId: [null],
      // referralNumber: [null],
      // 'patients': this.formBuilder.array([

      // ])
    });
    if (!this.invoiceId) {
      this.addNewPatient();
    }
    console.log('invoiceReferralId', this.invoiceReferralId);
    if (this.invoiceReferralId !== null && this.invoiceReferralId !== '' && this.invoiceReferralId !== undefined) {
      this.invoiceForm.get('referralId')?.setValue(this.invoiceReferralId);
      this.invoiceForm?.get('referralId')?.setValidators([Validators.required]);
      this.invoiceForm?.get('referralId')?.updateValueAndValidity;
    }
    else {
      this.invoiceForm.get('referralId')?.setValue('');
      this.invoiceForm?.get('referralId')?.clearValidators;
      this.invoiceForm?.get('referralId')?.updateValueAndValidity;
    }

  }

  createPatientFormGroup(invoice?: any): FormGroup {
    let fullName: any;
    if (invoice?.lastName || invoice?.firstName) {
      fullName = invoice?.lastName ? `${invoice?.firstName || ''} ${invoice?.lastName || ''}` : `${invoice?.firstName || ''}`;
    } else {
      fullName = invoice?.firstName;
    }
    let id = null;
    let invoiceMasterId = null;
    if (this.duplicateInvoice == 'duplicate') {
      id = null;
      invoiceMasterId = null
    } else {
      id = invoice?.id || null;
      invoiceMasterId = this.invoiceId || null
    }
    return this.formBuilder.group({
      dob: [invoice?.dob || null, [Validators.required, this.validateManualEnterdob]],  // Formate YYYY-MM-DD
      "employerName": [null],
      // "patientName": [fullName || null, Validators.compose([Validators.required, Validators.pattern(AppRegExpressionsConfig.fullName)])],
      "patientName": [fullName || null, Validators.required],
      "firstName": [invoice?.firstName || null],
      "id": [id || null],
      "invoiceMasterId": [invoiceMasterId || null],
      "lastName": [invoice?.lastName || null],
      "serviceEndDate": [(!this.duplicateInvoice ? invoice?.serviceEndDate : null) || null],
      "serviceStartDate": [(!this.duplicateInvoice ? (invoice?.serviceStartDate?.split('T')[0] || this.httpService.getServiceDate(invoice?.serviceStartDate)) : null) || null, [Validators.required, this.validateManualEnterdob]],
      "userInfoId": [invoice?.userInfoId || null],
      "serviceLinesInfo": this.formBuilder.array([this.createServiceFormGroup()]),
      "documentKeys": [(!this.duplicateInvoice ? invoice?.documentKeys : null) || null],
      // patientName: [null],
      // dob: [null],
      'patientEmrId': [invoice?.patientEmrId || null],
      // serviceDate: [null],
      // services: this.formBuilder.array([this.createServiceFormGroup()])
    });
  }

  createServiceFormGroup(service?: any): FormGroup {
    return this.formBuilder.group({
      "billedAmount": [service?.billedAmount || null, [Validators.required]],
      "cptCode": [service?.cptCode || null],
      "description": [service?.description || null],
      "dxCodes": [service?.dxCodes || null],
      "notes": [service?.notes || null],
      "status": [service?.status || null],
      "units": [service?.units || null],

      // diagnosisCode: [null],
      // serviceDescription: [null],
      // cptCode: [null],
      // units: [null],
      // serviceCost: [null]
      //totalCost: [null]
    });
  }



  // Address Form Group
  private addressFormGroup(): FormGroup {
    return this.formBuilder?.group({
      "city": [null],
      "country": [null],
      "state": [null],
      "street": [null],
      "zipCode": [null],
      "zipCodeString": [null]
    });
  }
  validateManualEnterdob(control: AbstractControl): ValidationErrors | null {
    const date = new Date(control.value);
    const toDayDate = new Date();
    if (date != undefined && date != null) {
      if (date.getFullYear() < 1910 || date > toDayDate) {
        return { invalidDate: true }; // Invalid date
      }
      return null; // Valid date
    }
    return { invalidDate: true }; // Invalid if no date is provided
  }
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (this.invoiceForm.dirty) {
      $event.returnValue = true;
    }
  }
  public openService(event: any, row: any, mainrow: any) {
    if (this.selectedServiceActionRow === row) {
      this.openServiceActions = !this.openServiceActions;
    }
    else {
      this.openServiceActions = true;
    }

    this.selectedServiceActionRow = row;
    this.selectedActionRow = mainrow;

  }
  // public closeUniversalActions() {

  //   this.openActions = false;
  // }

  public mouseServiceIn() {
    this.enableServiceActions = true;

  }

  public mouseServiceOut() {
    this.enableServiceActions = false;

  }


  public openUniversalActions(event: any, row: any) {
    if (this.selectedActionRow === row) {
      this.openActions = !this.openActions;
    }
    else {
      this.openActions = true;
    }

    this.selectedActionRow = row;

  }
  public closeUniversalActions() {

    this.openActions = false;
  }

  public mouseIn() {
    this.enableActions = true;

  }

  public mouseOut() {
    this.enableActions = false;

  }


  ngOnInit() {
    this.loadBillingCodes();
    this.loadAssesmentIcdCodes();
    if (!this.invoiceId) {
      this.defaultOrgVals();
    }
    this.loadPatient();
  }
  ngAfterViewInit() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.navigationSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.invoiceForm.dirty && !confirm('You have unsaved changes. Are you sure you want to leave this page?')) {
          this.router.navigate([this.router.url]);
        }
      }
    });

  }
  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  totalCost(index: number): any {
    const patient = this.getPatientsControls()?.controls[index]?.value;
    let totalCost = 0;

    if (patient && patient.serviceLinesInfo) {
      patient.serviceLinesInfo.forEach((service: { units: any; billedAmount: any; }) => {
        const units = parseInt(service.units || '1', 10);
        const billedAmount = parseFloat(service.billedAmount || '0');
        totalCost += units * billedAmount;
      });
    }
    return totalCost;
  }

  validateDecimal(event: Event, patientIndex: number, serviceIndex: number): void {
    const input = event.target as HTMLInputElement;
    let value = input.value;
    // Ensure only up to two decimal places if more than 2 digits after decimal
    const decimalIndex = value.indexOf('.');
    console.log("decimalIndex ", decimalIndex, "value ", value)
    if (decimalIndex !== -1 && value.length - decimalIndex - 1 > 2) {
      value = parseFloat(value).toFixed(2); // Round to 2 decimal places
      input.value = value; // Update the displayed value
      console.log("updated vcalue", value, "input.value", input.value)
    }
    // else {
    //   value = input.value
    // }
    // Update the form control value
    const patients = this.invoiceForm.get('memberActivityDtoList') as FormArray;
    if (patients.at(patientIndex)) {
      const services = (patients.at(patientIndex).get('serviceLinesInfo') as FormArray);
      if (services.at(serviceIndex)) {
        const control = services.at(serviceIndex).get('billedAmount');
        if (control && decimalIndex !== -1 && value.length - decimalIndex - 1 > 2) {
          control.setValue(value, { emitEvent: false });
        }
      }
    }
  }


  getInvoiceTotal(): number {
    let invoiceTotal = 0;
    let deniedTotal = 0;
    const patientsArray = this.invoiceForm.get('memberActivityDtoList') as FormArray;
    patientsArray.controls.forEach((patientControl: AbstractControl) => {
      if (patientControl instanceof FormGroup) {
        const servicesArray = patientControl.get('serviceLinesInfo') as FormArray;
        servicesArray.controls.forEach((serviceControl: AbstractControl) => {
          if (serviceControl instanceof FormGroup) {
            const billedAmount = parseFloat(serviceControl.get('billedAmount')?.value) || 0;
            const units = serviceControl.get('units')?.value || 1;

            // Add the billedAmount to invoiceTotal
            invoiceTotal += units * billedAmount;
            if (serviceControl?.get('status')?.value === 'DENIED') {
              deniedTotal += units * billedAmount;
            }
          }
        });
      }
    });
    this.totalDeniedAmount = deniedTotal;
    return invoiceTotal;
  }


  // get ICD codes
  public trackByFnCptCodes(item: any) {
    return item?.billingCodesMasterData?.cptCode || item?.cptCode;
  }

  public trackByFnDxCodes(item: any) {
    return item?.code || item?.code;
  }

  private loadBillingCodes() {
    this.allBillingCodes = concat(
      of([]), // default items
      this.billingInput.pipe(
        distinctUntilChanged(),
        tap(() => this.billingCodesLoading = true),
        switchMap(term => this.httpService.billingCptCodesTrackBy(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.billingCodesLoading = false)
        ))
      )
    );
  }

  // Search Dx Code
  private loadAssesmentIcdCodes() {
    this.allIcdCodesObservebale = concat(
      of([]), // default items
      this.icdInput.pipe(
        distinctUntilChanged(),
        tap(() => this.icdCodesLoading = true),
        switchMap(term => this.httpService?.icdCodesTrackByCategory(term, 'All').pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.icdCodesLoading = false)
        ))
      )
    );
  }

  public closeModel() {
    this.showModalCancel = false;
  }

  addNewService(patientFormGrp?: any, patientIndex?: number) {
    const getService = patientFormGrp?.get('serviceLinesInfo') as FormArray;
    getService.push(this.createServiceFormGroup());
  }


  getPatientsControls() {
    return (this.invoiceForm.get('memberActivityDtoList') as FormArray);
  }

  getMemberControls(i: any) {
    return (this.getPatientsControls()?.at(i)?.get('serviceLinesInfo') as FormArray);
  }

  public patientSericesArray(patient: any): FormArray {
    return patient?.get('serviceLinesInfo') as FormArray;
  }

  getServices(index: number): FormArray {
    return this.getPatientsControls()
      .at(index)
      .get('serviceLinesInfo') as FormArray;
  }

  addNewPatient() {
    const patientsArray = this.invoiceForm.get('memberActivityDtoList') as FormArray;
    patientsArray.push(this.createPatientFormGroup());
    // this.addNewService(this.createPatientFormGroup())
  }

  public async onCreateInvoiceSubmit(callFrom: String) {
    if (this.invoiceForm?.invalid) {
      this.markAllAsTouched(this.invoiceForm);
      return;
    }

    const invoiceDetails = JSON.parse(JSON?.stringify(this.invoiceForm?.value))
    const params = invoiceDetails;
    if (invoiceDetails?.memberActivityDtoList?.length === 0) {
      this.noPatientDetailsModal.show();
      return;
    }

    // Uploading the selected documents
    let allDocsUpload: any[] = [];
    let allDocsUploaded: any[] = [];
    this.loadSpinner = true;
    if (this.documentsList && this.documentsList?.length > 0) {
      let dupDocList: any[] = [...this.documentsList] || [];
      dupDocList?.forEach((docFile: any) => {
        const getData = this.uploadAdditionalDocuments(docFile);
        allDocsUpload?.push(this.httpService?.makePostWithFormData(getData?.action, getData?.formData));
      });
      let allKeys: any[] = [];
      try {
        const allUploadKeys = await forkJoin(allDocsUpload)?.toPromise();
        allUploadKeys?.forEach((keyDoc: any) => {
          const action = `record/listIndividualDocument?documentType=Invoice&additionalKey=${keyDoc?.keyName}`;
          allKeys?.push(this.httpService?.makeGetRequest(action));
        });
      } catch {
        this.loadSpinner = false;
      }
      // S3 document sissue
      try {
        const alS3Docs = await forkJoin(allKeys)?.toPromise();
        alS3Docs?.forEach((s3Doc: any) => {
          if (s3Doc?.responseObject) {
            allDocsUploaded?.push(s3Doc?.responseObject);
          }
        });
      } catch {
        this.loadSpinner = false;
      }
    }
    const documentKeys: any[] = this.readDocumentsList || [];
    allDocsUploaded?.forEach((docKeys: any) => {
      const obj = {
        "s3Key": docKeys?.s3Key,
        "name": docKeys?.documentName,
        "s3Path": docKeys?.s3Path,
        "fileType": docKeys?.fileType
      }
      documentKeys?.push(obj);
    });
    params.documentKeys = documentKeys || [];
    params["billedAmount"] = this.getInvoiceTotal()
    params["facilityId"] = this.facilityId;
    params["totalPatientCount"] = invoiceDetails?.memberActivityDtoList?.length || 0;

    let allUnits = 0;
    invoiceDetails?.memberActivityDtoList?.forEach((memItem: any) => {
      memItem?.serviceLinesInfo?.forEach((servItem: any) => {
        allUnits = allUnits + servItem?.units;
      });
    });
    params['totalUnits'] = allUnits;
    if (callFrom === 'SAVE') {
      params["invoiceStatus"] = "OPEN"
    }
    else {
      params["invoiceStatus"] = "SUBMITTED"
    }
    let action = '';
    if (this.invoiceId && this.duplicateInvoice != 'duplicate') {
      params['id'] = this.invoiceId;
      delete params?.invoiceCreatedDate;
      action = 'healthWorks/updateInvoice';
    } else {
      action = 'healthWorks/createInvoice';
    }
    this.loadSpinner = true;
    this.externalService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
      if (data?.status == 'SUCCESS') {
        if (callFrom === 'SAVE') {
          this.saveInvoice = true;
          this.notifyText = 'Details saved successfully.';
          this.invoiceForm?.reset();
          this.exceptionalModal = false;
        }
        else {
          this.saveAndUpdate = true;
          this.notifyText = 'Invoice created successfully.'
          this.invoiceForm?.reset();
          // this.router.navigate(['/invoice/invoiceBoard']);
        }
        this.exceptionalModal = true;
        this.documentsList = [];
        this.defaultOrgVals();
      }
      else {
        this.notifyText = data?.msg;
        this.exceptionalModal = true;
      }
      this.loadSpinner = false;
      // this.exceptionalModal = false;
    }, error => {
      this.loadSpinner = false;
      // this.exceptionalModal = false;
      this.notifyText = error?.message || error?.msg || 'Something went wrong please try again later.'
      this.exceptionalModal = true;
    });
  }

  public contactSearchCall(index: number): void {
    const patientNamelength = this.getPatientsControls()?.controls[index]?.value?.patientName
    // this.getPatientsControls()?.controls[index]?.get('patientName')?.invalid 
    this.loadSpinner = true
    // this.showModalCancel = true;
    let searchValue = this.getPatientsControls()?.controls[index]?.value?.patientName;
    const dobDate = this.getPatientsControls()?.controls[index]?.value?.dob
    // let searchValue = this.searchForm.get('SEARCH_PATIENT_FIELD')?.value;
    // let searchValue = 'test';
    let searchParameter = ''
    if (searchValue.includes('')) {
      searchParameter = 'fullName';

    } else {
      searchParameter = 'firstName';
    }
    const action = `userInfo/patientSearch?searchParameter=${searchParameter}&searchParameterValue=${searchValue}`;
    this.httpService?.makePostRequest(action, '').subscribe((data: any) => {
      this.loadSpinner = false
      if (data?.status === 'SUCCESS') {
        this.nameSearchResults = data?.responseObject || [];
        //   if(!patientNamelength.includes(',')){
        //     let showPatientError = document.getElementById(`showError_${index}`) as HTMLElement;
        //     showPatientError.style.display = 'block';
        //   return;
        // }else{
        //   let showPatientError = document.getElementById(`showError_${index}`) as HTMLElement;
        //   showPatientError.style.display = 'none';
        // }
        // if (this.nameSearchResults.length === 0) {
        //   const createUserUrl = `healthWorks/createNewUserEntry`
        //   const addUserParam: any = {}
        //   let newUserFirstName = ''
        //   let newUserLastName = ''
        //   if (searchValue.includes(',')) {
        //     newUserFirstName = searchValue.split(',')[1]
        //     newUserLastName = searchValue.split(',')[1]
        //   }
        //   addUserParam['firstName'] = newUserFirstName
        //   addUserParam["lastName"] = newUserLastName
        //   addUserParam["usrDateOfBirth"] = dobDate
        //   this.httpService?.makePostRequest(createUserUrl, addUserParam).subscribe((newuser: any) => {
        //     if (newuser?.status === 'SUCCESS') {
        //       this.choosepatient(newuser?.responseObject, index, {})
        //       console.log("newuser", newuser)
        //     }
        //   },
        //     error => {
        //       this.loadSpinner = false
        //     });

        // }
      }
    },
      error => {
        this.loadSpinner = false
      });
  }

  choosepatient(contact: any, index: number, patient: any) {
    // const formGroup = this.getPatientsControls() as unknown as FormGroup;
    const newValue = this.getPatientsControls()?.controls[index]?.value; // Clone the existing object
    // newValue.patientName = contact?.lastName + ', ' + contact?.firstName
    // newValue.dob = contact?.dob

    // Sakshi - Never user new Date() obj due to convesion in other regions it will display one day less

    // const dob = new Date(contact?.dob); // Convert ISO 8601 date string to Date object
    // const day = dob.getDate().toString().padStart(2, '0'); // Get day and pad with leading zero if needed
    // const month = (dob.getMonth() + 1).toString().padStart(2, '0'); // Get month and pad with leading zero if needed
    // const year = dob.getFullYear(); // Get full year
    // const formattedDOB = `${year}-${month}-${day}`;
    const formattedDOB = contact?.dob?.split('T')[0];
    newValue.lastName = contact?.lastName || null;
    newValue.firstName = contact?.firstName || null;
    if (contact?.patientName) {
      newValue.patientName = contact.patientName;
    }
    if (contact?.firstName || contact?.lastName) {
      newValue.patientName = contact?.lastName ? `${contact?.firstName || ''} ${contact?.lastName || ''}` : `${contact?.firstName || ''}`;
    }
    newValue.dob = formattedDOB;
    newValue.userInfoId = contact?.userInfoId
    this.getPatientsControls()?.controls[index]?.patchValue(newValue)
    this.nameSearchResults = []
  }

  newPatient(i: any) {
    let searchValue = this.getPatientsControls()?.controls[i]?.value?.patientName?.label || this.getPatientsControls()?.controls[i]?.value?.patientName;
    const dobDate = this.getPatientsControls()?.controls[i]?.value?.dob;
    if (this.nameSearchResults.length === 0) {
      const createUserUrl = `healthWorks/createNewUserEntry`;
      const addUserParam: any = {}
      let newUserFirstName = '';
      let newUserLastName = '';
      if (searchValue?.includes(' ')) {
        newUserFirstName = searchValue?.split(' ')[0];
        newUserLastName = searchValue?.split(' ')[2] || searchValue?.split(' ')[1];
      }
      if (searchValue) {
        newUserFirstName = `${newUserFirstName || searchValue}`;
      }
      addUserParam['firstName'] = newUserFirstName;
      addUserParam["lastName"] = newUserLastName;
      addUserParam["usrDateOfBirth"] = dobDate;
      // if invalidate neter and restrict the calling the create patient
      if (this.httpService?.yearValidator(dobDate)?.invalidDate) {
        return;
      }
      this.httpService?.makePostRequest(createUserUrl, addUserParam).subscribe((newuser: any) => {
        if (newuser?.status === 'SUCCESS') {
          if (newuser?.responseObject) {
            this.choosepatient(newuser?.responseObject, i, {})
          }
        }
      },
        error => {
          this.loadSpinner = false
        });
    }
  }
  confirmDeletePatient(index: number) {
    this.patientIndex = index;
    this.confirmPatientDelete.show();
    //this.getPatientsControls()?.removeAt(index);
  }

  deletePatient(index: number) {

    this.getPatientsControls()?.removeAt(index);
  }

  confirmDeleteService(patientIndex: number, serviceIndex: number) {
    this.patientIndex = patientIndex;
    this.serviceIndex = serviceIndex;
    this.confirmServiceDelete.show();

  }

  deleteService(patientIndex: number, serviceIndex: number) {
    const patientFormArray = this.getPatientsControls()?.at(patientIndex) as FormArray;
    const servicesFormArray = patientFormArray.get('serviceLinesInfo') as FormArray;
    servicesFormArray.removeAt(serviceIndex);
  }

  onBlurMethod() {
    this.clearForm();
  }

  public clearForm(): void {

  }

  markAllAsTouched(formGroup: FormGroup | FormArray) {
    Object.values(formGroup.controls).forEach(control => {
      if (control instanceof FormGroup || control instanceof FormArray) {
        this.markAllAsTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  public changeCptCodes(event: any, patientIndex: number, serviceIndex: number): void {
    // this.invoiceForm = this.claimSubmissionFrom.get('claimInformation') as FormArray;
    // this.invoiceForm?.controls[index]?.get('professionalService')?.get('placeOfServiceCode')?.setValue(event?.placeOfService?.trim());
    // this.invoiceForm?.get('memberActivityDtoList')?[index]?.get('serviceLinesInfo')?.setValue(event?.cptCode);
    const patientFormArray = this.invoiceForm.get('memberActivityDtoList') as FormArray;
    const patientFormGroup = patientFormArray.at(patientIndex) as FormGroup;
    const servicesFormArray = patientFormGroup.get('serviceLinesInfo') as FormArray;
    const serviceFormGroup = servicesFormArray.at(serviceIndex) as FormGroup;
    serviceFormGroup.get('cptCode')?.setValue(event?.cptCode.trim());
    serviceFormGroup.get('description')?.setValue(event?.codeDescription.trim());
  }

  // Documents upload
  public uploadFileSelect(event: any): void {
    if (event.target.files.length > 0) {
      const files = event.target.files;
      Array.from(files)?.forEach((fileDoc: any) => {
        const fileSize = fileDoc?.size / 1024 / 1024;
        if (fileSize <= 25 && (fileDoc?.type === 'application/pdf' || fileDoc?.type === 'image/png' || fileDoc?.type === 'image/jpeg')) {
          if (!this.documentsList?.find((item: any) => item?.name === fileDoc?.name)) {
            this.documentsList?.push(fileDoc);
          }
        }
        else {
          if (this.documentsList && this.documentsList?.length === 0) {
            this.documentsList = [];
          }
          if (this.fileUpload) {
            this.fileUpload.nativeElement.value = "";
          }
          this.notifyText = "Supports only PDF, PNG, JPEG files and Files larger than 25MB cannot be uploaded";
          this.exceptionalModal = true;
          return;
        }
      });
    }
  }

  // Confirmation popup
  public deleteConfirmationDocs(index: number, doc: any, isReadOrUpload: string, documentDeleteConfirmation: UiModalComponent): void {
    this.deletedDocIndex = index;
    this.deleteSelectedDoc = doc;
    this.deleteDocsFlag = isReadOrUpload;
    documentDeleteConfirmation?.show();
  }

  // Delete selected file after upload
  public deleteSelectedFile(index: number): void {
    this.documentsList?.splice(index, 1);
    if (this.fileUpload) {
      this.fileUpload.nativeElement.value = "";
    }
  }

  public deleteReadSelectedFile(index: number): void {
    this.readDocumentsList?.splice(index, 1);
  }

  public deleteDocFromConfirm(documentDeleteConfirmation: UiModalComponent): void {
    if (this.deleteDocsFlag === 'upload') {
      this.deleteSelectedFile(this.deletedDocIndex);
    } else {
      this.deleteReadSelectedFile(this.deletedDocIndex);
    }
    documentDeleteConfirmation?.hide();
  }

  // Clear File Upload
  public clearFileUpload(): void {
    if (this.fileUpload) {
      this.fileUpload.nativeElement.value = "";
    }
  }

  // Upload the Additonal Uploaded Documents
  private uploadAdditionalDocuments(uploadFile?: any) {
    const formData = new FormData();
    const timestamp = this.getCurrentTimeStamp();
    const isFile = true;
    const documentName = 'Invoice' + "_" + timestamp + "_" + uploadFile?.name;
    let actionAppend = '';

    formData.append('idFile', uploadFile);
    // ${this.sendNewFaxForm?.value?.patientName ? this.sendNewFaxForm?.value?.patientName?.trim() + '_' : ''}${uploadFile?.name}
    actionAppend = `record/addDocument?documentName=${documentName || uploadFile?.name}&documentType=Invoice&isFile=${isFile}&fileType=${uploadFile?.type}`;
    const action = actionAppend;
    this.loadSpinner = true;
    return {
      action,
      formData
    }
  }

  public getCurrentTimeStamp() {
    const current = new Date();
    current.setHours(0)
    current.setMinutes(0)
    current.setSeconds(0)
    current.setMilliseconds(0)
    const timestamp = current.getTime();
    return timestamp;
  }

  getExpand(icon: any, index: any) {
    if (icon == 'more') {
      let expandMore: any = document.getElementById(`expandmore_${index}`) || null;
      expandMore.style.display = 'none';
      let expandLess: any = document.getElementById(`expandless_${index}`) || null;
      expandLess.style.display = 'block';

      const patientsArray = this.invoiceForm.get('memberActivityDtoList') as FormArray;
      patientsArray.controls.forEach((patientControl: AbstractControl) => {
        if (patientControl instanceof FormGroup) {
          const servicesArray = patientControl.get('serviceLinesInfo') as FormArray;
          servicesArray.controls.forEach((serviceControl: AbstractControl, i: any) => {
            if (serviceControl instanceof FormGroup) {
              let services: any = document.getElementById(`services_${index}${i}`) || null;
              services.style.visibility = 'collapse';
              // Calculate the product of units and service cost and add to invoiceTotal
            }
          });
        }
      });
    } else {
      let expandMore: any = document.getElementById(`expandmore_${index}`) || null;
      expandMore.style.display = 'block';
      let expandLess: any = document.getElementById(`expandless_${index}`) || null;
      expandLess.style.display = 'none';
      const patientsArray = this.invoiceForm.get('memberActivityDtoList') as FormArray;
      patientsArray.controls.forEach((patientControl: AbstractControl) => {
        if (patientControl instanceof FormGroup) {
          const servicesArray = patientControl.get('serviceLinesInfo') as FormArray;
          servicesArray.controls.forEach((serviceControl: AbstractControl, i: any) => {
            if (serviceControl instanceof FormGroup) {
              let services: any = document.getElementById(`services_${index}${i}`) || null;
              services.style.visibility = 'visible';
              // Calculate the product of units and service cost and add to invoiceTotal
            }
          });
        }
      });
    }
  }
  // make Org and Submitter as Default values
  public defaultOrgVals(): void {
    if (sessionStorage?.getItem('submitterOrgDetails')) {
      const orgDetails = JSON?.parse(sessionStorage?.getItem('submitterOrgDetails') || '');
      this.invoiceForm?.get('organizationName')?.setValue(orgDetails?.organizationName || null);
      this.invoiceForm?.get('orgId')?.setValue(orgDetails?.orgId || null);
      this.invoiceForm?.get('submitterName')?.setValue((`${orgDetails?.firstName} ${orgDetails?.lastName}`) || null);
      this.invoiceForm?.get('email')?.setValue(orgDetails?.email || null);
      if (orgDetails?.orgAddress) {
        this.invoiceForm?.get('orgAddress')?.patchValue(orgDetails?.orgAddress);
      }
    }
  }



  getInvoiceDetails(id: any) {

    let action = `healthWorks/getInvoiceDetails?invoiceMasterId=${id}`;
    this.loadSpinner = true;
    this.externalService.makeRequestWithAction(action).subscribe(res => {
      this.loadSpinner = false;
      if (res) {
        let responseObject = res.responseObject || {};
        let invoiceMaster = responseObject?.invoiceMaster || {};
        this.status = responseObject.invoiceMaster.invoiceStatus
        this.invoiceForm.get('invoiceId')?.setValue(responseObject?.invoiceMaster?.invoiceId);
        this.invoiceForm.get('invoiceName')?.setValue(responseObject?.invoiceMaster?.invoiceName);
        this.invoiceForm.get('referenceId')?.setValue(responseObject?.invoiceMaster?.referenceId);
        this.invoiceForm.get('referralId')?.setValue(responseObject?.invoiceMaster?.referralId);
        this.invoiceForm.get('comments')?.setValue(responseObject?.invoiceMaster?.comments);
        this.invoiceForm.get('email')?.setValue(responseObject?.invoiceMaster?.email);
        this.invoiceForm.get('facilityId')?.setValue(responseObject?.invoiceMaster?.facilityId);
        this.invoiceForm.get('organizationName')?.setValue(responseObject?.invoiceMaster?.organizationName);
        this.invoiceForm.get('submitterName')?.setValue(responseObject?.invoiceMaster?.submitterName);
        this.invoiceForm.get('orgId')?.setValue(responseObject?.invoiceMaster?.orgId);
        this.invoiceForm.get('billedAmount')?.setValue(responseObject?.invoiceMaster?.billedAmount);
        this.invoiceForm.get('invoiceStatus')?.setValue(responseObject?.invoiceMaster?.invoiceStatus);
        this.invoiceForm.get('id')?.setValue(responseObject?.invoiceMaster?.id);
        if (!this.duplicateInvoice) {
          this.invoiceForm.get('documentKeys')?.setValue(responseObject?.invoiceMaster?.documentKeys);
          this.readDocumentsList = responseObject?.invoiceMaster?.documentKeys;
        }
        let memberActivityList = responseObject?.memberActivityList;
        const patientFormArray = this.invoiceForm.get('memberActivityDtoList') as FormArray;
        memberActivityList.forEach((ele: any, i: any) => {
          // patientFormArray.controls[i].get('dob')?.setValue(ele.dob);
          patientFormArray.push(this.createPatientFormGroup(ele));
          let serviceList = ele.serviceLinesInfo;
          serviceList.forEach((ele: any, j: any) => {
            let serviceInfo = patientFormArray.controls[i].get('serviceLinesInfo') as FormArray;
            if (serviceList && serviceList.length > 0) {
              serviceInfo.push(this.createServiceFormGroup(ele));
            }
            // serviceInfo.removeAt(0);
          })
          let serviceInfo = patientFormArray.controls[i].get('serviceLinesInfo') as FormArray;
          serviceInfo.removeAt(0);
        })
        //   if(memberActivityList.length >0){
        //   memberActivityList.forEach((ele:any,i:any) =>{
        //     patientFormArray.value.forEach((data:any,index:any) =>{
        //         patientFormArray.controls[index].get('serviceStartDate')?.patchValue(new Date(ele.serviceStartDate));
        //     })
        //   })
        // }

      }
    }, (error: any) => {
      this.loadSpinner = false;
      this.notifyText = error?.message || 'Something went wrong please try again later.';
      this.exceptionalModal = true;
      // this.updateUserdataCard?.cardRefreshHide();
    });
  }

  // upload Additional documents
  public openUploadUniversalDocuments(patientDetails: any, index: number): void {
    const getFormGroup = this.getPatientsControls()?.at(index) as FormGroup;
    if (!patientDetails?.userInfoId) {
      this.notifyText = 'Please choose patient first to upload documents.';
      this.exceptionalModal = true;
      this.isSuccessNotify = false;
      return;
    }
    this.selectedUserForAddDocs = getFormGroup as FormGroup;
    patientDetails.id = patientDetails?.userInfoId;
    this.uploadDocument?.invoiceDocumentsOpen(patientDetails, 'Account', '');
  }

  // after upload additonal docs for user to save s3 path and s3 key
  public async getS3Path(event: any) {
    if (event?.errorMessage) {
      this.notifyText = event?.errorMessage;
      this.exceptionalModal = true;
      return;
    }
    this.notifyText = event?.successMessage;
    this.exceptionalModal = true;
    const action = `record/listIndividualDocument?documentType=${event?.documentType}&additionalKey=${event?.keyName}&userInfoId=${event?.userInfoId}`;
    const getDetails: any = await this.httpService?.makeGetRequest(action)?.toPromise();
    const existingDocsValue = this.selectedUserForAddDocs?.get('documentKeys')?.value || [];
    if (getDetails?.responseObject) {
      existingDocsValue?.push({
        "s3Key": getDetails?.responseObject?.s3Key,
        "name": getDetails?.responseObject?.documentName,
        "s3Path": getDetails?.responseObject?.s3Path,
        "fileType": getDetails?.responseObject?.fileType,
        "userInfoId": event?.userInfoId
      });
      this.selectedUserForAddDocs?.get('documentKeys')?.setValue(existingDocsValue);
    }
  }

  // Documents view
  public viewDownloadDocument(documentKeys: any, invoiceViewDocument: UiModalComponent) {
    let documentView: any;
    // this.allPatientDocsSelectedKeys = documentKeys || [];
    if (documentKeys && documentKeys?.length > 0) {
      documentView = documentKeys[0];
    }
    if (!documentView?.s3Key || !documentView?.s3Path) {
      this.notifyText = 'No documents available for this patient.';
      this.exceptionalModal = true;
      return;
    }
    this.selectedDocumentName = documentView?.documentName || documentView?.name;
    const fileType = documentView?.fileType;
    const action = `record/downloadDocument?s3Key=${documentView?.s3Key}&s3Path=${documentView?.s3Path}`;
    this.loadSpinner = true;
    this.httpService?.downloadImage(action)?.subscribe((data: any) => {
      if (!data) {
        this.loadSpinner = false;
        return;
      }
      const file = new Blob([data], { type: fileType || 'application/pdf' })
      const fileURL = URL.createObjectURL(file);
      invoiceViewDocument?.show();
      setTimeout(() => {
        const iframeEle = document?.getElementById('invoice-create-patient-doc') as HTMLElement;
        iframeEle?.setAttribute('src', fileURL);
      }, 500);
      this.loadSpinner = false;
    },
      (error: any) => {
        this.notifyText = error?.message || 'Something went wrong please try again later.'
        this.exceptionalModal = true;
        this.loadSpinner = false;
      });
  };

  public viewDownloadDocumentMultiple(document: any, invoicePatientLevalDocumnets: UiModalComponent, index: number, allPatientDocsSelectedKeys: any): void {
    this.viewDownloadDocument([document], invoicePatientLevalDocumnets);
    this.allPatientDocsSelectedKeys = allPatientDocsSelectedKeys || [];
    this.docViewIndex = index;
  }

  // search patient name
  private loadPatient() {
    this.allPatientList = concat(
      of([]), // default items
      this.patientInput.pipe(
        distinctUntilChanged(),
        tap(() => this.patientNameLoading = true),
        switchMap(term => this.httpService?.patientTrackBy(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.patientNameLoading = false)
        ))
      )
    );
  }

  public trackByFnPaientName(item: any) {
    return item?.lastName;
  }

  // Uploaded doc view
  public viewDocumentUploaded(doc: any, index: number, invoicePatientLevalDocumnets: UiModalComponent): void {
    this.allPatientDocsSelectedKeys = [...this.readDocumentsList, ...this.documentsList];
    this.selectedRecord = index;
    if (doc?.s3Key && doc?.s3Path) {
      this.viewDownloadDocument([doc], this.invoicePatientLevalDocumnets);
      return;
    }
    this.selectedDocumentName = doc?.name;
    let file = new Blob([doc], { type: doc?.type || 'application/pdf' });
    let fileURL = URL?.createObjectURL(file);
    let iframeEle = null;
    iframeEle = document?.getElementById('invoice-create-patient-doc') as HTMLElement;
    iframeEle?.setAttribute('src', fileURL);
    invoicePatientLevalDocumnets?.show();
  }

  // Newxt Prev documents view
  public callNextPrev(action: any, record: any, invoicePatientLevalDocumnets: UiModalComponent) {
    let indexVal = record;
    this.selectedRecord = record;
    if (action === 'prev') {
      if (this.selectedRecord === 0) {
        indexVal = this.selectedRecord;
      } else {
        indexVal = this.selectedRecord - 1;
        this.selectedRecord = indexVal;
        if (this.allPatientDocsSelectedKeys[indexVal]?.s3Key && this.allPatientDocsSelectedKeys[indexVal]?.s3Path) {
          this.viewDownloadDocument([this.allPatientDocsSelectedKeys[indexVal]], invoicePatientLevalDocumnets);
        } else {
          this.selectedDocumentName = this.allPatientDocsSelectedKeys[indexVal]?.name;
          let file = new Blob([this.allPatientDocsSelectedKeys[indexVal]], { type: this.allPatientDocsSelectedKeys[indexVal]?.type || 'application/pdf' });
          let fileURL = URL?.createObjectURL(file);
          let iframeEle = null;
          iframeEle = document?.getElementById('invoice-create-patient-doc') as HTMLElement;
          iframeEle?.setAttribute('src', fileURL);
        }
      }
    } else {
      const arrLength = this.allPatientDocsSelectedKeys?.length;
      if (this.selectedRecord === (arrLength - 1)) {
        indexVal = this.selectedRecord;
      } else {
        indexVal = this.selectedRecord + 1;
        this.selectedRecord = indexVal;
        if (this.allPatientDocsSelectedKeys[indexVal]?.s3Key && this.allPatientDocsSelectedKeys[indexVal]?.s3Path) {
          this.viewDownloadDocument([this.allPatientDocsSelectedKeys[indexVal]], invoicePatientLevalDocumnets);
        } else {
          this.selectedDocumentName = this.allPatientDocsSelectedKeys[indexVal]?.name;
          let file = new Blob([this.allPatientDocsSelectedKeys[indexVal]], { type: this.allPatientDocsSelectedKeys[indexVal]?.type || 'application/pdf' });
          let fileURL = URL?.createObjectURL(file);
          let iframeEle = null;
          iframeEle = document?.getElementById('invoice-create-patient-doc') as HTMLElement;
          iframeEle?.setAttribute('src', fileURL);
        }
      }
    }
  }

  // User level docs view
  public userLevelDocsView(index: number, invoicePatientLevalDocumnets: UiModalComponent): void {
    const patient = this.getPatientsControls()?.at(index) as FormGroup;
    this.selectedViewPatientDocsForm = patient;
    this.isUserLevelDocsView = true;
    this.selectedRecord = 0;
    this.allPatientDocsSelectedKeys = patient?.value?.documentKeys || [];
    this.viewDownloadDocument(patient?.value?.documentKeys, invoicePatientLevalDocumnets);
  }

  // delete document
  public deleteSelectedUserDoc(selectedDoc: any) {
    if (!selectedDoc) {
      return;
    }
    const action = `record/deleteDocument?s3Key=${selectedDoc?.s3Key}&userInfoId=${selectedDoc?.userInfoId || this.selectedViewPatientDocsForm?.value?.userInfoId}`;
    this.loadSpinner = true;
    this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
      this.loadSpinner = false;
      if (data?.status === 'SUCCESS') {
        this.allPatientDocsSelectedKeys?.splice(this.selectedRecord, 1);
        this.selectedRecord = 0;
        this.selectedViewPatientDocsForm?.get('documentKeys')?.setValue(this.allPatientDocsSelectedKeys || []);
        if (this.allPatientDocsSelectedKeys?.length === 0) {
          this.invoicePatientLevalDocumnets?.hide();
        } else {
          this.viewDownloadDocument(this.allPatientDocsSelectedKeys, this.invoicePatientLevalDocumnets);
        }
        this.notifyText = data?.msg;
      } else {
        this.notifyText = data?.msg;
      }
      this.exceptionalModal = true;
    }, error => {
      this.loadSpinner = false;
      this.notifyText = error?.msg || error?.message;
      this.exceptionalModal = true;
    });
  }

  // Save and submit popup confirmation for duplcate invoice
  public duplicateSaveSubmit(saveSubmitFlag: string, duplicateInvoiceConfirm: UiModalComponent): void {
    if (this.invoiceForm?.invalid) {
      this.markAllAsTouched(this.invoiceForm);
      return;
    }
    const invoiceDetails = JSON.parse(JSON?.stringify(this.invoiceForm?.value))
    if (invoiceDetails?.memberActivityDtoList?.length === 0) {
      this.noPatientDetailsModal.show();
      return;
    }
    this.isDupSaveSubmitFlag = saveSubmitFlag;
    duplicateInvoiceConfirm?.show();
  }

}
