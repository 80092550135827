import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpService } from 'src/app/shared/http.service';

@Component({
  selector: 'app-cancel-appointment',
  templateUrl: './cancel-appointment.component.html',
  styleUrls: ['./cancel-appointment.component.scss']
})
export class CancelAppointmentComponent implements OnInit {


  public cancelAppointmentForm: UntypedFormGroup;
  public reasonOfCancel = [
    { desc: 'Time', value: 'Time' },
    { desc: 'Found alternate location / provider', value: 'Found alternate location / provider' },
    { desc: 'Too long a wait', value: 'Too long a wait' },
    { desc: 'No longer needed service', value: 'No longer needed service' },
    { desc: 'Other', value: 'Other' },
    { desc: 'I don’t want to specify', value: 'I don’t want to specify' }
  ]
  public cancelType = [
    { value: 'Reschedule', desc: 'Reschedule' },
    { value: 'Cancel', desc: 'Cancel' }
  ]
  public getAppointmentId: any;
  public appointmentDetails: any;
  public patientLastName: any;
  public messageShown: any;
  public apiStatus?: boolean;
  public showModalCancel?: boolean;
  public noRegistrations: any;
  public getParamId: any;
  public loadSpinner: boolean = false;
  public isOtherReason: boolean = false;

  constructor(private formBuilder: UntypedFormBuilder, private httpService: HttpService, private router: Router, private activeRoute: ActivatedRoute) {
    this.cancelAppointmentForm = this.formBuilder.group({
      'REGISTRATION_CODE': [null, Validators.required],
      "PATIENT_LAST_NAME": [null, Validators.required],
      'REASON_FOR_CANCEL': [null, Validators.required],
      'CHECK_VALID': [null],
      'REASON_TYPE': [null, Validators.required],
      // 'REASON_FOR_CANCEL_OTHER': [null],
    })
  }

  ngOnInit() {
    this.activeRoute.params
      .subscribe(
        (params: Params) => {
          const id = params['id'] || null;
          this.getParamId = id.toString();
          this.getAppointment(id);
          if (id) {
            this.cancelAppointmentForm?.get('REGISTRATION_CODE')?.setValue(id.toString());
            this.cancelAppointmentForm?.get('REGISTRATION_CODE')?.disable();
          }
        }
      );
    this.cancelAppointmentForm?.get('REASON_FOR_CANCEL')?.disable();
  }

  public checkCancelType(): void {
    const reasonType = this.cancelAppointmentForm?.get('REASON_TYPE')?.value;
    if (reasonType === 'Reschedule') {
      this.cancelAppointmentForm?.get('REASON_FOR_CANCEL')?.setValidators(null);
      this.cancelAppointmentForm?.get('REASON_FOR_CANCEL')?.disable();
    } else {
      this.cancelAppointmentForm?.get('REASON_FOR_CANCEL')?.setValidators([Validators.required]);
      this.cancelAppointmentForm?.get('REASON_FOR_CANCEL')?.enable();
    }
    this.cancelAppointmentForm?.updateValueAndValidity();
  }

  public async cancelAppointment() {
    this.loadSpinner = true;
    // let action: any = `appointment/cancel?id=${this.getParamId}&reason=${this.cancelAppointmentForm.value.REASON_FOR_CANCEL}`;
    const checkIs24hoursWindow = await this.httpService?.validate24HoursWindowCall(this.appointmentDetails?.id);
    if (checkIs24hoursWindow?.status === 'FAILED') {
      this.messageShown = checkIs24hoursWindow?.message;
      this.showModalCancel = true;
      this.loadSpinner = false;
      return;
    }
    if (!this.appointmentDetails) {
      const obj = {
        rescheduleId: this.getParamId,
        isReschedule: true
      }
      const facilityId = JSON.parse(sessionStorage.getItem('cancelRescheduleFacilityId') || '{}');
      sessionStorage.setItem('isReschedule', JSON.stringify(obj));
      this.router.navigate([`${facilityId}/scheduleappt`], {
        queryParams: {
          id: this.getParamId
        }
      });
      return;
    }

    const reasonType = this.cancelAppointmentForm?.value?.REASON_FOR_CANCEL;
    let cancelReason = null;
    if (reasonType === 'Other') {

      cancelReason = this.cancelAppointmentForm?.value?.REASON_FOR_CANCEL_OTHER || 'Rescheduling the appointment';
    }
    else {
      cancelReason = this.cancelAppointmentForm?.value?.REASON_FOR_CANCEL || 'Rescheduling the appointment';
    }

    let action: any = `appointment/registration/cancel?id=${this.getParamId}&reason=${cancelReason}`;
    const checkType = this.cancelAppointmentForm?.get('REASON_TYPE')?.value === 'Reschedule' ? false : true;
    if (!checkType) {
      // if(this.appointmentDetails?.appointmentStatus?.currentStatus === 'CANCELLED'){
      //   this.messageShown = 'Your already Re-Scheduled/Cancelled your appointment';
      //   this.showModalCancel = true;
      // }
      const obj = {
        rescheduleId: this.getParamId,
        isReschedule: true
      }
      const facilityId = JSON.parse(sessionStorage.getItem('cancelRescheduleFacilityId') || '{}');
      sessionStorage.setItem('isReschedule', JSON.stringify(obj));
      this.router.navigate([`${facilityId}/scheduleappt`], {
        queryParams: {
          id: this.getParamId
        }
      });
      return;
    }

    this.loadSpinner = true;
    this.httpService.makeRequestWithAction(action)
      .subscribe((data: any) => {
        if (data.status !== 'FAILED') {
          this.apiStatus = true;
          if (checkType) {
            this.messageShown = 'Your Appointment has been Cancelled Successfully';
            this.showModalCancel = true;
          } else {
            const obj = {
              rescheduleId: this.getParamId,
              isReschedule: true
            }
            const facilityId = JSON.parse(sessionStorage.getItem('cancelRescheduleFacilityId') || '{}');
            sessionStorage.setItem('isReschedule', JSON.stringify(obj));
            this.router.navigate([`${facilityId}/scheduleappt`], {
              queryParams: {
                id: this.getParamId
              }
            });
          }
        } else {
          this.apiStatus = false;
          this.messageShown = data.msg;
          this.showModalCancel = true;
        }
        this.loadSpinner = false;
      },
        (error?: any) => {
          this.loadSpinner = false;
        });
  }

  public checkLastName(): void {
    if (this.patientLastName) {
      const name = this.patientLastName?.toLowerCase();
      const enterName = this.cancelAppointmentForm?.value?.PATIENT_LAST_NAME;
      if (name === (enterName && enterName?.toLowerCase())) {
        this.cancelAppointmentForm?.get('CHECK_VALID')?.setValidators(null);
      } else {
        this.cancelAppointmentForm?.get('CHECK_VALID')?.setValidators([Validators.required]);
      }
      this.cancelAppointmentForm?.get('CHECK_VALID')?.updateValueAndValidity();
    } else {
      this.cancelAppointmentForm?.get('CHECK_VALID')?.setValidators([Validators.required]);
    }
  }

  public getAppointment(id: any): void {
    // let action: any = `appointment/getById?id=${id}`;
    let action: any = `appointment/registration/getAppointmentById?id=${id}`;

    this.loadSpinner = true;
    this.httpService.makeGetRequest(action)
      .subscribe((data: any) => {
        this.appointmentDetails = data.responseObject || null;
        if (this.appointmentDetails) {
          this.patientLastName = this.appointmentDetails.lastName || null;
          sessionStorage.setItem('cancelRescheduleFacilityId', JSON.stringify(this.appointmentDetails.facilityId));
        }
        this.loadSpinner = false;
        if (!data.responseObject) {
          this.noRegistrations = data.message;
          this.cancelAppointmentForm?.get('PATIENT_LAST_NAME')?.disable();
          this.cancelAppointmentForm?.get('REGISTRATION_CODE')?.disable();
          this.cancelAppointmentForm?.get('REASON_FOR_CANCEL')?.disable();
        }
      },
        (error: any) => {
          this.loadSpinner = false;
          this.noRegistrations = error?.message;
        });
  }

  public navigateHomePage(): void {
    if (this.apiStatus) {
      this.router.navigate(['/home']);
      this.showModalCancel = false;
    } else {
      this.showModalCancel = false;
    }
  }

  public cancelReasonSection(event: any) {
    if (event.value === "Other") {
      this.isOtherReason = true;
      //this.cancelAppointmentForm?.addControl('REASON_FOR_CANCEL_OTHER', new FormControl(Validators.required));
      this.cancelAppointmentForm.addControl('REASON_FOR_CANCEL_OTHER', new UntypedFormControl(null, Validators.required));
    }
    else {
      this.isOtherReason = false;
      // this.cancelAppointmentForm?.addControl('REASON_FOR_CANCEL_OTHER', new FormControl(null));
      this.cancelAppointmentForm.removeControl('REASON_FOR_CANCEL_OTHER');

    }
  }


}
